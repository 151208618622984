import { BsFillEyeFill, BsReplyFill } from "react-icons/bs";
import { FaLinkedin, FaTwitter, FaPhone, FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { GiClick } from "react-icons/gi";
import ModalComponenet from "../../../Componenets/ModalComponenet";
import { extractBodyContentFromEmail } from "../../../Utils/HtmlParser";
import extractDateTime from "../../../Utils/Timeconvert";
import NewModalUi from "../../../Componenets/NewModalUi";
import { useState } from "react";

export const columns = [
  {
    Header: "Name",
    accessor: "firstName",
    Cell: ({ value }) => {
      return (
        <div className="name-div">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#00BFD9",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              fontSize: "12px",
              fontWeight: "500",
              color: "white",
            }}
          >
            {value?.charAt(0)}
            {value?.indexOf(" ") !== -1 &&
              value?.charAt(value.lastIndexOf(" ") + 1)}
          </div>
          <h1 className="ml-2">{value}</h1>
        </div>
      );
    },
  },
  {
    Header: "Company",
    accessor: "companyName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Job Tittle",
    accessor: "jobTitle",
    Cell: ({ value }) => (
      <div>
        <h1>{value}</h1>
      </div>
    ),
  },
  {
    Header: () => <div style={{ textAlign: "center" }}>Social</div>,
    accessor: "linkedinUrl",

    Cell: ({ row }) => (
      <div className="flex justify-center">
        {row?.original?.linkedinUrl ? (
          <FaLinkedin
            title={row?.original?.linkedinUrl}
            className="mr-2"
            size={17}
            color={"#0077B5"}
            onClick={(e) => {
              window.open(row?.original?.linkedinUrl, "_blank");
              e.stopPropagation();
            }}
          />
        ) : (
          <FaLinkedin className="mr-2" size={17} color={"#D3D3D3"} />
        )}

        {row?.original?.twitterUrl && (
          <FaTwitter
            title={row?.original?.twitterUrl}
            className="mr-2"
            size={17}
            color={"#1DA1F2"}
            onClick={(e) => {
              e.stopPropagation();
              window.open(row?.original?.twitterUrl, "_blank");
            }}
          />
        )}

        {row?.original?.phoneNumber ? (
          <FaPhone
            title={row?.original?.phoneNumber}
            className="mr-2"
            size={17}
            color={row.original.phone ? "#128C7E" : "#D3D3D3"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        ) : (
          <FaPhone className="mr-2" size={17} color={"#D3D3D3"} />
        )}

        {row?.original?.whatsappNumber ? (
          <FaWhatsapp
            title={row?.original?.whatsappNumber}
            className="mr-2"
            size={17}
            color={"#25D366"}
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `https://wa.me/${row?.original?.whatsappNumber}`,
                "_blank"
              );
            }}
          />
        ) : (
          <FaWhatsapp className="mr-2" size={17} color={"#D3D3D3"} />
        )}
      </div>
    ),
  },
  {
    Header: () => <div style={{ textAlign: "center" }}>Stats</div>,
    accessor: "emailTracks",
    id: "openModall",
    Cell: ({ row }) => {
      const emailTracks = row.original?.emailTracks;
      const openRateSum = emailTracks?.reduce(
        (acc, curr) => acc + (curr?.openRate ?? 0),
        0
      );
      const clickRateSum = emailTracks?.reduce(
        (acc, curr) => acc + (curr?.clickRate ?? 0),
        0
      );
      const replyRate = emailTracks?.reduce(
        (acc, curr) =>
          acc + (curr?.replies?.length ? curr?.replies?.length : 0),
        0
      );

      const [showModal, setShowModal] = useState(false);
      const [showClickModal, setShowClickModal] = useState(false);
      const [isContentShown, setIsContentShown] = useState(true);

      const colors = ["#00BFD9", "#4926BD", "#4926BD"];
      // const color = colors[Math.floor(Math.random() * colors.length)];

      return (
        <div className="flex justify-center">
          <div className="bg-[#03C797] flex mr-2 relative justify-center items-center w-[25px] h-[25px] rounded-full">
            <HiOutlineMail size={16} color={"#fff"} />
            <div className="bg-[#008D67] flex absolute right-[-6px] top-[-8px] justify-center items-center w-[15px] h-[15px] rounded-full">
              <p className="text-white text-[12px] font-medium">
                {emailTracks?.length ? emailTracks?.length : 0}
              </p>
            </div>
          </div>

          <div
            className="bg-[#F1DAF2] flex relative mr-4 justify-center items-center w-[25px] h-[25px] rounded-full"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsFillEyeFill className=" " size={16} color={"#E8A3E7"} />
            <div className="bg-[#008D67] flex absolute right-[-6px] top-[-8px] justify-center items-center w-[15px] h-[15px] rounded-full">
              <p className="text-white text-[12px] font-medium">
                {openRateSum ? openRateSum : 0}
              </p>
            </div>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
              if (replyRate) {
                setShowModal(true);
              }
            }}
            className="bg-[#C5EDEE] flex mr-4 relative cursor-pointer justify-center items-center w-[25px] h-[25px] rounded-full"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsReplyFill className=" " size={16} color={"#3ECBC9"} />
            <div className="bg-[#008D67] flex absolute right-[-6px] top-[-8px] justify-center items-center w-[15px] h-[15px] rounded-full">
              <p className="text-white text-[12px] font-medium">
                {replyRate ? replyRate : 0}
              </p>
            </div>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
              if (clickRateSum) {
                setShowClickModal(true);
              }
            }}
            className="bg-[#03C797] cursor-pointer flex mr-2 relative justify-center items-center w-[25px] h-[25px] rounded-full"
          >
            <GiClick size={16} color={"#fff"} />
            <div className="bg-[#008D67] flex absolute right-[-6px] top-[-8px] justify-center items-center w-[15px] h-[15px] rounded-full">
              <p className="text-white text-[12px] font-medium">
                {clickRateSum ? clickRateSum : 0}
              </p>
            </div>
          </div>

          {/* reply modal  */}

          {showModal && (
            <ModalComponenet
              radious={"12px"}
              title={"Replied :"}
              setActiveModal={setShowModal}
              width={"60vw"}
              background={""}
            >
              <div className="p-4 text-[15px]">
                <div className=" flex justify-between border-b-2 border-border-gray pb-2">
                  <div className="flex">
                    <div>
                      <div
                        onClick={() =>
                          setIsContentShown((prevState) => !prevState)
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#00BFD9",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "white",
                          cursor: "pointer",
                          textTransform: "uppercase",
                        }}
                      >
                        {row?.original?.email && row?.original?.email.charAt(0)}
                      </div>
                    </div>
                    <div className="ml-2">
                      <h1
                        className="cursor-pointer font-semibold"
                        onClick={() =>
                          setIsContentShown((prevState) => !prevState)
                        }
                      >
                        {row?.original?.mailBoxEmail}
                      </h1>
                      <h1>
                        {extractBodyContentFromEmail(
                          emailTracks[0]?.replies[0]?.emailBody
                        )}
                      </h1>
                    </div>
                  </div>

                  <div>
                    {extractDateTime(emailTracks[0]?.replies[0]?.reply)}
                  </div>
                </div>

                {emailTracks?.map((track) =>
                  track.replies?.map((reply) => {
                    const index = reply?.reply?.indexOf("On");
                    const replyText =
                      index !== -1
                        ? reply?.reply?.substring(0, index)
                        : reply.reply;
                    return (
                      <div
                        key={reply._id}
                        className="mt-3 flex justify-between border-b-2 border-border-gray pb-2"
                      >
                        <div className="flex">
                          <div>
                            <div
                              onClick={() =>
                                setIsContentShown((prevState) => !prevState)
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#00BFD9",
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              {row?.original?.firstName &&
                                row?.original?.firstName.charAt(0)}
                            </div>
                          </div>
                          <div className="ml-2">
                            <h1 className="cursor-pointer font-semibold">
                              {track?.receiverEmail}{" "}
                            </h1>
                            <h1>{replyText}</h1>
                          </div>
                        </div>
                        <div>{extractDateTime(reply?.reply)}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </ModalComponenet>
          )}

          {/* //click modal  */}

          <NewModalUi
            showModal={showClickModal}
            setCloseModal={setShowClickModal}
            width={"40vw"}
          >
            <div className=" text-[15px]">
              {emailTracks?.map((track) =>
                track?.clickedLinks?.map((click) => {
                  return (
                    <div className="bg-[#f7f7f7] p-2 mt-2 rounded-[5px] flex items-center justify-between">
                      <a
                        href={click.link}
                        rel="noreferrer"
                        target="_blank"
                        className="block color-active"
                      >
                        {click?.link}
                      </a>
                      <h1 className="bg-[#3dcc85] px-[5px] py-[2px] text-white rounded-[3px]">
                        {click?.clickedCount}
                      </h1>
                    </div>
                  );
                })
              )}
            </div>
          </NewModalUi>

          {/* {showClickModal && (
            <ModalComponenet
              radious={"8px"}
              title={"Clicked :"}
              setActiveModal={setShowClickModal}
              width={"40vw"}
              background={""}
            >
              <div className="p-4 text-[15px]">
                {emailTracks?.map((track) =>
                  track?.clickedLinks?.map((click) => {
                    return (
                      <div className="bg-[#f7f7f7] p-2 mt-2 rounded-[5px] flex items-center justify-between">
                        <a
                          href={click.link}
                          rel="noreferrer"
                          target="_blank"
                          className="block color-active"
                        >
                          {click?.link}
                        </a>
                        <h1 className="bg-[#3dcc85] px-[5px] py-[2px] text-white rounded-[3px]">
                          {click?.clickedCount}
                        </h1>
                      </div>
                    );
                  })
                )}
              </div>
            </ModalComponenet>
          )} */}
        </div>
      );
    },
  },
];
