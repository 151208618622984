import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Social from "../../Shared/SocialLogin/Social";
import imagere from "../../../Images/15-min_prev_ui.png";
import { AuthContext } from "../../../Hooks/Authcontext";
import Loading from "../../Shared/Loading/Loading";
import { userLogin } from "../../../Services/apiServices";
import Logger from "../../../Helpers/Logger";
import { toast } from "react-toastify";
const logger = new Logger("Login.jsx");
const Login = () => {
  const navigate = useNavigate();
  const { setLogged, isLogged } = useContext(AuthContext);
  const [loginLoading, setloginLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    try {
      setloginLoading(true);
      let response = await userLogin(data);
      if (response?.data) {
        if (response.data.auth && response.data.token) {
          localStorage.setItem("token", response.data.token);
          navigate("/");
          setLogged(true);
        } else {
          toast.error(response?.data?.msg);
          setloginLoading(false);
          reset();
        }
        setloginLoading(false);
      }
    } catch (error) {
      if (error) {
        setloginLoading(false);
        toast.error("Technical Error!");
      }
      logger.error(error);
    }
  };

  useEffect(() => {
    if (isLogged) {
      navigate("/");
    }
  }, [isLogged, navigate]);

  if (loginLoading) {
    return <Loading text={"Please Wait ..."}></Loading>;
  }

  return (
    <div className=" conatiner mx-auto pt-[10rem]">
      <div className="max-w-[500px] mx-auto bg-white   shadow-deep  flex items-center rounded-[10px]">
        {/* input section  */}
        <div className=" w-full  h-full flex justify-center items-center py-12 px-8">
          <div className="w-full">
            <div className="mb-5">
              <span className="text-[#737791] text-[12px] tracking-[1px]">
                START FOR FREE
              </span>
              <h1 className="text-[#05004E] text-[20px] font-semibold">
                Log In Here
              </h1>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* email */}
              <div className="form-control mt-5">
                <label className="label">
                  <span className="label-text text-[.875rem] font-semibold">
                    Email*
                  </span>
                </label>
                <input
                  type="email"
                  placeholder="your email"
                  className="input-customize"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "email reQuired",
                    },
                  })}
                />
              </div>

              {/* password  */}
              <div className="form-control mt-5">
                <label className="label">
                  <span className="label-text text-[.875rem] font-semibold">
                    Password*
                  </span>
                </label>
                <input
                  type="password"
                  placeholder="your password"
                  className="input-customize"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "password is  reQuired",
                    },
                  })}
                />
              </div>

              <button
                type="submit"
                className="w-full mt-8 rounded-[4px] bg-[#5d5fef] h-[40px] text-[#fff]"
              >
                Login
              </button>
            </form>
            <Social setloginLoading={setloginLoading} className="mt-5"></Social>
            <div className="flex items-center text-center justify-center mt-2">
              <h1 className="text-muted text-sm">Dont Have account ?</h1>{" "}
              <span
                onClick={() => navigate("/registration")}
                className="text-muted font-bold text-sm cursor-pointer"
              >
                {" "}
                Sign Up
              </span>
            </div>
          </div>
        </div>

        {/* animation section    */}
        {/* <div className="w-[50%]   h-full hidden md:flex justify-center items-center">
          <div className="w-fit mx-auto">
            <img src={imagere} alt="" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
