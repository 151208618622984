
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GetUser from "../../../Hooks/GetUser";
import { UserContext } from "../../../Hooks/UserContext";
import { addMailbox } from "../../../Services/apiServices";
import Loading from "../../Shared/Loading/Loading";
import VerifyTokenModal from "./VerifyTokenModal";

const AddEmail = () => {
  const [activeVerifyModal, setActiveVerifyModal] = useState(false);
  const [responseType, setResponseType] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setRefetch, setRefetchMailbox } = useContext(UserContext);

  const [pathName, setPathname] = useState("");
  const navigate = useNavigate();

  const user = GetUser();

  useEffect(() => {
    const pathName = localStorage.getItem("importLocation");
    if (pathName) {
      setPathname(pathName);
    }
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const googleId = urlParams.get("googleId");
    const refreshToken = urlParams.get("refreshToken");

    async function addEmailtoMailbox() {
      const payload = {
        userId: user,
        email: email,
        googleId: googleId,
        refreshToken: refreshToken,

      };

      let response = await addMailbox(payload);

      if (response.data.status) {
        setLoading(false);
        setResponseType(true);
        setLoading(false);
        setRefetchMailbox(prevState => !prevState)
        setActiveVerifyModal(true);
      } else {
        setLoading(false);
        setResponseType(false);
        setActiveVerifyModal(true);
      }
    }
    if (email && googleId && refreshToken && user) {
      addEmailtoMailbox();
    }
  }, [setRefetchMailbox, user]);

  useEffect(() => {
    if (activeVerifyModal) {
      const timerId = setTimeout(() => {
        setActiveVerifyModal(false);

        if (pathName.includes("sequence")) {
          navigate(pathName);
          toast.success("email added succesfully");
        } else {
          navigate("/setting/mailboxes");
        }
        setRefetch((prev) => !prev);
      }, 5000);
      return () => clearTimeout(timerId);
    }
  }, [activeVerifyModal, navigate, pathName, setRefetch]);

  if (loading) {
    return <Loading changeLoader="changeLoader"></Loading>;
  }

  return (
    <div>
      {activeVerifyModal && (
        <VerifyTokenModal
          responseType={responseType}
          setActiveVerifyModal={setActiveVerifyModal}
        />
      )}
    </div>
  );
};

export default AddEmail;
