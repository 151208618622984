import React, { useContext, useEffect, useState } from "react";
import { MdOutlineLock } from "react-icons/md";
import GetUser from "../../../Hooks/GetUser";
import Loading from "../../Shared/Loading/Loading";
import PasswordUpdateModal from "./PasswordUpdateModal";
import { updateUserInfo, userData } from "../../../Services/apiServices";
import { Button } from "@mui/material";
import Select from "react-select";
import modifiedTimeZoneObjects from "../../../Utils/TimeZone";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { UserContext } from "../../../Hooks/UserContext";

const General = () => {
  const [loader, setLoader] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [showPasswordModal, setShowpasswordModal] = useState(false);
  const [name, setName] = useState("");
  const [reFetch, setRefetch] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const { setFetchUserData } = useContext(UserContext);
  const user = GetUser();

  useEffect(() => {
    setLoader(true);
    async function getUserinfo() {
      const payload = {
        user: user,
      };

      let response = await userData(payload);

      if (response) {
        setUserInfo(response.data.data);
        setName(response.data.data.name);
        setSelectedTimeZone(
          modifiedTimeZoneObjects.find(
            (timeZone) => timeZone.value === response.data.data?.timeZone
          )
        );
        setLoader(false);
      }
    }

    getUserinfo();
  }, [user, reFetch]);

  const handleFirstName = (event) => {
    setName(event.target.value);
  };

  // handle Submit
  const handleSubmit = async () => {
    setSubmitSpinner(true);
    const payload = {
      userId: user,
      timeZone: selectedTimeZone?.value,
      name: name,
    };

    try {
      let response = await updateUserInfo(payload);
      if (response.data.success) {
        setSubmitSpinner(false);
        toast.success(response.data.message);
        setFetchUserData((prevState) => !prevState);
      }
    } catch (error) {
      setSubmitSpinner(false);

      toast.error("An error occurred while updating user info");
    }
  };

  if (loader) {
    return <Loading whiteLoader="whiteLoader"></Loading>;
  }

  return (
    <div>
      {showPasswordModal && (
        <PasswordUpdateModal
          setRefetch={setRefetch}
          userInfo={userInfo}
          setShowpasswordModal={setShowpasswordModal}
        ></PasswordUpdateModal>
      )}
      <div className="w-[50%] bg-white shadow-primaryCustom  rounded-[10px]   ">
        <div className="border-b border-[#d3dae3]  p-4">
          <h1 className="primary-heading-color text-[14px] font-semibold">
            Account Info
          </h1>
        </div>

        <div className="p-4">
          <div className="flex gap-6 mt-2 items-center">
            <div className="flex-1">
              <label
                htmlFor="sequenceName"
                className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
              >
                Email
              </label>
              <h1 className="text-[13px]   flex items-center  pl-2 focus:outline-none border rounded-[5px] h-[2.2rem] border-[#d3dae3] hover:border-blue-400">
                {userInfo?.email}
              </h1>
            </div>

            <div className="flex-1">
              <label
                htmlFor="sequenceName"
                className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
              >
                Password
              </label>
              <div className="text-[13px]   flex items-center  pl-2 focus:outline-none border rounded-[5px] h-[2.2rem] border-[#d3dae3] hover:border-blue-400">
                <div
                  className="flex cursor-pointer "
                  onClick={() => setShowpasswordModal(true)}
                >
                  <MdOutlineLock className="icon-color text-[18px] hover:text-blue-400"></MdOutlineLock>
                  <span className="ml-2 font-medium text-[12px]  hover:text-blue-400">
                    Change Password
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-6 mt-6">
            <div className="flex-1">
              <label
                htmlFor="sequenceName"
                className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
              >
                Name
              </label>
              <input
                defaultValue={name}
                onChange={handleFirstName}
                id="sequenceName"
                type="text"
                className="w-full text-[13px]  primary-heading-color font-medium  pl-2 focus:outline-none border rounded-[5px] h-[2.2rem] border-[#d3dae3] focus:border-blue-400"
              />
            </div>
          </div>
          <div className="mt-6">
            <label className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block">
              TimeZone
            </label>
            <Select
              maxMenuHeight={160}
              menuPlacement={"top"}
              defaultValue={selectedTimeZone}
              onChange={setSelectedTimeZone}
              options={modifiedTimeZoneObjects}
              classNamePrefix="filter"
            />
          </div>

          <div className="mt-4">
            <LoadingButton
              className=" bg-[#583cea] btn"
              loading={submitSpinner}
              onClick={handleSubmit}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              Update
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
