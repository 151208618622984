import React, { useState } from "react";
import { UserContext } from "../Hooks/UserContext";

const ContextProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(false);
  const [activeStepModal, setActiveStepModal] = useState(false);
  const [activeProspectusModal, setActiveProspectusModal] = useState(false);
  const [sequenceId, setSequenceId] = useState("");
  const [reFetch, setRefetch] = useState(false);
  const [reFetchSeq, setRefetchSeq] = useState(false);
  const [stepData, setStepdata] = useState([]);
  const [prospectStats, setProspectStats] = useState({});
  const [showHelpModal, setHelpModal] = useState(false);
  const [filterBy, setFilterBy] = useState(null);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [channel, setchennel] = useState("Automated Email");
  const [openSequenceModal, setOpenSequenceModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [stepStartDate, setStepStartDate] = useState(null);
  const [fetch7step, setFetch7step] = useState({
    data: [],
    condition: false,
  });
  const [refetchMailbox, setRefetchMailbox] = useState(true);
  const [mailboxEmpty, setmailboxEmpty] = useState(false);
  const [importData, setImportData] = useState(null);
  const [importDataModal, setImportDataModal] = useState(false);

  const [closeBanner, setCloseBanner] = useState(
    JSON.parse(localStorage.getItem("closeBanner")) || false
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState();

  const [openListModal, setOpenListModal] = useState(false);
  const [fetchSequenceStep, setFetchSequenceStep] = useState(false);
  const [stepOneTimeZone, setStepOneTimeZone] = useState("");

  const [filterQuery, setFilterQuery] = useState({});
  const [fetchUserData, setFetchUserData] = useState(false);
  return (
    <UserContext.Provider
      value={{
        fetchUserData,
        setFetchUserData,
        selectedTimeZone,
        setSelectedTimeZone,
        filterQuery,
        setFilterQuery,
        openListModal,
        setOpenListModal,
        stepOneTimeZone,
        setStepOneTimeZone,
        fetchSequenceStep,
        setFetchSequenceStep,
        importDataModal,
        setImportDataModal,
        importData,
        setImportData,
        closeBanner,
        setCloseBanner,
        mailboxEmpty,
        setmailboxEmpty,
        setRefetchMailbox,
        refetchMailbox,
        setFetch7step,
        fetch7step,
        stepStartDate,
        setStepStartDate,
        setSelectedTemplate,
        selectedTemplate,
        openSequenceModal,
        setOpenSequenceModal,
        channel,
        setchennel,
        days,
        setDays,
        hours,
        setHours,
        filterBy,
        setFilterBy,
        activeStepModal,
        activeModal,
        showHelpModal,
        setHelpModal,
        prospectStats,
        setProspectStats,
        reFetchSeq,
        setRefetchSeq,
        setActiveProspectusModal,
        activeProspectusModal,
        stepData,
        setStepdata,
        sequenceId,
        setActiveModal,
        setActiveStepModal,
        setSequenceId,
        reFetch,
        setRefetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default ContextProvider;
