import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonComponent = () => {
    return (
        <div className="bg-white custom-shadow border border-gray p-4 mt-4">
            <div className="border-b-2 border-border-gray pb-1 flex justify-between items-center">
                <div className="flex items-center">
                    <div className="flex items-center">
                        <div className="flex justify-center items-center w-[30px] mr-4 h-[30px] bg-[#E9F2FF] rounded-full">
                            <Skeleton circle width={16} height={16} />
                        </div>
                        <h1 className="text-[13px] font-semibold primary-heading-color flex items-center">
                            <Skeleton width={80} />
                        </h1>
                    </div>
                    <div className="day color-gray text-[12px] ml-3 w-[45px] h-[30px] flex justify-center items-center">
                        <Skeleton width={30} />
                    </div>
                    <div>
                        {/* {index === 0 && <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />} */}
                    </div>
                    <div className="text-[12px] transition duration-200 ease-in-out color-gray ml-3 w-[6rem] h-[30px] flex justify-center items-center">
                        <Skeleton width={60} />
                    </div>
                    <div

                    >
                        <Skeleton width={16} height={16} />
                    </div>
                </div>
                <div className="cursor-pointer">

                    <Skeleton count={2} width={16} height={16} />


                </div>
            </div>
            <div style={{ display: "-webkit-box", WebkitBoxOrient: "vertical" }} className={`flex justify-between items-center py-6`}>
                <div className="flex">
                   
                    <h1 className="text-[14px] transition duration-200 ease-in-out text-[#000000] ml-4 font-semibold">
                        <Skeleton width={100} />
                    </h1>
                </div>
                <div className="text-overflow" style={{ width: "500px", height: "50px" }}>
                    <div className="color-gray text-overflow" style={{ display: "inline-block", width: "500px", height: "50px", overflow: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto", whiteSpace: "nowrap" }}>
                        <Skeleton width={400} />
                    </div>
                </div>
                <div>
                    <ul className="flex gap-6">
                        <li className="text-center cursor-pointer">
                            <h1 className="stats-color text-[14px] font-semibold">
                                <Skeleton width={40} />
                            </h1>
                            <h1 className="color-gray text-[14px]">
                                <Skeleton width={60} />
                            </h1>
                        </li>
                        <li className="text-center cursor-pointer">
                            <h1 className="stats-color text-[14px] font-semibold">
                                <Skeleton width={40} />
                            </h1>
                            <h1 className="color-gray text-[14px]">
                                <Skeleton width={60} />
                            </h1>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SkeletonComponent;
