import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Hooks/Authcontext";
import modalImage from "../../../Images/verify1.png";

const RegistrationModal = ({ setShowModal, userData, userToken }) => {
  const { setLogged } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogin = () => {
    localStorage.setItem("token", userToken);
    navigate("/");
  };

  return (
    <div className="popup">
      <div className="modal-empty-div"></div>
      <div className="content-container w-[50%] !h-[75%]">
        <div className=" w-full  popup-content min-h-full rounded-[8px]  !bg-white">

          <div className="flex justify-end items-center radius-top bg-white px-4 py-2">
            {/* <h1 className='text-[25px] text-[#151D48] font-semibold'>Start Sequence</h1> */}

            {/* <button className='text-[#3B59DF] text-[17px] font-semibold'>Cancel</button>
                        <button className='w-[130px] mx-8 cursor-pointer h-[40px] rounded-[8px] mt-3 font-semibold bg-[#3B59DF] text-white'>Save & Next</button> */}
            <button
              onClick={() => setShowModal(false)}
              className="text-[#000000] text-[30px]"
            >
              &times;
            </button>
          </div>
          <div className="flex justify-center items-center">
            <div>
              <div>
                <div className="flex justify-center items-center">
                  <img className="w-[16rem] h-[16rem]" src={modalImage} alt="" />
                </div>

                <div className="text-center">
                  <h1 className="text-[19px] font-semibold mt-3 mb-5">
                    Verify your email address
                  </h1>
                  <h1 className="text-[#484C57]">
                    You've entered{" "}
                    <span className="font-semibold text-black">
                      {userData?.email}
                    </span>{" "}
                    as the email address for your account
                  </h1>
                  <h1 className="text-[#484C57] mt-1">
                    please verify the email adress
                  </h1>
                </div>
              </div>

              <div className="flex justify-center items-center mt-4">
                <div>
                  <button className="w-[8.12rem] text-[14px] mr-3  cursor-pointer h-[40px] rounded-[3px] mt-3  bg-[#E0E5F8]  text-black">
                    Resend Link
                  </button>

                  <button
                    onClick={() => {
                      setLogged(true);
                      setShowModal(false);
                      handleLogin();
                    }}
                    className="w-[8.12rem] text-[14px] ml-3 cursor-pointer h-[40px] rounded-[3px] mt-3  bg-[#3B59DF] text-white"
                  >
                    Verify Later
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationModal;
