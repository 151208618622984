import React from "react";
import { useForm } from "react-hook-form";
import "./PeopleData.css";
import { useState } from "react";
import ModalComponenet from "../../../Componenets/ModalComponenet";
import GetUser from "../../../Hooks/GetUser";
import { peopleDatabase } from "../../../Services/apiServices";
import { toast } from "react-toastify";
import spinner from "../../../Images/button-loader.gif";
import Button from "../../../Componenets/Button";

const PeopleDatabase = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [totalFetchCount, setTotalFetchCount] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loader, setLoader] = useState({
    fetchCount: false,
    getCsv: false,
  });
  const user = GetUser();

  // fetch count handler
  const handleFromSubmit = async (data) => {
    setFormData(data);
    setLoader((prevState) => ({
      ...prevState,
      fetchCount: true,
    }));

    const payload = {
      apolloURL: data.apolloUrl,
      email: data.email,
      sample: true,
      userId: user,
    };

    let response = await peopleDatabase(payload);

    try {
      if (response.data.total) {
        setShowSubmitModal(true);
        setTotalFetchCount(response.data.total);
      }
    } catch (err) {
      toast.error("something went wrong");
    } finally {
      setLoader((prevState) => ({
        ...prevState,
        fetchCount: false,
      }));
    }
  };

  // get csv handler

  const handleGetCSV = async () => {
    setLoader((prevState) => ({
      ...prevState,
      getCsv: true,
    }));

    const payload = {
      apolloURL: formData.apolloUrl,
      email: formData.email,
      sample: false,
      userId: user,
    };

    let response = await peopleDatabase(payload);

    try {
      if (response.data.total) {
        setShowSubmitModal(false);
        toast.success(`People Data CSV has been sent to  ${formData.email}`);
        reset();
      }
    } catch (err) {
      toast.error("something went wrong");
    } finally {
      setLoader((prevState) => ({
        ...prevState,
        getCsv: false,
      }));
    }
  };

  return (
    <div className="flex justify-center items-center py-[8rem]">
      <div className="form-container shadow-favourite p-6 py-16">
        <div className="mb-16 text-center ">
          <h1 className="text-2xl mb-2 text-[#101828] font-medium">
            People Database
          </h1>
          <h1 className="text-[#667085]">please fill the input box</h1>
        </div>
        <form onSubmit={handleSubmit(handleFromSubmit)}>
          <div>
            <label
              className="label-text inline-block text-[.875rem] mb-2 text-[#667085] font-medium"
              htmlFor="email"
            >
              Email
            </label>
            <input
              name="email"
              className="input-people-database"
              type="text"
              {...register("email", {
                required: true,
              })}
            />
            {errors?.email && (
              <p className="text-red-500 text-[12px] mt-1">
                Email is required.
              </p>
            )}
          </div>

          <div className="mt-6">
            <label
              className="label-text mb-2 inline-block text-[.875rem] text-[#667085] font-medium"
              htmlFor="apolloUrl"
            >
              Appolo Url
            </label>
            <input
              name="apolloUrl"
              {...register("apolloUrl", {
                required: true,
              })}
              className="input-people-database"
              type="text"
            />
            {errors?.apolloUrl && (
              <p className="text-red-500 text-[12px] mt-1">
                Appolo url is required.
              </p>
            )}
          </div>

          <button
            disabled={loader.fetchCount}
            className="btn w-full mt-12"
            type="submit"
          >
            {loader.fetchCount ? (
              <img className="w-[55px] h-[55px]" src={spinner} alt="" />
            ) : (
              "Fetch Count"
            )}
          </button>
        </form>
      </div>
      {showSubmitModal && (
        <ModalComponenet
          childrenPadding={"1rem"}
          radious={"10px"}
          width={"40vw"}
          top={"10rem"}
          title={"Confirm"}
          setActiveModal={setShowSubmitModal}
        >
          <div>
            <h1 className="font-medium">
              Total people found was {totalFetchCount},{" "}
              {totalFetchCount > 500 && "But we fetch only 500 per search!"} Do
              you want to get the CSV to your email
            </h1>

            <div className="flex justify-end items-center mt-2">
              <div className="flex items-center">
                <button
                  onClick={() => setShowSubmitModal(false)}
                  className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
                >
                  Cancel
                </button>

                <Button
                  loading={loader.getCsv}
                  disabled={loader.getCsv}
                  onClick={handleGetCSV}
                  tittle={"Get CSV"}
                ></Button>
              </div>
            </div>
          </div>
        </ModalComponenet>
      )}
    </div>
  );
};

export default PeopleDatabase;
