import { Route, Routes } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import DashboardHome from "./Pages/Dashboard/DashboardHome/DashboardHome";
import DashboardMain from "./Pages/Dashboard/Main/DashboardMain";

import Prospectus from "./Pages/Dashboard/Prospectus/Prospectus";
import Emailstat from "./Pages/Dashboard/Sequence/CampainStat/EmailStat/Emailstat";

// import Sequence from "./Pages/Dashboard/Sequence/Sequence";
import Setting from "./Pages/Dashboard/Setting/Setting";
import Templates from "./Pages/Dashboard/Templates/Templates";
import Login from "./Pages/Login/Login/Login";
import Register from "./Pages/Login/Register/Register";
import Verify from "./Pages/Login/VerifyEmail/Verify";
import Header from "./Pages/Shared/Header/Header";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "./Hooks/Authcontext";
import Singlesequence from "./Pages/Dashboard/Sequence/Singlesequence";
import axios from "axios";
import "./MediaQuery/MediaQuery.css";
import { posthog } from "posthog-js";
import ContextProvider from "./Context/ContextProvider";
import General from "./Pages/Dashboard/Setting/General";
import Mailboxes from "./Pages/Dashboard/Setting/Mailboxes";
import AddEmail from "./Pages/Dashboard/Setting/AddEmail";
import GetUser from "./Hooks/GetUser";
import ContactImport from "./Pages/Dashboard/Prospectus/ContactImport";
import properties from "./config/properties";
import ImportContactSeq from "./Pages/Dashboard/Sequence/ImportContactSeq";
import MailboxesSetting from "./Pages/Dashboard/Setting/MailboxesSetting";
import MailboxDetails from "./Pages/Dashboard/Setting/MailboxDetails";
import DetailsStat from "./Pages/Dashboard/Sequence/DetailsStat";
import Unsubscribe from "./Componenets/Unsubscribe";
import AItoolkit from "./Pages/Dashboard/AItoolkit/AItoolkit";
import LinkedInTemplate from "./Pages/Dashboard/AItoolkit/LinkedInTemplate";
import MarkettingTemplate from "./Pages/Dashboard/AItoolkit/MarkettingTemplate";

import Ai from "./Pages/Dashboard/AItoolkit/Ai";
import GenarateRes from "./Pages/Dashboard/AItoolkit/GenarateRes";
import EmailTemplatesShow from "./Pages/Dashboard/AItoolkit/EmailTemplatesShow";
import Slack from "./Pages/Dashboard/AItoolkit/Slack";
import SevenStepAi from "./Pages/Dashboard/AItoolkit/SevenStepAi";
import LandingPageUi from "./Pages/Dashboard/LandingPage/LandingPageUi";
import LandingPageForm from "./Pages/Dashboard/LandingPage/LandingPageForm";
import PeopleDatabase from "./Pages/Dashboard/PeopleData/PeopleDatabase";

import SingleSequenceIndex from "./Pages/Dashboard/Sequence/SingleSequenceIndex";
import Analytics from "./Pages/Dashboard/Sequence/MiniComponent/Analytics";
import Leads from "./Pages/Dashboard/Sequence/MiniComponent/Leads";
import Options from "./Pages/Dashboard/Sequence/MiniComponent/Options";
import Schedule from "./Pages/Dashboard/Sequence/MiniComponent/Schedule";
import SequenceIndex from "./Pages/Dashboard/Sequence/SequenceIndex";
import Sequence from "./Pages/Dashboard/Sequence/MiniComponent/Sequence";

function App() {
  const user = GetUser();
  const [isLogged, setLogged] = useState(false);
  useEffect(() => {
    if (user) {
      setLogged(true);
    }
  }, [user]);

  return (
    <div>
      <ContextProvider>
        <AuthContext.Provider value={{ setLogged, isLogged, user }}>
          {/* <Header></Header> */}
          <Routes>
            <Route element={<DashboardMain />}>
              <Route index element={<DashboardHome />}></Route>
              <Route path="/dashboard" element={<DashboardHome />}></Route>
              {/* <Route
                path="sequence/:sequenceIdUrl"
                element={<Singlesequence></Singlesequence>}
              ></Route> */}

              <Route
                path="sequence/detailsStats/:sequenceId/:filterBy"
                element={<DetailsStat back={true} />}
              >
                <Route
                  path=":stepId"
                  element={<DetailsStat back={true} />}
                ></Route>{" "}
                <Route path="" element={<DetailsStat back={true} />}></Route>
              </Route>

              <Route path="sequence" element={<SequenceIndex />}></Route>

              {/* top bar for sequence  */}
              <Route
                path="sequence/:sequenceIdUrl"
                element={<SingleSequenceIndex></SingleSequenceIndex>}
              >
                <Route index element={<Analytics />} />
                <Route
                  path="leads/:sequenceId/:filterBy"
                  element={<DetailsStat back={false} />}
                />

                <Route path="options" element={<Options />} />
                <Route path="schedule" element={<Schedule />} />
                <Route path="sequence" element={<Sequence />} />
                {/* <Route
                  path="detailsStats/:sequenceId/:filterBy"
                  element={<StatsDetails />}
                >
                  <Route path=":stepId" element={<StatsDetails />}></Route>{" "}
                  <Route path="" element={<StatsDetails />}></Route>
                </Route> */}

                <Route
                  path="detailsStats/:sequenceId/:filterBy"
                  element={<DetailsStat back={true} />}
                >
                  <Route
                    path=":stepId"
                    element={<DetailsStat back={true} />}
                  ></Route>{" "}
                  <Route path="" element={<DetailsStat back={true} />}></Route>
                </Route>
              </Route>
              {/* top bar end for sequence  */}

              <Route path="setting" element={<Setting></Setting>}>
                <Route index element={<General></General>}></Route>
                <Route path="mailbox-setting" element={<MailboxesSetting />} />

                <Route
                  path="mailboxes"
                  element={<Mailboxes></Mailboxes>}
                ></Route>
                <Route
                  path="mailboxes/:mailboxId"
                  element={<MailboxDetails />}
                />
              </Route>

              <Route
                path="prospects"
                element={<Prospectus></Prospectus>}
              ></Route>
              <Route path="landing-page" element={<LandingPageForm />}></Route>
              <Route path="template" element={<Templates></Templates>}></Route>
              <Route path="people-database" element={<PeopleDatabase />} />
              <Route path="aicontent" element={<AItoolkit />}>
                <Route path="email" element={<EmailTemplatesShow />}></Route>
                <Route path="slack" element={<Slack />}></Route>
                <Route path="linkedin" element={<LinkedInTemplate />}></Route>

                <Route index element={<Ai />}></Route>
                <Route path="marketting" element={<MarkettingTemplate />} />
              </Route>
              <Route path="write_cold_email" element={<GenarateRes />} />
              <Route path="7_step_community_invite" element={<SevenStepAi />} />
            </Route>
            <Route path="/registration" element={<Register></Register>}></Route>
            <Route path="/landing-page-ui" element={<LandingPageUi />}></Route>
            <Route path="/login" element={<Login></Login>}></Route>
            <Route path="/verify" element={<Verify />}></Route>
            <Route path="/addEmail" element={<AddEmail />}></Route>
            <Route
              path="/contact-import"
              element={<ContactImport></ContactImport>}
            ></Route>
            <Route
              path="/contact-import-seq"
              element={<ImportContactSeq />}
            ></Route>

            <Route
              path="/unsubscribe/:prospectId"
              element={<Unsubscribe></Unsubscribe>}
            />
          </Routes>
        </AuthContext.Provider>
      </ContextProvider>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        closeButton={false}
        hideProgressBar={true}
        draggable={false}
        pauseOnHover={false}
        closeOnClick={false}
      />
    </div>
  );
}

export default App;
