import React, { useState } from "react";
import AileftSide from "./AileftSide";
import AiRightSide from "./AiRightSide";
import BackButton from "../../../Componenets/BackButton";

const GenarateRes = () => {
  const [aiResponse, setAiresponse] = useState();

  return (
    <div className="h-screen">

      <div className="grid grid-cols-2 h-full">
        <AileftSide setAiresponse={setAiresponse} />
        <AiRightSide aiResponse={aiResponse} />
      </div>
    </div>
  );
};

export default GenarateRes;
