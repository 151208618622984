import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "./Setting.css";


const Setting = () => {
  const active = "border-b color-active border-[#1991eb]";
  const deactive = "text-[#737791]";
  return (
    <div className="p-4">
      <div className="border-b border-border-gray bg-white h-[50px] px-4">
        <ul className="flex text-[14px] color-gray font-medium gap-4 h-full">
          <NavLink
            end
            to=""
            className={({ isActive }) =>
              `${isActive ? active : deactive} flex justify-center items-center`
            }
          >
            General
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              `${isActive ? active : deactive} flex justify-center items-center`
            }
            to="mailboxes"
          >
            Mailboxes
          </NavLink>


        </ul>
      </div>

      <div className="mt-4 ">
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default Setting;
