import React, { useEffect, useState } from "react";
import ModalComponenet from "../../../Componenets/ModalComponenet";
import { getListName } from "../../../Services/apiServices";
import GetUser from "../../../Hooks/GetUser";
import Select from "react-select";
import Button from "../../../Componenets/Button";
import { useContext } from "react";
import { UserContext } from "../../../Hooks/UserContext";

const ListNameModal = ({
  setFilterBy,
  setActiveButton,
  setSelectedListName,
  setActivePage,
}) => {
  const user = GetUser();
  const [options, setOptions] = useState([]);
  const [selectedList, setSelectedList] = useState();
  const { setOpenListModal } = useContext(UserContext);

  const handleSelectChange = (newValue) => {
    setSelectedList(newValue);
  };

  useEffect(() => {
    async function listName() {
      try {
        const response = await getListName(user);

        if (response.data.status && response.data.data.length > 0) {
          const newOptions = response.data.data.map((item) => ({
            value: item._id,
            label: item.name,
          }));
          setOptions(newOptions);
        }
      } catch (error) {
        console.error("Error fetching list names:", error);
      }
    }

    listName();
  }, [user]);

  const handleSubmit = () => {
    setFilterBy("list");
    setSelectedListName(selectedList);
    setOpenListModal(false);
    setActiveButton("list");
    setActivePage(1);
  };

  return (
    <ModalComponenet
      title={"List Name"}
      childrenPadding={"1rem"}
      width={"750px"}
      top={"12rem"}
      setActiveModal={setOpenListModal}
    >
      <div>
        <div className="">
          <label
            htmlFor="listName"
            className="text-[14px] font-500 mb-2 inline-block color-gray"
          >
            Select Your List Name :
          </label>
          <Select
            id="listName"
            onChange={handleSelectChange}
            options={options}
            value={selectedList}
          />
        </div>

        <div className="flex justify-end p-4 mt-8">
          <div className="flex">
            <button
              onClick={() => setOpenListModal(false)}
              className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
            >
              Cancel
            </button>
            <div>
              <Button onClick={handleSubmit} tittle={"Save"} />
            </div>
          </div>
        </div>
      </div>
    </ModalComponenet>
  );
};

export default ListNameModal;
