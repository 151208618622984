import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material";

import ListItemText from "@mui/material/ListItemText";
import BoltIcon from "@mui/icons-material/Bolt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ProspectDrawerTabs from "./ProspectDrawerTabs";
import "simplebar-react/dist/simplebar.min.css";
import GetUser from "../../../Hooks/GetUser";
import {
  fetchPropspectData,
  updatePropspect,
} from "../../../Services/apiServices";
import SimpleBar from "simplebar-react";

const popOverItem = [
  {
    name: "Lead",
  },
  {
    name: "Interested",
    color: "#97e0b6",
  },
  {
    name: "Meeting Booked",
    color: "#be83e4",
  },
  {
    name: "Meeting Completed",
    color: "#f1a842",
  },
  {
    name: "Closed",
    color: "#cae32b",
  },
  {
    name: "Out Of office",
    color: "#93bced",
  },
  {
    name: "Wrong Person",
    color: "#a5aab6",
  },
  {
    name: "Not Interested",
    color: "#ea7d84",
  },
];

const LeadsUpdateDrawer = ({
  isOpen,
  setIsOpen,
  toggleDrawer,
  singleRowData,
  setFetchDetailsStats,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataLoad, setDataLoad] = useState(false);
  const currentItem =
    popOverItem.find((item) => item.name === singleRowData?.stage) ||
    popOverItem[0];

  const [selectedItem, setSelectedItem] = useState(currentItem);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const user = GetUser();

  const handleMenuItemClick = async (item) => {
    setSelectedItem(item);
    handleClose();

    const payload = {
      userId: user,
      prospectId: singleRowData?._id
        ? singleRowData._id
        : singleRowData.emailTracks && singleRowData.emailTracks.length > 0
        ? singleRowData.emailTracks[0].prospectId
        : undefined,
      updateData: {
        stage: item.name,
      },
    };

    await updatePropspect(payload);
  };

  useEffect(() => {
    setDataLoad(true);
    async function fetchTheProspect() {
      const payload = {
        userId: user,
        email: singleRowData.email,
      };
      let response = await fetchPropspectData(payload);

      if (response.data.success) {
        const foundItem = popOverItem.find(
          (item) => item.name === response.data.data?.stage
        );

        setDataLoad(false);

        // Update the selectedItem state with the found item or the default
        setSelectedItem(foundItem || popOverItem[0]);
      }
    }

    if (isOpen && singleRowData.email) {
      fetchTheProspect();
    }
  }, [isOpen, singleRowData.email, user]);

  return (
    <Drawer
      duration={500}
      overlayOpacity={0}
      open={isOpen}
      direction="right"
      className="shadow-drawer min-w-[400px] w-[30%] overflow-y-auto "
      onClose={toggleDrawer}
    >
      <SimpleBar style={{ height: "100%" }}>
        <div>
          <div onClick={toggleDrawer} className="flex justify-end pt-8 pr-8">
            <CloseIcon className="text-[#6d727e] cursor-pointer" />
          </div>

          <div className="p-8">
            <div>
              <div className=" flex gap-4 items-center">
                <div className="flex items-center w-[40px] h-[40px] justify-center bg-[#ffcc6b] rounded-full ">
                  <h1 className="uppercase">
                    {singleRowData?.email
                      ? singleRowData.email.slice(0, 2)
                      : ""}
                  </h1>
                </div>
                <h1 className="text-[14px]">{singleRowData?.email}</h1>
              </div>

              <div className="mt-4">
                {dataLoad ? (
                  <CircularProgress />
                ) : (
                  <Button
                    aria-describedby={id}
                    onClick={handleClick}
                    variant="outlined"
                  >
                    <BoltIcon fontSize="small" />
                    {selectedItem?.name}
                    <KeyboardArrowDownIcon fontSize="small" />
                  </Button>
                )}

                <Popover
                  PaperProps={{
                    style: { width: "300px" },
                  }}
                  className="popover-shadow w-[300px]"
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <MenuList>
                    {popOverItem.map((item, index) => (
                      <MenuItem
                        className="px-2"
                        key={index}
                        onClick={() => handleMenuItemClick(item)}
                      >
                        <ListItemIcon>
                          <BoltIcon
                            sx={{ color: item.color, fontSize: "30px" }}
                          />
                        </ListItemIcon>
                        <ListItemText className="text-black">
                          {item.name}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Popover>
              </div>

              <div className="mt-4">
                <form action="">
                  <ProspectDrawerTabs
                    setFetchDetailsStats={setFetchDetailsStats}
                    singleRowData={singleRowData}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </Drawer>
  );
};

export default LeadsUpdateDrawer;
