import React, { useEffect } from "react";
import ReactApexChart from "apexcharts";

const TotalMessege = () => {
  const chartRef = React.useRef(null);

  useEffect(() => {
    const options = {
      chart: {
        type: "bar",
        height: 430,
        width: "100%",
        stacked: true,
      },
      plotOptions: {
        bar: {
          columnWidth: "35%",
        },
      },
      colors: ["#00E096", "#0095FF"],
      series: [
        {
          name: "Replied",
          data: [42, 52, 16, 55, 59, 51],
        },
        {
          name: "Not replied",
          data: [26, 12, 14, 17, 25, 31],
        },
      ],
      labels: ["san", "sun", "mon", "tue", "wed", "thru"],
      xaxis: {
        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#78909c",
          },
        },
      },
      title: {
        text: "Total Messege",
        align: "left",
        style: {
          fontSize: "17px",
        },
      },
    };

    var chart = new ReactApexChart(chartRef.current, options);
    chart.render();
  }, []);

  return <div ref={chartRef} className="bg-white custom-shadow" id="chart" />;
};

export default TotalMessege;
