import React, { useState } from "react";
import { FormControlLabel, Menu, MenuItem } from "@mui/material";
import { Android12Switch } from "../../../../Utils/SwitchIcon";
import { VscEllipsis } from "react-icons/vsc";
import { BsPencilFill } from "react-icons/bs";
import { TiSocialLinkedin } from "react-icons/ti";
import { FiMail } from "react-icons/fi";
import { deleteStep, updateStepData } from "../../../../Services/apiServices";
import Swal from "sweetalert2";
import UpdateTimeModal from "../UpdateTimeModal";
import { Tooltip } from "react-tooltip";
import UpdateStepModal from "../UpdateStepModal";
const SequenceSteps = ({
  step,
  fullArr,
  user,
  index,
  sequenceId,
  setFetchSequenceStep,
}) => {
  const [activeStatus, setActiveStatus] = useState(step.activeStatus);
  const [updateDateModal, setUpdateDateModal] = useState(false);
  const [stepUpdateModalActive, setStepUpdateModalActive] = useState(false);

  // day logic
  const firstStep = fullArr[0];
  const firstDate = new Date(firstStep?.triggerGapDate);
  const currentDate = new Date(step?.triggerGapDate);
  var diff = currentDate.getTime() - firstDate.getTime();
  const diffDays = Math.round(diff / (1000 * 60 * 60 * 24));

  // badge show
  const statusShow = (activeStatus) => {
    if (activeStatus.toLowerCase() === "completed") {
      return <h1 className="text-[#245900] bg-[#def2d0] badge">Completed</h1>;
    } else if (activeStatus.toLowerCase() === "running") {
      return <h1 className="text-[#151414] bg-[#F8F814] badge">Running</h1>;
    } else if (activeStatus.toLowerCase() === "not started") {
      return <h1 className="text-[#900] bg-[#ffdcdc] badge">Not Started</h1>;
    } else {
      return <h1 className="text-white bg-red-500 badge">Disabled</h1>;
    }
  };

  // toggle switch
  const handleToggleChange = async (event) => {
    setActiveStatus(event.target.checked);
    const payload = {
      userId: user,
      sequenceId: sequenceId,
      stepId: step._id,
      stepData: { activeStatus: event.target.checked },
    };
    await updateStepData(payload);
  };

  //for mui menu
  const [anchorElm, setAnchorElm] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setAnchorElm(null);
    setOpen(false);
  };

  const handleClick = (e) => {
    setAnchorElm(e.currentTarget);
    setOpen(true);
  };

  // step delete
  const deleteSequenceStep = () => {
    Swal.fire({
      title: "Are you sure?",
      html: `Are you sure you want to delete step <b>${index + 1}</b>`,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          user: user,
          sequenceId: sequenceId,
          stepId: step._id,
        };

        let res = await deleteStep(payload);
        if (res.data.success) {
          Swal.fire("Deleted!", "Your step has been deleted.", "success");
          setFetchSequenceStep((prevState) => !prevState);
        }
      }
    });
  };

  return (
    <div className="mt-8">
      <div className="bg-white custom-shadow border border-gray  p-4">
        <div className=" border-b-2 border-border-gray pb-1 flex justify-between items-center">
          <div className="flex items-center">
            <div className="flex items-center">
              <div className="flex justify-center items-center w-[30px] mr-4 h-[30px] bg-[#E9F2FF] rounded-full">
                {step.channel.includes("LinkedIn") ? (
                  <TiSocialLinkedin className="text-[#1991EB] text-[16px] inline-block" />
                ) : (
                  <FiMail className="text-[#1991EB] text-[14px] inline-block" />
                )}
              </div>

              <h1 className="text-[13px] font-semibold primary-heading-color flex items-center">
                {step.channel}
              </h1>
            </div>
            {
              <>
                <div className="  day color-gray text-[12px] ml-3 w-[45px] h-[30px] flex justify-center items-center">
                  day {diffDays + 1}
                </div>
                <div
                  className={`text-[12px]  transition duration-200 ease-in-out color-gray  mx-2 h-[30px] flex justify-center items-center`}
                >
                  {statusShow(step?.status)}
                </div>

                {/* {index !== 0 && (
                  <div
                    data-tooltip-content="Edit Time"
                    data-tooltip-id="EditTime"
                    onClick={() => setUpdateDateModal(true)}
                    className="text-[15px] ml-2 icon-color Ellipsis cursor-pointer"
                  >
                    <Tooltip id="EditTime" />
                    <BsPencilFill />
                  </div>
                )} */}

                <div
                  data-tooltip-content="Edit Time"
                  data-tooltip-id="EditTime"
                  onClick={() => setUpdateDateModal(true)}
                  className="text-[15px] ml-2 icon-color Ellipsis cursor-pointer"
                >
                  <Tooltip id="EditTime" />
                  <BsPencilFill />
                </div>

                <div>
                  <h1
                    className={`${
                      step.thread.toLowerCase() === "new"
                        ? "text-white ml-4 bg-[#1fc662] badge-small"
                        : "text-white ml-4 bg-[#17a2b8] badge-small"
                    }   `}
                  >
                    {step.thread.toLowerCase() === "new" ? "new" : "reply"}
                  </h1>
                </div>
              </>
            }
          </div>

          <div className="cursor-pointer ">
            <div>
              <Tooltip id="ellipsis" />
              <VscEllipsis
                data-tooltip-content="Edit"
                data-tooltip-id="ellipsis"
                onClick={handleClick}
                className="text-[25px] icon-color Ellipsis"
              ></VscEllipsis>
            </div>

            <Menu anchorEl={anchorElm} open={open} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setStepUpdateModalActive(true);
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  deleteSequenceStep();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div
          style={{ display: "-webkit-box", WebkitBoxOrient: "vertical" }}
          className={`flex  items-center py-6`}
        >
          <div className="flex">
            <FormControlLabel
              control={
                <Android12Switch
                  checked={activeStatus}
                  onChange={handleToggleChange}
                />
              }
            />
          </div>

          <div className="text-overflow">
            <div
              className="color-gray text-overflow ml-12"
              dangerouslySetInnerHTML={{ __html: step?.template }}
            ></div>
          </div>
        </div>
      </div>

      {updateDateModal && (
        <UpdateTimeModal
          setFetchSequenceStep={setFetchSequenceStep}
          sequenceId={sequenceId}
          step={step}
          stepid={step._id}
          index={index}
          setUpdateDateModal={setUpdateDateModal}
        ></UpdateTimeModal>
      )}

      {stepUpdateModalActive && (
        <UpdateStepModal
          step={step}
          formattedDate={step?.triggerGapDate}
          stepId={step._id}
          allstepData={step}
          index={index}
          sequenceId={sequenceId}
          setStepUpdateModalActive={setStepUpdateModalActive}
          setFetchSequenceStep={setFetchSequenceStep}
        ></UpdateStepModal>
      )}
    </div>
  );
};

export default SequenceSteps;
