import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './Aitoolkit.css'

const AItoolkit = () => {
    const active = "border-b color-active border-[#1991eb]";
    const deactive = "text-[#737791]";
    return (
        <div>
            <div>
                <div className="border-b border-border-gray  h-[50px] px-4">
                    <ul className="flex text-[15px] color-gray font-medium gap-4 h-full">
                        <NavLink
                            className={({ isActive }) =>
                                `${isActive ? active : deactive} flex justify-center items-center`
                            }
                            to=""
                            end
                        >

                            AI

                        </NavLink>


                        <NavLink
                            className={({ isActive }) =>
                                `${isActive ? active : deactive} flex justify-center items-center`
                            }
                            to="slack"

                        >

                            Slack

                        </NavLink>


                        <NavLink

                            to="email"
                            className={({ isActive }) =>
                                `${isActive ? active : deactive} flex justify-center items-center`
                            }
                        >
                            Email
                        </NavLink>

                    </ul>
                </div>

                <div className="mt-4 p-4">
                    <Outlet></Outlet>
                </div>
            </div>
        </div>
    );
};

export default AItoolkit;