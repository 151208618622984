import React from "react";
import expired from "../Images/expired.png";
import properties from "../config/properties";
import { useNavigate } from "react-router-dom";
const ExpiredMailbox = ({ expiredEmail, setShowErrorModal }) => {
  const navigate = useNavigate()



  const handleNavigate = () => {
    navigate('/setting/mailboxes')
  }


  return (
    <div>
      <div className="popup">
        <div className="modal-empty-div"></div>
        <div className="content-container mt-3">
          <div className="bg-[#F6F7F8] w-[500px] h-auto popup-content !rounded-[4px] p-4">
            <div className="flex justify-end items-center radius-top  ">
              <button
                onClick={() => setShowErrorModal(false)}
                className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[30px] w-[18px] h-[18px] flex justify-center items-center"
              >
                &times;
              </button>
            </div>
            <div className="">
              <img className="w-[70px] h-[70px] mb-5 mx-auto" src={expired} alt="" />

              <h1 className="  primary-heading-color  font-semibold">
                <h1 className="mb-2">
                  Below Mailbox Got Expired Please Re-Link .
                </h1>
                <div>
                  <ul>
                    {
                      expiredEmail.map(mail => <li className="bg-[#fff] p-2 mb-2">{mail}</li>)
                    }
                  </ul>
                </div>
              </h1>

              <button onClick={handleNavigate}
                className={`button-background mt-2 text-white font-500 hover:bg-[#1991eb]  h-[32px] rounded-[3px] px-[12px] text-[13px]`}
              >

                Re Link Mailbox

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredMailbox;
