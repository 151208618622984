import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import "./OverallInsite.css";

const OverallInsite = () => {
  const chartRef = React.useRef(null);

  useEffect(() => {
    const optionsLine = {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      colors: ["#A700FF", "#EF4444", "#3CD856"],
      stroke: {
        curve: "smooth",
        width: 5,
      },
      series: [
        {
          name: "Email Outreach",
          data: [1, 15, 26, 20, 33, 27, 1, 15, 26, 33, 27],
        },
        {
          name: "Community Member",
          data: [3, 33, 21, 42, 19, 32, 1, 12, 26, 19, 9],
        },
        {
          name: "Unique meeting",
          data: [0, 39, 22, 11, 29, 43, 1, 25, 26, 3, 33],
        },
      ],
      title: {
        text: "Overall Insights",
        align: "left",
        offsetY: 15,
        offsetX: 10,
      },
      markers: {
        size: 6,
        strokeWidth: 0,
        hover: {
          size: 9,
        },
      },
      grid: {
        show: true,
        padding: {
          bottom: 0,
        },
      },
      labels: [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "sep",
        "oct",
        "nov",
        "dec",
      ],
      xaxis: {
        tooltip: {
          enabled: false,
        },
      },
    };
    var chartLine = new ApexCharts(
      document.querySelector("#chart"),
      optionsLine
    );
    chartLine.render();
  }, []);

  return <div ref={chartRef} className="bg-white custom-shadow font-medium" id="chart" />;
};

export default OverallInsite;
