import { customVariable } from "./customVariable";

export function validatePersonalize(html, inputValue) {
  const regex = /{{(.*?)}}/g;

  // Find placeholders in the HTML
  const htmlPersonalizeItems = html.match(regex);

  // Find placeholders in the input value
  const inputValuePersonalizeItems = inputValue.match(regex);

  // Find incorrect matches in HTML
  const incorrectMatchHtml = htmlPersonalizeItems
    ? htmlPersonalizeItems.filter((item) => {
        const itemName = item.replace(/{{|}}/g, "").trim();
        return !customVariable.includes(itemName);
      })
    : [];

  // Find incorrect matches in input value
  const incorrectMatchInputValue = inputValuePersonalizeItems
    ? inputValuePersonalizeItems.filter((item) => {
        const itemName = item.replace(/{{|}}/g, "").trim();
        return !customVariable.includes(itemName);
      })
    : [];

  // Combine and return the incorrect matches
  return [...incorrectMatchHtml, ...incorrectMatchInputValue];
}
