export const sample_import_data = [
  {
    "Full Name": "Gokul Rangarajan",
    "First Name": "Gokul",
    "Last Name": "Rangarajan",
    "Job Title": "CEO",
    Experince: 12,
    "Company Name": "KLUB STACK",
    "Email Address": "gokul@pitchworks.club",
    "Linkedin URL": "https://www.linkedin.com/in/gokulrangarajan/",
    "Twitter URL": "https://twitter.com/The_Product_VC",
    "Mobile Number": "+91-9790703608",
    "Company Website": "www.pitchworks.club",
    "One line personalization":
      "I see that you have worked with Freshworks, Bigbasket and Keka HR as a product Manager",
    Competitor: "Slidebean, Slydes",
    "Company Description": "Helping saas founder do the Fundrasing",
    "Years in the company": 3,
  },
  {
    "Full Name": "Raju Kancharla",
    "First Name": "Raju",
    "Last Name": "Kancharla",
    "Job Title": "Co-Founder",
    Experince: 4,
    "Company Name": "KLUB STACK",
    "Email Address": "raju@klubstack.com",
    "Linkedin URL": "https://www.linkedin.com/in/rajukancharla/",
    "Twitter URL": "https://twitter.com/RajuKancharla21",
    "Mobile Number": "+91-8309020392",
    "Company Website": "www.klubstack.com",
    "One line personalization":
      "Your background in Python, machine learning, and artificial intelligence is impressive.",
    Competitor: "",
    "Company Description": "One place to automate everything!",
    "Years in the company": 4,
  },
];
