import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../Componenets/Button";
import { UserContext } from "../../../Hooks/UserContext";
import { deleteMailbox } from "../../../Services/apiServices";

const DeleteMailboxModal = ({ setShowDeleteModal, senderEmail, user }) => {
  const [loader, setLoader] = useState(false);

  const { setRefetch, setRefetchMailbox } = useContext(UserContext);
  const handleDeleteMailbox = async () => {
    setLoader(true);
    const payload = {
      user: user,
      senderEmail: senderEmail,
    };

    let response = await deleteMailbox(payload);
    console.log(response.data);
    if (response.data.success) {
      setShowDeleteModal(false);
      setLoader(false);
      setRefetchMailbox(prevState => !prevState)

      toast.success(response.data.message);
      setRefetch((prev) => !prev);
    }
  };
  return (
    <div>
      <div className="popup  ">
        <div className="modal-empty-div"></div>
        <div className="content-container mt-3">
          <div className="bg-white w-[500px]  popup-content !rounded-[4px] p-4">
            <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
              <h1 className="text-[#242d3e] text-[15px] font-semibold">
                Delete Mailbox
              </h1>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
              >
                &times;
              </button>
            </div>

            <div className="py-4">
              <h1 className="text-[14px] font-500 text-[#242d3e]">
                Are you sure you want to delete <b>{`${senderEmail}`}</b>{" "}
                mailbox
              </h1>
            </div>
            <div className="flex justify-end">
              <div className="flex">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
                >
                  Cancel
                </button>

                <div>
                  <Button
                    loading={loader}
                    disabled={loader}
                    onClick={handleDeleteMailbox}
                    tittle={"Delete"}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteMailboxModal;
