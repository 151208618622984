import React, { useContext, useState } from "react";
import axios from "axios";
import "./Register.css";
import { useForm } from "react-hook-form";
import registerImage from "../../../Images/register.png";
import Social from "../../Shared/SocialLogin/Social";
import { AuthContext } from "../../../Hooks/Authcontext";
import RegistrationModal from "./RegistrationModal";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading/Loading";
import properties from "../../../config/properties";
import Logger from "../../../Helpers/Logger";
import { userSignup } from "../../../Services/apiServices";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState("");
  const { setLogged, isLogged } = useContext(AuthContext);
  const [userToken, setUserToken] = useState(null);
  const [loginLoading, setloginLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = async (data) => {
    setUserData(data);
    try {
      setloginLoading(true);
      let res = await userSignup(data);
      if (res.data.success === true) {
        setUserToken(res?.data.token);
        // send verification link
        setShowModal(true);
        setloginLoading(false);
        reset();
      } else {
        setloginLoading(false);
        toast.error("email exist");
        //email exist\
      }
      setloginLoading(false);
    } catch (error) {
      Logger.error(error);
    }
  };
  const navigate = useNavigate();
  if (loginLoading) {
    return <Loading text={"Please Wait ..."}></Loading>;
  }

  return (
    <div className=" conatiner mx-auto h-screen flex items-center justify-center">
      <div className="w-[500px] mx-auto bg-white   shadow-deep  flex items-center rounded-[10px]">
        {/* input section  */}
        <div className=" w-full  h-full flex justify-center items-center py-8 px-6">
          <div className="w-full">
            <div className="mb-5">
              <span className="text-[#737791] text-[12px] tracking-[1px]">
                START FOR FREE
              </span>
              <h1 className="text-[#05004E] text-[20px] font-semibold">
                Create an account
              </h1>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control">
                {/* first name */}
                <label className="label">
                  <span className="label-text text-[.875rem] font-semibold">
                    Your Name
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="enter your name"
                  className="input-customize"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "First Name reQuired",
                    },
                  })}
                />
              </div>

              <div className="form-control mt-4">
                <label className="label">
                  <span className="label-text text-[.875rem] font-semibold">
                    Email*
                  </span>
                </label>
                <input
                  type="email"
                  placeholder="your email"
                  className="input-customize"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "email reQuired",
                    },
                  })}
                />
              </div>

              {/* password  */}
              <div className="form-control mt-4">
                <label className="label">
                  <span className="label-text text-[.875rem] font-semibold">
                    Password*
                  </span>
                </label>
                <input
                  type="password"
                  placeholder="your password"
                  className="input-customize"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "password is  reQuired",
                    },
                  })}
                />
              </div>

              <button
                type="submit"
                className="w-full mt-10 rounded-[4px]  bg-[#5d5fef]  h-[40px] text-[#fff]"
              >
                Registration
              </button>
            </form>
            {/* google oath  */}
            <Social setloginLoading={setloginLoading}></Social>
            <div className="flex items-center text-center justify-center mt-2">
              <h1 className="text-muted text-sm">Dont Have account ?</h1>{" "}
              <span
                onClick={() => navigate("/login")}
                className="text-muted font-bold text-sm cursor-pointer"
              >
                {" "}
                Sign In
              </span>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <RegistrationModal
          userToken={userToken}
          userData={userData}
          setShowModal={setShowModal}
        ></RegistrationModal>
      )}
    </div>
  );
};

export default Register;
