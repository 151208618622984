import React from "react";
import ModalComponenet from "./ModalComponenet";

const ConformationModal = ({ setConfirmModal, setActiveStepModal }) => {
  return (
    <ModalComponenet
      setActiveModal={setConfirmModal}
      title={"Confirmation"}
      top={"8rem"}
      width={"40vw"}
      zIndex={999999}
      background={""}
    >
      <div className="p-4">
        <h1>
          You have unsaved changes. Are you sure you want to close the modal ?
        </h1>
        <div className="flex justify-end mt-4">
          <div className="flex">
            <button
              onClick={() => setConfirmModal(false)}
              className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
            >
              Cancel
            </button>

            <div>
              <button
                onClick={() => setActiveStepModal(false)}
                className="button-danger"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalComponenet>
  );
};

export default ConformationModal;
