import React from "react";

const ComunityContributer = () => {
  const ContributerData = [
    {
      name: "Paremela Helpert",
      interaction: 45,
      barBgPercent: "#0095FF",
      barBg: "#CDE7FF",
    },
    {
      name: "Michael Scot",
      interaction: 29,
      barBgPercent: "#00E096",
      barBg: "#8CFAC7",
    },
    {
      name: "Jim halpert",
      interaction: 28,
      barBgPercent: "#884DFF",
      barBg: "#C5A8FF",
    },
    {
      name: "Dwight Shrute",
      interaction: 25,
      barBgPercent: "#FF8F0D",
      barBg: "#FFD5A4",
    },
    {
      name: "Paremela Helpert",
      interaction: 45,
      barBgPercent: "#0095FF",
      barBg: "#CDE7FF",
    },
  ];

  return (
    <div className="bg-white h-[100%] p-4 custom-shadow">
      <h1 className="text-[#05004E] text-[18px] font-semibold">
        Top Community contributers
      </h1>
      <table className="w-full h-[calc(100%-3rem)] table-progess">
        <thead
          className=" !bg-white border-b-2 border-[
#EDF2F6]"
        >
          <tr>
            <th>#</th>
            <th>Name</th>
            <th className="w-[14rem]">Popularity</th>
            <th>Interaction</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {ContributerData?.map((data, index) => (
            <tr
              key={index}
              className="border-b-2 border-[
                                #EDF2F6]"
            >
              <td>0{index + 1}</td>
              <td>{data.name}</td>
              <td>
                <div>
                  <div
                    style={{ backgroundColor: data.barBg }}
                    class=" h-[.4rem] rounded-full"
                  >
                    <div
                      style={{
                        width: `${data.interaction}%`,
                        backgroundColor: data.barBgPercent,
                      }}
                      class={`h-full  progress-bar`}
                    ></div>
                  </div>
                </div>
              </td>

              <td className="">
                <div
                  style={{
                    backgroundColor: data.barBg,
                    borderColor: data.barBgPercent,
                  }}
                  className=" text-center border-2   rounded-[8px]"
                >
                  <h1>{data.interaction}%</h1>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComunityContributer;
