import React, { useContext, useRef, useState } from 'react';
import AIimage from '../../../Images/AI/icons8-artificial-intelligence-64.png'
import { GiRegeneration } from 'react-icons/gi';
import { RiFileCopy2Line } from 'react-icons/ri';
import SequenceStep from '../Sequence/SequenceStep';


import ModalComponenet from '../../../Componenets/ModalComponenet';
import email from '../../../Images/AI/email-inbox-letter-svgrepo-com.svg'
import { createSeq } from '../../../Services/apiServices';
import GetUser from '../../../Hooks/GetUser';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../Hooks/UserContext';
import Button from '../../../Componenets/Button';
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonComponent from './Skeleton';



const SevenStepRight = ({ aiResponseSevenStep, loader, setLoader }) => {
    const { setRefetch, reFetch, setFetch7step } = useContext(UserContext)
    const [openSequenceNameModal, setOpenSequenceNameModal] = useState(false)
    const [loaderSeq, setloaderSeq] = useState(false)
    const sequenceRef = useRef()
    const navigate = useNavigate()

    console.log(aiResponseSevenStep, 'aiResponseSevenStep')

    const createSequence = async () => {


        if (sequenceRef.current.value !== "") {
            setloaderSeq(true)
            const payload = {
                userId: GetUser(),

                templateName: 'Completly Custom',
                sequenceName: sequenceRef.current.value,
            }

            let res = await createSeq(payload)

            if (res.data.success) {
                setloaderSeq(false)
                setRefetch(!reFetch);
                navigate(`/sequence/${res.data.sequenceId}`);
                setFetch7step(prevState => ({ ...prevState, condition: true }))
                setOpenSequenceNameModal(false)

            }

        }
    };








    return (
        <div className='bg-white h-full'>
            <div className="p-4 bg-white">
                <div className="flex justify-end">
                    <div className="text-[13px] cursor-pointer w-28 flex items-center custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] hover:border-blue-400">
                        <div className="flex items-center">
                            <GiRegeneration className="icon-color text-[18px] hover:text-blue-400"></GiRegeneration>
                            <span className="ml-2 font-medium text-[12px] color-gray hover:text-blue-400">
                                Re Generate
                            </span>
                        </div>
                    </div>
                    <div className="text-[13px] ml-3 w-20 cursor-pointer  flex items-center custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] hover:border-blue-400">
                        <div className="flex items-center">
                            <RiFileCopy2Line className="icon-color text-[18px] hover:text-blue-400"></RiFileCopy2Line>
                            <span className="ml-2 font-medium text-[12px] color-gray hover:text-blue-400">
                                Copy
                            </span>
                        </div>
                    </div>
                </div>

                {
                    loader ? (
                        <div>
                            <SkeletonComponent />
                            <SkeletonComponent />
                            <SkeletonComponent />
                            <SkeletonComponent />
                            <SkeletonComponent />
                            <SkeletonComponent />
                            <SkeletonComponent />
                        </div>
                    ) : aiResponseSevenStep.length !== 0 ? (
                        <>
                            <div className="">
                                {aiResponseSevenStep?.map((step, index) => (
                                    <SequenceStep
                                        fromAi={true}
                                        activeStatus={aiResponseSevenStep.activeStatus}
                                        firstTriggerGapDate={aiResponseSevenStep.triggerGapDate}
                                        index={index}
                                        step={step}
                                        key={index}
                                    ></SequenceStep>
                                ))}
                            </div>

                            <div className="flex justify-center items-center mt-6">
                                <button
                                    onClick={() => setOpenSequenceNameModal(true)}
                                    className="btn !text-medium"
                                >
                                    + Create Sequence
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="flex justify-center items-center h-full pt-[5rem] generated">
                            <div>
                                <img className="w-[100px] h-[100px] mx-auto opacity-30" src={AIimage} alt="" />
                                <p className="text-center">Fill out the form on the left to see your generated content here</p>
                            </div>
                        </div>
                    )
                }








                {openSequenceNameModal && <ModalComponenet childrenPadding={'1rem'} radious={'1rem'} width={'37vw'} title={'Ai Generate Sequence'} setActiveModal={setOpenSequenceNameModal}>
                    <div className="">
                        <div className='mb-3'>
                            <img className="w-[70px] h-[70px] mx-auto" src={email} alt="" />

                        </div>
                        <div>
                            <label
                                htmlFor="sequenceName"
                                className="text-[12px] font-semibold mb-1  text-[#5d6a7e] block"
                            >
                                Sequence Name:
                            </label>
                            <input
                                ref={sequenceRef}
                                id="sequenceName"
                                type="text"
                                className="w-full p-2  primary-heading-color font-medium custom-shadow  focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                            />
                        </div>

                        <div className='mt-3  '>
                            <Button disabled={loaderSeq} loading={loaderSeq} onClick={createSequence} tittle={'Create'}></Button>

                        </div>
                    </div>
                </ModalComponenet>}
            </div>
        </div>
    );
};

export default SevenStepRight;