class Logger {
  constructor(source) {
    this.source = source;
  }
  info(mssg) {
    console.log("KB : ", this.source, " ", mssg);
  }
  verbose(...data) {
    console.log("KB : ", this.source, " ", ...data);
  }

  error(err) {
    console.error("KB : ", this.source, " ", err);
  }
}
export default Logger;
