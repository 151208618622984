import axios from "axios";
import React, { useContext, useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../Componenets/Button";
import properties from "../../../config/properties";
import { UserContext } from "../../../Hooks/UserContext";
import Loading from "../../Shared/Loading/Loading";

const DeleteSequenceModal = ({
  setModalState,
  index,
  sequenceId,
  sequenceName,
  user,
  stepId,
  stepDelete,
  setActiveDeleteModal
}) => {
  const { reFetch, setRefetch } = useContext(UserContext);

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const handleDeletestep = () => {
    setLoader(true)
    if (stepDelete) {
      axios
        .delete(
          `${properties.REACT_APP_SERVER_URL}/api/v1/step/deleteStep/${user}/${sequenceId}/${stepId}`
        )
        .then((res) => {
          if (res.data.success) {
            toast.success('step deleted')
            setActiveDeleteModal(false)
            setRefetch(!reFetch);
            setLoader(false)

          }
        });
    } else {
      axios
        .delete(
          `${properties.REACT_APP_SERVER_URL}/api/v1/sequence/deleteSequence/${user}/${sequenceId}`
        )
        .then((res) => {
          if (res.data.success) {
            navigate("/sequence");
            setModalState(prevState => ({ ...prevState, activeDeleteModal: false }))

            setLoader(false)
          }
        });
    }
  };
  const closeDelete = () => {

  }



  return (
    <div>
      <div className="popup  ">
        <div className="modal-empty-div"></div>
        <div className="content-container mt-3">
          <div className="bg-white w-[500px]  popup-content !rounded-[4px] p-4">
            <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
              <h1 className="text-[#242d3e] text-[15px] font-semibold">
                Delete {sequenceName ? sequenceName : "step"}{" "}
                {!sequenceName && index + 1}{" "}
              </h1>
              <button
                onClick={() => {
                  setActiveDeleteModal(false)

                  setModalState(prevState => ({ ...prevState, activeDeleteModal: false }))
                }


                }
                className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
              >
                &times;
              </button>
            </div>

            <div className="py-4">
              <h1 className="text-[14px] font-500">
                Are you sure you want to delete{" "}
                {sequenceName ? sequenceName : "step"}{" "}
                {!sequenceName && index + 1} ?
              </h1>
            </div>
            <div className="flex justify-end">
              <div className="flex">
                <button
                  onClick={closeDelete}
                  className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
                >
                  Cancel
                </button>

                <div>


                  <Button loading={loader} disabled={loader} onClick={handleDeletestep} tittle={'Delete'}></Button>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default DeleteSequenceModal;
