import React, { useContext } from 'react';
import SingleCard from './SingleCard';


const EmailTemplate = ({ filterTemplate }) => {

    return (
        <div className='grid grid-cols-4 gap-4'>
            {
                filterTemplate?.map((template, index) => {
                    return <div className="div" onClick={template?.onclick}>
                        <SingleCard key={index} template={template} />
                    </div>
                })
            }
        </div>
    );
};

export default EmailTemplate;