import React from "react";
import { customVariable } from "../../../Utils/customVariable";

const PersonalizeComponent = ({
  handleInsertVariable,
  personalizeBtn,
  isPersonalisePopupOpen,
}) => {
  return (
    <div ref={personalizeBtn}>
      <div>
        {isPersonalisePopupOpen && (
          <div className="w-[17rem] border-2 rounded-[6px] border-[#d3dae3] right-2 shadow-personalize z-40 bottom-0 h-[16rem] bg-white absolute">
            <div className="relative h-full w-full">
              <ul className="absolute h-full w-full overflow-auto  scrollbar-container ">
                {customVariable.map((variable) => (
                  <li
                    key={variable}
                    onClick={() => handleInsertVariable(`{{${variable}}}`)}
                    className="border-personalize py-2 hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                  >
                    <button className="text-[13px] color-blue">
                      {`{{${variable}}}`}
                    </button>
                  </li>
                ))}

                {/* <li
                  onClick={() => handleInsertVariable("{{full name}}")}
                  className="border-personalize py-2 hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue">
                    {`{{full name}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{first name}}")}
                  className="border-personalize py-2 hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue  ">
                    {`{{first name}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{last name}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue  ">
                    {`{{last name}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{company name}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue  ">
                    {`{{company name}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{CEO name}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue   ">
                    {`{{CEO name}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{city}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue   ">
                    {`{{city}}`}
                  </button>
                </li>

                <li
                  onClick={() =>
                    handleInsertVariable("{{one line personalization}}")
                  }
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue   ">
                    {`{{one line personalisation}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{company size}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue   ">
                    {`{{company Size}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{competitor}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue   ">
                    {`{{competitor}}`}
                  </button>
                </li>

                <li
                  onClick={() =>
                    handleInsertVariable("{{years in the company}}")
                  }
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue   ">
                    {`{{years in the company}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{job title}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue">
                    {`{{job title}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{location}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue">
                    {`{{location}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{country}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue">
                    {`{{country}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{state}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue">
                    {`{{state}}`}
                  </button>
                </li>

                <li
                  onClick={() => handleInsertVariable("{{city}}")}
                  className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
                >
                  <button className="text-[13px] color-blue">
                    {`{{city}}`}
                  </button>
                </li> */}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalizeComponent;
