import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";

import properties from "./config/properties";
import "./Custom-css/Custom.css";

import { Carousel, initTE } from "tw-elements";
import { Provider } from "react-redux";
import { store } from "./redux/store";

initTE({ Carousel });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={properties.REACT_APP_OAUTH_CLIENT_ID}>
        <Provider store={store}>
          <App />
        </Provider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </>
);

reportWebVitals();
