export const emoji = [
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "😅",
  "😂",
  "🤣",
  "🥲",
  "☺️",
  "😊",
  "😇",
  "🙂",
  "🙃",
  "😉",
  "😌",
  "😍",
  "🥰",
  "😘",
  "😗",
  "😙",
  "😚",
  "😋",
  "😛",
  "😝",
  "😜",
  "🤪",
  "🤨",
  "🧐",
  "🤓",
  "😎",
  "🥸",
  "🤩",
  "🥳",
  "😏",
  "😒",
  "😞",
  "😔",
  "😟",
  "😕",
  "🙁",
  "😣",
  "😖",
  "😫",
  "😩",
  "🥺",
  "😢",
  "😭",
  "😤",
  "😠",
  "😡",
  "🤬",
  "🤯",
  "😳",
  "🥵",
  "🥶",
  "😱",
  "😨",
  "😰",
  "😥",
  "😓",
  "🤗",
  "🤔",
  "🤭",
  "🤫",
  "🤥",
  "😶",
  "😐",
  "😑",
  "😬",
  "🙄",
  "😯",
  "😦",
  "😧",
  "😮",
  "😲",
  "🥱",
  "😴",
  "🤤",
  "😪",
  "😵",
  "🤢",
  "🤮",
  "🤧",
  "😷",
  "🤒",
  "🤕",
  "🤑",
  "👍",
  "👎",
  "👊",
  "✊",
  "🤛",
  "🤜",
  "🤞",
  "✌️",
  "🤟",
  "🤘",
  "👌",
  "👈",
  "👉",
  "👆",
  "👇",
  "☝️",
  "✋",
  "🤚",
  "🖐️",
  "🖖",
  "👋",
  "🤙",
  "💪",
  "🦾",
  "🦿",
  "🙌",
  "👏",
  "🤝",
  "👂",
  "👃",
  "🧠",
  "🦷",
  "🦴",
  "👀",
  "👁️",
  "👅",
  "👄",
  "💋",
  "🩸",
  "👶",
  "🧒",
  "👦",
  "👧",
  "🧑",
  "👱",
  "👶🏻",
  "👦🏻",
  "👧🏻",
  "👨🏻",
  "👩🏻",
  "👱🏻‍♀️",
  "👱🏻‍♂️",
  "🧔🏻",
  "👴🏻",
  "👵🏻",
  "👲🏻",
  "👳🏻‍♀️",
  "👳🏻‍♂️",
  "🧕🏻",
  "👮🏻‍♀️",
  "👮🏻‍♂️",
  "👷🏻‍♀️",
  "👷🏻‍♂️",
  "💂🏻‍♀️",
  "💂🏻‍♂️",
  "🕵🏻‍♀️",
  "🕵🏻‍♂️",
  "👩🏻‍⚕️",
  "👨🏻‍⚕️",
  "👩🏻‍🌾",
  "👨🏻‍🌾",
  "👩🏻‍🍳",
  "👨🏻‍🍳",
  "👩🏻‍🎓",
  "👨🏻‍🎓",
  "👩🏻‍🎤",
  "👨🏻‍🎤",
  "👩🏻‍🏫",
  "👨🏻‍🏫",
  "👩🏻‍🏭",
  "👨🏻‍🏭",
  "👩🏻‍💻",
  "👨🏻‍💻",
  "👩🏻‍💼",
  "👨🏻‍💼",
  "👩🏻‍🔧",
  "👨🏻‍🔧",
  "👩🏻‍🔬",
  "👨🏻‍🔬",
  "👩🏻‍🎨",
  "👨🏻‍🎨",
  "👩🏻‍🚒",
  "👨🏻‍🚒",
  "👩🏻‍✈️",
  "👨🏻‍✈️",
  "👩🏻‍🚀",
  "👨🏻‍🚀",
  "👩🏻‍⚖️",
  "👨🏻‍⚖️",
  "👰🏻",
  "🤵🏻",
  "👸🏻",
  "🤴🏻",
  "🤶🏻",
  "🎅🏻",
  "🦸‍♀️",
  "🦸‍♂️",
  "🦹‍♀️",
  "🦹‍♂️",
  "🧙‍♀️",
  "🧙‍♂️",
  "🧚‍♀️",
  "🧚‍♂️",
  "🧛‍♀️",
  "🧛‍♂️",
  "🧜‍♀️",
  "🧜‍♂️",
  "🧝‍♀️",
  "🧝‍♂️",
  "🙍‍♀️",
  "🙍‍♂️",
  "🙎‍♀️",
  "🙎‍♂️",
  "🙅‍♀️",
  "🙅‍♂️",
  "🙆‍♀️",
  "🙆‍♂️",
  "💁‍♀️",
  "💁‍♂️",
  "🙋‍♀️",
  "🙋‍♂️",
  "🙇‍♀️",
  "🙇‍♂️",
  "🤦‍♀️",
  "🤦‍♂️",
  "🤷‍♀️",
  "🤷‍♂️",
  "💆‍♀️",
  "💆‍♂️",
  "💇‍♀️",
  "💇‍♂️",
  "🚶‍♀️",
  "🚶‍♂️",
  "🏃‍♀️",
  "🏃‍♂️",
  "💃",
  "🕺",
  "👯‍♀️",
  "👯‍♂️",
  "👫",
  "👬",
  "👭",
  "💑",
  "👩‍❤️‍👨",
  "👨‍❤️‍👨",
  "👩‍❤️‍👩",
  "👪",
  "👱‍♀️",
  "👱‍♂️",
  "👨‍🦰",
  "👩‍🦰",
  "👨‍🦱",
  "👩‍🦱",
  "👨‍🦲",
  "👩‍🦲",
  "👨‍🦳",
  "👩‍🦳",
  "🦮",
  "🐕‍🦺",
  "🐩",
  "🐕",
  "🐈",
  "🐈‍⬛",
  "🦜",
  "🌟",
  "✨",
  "🔥",
  "💥",
  "🌈",
  "🌺",
  "🌻",
  "🌼",
  "🌷",
  "🌱",
  "🍁",
  "🍂",
  "🍃",
  "🌿",
  "🍀",
  "🌾",
  "🍄",
  "🐚",
  "🌊",
  "🌕",
  "🌖",
  "🌗",
  "🌘",
  "🌑",
  "🌒",
  "🌓",
  "🌔",
  "🌙",
  "🌎",
  "🌍",
  "🌏",
  "🚀",
  "🛸",
  "🌌",
  "🌠",
  "🌞",
  "🌝",
  "🌛",
  "🌜",
  "🌧️",
  "⚡",
  "🌩️",
  "❄️",
  "☃️",
  "⛄",
  "🌨️",
  "🌬️",
  "🌪️",
  "🌫️",
  "🌥️",
  "🌦️",
  "🌤️",
  "🌀",
  "💦",
  "💨",
  "☀️",
  "⛅",
  "⛈️",
  "🌰",
  "🌐",
  "🗺️",
  "🏔️",
  "⛰️",
  "🌋",
  "🗻",
  "🏕️",
  "🏞️",
  "🐶",
  "🐱",
  "🐭",
  "🐹",
  "🐰",
  "🦊",
  "🐻",
  "🐼",
  "🐨",
  "🐯",
  "🦁",
  "🐮",
  "🐷",
  "🐽",
  "🐸",
  "🐙",
  "🐵",
  "🙈",
  "🙉",
  "🙊",
  "🐒",
  "🦍",
  "🐔",
  "🐧",
  "🐦",
  "🐤",
  "🐣",
  "🐥",
  "🦆",
  "🦢",
  "🦉",
  "🦚",
  "🦩",
  "🐝",
  "🐛",
  "🦋",
  "🐌",
  "🐞",
  "🐜",
  "🦗",
  "🕷️",
  "🕸️",
  "🦂",
  "🐢",
  "🐍",
  "🦎",
  "🦖",
  "🦕",
  "🐊",
  "🐋",
  "🐳",
  "🐬",
  "🦈",
  "🐟",
  "🐠",
  "🐡",
  "🦐",
  "🦑",
  "🦀",
  "🦞",
  "🌵",
  "🎄",
  "🌲",
  "🌳",
  "🌴",
  "☘️",
  "🎍",
  "🎋",
  "💐",
  "🌹",
  "🥀",
  "🌸",
  "🌚",
  "🍇",
  "🍈",
  "🍉",
  "🍊",
  "🍋",
  "🍌",
  "🍍",
  "🥭",
  "🍎",
  "🍏",
  "🍐",
  "🍑",
  "🍒",
  "🍓",
  "🥝",
  "🍅",
  "🥥",
  "🥑",
  "🍆",
  "🥔",
  "🥕",
  "🌽",
  "🌶️",
  "🥒",
  "🥬",
  "🥦",
  "🧄",
  "🧅",
  "🥜",
  "🍞",
  "🥐",
  "🥖",
  "🥨",
  "🥯",
  "🥞",
  "🧇",
  "🧀",
  "🍖",
  "🍗",
  "🥩",
  "🥓",
  "🍔",
  "🍟",
  "🍕",
  "🌭",
  "🥪",
  "🌮",
  "🌯",
  "🥙",
  "🧆",
  "🥚",
  "🍳",
  "🥘",
  "🍲",
  "🥣",
  "🥗",
  "🍱",
  "🍘",
  "🍙",
  "🍚",
  "🍛",
  "🍜",
  "🍝",
  "🍠",
  "🍢",
  "🍣",
  "🍤",
  "🍥",
  "🥮",
  "🍡",
  "🥟",
  "🥠",
  "🥡",
  "🍦",
  "🍧",
  "🍨",
  "🍩",
  "🍪",
  "🎂",
  "🍰",
  "🧁",
  "🥧",
  "🍫",
  "🍬",
  "🍭",
  "🍮",
  "🍯",
  "🍼",
  "🥛",
  "☕",
  "🍵",
  "🍶",
  "🍾",
  "🍷",
  "🍸",
  "🍹",
  "🍺",
  "🍻",
  "🥂",
  "🥃",
  "🥤",
  "🧃",
  "🧉",
  "🧊",
  "🧋",
  "🫖",
  "🍽️",
];
