import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { TiTimes } from 'react-icons/ti';

const InputField = ({ label, name, register, required, icon, setAdditionalFields, additionalFields, stateName }) => {
    return (
        <div className="flex-1">
            <div className='flex items-center mb-2'>
                <label
                    htmlFor={name}
                    className="text-[12px] font-semibold  text-[#5d6a7e] block"
                >

                    {label}



                </label>

                {(icon && additionalFields !== 1) && <TiTimes className='ml-2 icon-color cursor-pointer' onClick={() => setAdditionalFields((prevState) => {
                    return {
                        ...prevState, [stateName]: prevState[stateName] - 1
                    }
                })} />}
            </div>
            <div className='flex items-start'>
                <input
                    {...register(name, { required: required })}
                    id={name}
                    type="text"
                    className="w-full text-[13px]  primary-heading-color font-medium custom-shadow-1 pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                />



                {
                    icon && < button type="button" className='ml-2 p-2 border-2 border-border-gray' onClick={() => setAdditionalFields((prevState) => {
                        return {
                            ...prevState, [stateName]: prevState[stateName] + 1
                        }
                    })}><BiPlus /></button>
                }
            </div>
        </div >
    );
};

export default InputField