import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GetUser from "../../../Hooks/GetUser";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading/Loading";
import {
  mailboxConnection,
  saveProspects,
  uploadProspect,
} from "../../../Services/apiServices";
import { UserContext } from "../../../Hooks/UserContext";
import ImportComponent from "../../../Componenets/ImportComponenet";
import UploadDialog from "./UploadDialog";

const ImportContactSeq = () => {
  const user = GetUser();

  const [mailboxes, setMailboxes] = useState([]);
  const { sequenceId, setImportData, setImportDataModal } =
    useContext(UserContext);
  const [loader, setLoader] = useState();
  const [excelsheetId, setExcelsheedId] = useState(null);
  const [csvColumn, setCsvColumn] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [columnMatch, setColumnMatch] = useState(false);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();
  const [uploadedRows, setUploadedRows] = useState(0);
  const [selectedListName, setSelectedListName] = useState([]);

  // good or bad contact
  const [contactCounts, setContactCounts] = useState({
    contactsWithoutEmail: 0,
    contactsWithoutCompanyName: 0,
  });
  const [goodContacts, setGoodContacts] = useState([]);
  const [badContacts, setBadContacts] = useState([]);

  const [importStatsModal, setImportStatsModal] = useState(false);

  useEffect(() => {
    async function getMailboxConnection() {
      const payload = {
        user: user,
      };
      let response = await mailboxConnection(payload);
      if (response) {
        if (response.data.data.connections.length > 0) {
          setSelectedOption({
            label: response.data.data.connections[0].email,
            value: response.data.data.connections[0].email,
          });
        }
        setMailboxes(
          response.data.data.connections.map((mailbox) => ({
            value: mailbox.email,
            label: mailbox.email,
          }))
        );

        setLoader(false);
      }
    }
    getMailboxConnection();
  }, [user]);

  // for upload file
  const handleFileUpload = async (excelFile) => {
    if (excelFile) {
      // extract csv file
      const data = await excelFile?.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData?.length === 0 || Object.values(jsonData[0]).length === 0) {
        toast.error("Invalid Excel sheet");
        return;
      }

      const sheetNames = workbook.SheetNames;
      const columnNames = new Set();

      sheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (jsonData?.length > 0) {
          const sheetColumnNames = jsonData[0];
          sheetColumnNames.forEach((columnName) => {
            columnNames.add(columnName);
          });
        }
      });

      setCsvColumn([...columnNames]);

      // Check for missing email or company name
      let contactsWithoutEmailCount = 0;
      let contactsWithoutCompanyNameCount = 0;

      const emailColumnRegex = /email/i;
      const companyNameColumnRegex = /company.*name/i;

      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (sheetData.length > 0) {
        const headerRow = sheetData[0];
        const emailColumnIndex = headerRow.findIndex((columnName) =>
          columnName.match(emailColumnRegex)
        );

        let companyNameColumnIndex = -1;

        headerRow.forEach((columnName, index) => {
          if (
            columnName.match(companyNameColumnRegex) ||
            columnName.toLowerCase() === "company" ||
            columnName.toLowerCase() === "company name"
          ) {
            companyNameColumnIndex = index;
          }
        });

        if (emailColumnIndex !== -1) {
          contactsWithoutEmailCount = sheetData.reduce(
            (count, row) => (row && !row[emailColumnIndex] ? count + 1 : count),
            0
          );
        }

        if (companyNameColumnIndex !== -1) {
          contactsWithoutCompanyNameCount = sheetData.reduce(
            (count, row) =>
              row && !row[companyNameColumnIndex] ? count + 1 : count,
            0
          );
        }
        const contactsWithEmailAndCompanyName = jsonData.filter(
          (contact) =>
            contact[headerRow[emailColumnIndex]] &&
            contact[headerRow[companyNameColumnIndex]]
        );

        const contactsWithoutEmailOrCompanyName = jsonData.filter(
          (contact) =>
            !contact[headerRow[emailColumnIndex]] ||
            !contact[headerRow[companyNameColumnIndex]]
        );

        setGoodContacts(contactsWithEmailAndCompanyName);
        setBadContacts(contactsWithoutEmailOrCompanyName);
      }

      setContactCounts({
        contactsWithoutEmail: contactsWithoutEmailCount,
        contactsWithoutCompanyName: contactsWithoutCompanyNameCount,
      });

      // send file to the backend
      const formData = new FormData();
      formData.append("excelFile", excelFile);
      formData.append("userId", user);

      setLoader(true);
      const payload = formData;
      let res = await uploadProspect(payload);
      if (res.data.success === true) {
        setExcelsheedId(res.data.excelsheetId);
        setImportStatsModal(true);
        // setDisablebtn(false);
        // console.log("in");
        setFileUpload(true);
        setUploadedRows(jsonData.length);

        toast.success(`${jsonData.length} contact uploaded`);
        setLoader(false);
      }
    }
  };

  const handleImport = async () => {
    if (Object.values(columnMatch).includes("email") === false) {
      return toast.error("Please select email in the match columns");
    } else if (Object.values(columnMatch).includes("companyName") === false) {
      return toast.error("Please select companyName  in the match columns");
    }
    setLoader(true);
    const payload = {
      matchColumns: columnMatch,
      userId: user,
      excelsheetId: excelsheetId,
      sequenceId: sequenceId,
      senderEmail: selectedOption?.value,
      listName: selectedListName.value ? selectedListName.value : null,
    };

    try {
      let res = await saveProspects(payload);
      if (res.data.success) {
        const pathName = localStorage.getItem("importLocation");

        setImportData(res.data.importedProspectCount);

        setLoader(false);
        navigate(pathName);
        setTimeout(() => {
          setImportDataModal(true);
        }, 2000);
      }
    } catch (error) {
      toast.error("Failed to save prospects");
    } finally {
      setLoader(false);
    }
  };

  if (loader) {
    return <Loading whiteLoader="whiteLoader"></Loading>;
  }

  return (
    <div>
      <ImportComponent
        selectedListName={selectedListName}
        setSelectedListName={setSelectedListName}
        setFileUpload={setFileUpload}
        uploadedData={uploadedRows}
        mailboxes={mailboxes}
        setSelectedOption={setSelectedOption}
        showMailbox={true}
        to={`/sequence/${sequenceId}`}
        handleImport={handleImport}
        handleFileUpload={handleFileUpload}
        fileUpload={fileUpload}
        csvColumn={csvColumn}
        setColumnMatch={setColumnMatch}
        loader={loader}
      />

      {importStatsModal && (
        <UploadDialog
          setFileUpload={setFileUpload}
          contactCounts={contactCounts}
          setImportStatsModal={setImportStatsModal}
          badContacts={badContacts}
          goodContacts={goodContacts}
        />
      )}
    </div>
  );
};

export default ImportContactSeq;
