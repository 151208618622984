import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { emailSent7day } from "../../../Services/apiServices";
import GetUser from "../../../Hooks/GetUser";
import Skeleton from 'react-loading-skeleton';
const TotalOutreach = () => {
  const chartRef = useRef(null);
  const userId = GetUser();


  useEffect(() => {

    async function fetchData() {
      let response = await emailSent7day(userId);
      if (response.data.status) {


        const weeklyEmailSentData = response.data.weeklyEmailSentData;
        const days = Object.keys(weeklyEmailSentData);
        const updatedData = [];

        for (let i = 0; i < days.length; i++) {
          const day = days[i];
          updatedData.push(weeklyEmailSentData[day] || 0);
        }

        const options = {
          series: [
            {
              name: "Email",
              data: updatedData,
            },
          ],
          chart: {
            type: "bar",
            height: 430,
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          stroke: {
            show: true,
            width: 5,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: days,
          },
          title: {
            text: "Tracking Email within 7 days",
            align: "left",
            offsetY: 15,
            offsetX: 10,
          },
        };

        var chart = new ApexCharts(
          document.querySelector("#outreach"),
          options
        );
        chart.render();
      }


    }

    fetchData();
  }, [userId]);

  return (
    <div
      ref={chartRef}
      className="bg-white custom-shadow font-medium"
      id="outreach"
    >

    </div>
  );
};

export default TotalOutreach;
