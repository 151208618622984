import React, { useEffect, useState } from "react";
import downArrow from "../../../Images/downarrow.svg";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import properties from "../../../config/properties";
import { FiChevronDown } from 'react-icons/fi'
import { hubspotMatchColumn } from "../../../Services/apiServices";
import GetUser from "../../../Hooks/GetUser";
import Button from "../../../Componenets/Button";

const HubSpotMatchModal = ({
  hubspotAccessToken,
  hubspotProperties,
  setOpenHubspotModal,
}) => {
  const [hubspotPropertiesUI, setHubspotPropertiesUI] = useState([]);
  const [hubspotProperitesBackend, setHubspotProperitesBackend] = useState([]);
  const [matchProperties, setMatchProperties] = useState({});
  const [disableBtn, setDisablebtn] = useState(true);
  const user = GetUser()
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  // optionConfig represents Klubstack Properties.
  // here, key represents options that are shown as UI(frontend) and value represents field name of prospects schema(backend)
  const optionConfig = {
    "Full Name": "name",
    "First Name": "firstName",
    "Last Name": "secondName",
    "Email": "email",
    "Job Title": "jobTitle",
    "Company Name": "companyName",
    "Years of Experience": "yearsOfExperience",
    "Company Website": "companyWebUrl",
    "LinkedIn Url": "linkedinUrl",
    "Phone Number": "phoneNumber",
    "Whatsapp Number": "whatsappNumber",
    "Location": "location",
    "Company size": "companySize",

    "Twitter Url": "twitterUrl",
    "Pomotion": "promotion",
    "Recent Job change": "recentJobChange",
    "Years in the company": "yersInTheCompany",
    "Company Description": "companyDescription",
    "CEO": "ceo",
    "Competitor": "competitor",
    "One line personalization": "oneLinePersonalization",
  };

  useEffect(() => {
    // check auth


    let tempHubspotProperties = [];
    let tempHubspotArr = hubspotProperties.map((item) => {
      switch (item) {
        case "firstname":
          tempHubspotProperties.push(item);
          return "First Name";
        case "lastname":
          tempHubspotProperties.push(item);
          return "Last Name";
        case "associatedcompanyid":
          tempHubspotProperties.push(item);
          return "Company ID";
        case "mobilephone":
          tempHubspotProperties.push(item);
          return "Mobile Phone";
        case "jobtitle":
          tempHubspotProperties.push(item);
          return "Job Title";
        case "twitterprofilephoto":
          return "";
        case "twitterhandle":
          return "";
        default:
          let name = item;
          if (name.includes("_")) {
            let tempValue = name.split("_");
            name = "";
            for (let i = 0; i < tempValue.length; i++) {
              let tempStr = tempValue[i].slice(1, tempValue[i].length);
              name += tempValue[i].charAt(0).toUpperCase() + tempStr + " ";
            }
          } else {
            let tempStr = name.slice(1, name.length);
            name = name.charAt(0).toUpperCase() + tempStr;
          }
          tempHubspotProperties.push(item);
          return name;
      }
    });

    setHubspotProperitesBackend([...tempHubspotProperties]);

    tempHubspotArr = tempHubspotArr.filter((item) => {
      if (item) {
        return true;
      } else {
        return false;
      }
    });

    setHubspotPropertiesUI(tempHubspotArr);
  }, []);

  function updateOptions(index, selectedValue) {
    let hubspotProperitesVal = Object.values(hubspotProperitesBackend);
    let selectedOption = {};

    for (let i = 0; i < hubspotProperitesVal.length; i++) {
      selectedOption[hubspotProperitesVal[i]] = document.getElementById(
        `${hubspotProperitesVal[i]} ${i}`
      ).value;
    }

    // check if same option selected more than once.
    let selectedOptionsArr = Object.values(selectedOption);
    // example: selectedOptionsArr ['Full Name', 'First Name', 'Email']
    // if email selected twice, selectedOptionsArr ['Full Name', 'First Name', 'Email', 'Email']
    for (let i = 0; i < selectedOptionsArr.length; i++) {
      for (let j = 0; j < selectedOptionsArr.length; j++) {
        if (
          i !== j &&
          i !== index &&
          selectedOptionsArr[i] === selectedOptionsArr[j]
        ) {
          // Select Previous Mapping to Ignore
          selectedOption[hubspotProperitesVal[i]] = "Ignore";
          document.getElementById(`${hubspotProperitesVal[i]} ${i}`).value =
            "Ignore";
          delete matchProperties[hubspotProperitesVal[i]];
        }
      }
    }
    // example: selectedOption = {
    //     "Full Name": "Full Name",
    //     "Email Address": "Email",
    // }
    // In selectedOption, Keys represents hubspot properties and Values represents selected options
    for (let i = 0; i < hubspotProperitesVal.length; i++) {
      if (selectedOption[hubspotProperitesVal[i]] !== "Ignore") {
        matchProperties[hubspotProperitesVal[i]] =
          selectedOption[hubspotProperitesVal[i]];
      }
    }
    setMatchProperties({ ...matchProperties });

    // example: matchProperties = {
    //     "Full Name": "name",
    //     "Email Address": "email",
    // }
    // In matchProperties, Keys represents column names of excelsheet and Values represents attributes of database schema
    if (selectedValue === "Ignore") {
      // console.log(selectedValue, index);
      let allKeysOfMatchProperties = Object.keys(matchProperties);

      for (let i = 0; i < allKeysOfMatchProperties.length; i++) {
        if (allKeysOfMatchProperties[i] === hubspotProperties[index]) {
          delete matchProperties[allKeysOfMatchProperties[i]]; // if selected option is Ignore, then remove from matchProperties
          setMatchProperties({ ...matchProperties });
        }
      }
    }

    console.log(matchProperties);
    if (!Object.values(matchProperties).includes("email")) {
      setDisablebtn(true);
    } else {
      setDisablebtn(false);
    }
  }

  const submitMatchProspects = async () => {
    // making key as value and value as key in tempObj
    // so that keys represent klubstack properties and values represent hubspot properties
    var allKeysOfMatchProperties = Object.keys(matchProperties);
    var allValuesOfMatchProperties = Object.values(matchProperties);
    var tempObj = {};
    for (let i = 0; i < allValuesOfMatchProperties.length; i++) {
      tempObj[allValuesOfMatchProperties[i]] = allKeysOfMatchProperties[i];
    }

    // console.log(tempObj)
    setLoader(true)
    const payLoad = {
      userId: user,
      hubspotAccessToken: hubspotAccessToken,
      matchProperties: tempObj,
    }

    let res = await hubspotMatchColumn(payLoad)
    if (res.data.success === true) {
      setLoader(false)
      window.location.href = res.data.redirect;
    }

  };

  return (
    <div className="popup">
      <div className="modal-empty-div"></div>
      <div className="content-container w-[60vw] !h-[92%] max-h-[92%] !z-[9]">
        <div className=" popup-content min-h-full  !bg-white  p-4">

          {/* <div className="flex items-center">
              <button
                disabled={disableBtn}
                onClick={() => submitMatchProspects()}
                className={` w-[130px] mx-4 relative cursor-pointer h-[40px] rounded-[8px]  font-semibold bg-[#3B59DF] text-white ${disableBtn ? "disabled-btn" : ""
                  }`}
              >
                Save
              </button>

              <button
                onClick={() => {
                  setOpenHubspotModal(false);
                  navigate("/prospects");
                }}
                className="text-[#000000]  bottom-3 text-[30px]"
              >
                &times;
              </button>
            </div> */}

          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
            <h1 className="primary-heading-color font-semibold">
              Hubspot Match Column
            </h1>

            <div className="flex items-center">
              {/* <button
                disabled={disableBtn}
                onClick={() => submitMatchProspects()}
                className={` w-[130px] mx-4 relative cursor-pointer h-[40px] rounded-[8px]  font-semibold bg-[#3B59DF] text-white ${disableBtn ? "disabled-btn" : ""
                  }`}
              >
                Save
              </button> */}


              {/* <button
                onClick={() => {
                  setOpenHubspotModal(false);
                  navigate("/prospects");
                }}
                className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
              >
                &times;
              </button> */}

              <button
                onClick={() => {
                  setOpenHubspotModal(false);
                  navigate("/prospects");
                }}
                className={`border  border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
              >
                Cancel
              </button>

              <Button loading={loader} disabled={loader} tittle={'Save'} onClick={submitMatchProspects}>

              </Button>
            </div>

          </div>




          <div className="w-[80%] mx-auto my-10">
            <div className="flex justify-center">
              <div>
                <div className="flex justify-between">
                  <h1 className="text-[15px] font-medium ">
                    Hubspot Properties
                  </h1>
                  <h1 className="text-[15px] font-medium ">
                    Klubstack Properties
                  </h1>
                </div>
                <div>
                  {hubspotPropertiesUI.map((singleCol, index) => {
                    return (
                      <div onLoad={() => updateOptions(index)}>
                        <div
                          key={index}
                          className="flex  relative items-center my-7"
                        >
                          <div
                            className={` flex items-center pl-4 w-[17.6rem]  h-[3rem] rounded-[6px] border-column`}
                          >
                            <p className="text-[14px]">{singleCol}</p>
                          </div>
                          <div>
                            <select
                              id={`${hubspotProperitesBackend[index]} ${index}`}
                              className={`w-[17rem] focus:outline-none ml-20 h-12 rounded-[4px] text-[14px]`}
                              onChange={(e) =>
                                updateOptions(index, e.target.value)
                              }
                            >
                              <option
                                className="input-item"
                                value="Ignore"
                                selected
                              >
                                Ignore
                              </option>
                              {Object.keys(optionConfig).map((i) => {
                                return singleCol
                                  .toLowerCase()
                                  .includes(i.toLowerCase()) ? (
                                  <option
                                    key={i}
                                    className="input-item"
                                    value={optionConfig[i]}
                                    selected
                                  >
                                    {i}
                                  </option>
                                ) : singleCol
                                  .toLowerCase()
                                  .includes(i.toLowerCase().split(" ")[0]) ? (
                                  <option
                                    key={i}
                                    className="input-item"
                                    value={optionConfig[i]}
                                    selected
                                  >
                                    {i}
                                  </option>
                                ) : (
                                  <option
                                    key={i}
                                    className="input-item"
                                    value={optionConfig[i]}
                                  >
                                    {i}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="inline-block absolute right-[1.5rem] top-4">
                              {/* <img
                                className="w-[11px] h-[11px]"
                                src={downArrow}
                                alt=""
                              /> */}
                              <FiChevronDown />
                            </div>
                          </div>
                          {/* <KlubstackColumn index={index}></KlubstackColumn> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default HubSpotMatchModal;
