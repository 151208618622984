import React, { useCallback, useContext, useEffect, useState } from "react";
import { AiFillBackward } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import { columns } from "./TableCoulmns";
import GetUser from "../../../Hooks/GetUser";
import { detailsStats } from "../../../Services/apiServices";
import Loading from "../../Shared/Loading/Loading";
import ReactPaginate from "react-paginate";
import contactSvg from "../../../Images/Contact.svg";
import LeadsUpdateDrawer from "./LeadsUpdateDrawer";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../../Hooks/useDebounce";

import ModalComponenet from "../../../Componenets/ModalComponenet";
import { UserContext } from "../../../Hooks/UserContext";
import AddProspectDrawer from "../Prospectus/AddProspectDrawer";

const DetailsStat = ({ back }) => {
  const [loader, setLoader] = useState(false);
  let { sequenceId, filterBy, stepId } = useParams();
  const [detailsData, setDetailsData] = useState([]);
  const [noData, setNodata] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [singleRowData, setSingleRowData] = useState({});
  const [fethcDetailsStats, setFetchDetailsStats] = useState(false);
  const [ableUpdateProspect, setAbleUpdateProspect] = useState(false);
  const [showLeadsBtn, setShowLeadsBtn] = useState(false);
  const [search, setSearch] = useState("");
  const { importDataModal, importData, setImportDataModal } =
    useContext(UserContext);
  const location = useLocation();
  const isLead = location.pathname.split("/")[3];

  const user = GetUser();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/sequence/${sequenceId}`);
  };

  useEffect(() => {
    // Check if the URL contains "prospectsInSeq"
    const containsProspectsInSeq = location.pathname.includes("prospectsInSeq");
    if (containsProspectsInSeq) {
      setShowLeadsBtn(true);
    }
  }, [location]);

  const handleSearch = useCallback(
    async (searchValue) => {
      setLoader(true);

      let params = new URLSearchParams();
      params.append("userId", user);
      params.append("filterBy", filterBy);
      params.append("skipFrom", 20 * currentPage);
      params.append("sequenceId", sequenceId);
      if (searchValue) {
        params.append("searchText", searchValue);
      }
      if (stepId) {
        params.append("stepId", stepId);
      }

      try {
        let res = await detailsStats(params.toString());

        if (res.data.status) {
          setDetailsData(res.data.prospects);
          setTotalData(res?.data.totalProspects);
          setLoader(false);
        }
      } catch (error) {
        // Handle any errors that may occur during the API request
        console.error("An error occurred:", error);
        // You can set error state or perform other error handling here
      }
    },
    [currentPage, filterBy, sequenceId, stepId, user]
  );

  const debouncedSearchValue = useDebounce(search, 1000);

  useEffect(() => {
    handleSearch(debouncedSearchValue);
  }, [debouncedSearchValue, handleSearch]);

  const handleChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };

  useEffect(() => {
    setLoader(true);
    // if (isLead === "leads") {
    //   setAbleUpdateProspect(true);
    // }

    async function getDetails() {
      let params = new URLSearchParams();
      params.append("userId", user);
      params.append("skipFrom", 20 * currentPage);
      params.append("sequenceId", sequenceId);
      params.append("filterBy", filterBy);
      if (stepId) {
        params.append("stepId", stepId);
      }

      let response = await detailsStats(params.toString());

      if (response.data.status) {
        setTotalData(response?.data.totalProspects);

        if (response?.data?.prospects) {
          setDetailsData(response?.data?.prospects);
          setLoader(false);

          // Find the index of the row with matching _id in detailsData
          const matchingIndex = response.data.prospects.findIndex(
            (prospect) => prospect?._id === singleRowData?._id
          );

          // If a matching index is found, update the singleRowData state
          if (matchingIndex !== -1) {
            setSingleRowData(response.data.prospects[matchingIndex]);
          }
        } else {
          setNodata(true);
          setLoader(false);
        }
      }
    }

    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    filterBy,
    sequenceId,
    user,
    fethcDetailsStats,
    isLead,
    stepId,
  ]);

  const data = React.useMemo(() => detailsData, [detailsData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleImportContact = () => {
    navigate("/contact-import-seq");
    localStorage.setItem("importLocation", location.pathname);
  };

  // for drawer
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddProspect, setIsOpenAddProspec] = useState(false);

  const toggleDrawerAddProspec = () => {
    setIsOpenAddProspec((prevState) => !prevState);
  };
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  if (noData) {
    return (
      <div>
        <div className="mt-[4rem]">
          <img className="w-[300px] mx-auto" src={contactSvg} alt="" />
          <div className="mt-12 flex justify-center items-center">
            <button
              onClick={handleImportContact}
              className="bg-blue-dark btn !text-white"
            >
              Import Contacts
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      {showLeadsBtn && (
        <div className="flex gap-4 justify-end">
          <button
            onClick={() => setIsOpenAddProspec((prevState) => !prevState)}
            className="bg-blue-dark btn !text-white"
          >
            Add Prospect
          </button>

          <button
            onClick={handleImportContact}
            className="bg-blue-dark btn !text-white"
          >
            Import Contact
          </button>
        </div>
      )}

      <div className="">
        <div className="table-container relative min-h-[30rem] bg-white mt-4 p-6 shadow-medium  rounded-[7px]">
          <div className="relative w-[70%] my-2">
            <input
              value={search}
              onChange={handleChange}
              className="   input-search"
              type="text"
              placeholder="search name,company name"
            ></input>
            <SearchIcon className="absolute top-[10px] left-[10px] text-muted" />
          </div>

          {loader ? (
            <Loading whiteLoader={"whiteLoader"}></Loading>
          ) : (
            <>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        className="cursor-pointer hover:bg-[#f2f9ff] py-3"
                        {...row.getRowProps()}
                        onClick={() => {
                          setSingleRowData(row.original);
                          setIsOpen((prevState) => !prevState);
                        }}
                      >
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            className="!p-4"
                            style={{
                              padding: "16px",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div>
                {totalData > 20 && (
                  <div>
                    <ReactPaginate
                      initialPage={currentPage}
                      pageCount={Math.ceil(totalData / 20)}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={"flex justify-center my-4"}
                      pageClassName={
                        "mx-2 py-2 px-3 rounded-md  font-semibold text-[#005F8E]"
                      }
                      activeClassName={
                        "border-[7px]  border-border-gray text-[#0DA5F5] background-red-500 p-3 font-semibold bg-white"
                      }
                      previousClassName={
                        "mx-2 py-2 px-3 text-[#005F8E] font-medium cursor-pointer"
                      }
                      nextClassName={
                        "mx-2 py-2 px-3 text-[#005F8E] font-medium cursor-pointer"
                      }
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={
                        "mx-2 py-2 px-3 rounded-md  text-gray-700 hover:bg-gray-100 cursor-pointer"
                      }
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <LeadsUpdateDrawer
        setSingleRowData={setSingleRowData}
        setFetchDetailsStats={setFetchDetailsStats}
        singleRowData={singleRowData}
        setIsOpen={setIsOpen}
        toggleDrawer={toggleDrawer}
        isOpen={isOpen}
      />

      {/* show propspect upload stats  */}
      {importDataModal && (
        <ModalComponenet
          childrenPadding={"1rem"}
          radious={"10px"}
          title={"imported data"}
          width={"50vw"}
          setActiveModal={setImportDataModal}
        >
          <div className="w-[90%] mx-auto">
            <div className=" grid grid-cols-2 gap-8">
              <div>
                <div className=" shadow-sm  rounded-[0.7em] border border-border-gray flex justify-center items-center p-3 h-full">
                  <div className="rouned-[0.75em]   font-medium flex flex-col  justify-center items-center rounded-[.7em] p-2">
                    <span className="text-[40px]">
                      {importData?.newProspectsCount}
                    </span>
                    <h1 className=" mt-4">New Prospects</h1>
                  </div>
                </div>
              </div>

              <div>
                <div className=" shadow-sm rounded-[0.7em] border border-border-gray flex justify-center items-center p-3">
                  <div className=" rouned-[0.75em]   font-medium  flex-col flex justify-center items-center rounded-[.7em] p-2">
                    <span className="text-[40px]">
                      {importData?.updatedProspectsCount}
                    </span>
                    <h1 className=" mt-4">Updated Prospects</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-center">
              <button
                onClick={() => setImportDataModal(false)}
                className="button-normal bg-blue-dark !text-white"
              >
                Proced
              </button>
            </div>
          </div>
        </ModalComponenet>
      )}

      {
        <AddProspectDrawer
          sequenceId={sequenceId}
          isOpen={isOpenAddProspect}
          toggleDrawer={toggleDrawerAddProspec}
          fromLeads={true}
        />
      }
    </div>
  );
};

export default React.memo(DetailsStat);
