import React, { useEffect, useState } from "react";
import ModalComponenet from "../../../Componenets/ModalComponenet";
import GetUser from "../../../Hooks/GetUser";
import { addProspectTolist, getListName } from "../../../Services/apiServices";

import Button from "../../../Componenets/Button";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";

const AddToListModal = ({ setOpenAddtoListModal, selectedProspects }) => {
  const user = GetUser();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedList, setSelectedList] = useState();

  const handleSelectChange = (newValue) => {
    setSelectedList(newValue);
  };

  useEffect(() => {
    async function listName() {
      try {
        const response = await getListName(user);

        if (response.data.status && response.data.data.length > 0) {
          const newOptions = response.data.data.map((item) => ({
            value: item.name,
            label: item.name,
          }));
          setOptions(newOptions);
        }
      } catch (error) {
        console.error("Error fetching list names:", error);
      }
    }

    listName();
  }, [user]);

  const handleSave = async () => {
    setSubmitLoading(true);
    const payload = {
      userId: user,
      listName: selectedList.label,
      prospectIds: selectedProspects,
    };

    let response = await addProspectTolist(payload);
    if (response.data.status) {
      setOpenAddtoListModal(false);
      setSubmitLoading(true);
      toast.success("successfully added to list");
    }
  };

  return (
    <ModalComponenet
      setActiveModal={setOpenAddtoListModal}
      title={"List Name"}
      childrenPadding={"1rem"}
      width={"750px"}
      top={"12rem"}
      radious={"5px"}
    >
      <div className="">
        <label
          htmlFor="listName"
          className="text-[14px] font-500 mb-2 inline-block color-gray"
        >
          Select Your List Name :
        </label>
        <CreatableSelect
          isClearable
          id="listName"
          onChange={handleSelectChange}
          options={options}
          value={selectedList}
        />

        <div className="flex justify-end p-4 mt-8">
          <div className="flex">
            <button
              onClick={() => setOpenAddtoListModal(false)}
              className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
            >
              Cancel
            </button>
            <div>
              <Button
                loading={submitLoading}
                disabled={submitLoading}
                onClick={handleSave}
                tittle={"Save"}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalComponenet>
  );
};

export default AddToListModal;
