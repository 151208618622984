import React, { useContext, useEffect, useState } from "react";

// import MenuItem from "@mui/material/MenuItem";

import { UserContext } from "../../../../Hooks/UserContext";

import { BsFillFastForwardFill } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineDatabase,
} from "react-icons/ai";
import { FiX } from "react-icons/fi";
import "./FilterSidebar.scss";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { getListName } from "../../../../Services/apiServices";
import GetUser from "../../../../Hooks/GetUser";
import { DateTime } from "luxon";

const FilterDrawer = () => {
  const { setFilterQuery, filterQuery } = useContext(UserContext);
  const [activeText, setActiveText] = useState(null);

  const [anchorElStartDate, setanchorElStartDate] = useState(null);

  const [anchorElEndDate, setanchorElEndDate] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [openSequence, setOpenSequence] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [openListName, setOpenListName] = useState(false);
  const [listNameOption, setListNameOption] = useState();
  const [selectedListName, setSelectedListName] = useState("");

  const [selectedSequence, setSelectedSequence] = useState(null);

  const options = [
    { value: "activeInSequence", label: "Active In Sequence" },
    { value: "deactiveInSequence", label: "Deactive in sequence" },
    { value: "notInSequence", label: "Not in sequence" },
  ];

  const user = GetUser();

  useEffect(() => {
    async function listName() {
      try {
        const response = await getListName(user);

        if (response.data.status && response.data.data.length > 0) {
          const newOptions = response.data.data.map((item) => ({
            value: item._id,
            label: item.name,
          }));
          setListNameOption(newOptions);
        }
      } catch (error) {
        console.error("Error fetching list names:", error);
      }
    }

    listName();
  }, [user]);

  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "14px",
      };
    },
    control: (base) => ({
      ...base,

      minHeight: 33,
    }),
  };

  const handleClickStartDate = (event) => {
    setanchorElStartDate(event.currentTarget);
  };

  const handleClickEndDate = (event) => {
    setanchorElEndDate(event.currentTarget);
  };

  const handleFilterListName = (item) => {
    setFilterQuery((prevState) => ({
      ...prevState,
      listId: item.value,
    }));
    setSelectedListName(item);
  };

  const handleStartDate = (date) => {
    const originalDate = DateTime.fromJSDate(new Date(date));
    const formattedDate = originalDate.toFormat("yyyy-MM-dd");
    setFilterQuery((prevState) => ({
      ...prevState,
      date_range: {
        startDate: formattedDate,
      },
    }));

    setStartDate(date);
  };

  const handleEndDate = (date) => {
    const originalDate = DateTime.fromJSDate(new Date(date));
    const formattedDate = originalDate.toFormat("yyyy-MM-dd");
    setFilterQuery((prevState) => ({
      ...prevState,
      date_range: {
        endDate: formattedDate,
      },
    }));
    setEndDate(date);
  };

  const handleSequenceFilter = (selected) => {
    setSelectedSequence(selected);
    const optionValue = selected.value;
    const newFilterQuery = { ...filterQuery };
    if (optionValue === "activeInSequence") {
      delete newFilterQuery["deactiveInSequence"];
      delete newFilterQuery["notInSequence"];
    } else if (optionValue === "deactiveInSequence") {
      delete newFilterQuery["activeInSequence"];
      delete newFilterQuery["notInSequence"];
    } else if (optionValue === "notInSequence") {
      delete newFilterQuery["activeInSequence"];
      delete newFilterQuery["deactiveInSequence"];
    }

    newFilterQuery[optionValue] = true;
    setFilterQuery(newFilterQuery);
  };

  const handleRemoveSequenceFilter = () => {
    const newFilterQuery = { ...filterQuery };

    delete newFilterQuery["deactiveInSequence"];

    delete newFilterQuery["activeInSequence"];

    setFilterQuery(newFilterQuery);
    setSelectedSequence(null);
  };

  const handleRemoveDateFilter = () => {
    const newFilterQuery = { ...filterQuery };

    delete newFilterQuery["date_range"];

    setFilterQuery(newFilterQuery);
    setStartDate(null);
    setEndDate(null);
  };

  const handleRemoveListFilter = () => {
    const newFilterQuery = { ...filterQuery };

    delete newFilterQuery["listId"];

    setFilterQuery(newFilterQuery);
    setSelectedListName(null);
  };

  return (
    <div>
      <div
        className={`p-2 transition-all duration-200 ${
          selectedSequence && " border border-blue-700"
        }`}
      >
        <div className=" py-3 border-b border-primary hover:bg-[#f7f7f7]">
          <div
            className={`flex items-center justify-between cursor-pointer `}
            onClick={() => {
              setActiveText("active-sequence");
              setOpenSequence((prevState) => !prevState);
            }}
          >
            <div
              className={`flex items-center gap-2 ${
                selectedSequence && "text-blue-600"
              }`}
            >
              <BsFillFastForwardFill />
              <h1>Sequence</h1>
            </div>

            <div className="flex gap-2 items-center">
              {selectedSequence && (
                <div onClick={handleRemoveSequenceFilter}>
                  <button className="transition-all duration-150 border border-primary rounded-[10px] px-[12px] py-[2px] hover:bg-[#fff] hover:border-blue-500 hover:text-blue-600">
                    {" "}
                    <FiX className="hover:text-blue-600" />
                  </button>
                </div>
              )}

              {openSequence ? (
                <AiOutlineCaretUp
                  className={`${selectedSequence && "text-blue-600"}`}
                  u8ui
                />
              ) : (
                <AiOutlineCaretDown
                  className={`${selectedSequence && "text-blue-600"}`}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={`submenu transition-all mt-2 duration-200 w-full  ${
            openSequence ? "inline-block " : "hidden"
          } `}
        >
          <Select
            styles={colourStyles}
            onChange={(selected) => {
              handleSequenceFilter(selected);
            }}
            name="colors"
            className="basic-multi-select w-full"
            classNamePrefix="filter"
            options={options}
          />
        </div>
      </div>

      <div
        className={`p-2 transition-all duration-200 ${
          (startDate || endDate) && " border border-blue-700"
        }`}
      >
        <div className=" py-3 border-b border-primary relative hover:bg-[#f7f7f7]">
          <div
            className={`flex items-center justify-between cursor-pointer `}
            onClick={() => {
              setActiveText("date-range");
              setOpenDate((prevState) => !prevState);
            }}
          >
            <div
              className={`flex items-center gap-2 ${
                (startDate || endDate) && "text-blue-600"
              }`}
            >
              <MdDateRange />
              <h1>Date Range</h1>
            </div>

            <div className="flex gap-2 items-center">
              {(startDate || endDate) && (
                <div onClick={handleRemoveDateFilter}>
                  <button className="transition-all duration-150 border border-primary rounded-[10px] px-[12px] py-[2px] hover:bg-[#fff] hover:border-blue-500 hover:text-blue-600">
                    {" "}
                    <FiX className="hover:text-blue-600" />
                  </button>
                </div>
              )}

              {openDate ? (
                <AiOutlineCaretUp
                  className={`${(startDate || endDate) && "text-blue-600"}`}
                />
              ) : (
                <AiOutlineCaretDown
                  className={`${(startDate || endDate) && "text-blue-600"}`}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={`submenu transition-all relative duration-200 w-full  ${
            openDate ? "inline-block " : "hidden"
          } `}
        >
          <div className="w-full mt-4">
            <div onClick={handleClickStartDate} className="mt-2">
              <DatePicker
                placeholderText="From"
                selected={startDate}
                onChange={(date) => handleStartDate(date)}
              />
            </div>
            <div onClick={handleClickEndDate} className="mt-2">
              <DatePicker
                placeholderText="To"
                selected={endDate}
                onChange={(date) => handleEndDate(date)}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`p-2 transition-all duration-200 ${
          selectedListName && " border border-blue-700"
        }`}
      >
        <div className=" py-3 border-b border-primary relative hover:bg-[#f7f7f7]">
          <div
            className={`flex items-center justify-between cursor-pointer `}
            onClick={() => {
              setActiveText("list-name");
              setOpenListName((prevState) => !prevState);
            }}
          >
            <div
              className={`flex items-center gap-2 ${
                selectedListName && "text-blue-600"
              }`}
            >
              <AiOutlineDatabase />
              <h1>List Name</h1>
            </div>

            <div className="flex gap-2 items-center">
              {selectedListName && (
                <div onClick={handleRemoveListFilter}>
                  <button className="transition-all duration-150 border border-primary rounded-[10px] px-[12px] py-[2px] hover:bg-[#fff] hover:border-blue-500 hover:text-blue-600">
                    {" "}
                    <FiX className="hover:text-blue-600" />
                  </button>
                </div>
              )}
              {openListName ? (
                <AiOutlineCaretUp
                  className={`${selectedListName && "text-blue-600"}`}
                />
              ) : (
                <AiOutlineCaretDown
                  className={`${selectedListName && "text-blue-600"}`}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={`submenu transition-all relative duration-200 w-full  ${
            openListName ? "inline-block " : "hidden"
          } `}
        >
          <div className=" w-full mt-4">
            <div>
              <Select
                onChange={(selected) => {
                  handleFilterListName(selected);
                }}
                name="colors"
                className="basic-multi-select w-full"
                classNamePrefix="filter"
                options={listNameOption}
                styles={colourStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterDrawer;
