import React, { useContext } from "react";
import { useState } from "react";
import GetUser from "../../../../Hooks/GetUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getSequenceWithSteps } from "../../../../Services/apiServices";
import { toast } from "react-toastify";
import Loading from "../../../Shared/Loading/Loading";
import SequenceSteps from "./SequenceSteps";
import TimeintervalModal from "../TimeintervalModal";
import { UserContext } from "../../../../Hooks/UserContext";
import SequenceSvg from "../../../../Images/sequence.svg";
import ModalComponenet from "../../../../Componenets/ModalComponenet";
const Sequence = () => {
  const [loading, setLoading] = useState(false);
  const [sequenceData, setSequenceData] = useState([]);
  const { fetchSequenceStep, setFetchSequenceStep } = useContext(UserContext);
  const [timeIntervalModal, setTimeIntervalModal] = useState(false);
  const user = GetUser();
  const location = useLocation();
  const sequenceIdUrl = location.pathname.split("/")[2];
  const [emptyData, setEmptyData] = useState(false);
  useEffect(() => {
    setLoading(true);
    const fetchSequenceStats = async () => {
      const payload = {
        sequenceId: sequenceIdUrl,
        userId: user,
      };
      try {
        const response = await getSequenceWithSteps(payload);
        if (response.data.status) {
          setSequenceData(response.data.steps);
          if (response.data.steps.length === 0) {
            setEmptyData(true);
          } else {
            setEmptyData(false);
          }
          // setChecked(response.data.data?.settings?.pauseProspectsOnReply)
        }
      } catch (error) {
        toast.error("Error fetching sequence stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSequenceStats();
  }, [sequenceIdUrl, user, fetchSequenceStep]);

  const navigate = useNavigate();
  const handleImportContact = async () => {
    navigate("/contact-import-seq");
    localStorage.setItem("importLocation", location.pathname);
  };

  if (loading) {
    return <Loading text={"Load Your Sequence"} />;
  }

  return (
    <div>
      {emptyData ? (
        <div className="mt-[5rem]">
          <img className="w-[300px] mx-auto" src={SequenceSvg} alt="" />
          <div className="flex mt-12 gap-4 justify-center">
            <div>
              <button
                onClick={() => setTimeIntervalModal(true)}
                className="bg-blue-dark btn !text-white"
              >
                Add Step
              </button>
            </div>
            {/* <div>
              <button
                onClick={handleImportContact}
                className="bg-blue-dark btn !text-white"
              >
                Import Contacts
              </button>
            </div> */}
          </div>
        </div>
      ) : (
        <>
          <div className="flex gap-4 justify-end">
            <div>
              <button
                onClick={() => setTimeIntervalModal(true)}
                className="bg-blue-dark btn !text-white"
              >
                Add Step
              </button>
            </div>
          </div>
          <div>
            {sequenceData.map((step, index, fullArr) => (
              <SequenceSteps
                step={step}
                index={index}
                setFetchSequenceStep={setFetchSequenceStep}
                key={index}
                user={user}
                sequenceId={sequenceIdUrl}
                fullArr={fullArr}
              />
            ))}
          </div>
        </>
      )}

      {timeIntervalModal && (
        <TimeintervalModal
          sequenceData={sequenceData}
          setTimeIntervalModal={setTimeIntervalModal}
        ></TimeintervalModal>
      )}
    </div>
  );
};

export default Sequence;
