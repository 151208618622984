import React, { useEffect, useRef, useState } from 'react';
import { VscEllipsis } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import GetUser from '../../../Hooks/GetUser';
import DeleteMailboxModal from './DeleteMailboxModal';
import ReactSwitch from 'react-switch';
import { setWarmUpmode } from '../../../Services/apiServices';
import ModalComponenet from '../../../Componenets/ModalComponenet';
import { toast } from 'react-toastify';
import Button from '../../../Componenets/Button';

const SingleRow = ({ mailbox }) => {
    const [showPopUp, setShowPopup] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [resetModal, setResetModal] = useState(false)
    const [mailboxId, setMailboxId] = useState('')
    const [loader, setLoader] = useState(false)
    const user = GetUser()
    const navigate = useNavigate()

    const navigateMailboxDetails = (id) => {
        navigate(`${id}`)
    }
    const popupref = useRef(null)

    useEffect(() => {

        const handleClickOutside = (e) => {
            if (!popupref?.current?.contains(e.target)) {
                setShowPopup(false);
            }

        };

        document.addEventListener("mousedown", handleClickOutside, true);
        return () => {
            document.addEventListener("mousedown", handleClickOutside, true);
        };
    }, [])

    const [checked, setChecked] = useState(mailbox.warmUpMode);

    const handleChange = async (nextChecked, mailbox, event) => {
        event.stopPropagation();
        setMailboxId(mailbox._id)
        setChecked(nextChecked);
        const payload = {
            userId: user,
            id: mailbox._id,
            warmUpMode: nextChecked,
        };

        if (!mailbox.warmUpStartedDate && nextChecked) {
            payload.resetWarmUp = true
        } else if (mailbox.warmUpStartedDate && nextChecked) {
            setResetModal(true);
            // return;
        }

        let response = await setWarmUpmode(payload);
        if (response.data.success) {
            // toast.success('updated')
        }

    };



    const closeModal = () => {
        setResetModal(false)

    }



    const submitReset = async () => {
        setLoader(true)

        const payload = {
            userId: user,
            id: mailboxId,
            warmUpMode: true,
            resetWarmUp: true
        }
        let response = await setWarmUpmode(payload)
        if (response.data.success) {
            setChecked(true);
            setLoader(false)
            setResetModal(false)

        }
    }





    // convert the date 
    const date = new Date(mailbox?.mailBoxLinkedDate
    )
    const formattedDate = date.toLocaleDateString('default', { day: 'numeric', month: 'long' })

    return (
        <div className='hover:bg-[#F7F9FB] cursor-pointer'>
            {
                showDeleteModal && <DeleteMailboxModal senderEmail={mailbox.email} user={user} setShowDeleteModal={setShowDeleteModal}></DeleteMailboxModal>
            }
            <div ref={popupref} className="p-4" onClick={() => navigateMailboxDetails(mailbox?._id)} >
                <div className="color-gray text-[14px] font-semibold flex ">
                    <div className="flex flex-1 basis-[22%]">{mailbox?.email} {mailbox?.expiredToken &&
                        <span className='expired ml-2 bg-red-400'>Expired</span>
                    } </div>
                    <div className="flex flex-1 pl-[60px]">

                        <div className='ml-4' onClick={(e) => e.stopPropagation()}>
                            <ReactSwitch

                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={26}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={19}
                                width={45}
                                className="react-switch"
                                id="material-switch"
                                checked={checked}
                                onChange={(nextChecked, event) => handleChange(nextChecked, mailbox, event)}
                            />

                        </div>
                    </div>

                    <div className="flex flex-1 pl-[60px]">{mailbox?.dailyLimit}</div>

                    <div className="flex flex-1">{mailbox?.hourlyLimit}</div>
                    <div className="flex flex-1">{formattedDate}</div>


                    <div onClick={(e) => { e.stopPropagation(); setShowPopup(prev => !prev); }} className="cursor-pointer relative border border-border-gray p-1">
                        <VscEllipsis className="text-[18px]"></VscEllipsis>
                    </div>


                </div>
                <div
                    className={`bg-white absolute z-10 custom-shadow-2 text-[12px]  right-4 rounded-[3px] `}
                >
                    <ul className="color-gray">
                        <li
                            onClick={(e) => { e.stopPropagation(); setShowDeleteModal(true) }}
                            className={`${showPopUp ? '' : "hidden"
                                } cursor-pointer my-2 py-[7px] px-[14px] z-50 hover:bg-[#1991eb]  hover:text-white  font-semibold`}
                        >
                            Delete
                        </li>


                    </ul>
                </div>
            </div>

            {
                resetModal && <ModalComponenet top={'10rem'}
                    width={'40vw'}
                    background={'#fff'} childrenPadding={'1rem'} title={'Reset'} setActiveModal={setResetModal}>
                    <div>
                        <h1 className='text-black'>Are you sure want to reset warmup process ?</h1>
                        <div className="flex justify-end mt-4">
                            <div className="flex">
                                <button
                                    onClick={closeModal}
                                    className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
                                >
                                    Cancel
                                </button>

                                <div>


                                    <Button onClick={submitReset} tittle={'Reset'} loading={loader} disabled={loader}>

                                    </Button>

                                    {/* <button onClick={submitReset} className="button-danger">
                                        Reset
                                    </button> */}



                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponenet>
            }


        </div>
    );
};

export default SingleRow;