export const customVariable = [
  "full name",
  "first name",
  "last name",
  "company name",
  "CEO name",
  "one line personalization",
  "city",
  "state",
  "country",
  "location",
  "job title",
  "years in the company",
  "competitor",
];
