import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../Componenets/Button";
import GetUser from "../../../Hooks/GetUser";
import {
  getStepdata,
  updateStepData,
  updateStepTriggerDate,
} from "../../../Services/apiServices";
import whiteLoadder from "../../../Images/whiteSpinner.gif";
import { toast } from "react-toastify";
import TimeAndDateUi from "../../../Componenets/TimeAndDateUi";
import { formattingTime } from "../../../Utils/FormateTimeAndDate";
import DateAndTimePicker from "./DateAndTimePicker";
import dayjs from "dayjs";
import { TimeInterval } from "../../../Utils/AllTimeInterval";
import { validateTime } from "@mui/x-date-pickers/internals";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { DateTime } from "luxon";
const UpdateTimeModal = ({
  step,
  stepid,
  setUpdateDateModal,
  index,

  sequenceId,
  setFetchSequenceStep,
}) => {
  const { register, handleSubmit, reset } = useForm();
  const [selectedDate, setSelectedDate] = useState();

  const user = GetUser();
  const [loader, setLoader] = useState({
    fetchData: false,
    submit: false,
  });

  const currentTime = TimeInterval.find(
    (interval) => interval.value === step?.triggerTime
  );

  useEffect(() => {
    const formattedDate = DateTime.now().toFormat("dd-MM-yyyy");
    const serverResponseTriggerDate = step?.triggerDate || formattedDate;
    const formattedTriggerDate = DateTime.fromFormat(
      serverResponseTriggerDate,
      "dd-MM-yyyy"
    );
    setSelectedDate(dayjs(formattedTriggerDate || formattedDate));
  }, [step?.triggerDate]);

  const [selectedTime, setSelectedTime] = useState(currentTime);

  useEffect(() => {
    setLoader((prev) => {
      return { ...prev, fetchData: true };
    });
    async function stepData() {
      const payload = {
        user: user,
        stepId: stepid,
      };
      let res = await getStepdata(payload);
      if (res.data.status) {
        reset({
          dayInterval: res.data.data.dayInterval,
          hourInterval: res.data.data.hourInterval,
          channel: res.data.data.channel,
        });
        setLoader((prev) => {
          return { ...prev, fetchData: false };
        });
      }
    }
    stepData();
  }, [reset, stepid, user]);

  const onSubmit = async (data) => {
    setLoader((prev) => {
      return { ...prev, submit: true };
    });

    if (index === 0) {
      const payload = {
        userId: user,
        stepId: stepid,
        triggerDate: selectedDate.format("DD-MM-YYYY"),
        triggerTime: selectedTime.value,
      };

      let res = await updateStepTriggerDate(payload);
      if (res.data.status) {
        setLoader((prev) => {
          return { ...prev, submit: false };
        });
        toast.success("Updated Successfully");
        setUpdateDateModal(false);
        setFetchSequenceStep((prevState) => !prevState);
      }
    } else {
      const payload = {
        userId: user,
        sequenceId: sequenceId,
        stepId: stepid,
        stepData: {
          dayInterval: Number(data.dayInterval),
          hourInterval: Number(data.hourInterval),
        },
      };

      let res = await updateStepData(payload);
      if (res.data.success) {
        setLoader((prev) => {
          return { ...prev, submit: false };
        });
        toast.success("Updated Successfully");
        setUpdateDateModal(false);
        setFetchSequenceStep((prevState) => !prevState);
      }
    }
  };

  return (
    <div className="popup">
      <div className="modal-empty-div"></div>
      <div className="content-container w-[40%] mt-4 !overflow-y-visible">
        <div className="bg-white max-w-[900px]  popup-content !rounded-[4px] p-4">
          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-2">
            <h1 className="primary-heading-color">Update Date</h1>
            <button
              onClick={() => setUpdateDateModal(false)}
              className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
            >
              &times;
            </button>
          </div>

          {loader.fetchData ? (
            <img
              className="w-[130px] h-[130px] mx-auto"
              src={whiteLoadder}
              alt="spinner"
            />
          ) : (
            <div>
              <form onSubmit={handleSubmit(onSubmit)} className=" p-4">
                {index === 0 ? (
                  // <TimeAndDateUi timeId={timeId} dateId={dateId} formattedDate={formattedDate} />

                  <DateAndTimePicker
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                  />
                ) : (
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <label
                        htmlFor="dailyLimit"
                        className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                      >
                        Day
                      </label>
                      <input
                        id="dailyLimit"
                        type="number"
                        className="w-full text-[13px]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                        {...register("dayInterval")}
                      />
                    </div>

                    <div className="flex-1">
                      <label
                        htmlFor="hourLimit"
                        className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                      >
                        Hour
                      </label>
                      <input
                        id="hourLimit"
                        type="number"
                        className="w-full text-[13px]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                        {...register("hourInterval")}
                      />
                    </div>
                  </div>
                )}

                <div className="my-4">
                  <label
                    htmlFor="hourLimit"
                    className="text-[13px] font-semibold mb-2  text-[#5d6a7e] block"
                  >
                    Channel
                  </label>
                  <input
                    type="text"
                    disabled
                    className="w-full text-[13px] bg-[#pF4F4F4]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                    {...register("channel")}
                  />
                </div>

                <LoadingButton
                  className="mt-2 btn"
                  loading={loader.submit}
                  submitLoading
                  onClick={handleSubmit(onSubmit)}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  Save
                </LoadingButton>
                {/* 
                <Button
                  loading={loader.submit}
                  disabled={loader.submit}
                  type={"submit"}
                  tittle={"Save"}
                ></Button> */}
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateTimeModal;
