import React, { useEffect, useRef, useState } from 'react';
import { VscEllipsis } from 'react-icons/vsc';
import ModalDelete from '../../../Componenets/ModalDelete';
import GetUser from '../../../Hooks/GetUser';
import { deleteTemplate } from '../../../Services/apiServices';
import EditTemplateModal from './EditTemplateModal';
import { Tooltip } from 'react-tooltip';
const TableRow = ({ singleData, index, toggleTemplateActive, templates, setRefetch }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [activePopup, setActivePopup] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = (id) => {
        setActivePopup(activePopup === id ? null : id);
    }


    const user = GetUser()
    const popupRef = useRef(null)

    useEffect(() => {

        const handleClickOutside = (e) => {
            if (!popupRef?.current?.contains(e.target)) {
                // setActivePopup(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside, true);
        return () => {
            document.addEventListener("mousedown", handleClickOutside, true);
        };
    }, [])

    const handleDelete = async (id) => {
        setIsLoading(true);
        const payload = {
            user: user,
            id: id
        };
        let response = await deleteTemplate(payload);
        if (response.data.success) {
            setRefetch(prev => !prev)
            setShowDeleteModal(false);
            setIsLoading(false);
        }
    };



    return (

        <tr key={singleData._id} className='relative'>
            <td>0{index + 1}</td>
            <td className="text-[13px] cursor-pointer">
                {singleData?.templateName}
            </td>
            <td className="text-center">00</td>
            <td>{singleData?.channel}</td>
            <td className="text-center">--</td>

            <td className="text-center">
                <h1>00</h1>
                <h1 className="text-[#999999] text-[14px]">Clicked</h1>
            </td>
            <td className="text-center">
                <h1>00</h1>
                <h1 className="text-[#999999] text-[14px]">Opened</h1>
            </td>
            <td className="text-center">
                <h1>0</h1>
                <h1 className="text-[#999999] text-[14px]">Replied</h1>
            </td>
            <td className="text-center">
                <h1>0</h1>
                <h1 className="text-[#999999] text-[14px]">Bounced</h1>
            </td>

            <td className="text-center">
                <h1>--</h1>
            </td>

            <td className="text-center status">
                {singleData?.activeStatus ? (
                    <div className="flex justify-center">
                        <div
                            onClick={() => toggleTemplateActive(singleData._id)}
                            id={`active-${singleData._id}`}
                            className="w-10 mx-3 h-5 transition hover:cursor-pointer rounded-l-full rounded-r-full bg-blue-500 p-1"
                        ><div className="bg-white w-3 h-[100%] rounded-full float-right"></div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center">
                        <div
                            onClick={() => toggleTemplateActive(singleData._id)}
                            id={`active-${singleData._id}`}
                            className="w-10 mx-3 h-5 transition hover:cursor-pointer rounded-l-full rounded-r-full bg-slate-300 p-1"
                        >
                            <div className="bg-white w-3 h-[100%] rounded-full"></div>
                        </div>
                    </div>
                )}
            </td>

            <td className='!pr-0' >
                <div className='cursor-pointer rounded-[3px] focus:border-blue-400 flex justify-center relative border border-border-gray p-1' onClick={() => handleButtonClick(singleData._id)}>
                    <Tooltip id='ellipsis' />
                    <VscEllipsis
                        data-tooltip-content="Edit"
                        data-tooltip-id='ellipsis'

                        className="text-[25px] icon-color Ellipsis"
                    ></VscEllipsis>
                </div>
                <div
                    className={`bg-white custom-shadow border text-[14px] border-border-gray rounded-[7px]  ${activePopup === singleData._id ? '' : 'hidden'} absolute  py-[17px] px-[13px] top-16  right-[-10px] z-10 prospectus-item shadow-lg text-[12px]`}>
                    <ul className="">

                        <button onClick={() => {
                            setShowEditModal(true)
                            setActivePopup(null)
                        }}
                            className="h-[32px]  w-[65px]  block mb-2 rounded-[3px] px-[12px] text-[13px] border border-border-gray"
                        >
                            Edit
                        </button>

                        <button onClick={() => setShowDeleteModal(true)}
                            className="h-[32px] w-[65px]  rounded-[3px] px-[12px] text-[13px] border border-border-gray"
                        >
                            Delete
                        </button>
                    </ul>

                    {
                        showDeleteModal && <ModalDelete
                            key={singleData?._id}
                            title="Delete Template"
                            message={`Are you sure you want to delete ${singleData?.templateName} Template?`}
                            buttonText="Delete"
                            onClose={() => { setShowDeleteModal(false) }}
                            onClick={() => { handleDelete(singleData?._id) }}
                            isLoading={isLoading}
                        />
                    }
                    <div>

                    </div>

                </div>

            </td>
            {
                showEditModal && <EditTemplateModal templateId={singleData?._id} templates={templates} setRefetch={setRefetch} closeModal={setShowEditModal}></EditTemplateModal>
            }

        </tr>
    );
};

export default TableRow;