import React, { useState } from "react";

import { HiBadgeCheck } from "react-icons/hi";
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineStop } from "react-icons/ai";

import {
  FaLinkedinIn,
  FaPhone,
  FaRegEnvelope,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import LeadsUpdateDrawer from "../Sequence/LeadsUpdateDrawer";

const ProspectTable = ({
  result,

  selectProspect,
  isSelectAll,
  setNumberOfSelectedItem,
  sequenceNameObj,
  showProspectus,
  falsyLinkedInUrl,
  deleteProspects,
  handleDot,
  toggleSeqActive,
  setFetchDetailsStats,
}) => {
  const selectStyle = (bouncedEmail, unsubscribed) => {
    if (!bouncedEmail && !unsubscribed) {
      return (
        <h1 data-tooltip-id="valid email" data-tooltip-content="valid email">
          <Tooltip id="valid email" />
          <HiBadgeCheck className="text-[14px] text-green-600" />
        </h1>
      );
    } else if (bouncedEmail) {
      return (
        <h1
          data-tooltip-id="invalid email"
          data-tooltip-content="invalid email"
        >
          <Tooltip id="invalid email" />
          <RxCrossCircled className="text-[14px] text-red-600" />{" "}
        </h1>
      );
    } else {
      return (
        <h1 data-tooltip-id="UnSubscribe" data-tooltip-content="UnSubscribe">
          <Tooltip id="UnSubscribe"></Tooltip>

          <AiOutlineStop className="text-[14px] text-red-600" />
        </h1>
      );
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [singleRowData, setSingleRowData] = useState(null);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <tbody className="w-full table-body-prospect">
        {result?.length === 0 ? (
          <h1 className="w-[10rem]">There is no data</h1>
        ) : (
          result?.map((singleData, index) => (
            <>
              <tr
                onClick={() => {
                  setSingleRowData(singleData);

                  setIsOpen((prevState) => !prevState);
                }}
                key={singleData._id}
                className=" bg-white cursor-pointer"
              >
                <td className="text-[13px]">
                  <div className="flex gap-[1.2rem] w-[10rem]">
                    <div>
                      <label class="checkbox-container">
                        <input
                          type="checkbox"
                          onChange={() => selectProspect(singleData._id)}
                          className="cursor-pointer checkboxall"
                          id={singleData._id}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>

                    <div className="flex items-center ">
                      <label
                        htmlFor={singleData._id}
                        className="cursor-pointer"
                      >
                        {singleData?.name
                          ? singleData?.name
                          : `${
                              singleData?.firstName ? singleData?.firstName : ""
                            } ${
                              singleData?.secondName
                                ? singleData?.secondName
                                : ""
                            }`}
                      </label>
                      {selectStyle(
                        singleData.bouncedEmail,
                        singleData.unsubscribed
                      )}
                    </div>
                  </div>
                </td>

                <td>
                  <div className="truncate w-[150px]">
                    {singleData?.companyName}
                  </div>
                </td>

                <td>
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      width: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    {singleData?.jobTitle}
                  </div>
                </td>

                <td
                  className="text-center cursor-pointer"
                  title={sequenceNameObj[singleData._id]}
                >
                  {sequenceNameObj[singleData._id]
                    ? sequenceNameObj[singleData._id]?.length < 13
                      ? sequenceNameObj[singleData._id]
                      : `${sequenceNameObj[singleData._id]?.slice(0, 12)}...`
                    : `No Sequence`}
                </td>

                <td className="text-center flex justify-evenly !py-8">
                  <span className="cursor-pointer">
                    <FaRegEnvelope
                      className={!singleData.email ? "text-gray-400" : ""}
                      title={singleData?.email}
                    />
                  </span>
                  <span className="cursor-pointer">
                    {singleData?.linkedinUrl ? (
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href={`${singleData?.linkedinUrl}`}
                      >
                        <FaLinkedinIn />
                      </a>
                    ) : (
                      <FaLinkedinIn
                        onClick={falsyLinkedInUrl}
                        className="text-gray-400"
                      />
                    )}
                  </span>
                  <span className="cursor-pointer">
                    <FaPhone
                      className={!singleData.phoneNumber ? "text-gray-400" : ""}
                      title={`${singleData?.phoneNumber}`}
                    />
                  </span>
                  <span className="cursor-pointer">
                    <FaWhatsapp
                      className={
                        !singleData.whatsappNumber ? "text-gray-400" : ""
                      }
                      title={`${singleData?.whatsappNumber}`}
                    />
                  </span>
                  <span className="cursor-pointer">
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={`${singleData?.twitterUrl}`}
                    >
                      <FaTwitter
                        className={
                          !singleData.twitterUrl ? "text-gray-400" : ""
                        }
                      />
                    </a>
                  </span>
                </td>

                {/*             
            <td className="text-center">
              <div>
                {singleData?.numberOfTouche ? singleData?.numberOfTouche : "00"}
                <p className="text-xs text-slate">Invite Accepted</p>
              </div>
             
            </td>
            <td id={`stage-${singleData?._id}`} className="text-center ">
              {singleData?.stage}
            </td> */}

                <td className="text-center status">
                  {singleData?.activeInSequence ? (
                    <div className="flex justify-center">
                      <div
                        id={`active-${singleData._id}`}
                        onClick={() => toggleSeqActive(singleData._id)}
                        className="w-10 mx-3 h-5 transition hover:cursor-pointer rounded-l-full rounded-r-full bg-blue-500 p-1"
                      >
                        <div className="bg-white w-3 h-[100%] rounded-full float-right"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <div
                        id={`active-${singleData._id}`}
                        onClick={() => toggleSeqActive(singleData._id)}
                        className="w-10 mx-3 h-5 transition hover:cursor-pointer rounded-l-full rounded-r-full bg-slate-300 p-1"
                      >
                        <div className="bg-white w-3 h-[100%] rounded-full"></div>
                      </div>
                    </div>
                  )}
                </td>

                {/* there dot  */}
                <td className="relative">
                  <div
                    className={`bg-white absolute w-[12rem] py-4 pl-4 top-16  right-5 z-10 prospectus-item shadow-lg text-[12px]  ${
                      showProspectus[index] ? "" : "hidden"
                    }`}
                  >
                    <ul className="">
                      <button className="mb-1 block cursor-pointer text-[17px] font-semibold">
                        {singleData?.linkedinUrl && (
                          <a
                            rel="noreferrer"
                            target={"_blank"}
                            href={`${singleData?.linkedinUrl}`}
                          >
                            Visit Linkedin Profile
                          </a>
                        )}
                      </button>

                      <button
                        onClick={() => {
                          deleteProspects(singleData._id);
                        }}
                        className="mb-1 block cursor-pointer text-[17px] font-semibold"
                      >
                        {" "}
                        Delete
                      </button>

                      <button
                        disabled
                        className="mb-1 disabled-btn block cursor-pointer text-[17px] font-semibold"
                      >
                        1:1 Email{" "}
                      </button>

                      <button
                        disabled
                        className="mb-1 disabled-btn block cursor-pointer text-[17px] font-semibold"
                      >
                        Edit Prospect
                      </button>
                    </ul>
                  </div>
                </td>
              </tr>
            </>
          ))
        )}
      </tbody>
      {isOpen && (
        <LeadsUpdateDrawer
          setFetchDetailsStats={setFetchDetailsStats}
          singleRowData={singleRowData}
          setIsOpen={setIsOpen}
          toggleDrawer={toggleDrawer}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default ProspectTable;
