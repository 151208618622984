import React from 'react';
import ModalComponenet from '../../../Componenets/ModalComponenet';

const UploadDialog = ({ goodContacts, badContacts, setImportStatsModal, contactCounts, setFileUpload }) => {
    return (
        <ModalComponenet childrenPadding={'1rem'} radious={'10px'} title={'imported data'} width={'50vw'} setActiveModal={setImportStatsModal}>

            <div className="w-[90%] mx-auto">
                <div className=" grid grid-cols-2 gap-8 ">
                    <div>

                        <div className=" shadow-sm  rounded-[0.7em] border border-border-gray flex justify-center items-center p-3 h-full">
                            <div className="rouned-[0.75em]   font-medium flex flex-col  justify-center items-center rounded-[.7em] p-2">
                                <span className="text-[40px]">{goodContacts.length}</span>
                                <h1 className=" mt-4">Good Contacts</h1>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className=" shadow-sm rounded-[0.7em] border border-border-gray flex justify-center items-center p-3">
                            <div className=" rouned-[0.75em]   font-medium  flex-col flex justify-center items-center rounded-[.7em] p-2">
                                <span className="text-[40px]">{badContacts.length}</span>
                                <h1 className=" mt-4">Bad Contacts</h1>
                                {
                                    badContacts.length !== 0 && <div className="text-[14px] mt-2">
                                        <h1>Contacts With No Email - <span className="text-[16px]">{contactCounts.contactsWithoutEmail}</span></h1>
                                        <h1>Contacts With No Company Name - <span className="text-[16px]">{contactCounts.contactsWithoutCompanyName}</span> </h1>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex justify-center">
                    <div className="flex gap-8 items-center">
                        <button onClick={() => {
                            setFileUpload(false)
                            setImportStatsModal(false)
                        }
                        } className='button-normal bg-red-600 !text-white !border-0'>Cancel</button>
                        <button onClick={() => setImportStatsModal(false)} className='button-normal bg-blue-dark !text-white'>Proced</button>
                    </div>
                </div>
            </div>
        </ModalComponenet>
    );
};

export default UploadDialog;