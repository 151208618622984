import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../Hooks/UserContext";
import "./StepModal.css";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../../Hooks/Authcontext";
import "react-quill/dist/quill.snow.css";
import SubjectPersonalize from "../PersonalizeComponent/SubjectPersonalize";

import { RiBracesLine } from "react-icons/ri";

// import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import properties from "../../../config/properties";
import Loading from "../../Shared/Loading/Loading";
import {
  createStep,
  deleteAttachment,
  mailboxConnection,
} from "../../../Services/apiServices";
// import { Editor } from '@tinymce/tinymce-react';
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";

import draftToHtml from "draftjs-to-html";
import DraftEditor from "../../../Componenets/DraftEditor";
import { Tooltip } from "react-tooltip";
import ModalFooterIcon from "./ModalFooterIcon";
import UploadAttachment from "./UploadAttachment";

import ConformationModal from "../../../Componenets/ConformationModal";
import { validatePersonalize } from "../../../Utils/PersonalizeError";

const StepModal = ({ sequenceId }) => {
  const {
    stepOneTimeZone,
    days,
    setDays,
    hours,
    setHours,
    channel,
    setchennel,
    stepStartDate,
    setActiveStepModal,
    setStepStartDate,
    setFetchSequenceStep,
  } = useContext(UserContext);

  const { user } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState("");
  const [subjectPopup, setSubjectPopup] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [detectFocus, setDetectFocus] = useState(false);

  const [isChecked, setIschecked] = useState(false);
  const [signatureData, setSignatureData] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [isPersonalisePopupOpen, setIsPersonalisePopupOpen] = useState(false);
  const [isPopupOpenTemplate, setIsPopupOpenTemplate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [threadData, setThreadData] = useState("new");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [styles, setStyles] = useState({
    colSpan: 2,
    border: true,
    toggled: false,
  });

  const [previewEditorState, setPreviewEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [uploadedFile, setUploadedFile] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  // const inputRef = useRef(null);

  const subjectRef = useRef(null);
  // console.log(height, 'height')
  const modalRef = useRef();
  const selectSubject = useRef(null);
  const personalizeBtn = useRef(null);
  const templateBtn = useRef();

  const handleCheck = (e) => {
    setIschecked(e.target.checked);
  };

  const handleInsertInput = (value) => {
    setInputValue(inputValue + value);
    setSubjectPopup(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setLoader(true);

    axios
      .get(
        `${properties.REACT_APP_SERVER_URL}/api/v1/template/getCustomTemplates/${user}`
      )
      .then((res) => {
        setTemplates(res.data.data);
      });

    async function getMailboxConnection() {
      const payload = {
        user: user,
      };
      let response = await mailboxConnection(payload);
      if (
        response?.data?.data &&
        response.data.data?.connections &&
        response.data.data?.connections.length > 0 &&
        response.data.data?.connections[0].hasOwnProperty("emailSignature")
      ) {
        setSignatureData(response.data.data.connections[0].emailSignature);
        setLoader(false);
      } else {
        setSignatureData("");
        setLoader(false);
      }
    }
    getMailboxConnection();

    const handleClickOutside = (e) => {
      if (!personalizeBtn?.current?.contains(e.target)) {
        setIsPersonalisePopupOpen(false);
      }
      if (!templateBtn?.current?.contains(e.target)) {
        setIsPopupOpenTemplate(false);
      }
      if (!selectSubject?.current?.contains(e.target)) {
        setSubjectPopup(false);
      }
      if (modalRef.current && !modalRef?.current.contains(e.target)) {
        // setActiveStepModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside, true);
    document.body.style.overflow = "hidden";

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);

      document.body.style.overflow = "auto";
    };
  }, [setActiveStepModal, user]);

  // handle submit
  const onSubmit = async () => {
    setLoader(true);
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const data = {
      template: html,
      channel: channel,
      subject:
        channel.includes("LinkedIn") || threadData === "same"
          ? "LinkedIn"
          : inputValue,
    };

    // validate personalize

    const incorrectPersonalize = validatePersonalize(html, inputValue);

    if (incorrectPersonalize.length > 0) {
      const errorMessage =
        "Wrong personalization variables: " + incorrectPersonalize.join(", ");
      toast.error(errorMessage);
      setLoader(false);
      return;
    }

    for (let key in data) {
      if (!data[key]) {
        toast.error("fill all the field");
        setLoader(false);
        return;
      }
    }

    const payload = {
      userId: user,
      sequenceId: sequenceId,
      template: html,
      channel: channel,
      subject: inputValue,
      signatureEnabled: isChecked,
      timeIntervalHours: hours,
      timeIntervalDays: days,
      thread: threadData,
      fileNamesAndKeys: uploadedFile,
    };

    if (stepStartDate !== null) {
      payload.triggerDate = stepStartDate.date;
      payload.timezone = stepOneTimeZone;
      payload.triggerTime = "00:00";
    }

    try {
      let response = await createStep(payload);
      if (response.data.success) {
        toast.success("succesfully add step");
        setActiveStepModal(false);
        // setRefetch(!reFetch);
        setFetchSequenceStep((prevState) => !prevState);
        setStepStartDate(null);
        setDays(0);
        setHours(0);
        setchennel("Automated Email");
        setLoader(false);
      } else {
        toast.error("Failed to add step. Please try again.");
      }
    } catch (error) {
      console.error("Error adding step:", error);
      toast.error("An error occurred");
    } finally {
      setLoader(false);
    }
  };

  function handleBack() {
    if (styles.toggled) {
      setStyles({ colSpan: 2, border: true, toggled: false });
    } else {
      setStyles({ colSpan: 3, border: false, toggled: true });
    }
  }

  const handleThreadData = (event) => {
    setThreadData(event.target.value);
  };

  // upload file //

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > 25 * 1024 * 1024) {
      alert("File size should be less than 25 MB");
      return;
    }
    const formData = new FormData();
    formData.append("File", file);
    formData.append("userId", user);
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${properties.REACT_APP_SERVER_URL}/api/v1/attachments/uploadAttachments`,
      true
    );
    xhr.upload.addEventListener("progress", function (event) {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: percentComplete,
        }));
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.success) {
            const singleObj = response.fileNamesAndKeys.map((obj) => obj);
            setUploadedFile([...uploadedFile, ...singleObj]);
          }
        } else {
        }
      }
    };

    xhr.send(formData);
  };

  const handleDeleteAttachment = async (fileKey) => {
    const payload = {
      userId: user,
      fileKey: fileKey,
    };

    let response = await deleteAttachment(payload);
    if (response.data.success) {
      setUploadedFile(uploadedFile.filter((file) => file.fileKey !== fileKey));
      toast.success("succesfully delete attachment");
    }
  };

  // close modal
  const handleCloseModal = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const isEmptyTemplate = html.replace(/\s/g, "") === "<p></p>";
    const isInputEmpty = inputValue.trim() === "";

    if (!isInputEmpty || (!isEmptyTemplate && detectFocus)) {
      setConfirmModal(true);
    } else {
      setActiveStepModal(false);
    }
  };

  return (
    <div className="popup">
      {confirmModal && (
        <ConformationModal
          setConfirmModal={setConfirmModal}
          setActiveStepModal={setActiveStepModal}
        />
      )}

      <div className="modal-empty-div"></div>
      <div
        ref={modalRef}
        className="content-container  w-[100vw] !h-[100%] max-h-[100%] !z-[9]"
      >
        <div className="w-full  popup-content min-h-full  !bg-white  p-4">
          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
            <h1 className="primary-heading-color font-semibold">
              Step Creation
            </h1>
            <button
              onClick={handleCloseModal}
              className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
            >
              &times;
            </button>
          </div>

          <div className="w-[97%] mx-auto">
            {loader ? (
              <Loading whiteLoader="whiteLoader"></Loading>
            ) : (
              <div className="grid grid-cols-3">
                <div
                  className={`${styles.toggled ? "col-span-3" : "col-span-2"} ${
                    styles.border ? "border-r border-border-gray" : ""
                  } relative`}
                >
                  <div
                    onClick={handleBack}
                    className="absolute  text-[20px] right-[-10px]  cursor-pointer bg-[#e8eef4] text-[#1991eb]"
                  >
                    <Tooltip id="Preview ON" />
                    <Tooltip id="Preview OFF" />

                    {styles.toggled ? (
                      <AiOutlineEye
                        data-tooltip-content="Preview ON"
                        data-tooltip-id="Preview ON"
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="text-[#1991eb]"
                        data-tooltip-content="Preview OFF"
                        data-tooltip-id="Preview OFF"
                      />
                    )}
                  </div>
                  <div className="pr-[.8rem]">
                    <div className="pt-4 pr-2">
                      <div>
                        <div
                          ref={selectSubject}
                          className={` mb-4 w-full relative`}
                        >
                          <SubjectPersonalize
                            handleInsertInput={handleInsertInput}
                            subjectPopup={subjectPopup}
                          ></SubjectPersonalize>

                          <div className="mb-4">
                            <h1 className="color-gray text-[13px] font-semibold mb-2">
                              Thread:
                            </h1>
                            <div className="relative">
                              <select
                                value={threadData}
                                onChange={handleThreadData}
                                className="text-[13px] bg-white focus:outline-none border border-[#d3dae3] rounded-[4px] h-8 w-full appearance-none pl-3 pr-8"
                              >
                                <option value="new">New Thread</option>
                                <option value="same">Same Thread</option>
                              </select>
                              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                <RiArrowDropDownLine className="icon-color text-[25px]" />
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <h1
                              className={`${
                                channel.includes("LinkedIn") ||
                                threadData === "same"
                                  ? "hidden"
                                  : ""
                              } color-gray text-[13px] font-semibold mb-2`}
                            >
                              Subject:
                            </h1>

                            <div
                              className={`${
                                channel.includes("LinkedIn") ||
                                threadData === "same"
                                  ? "hidden"
                                  : ""
                              }  flex relative`}
                            >
                              <input
                                value={inputValue}
                                onChange={handleInputChange}
                                id="subject"
                                useRef={subjectRef}
                                placeholder="enter subject here"
                                type="text"
                                className={` border border-[#d3dae3] pl-2 pr-8 w-full h-8 rounded-[4px] text-[14px] hour-input`}
                              />
                              <div
                                data-tooltip-id="subject-personalize"
                                data-tooltip-content="personalization"
                                onClick={() => setSubjectPopup(!subjectPopup)}
                                className="cursor-pointer absolute right-0 bg-[#f7f9fb] px-2 flex justify-center items-center  h-full custom-brace-icon"
                              >
                                <Tooltip id="subject-personalize" />
                                <h1 className="text-[13px] ">
                                  <RiBracesLine className="icon-color"></RiBracesLine>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full">
                          <h1 className="color-gray text-[13px] font-semibold  mb-2">
                            Email Body :
                          </h1>

                          <div>
                            <div>
                              <div className="relative">
                                <div className="  text-[14px]  ">
                                  <DraftEditor
                                    setDetectFocus={setDetectFocus}
                                    templateBtn={templateBtn}
                                    setIsPopupOpenTemplate={
                                      setIsPopupOpenTemplate
                                    }
                                    setSubject={setInputValue}
                                    subjectinputRef={subjectRef}
                                    templates={templates}
                                    personalizeBtn={personalizeBtn}
                                    isPopupOpenTemplate={isPopupOpenTemplate}
                                    isPersonalisePopupOpen={
                                      isPersonalisePopupOpen
                                    }
                                    setIsPersonalisePopupOpen={
                                      setIsPersonalisePopupOpen
                                    }
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                    setPreviewEditorState={
                                      setPreviewEditorState
                                    }
                                  ></DraftEditor>

                                  <UploadAttachment
                                    handleDeleteAttachment={
                                      handleDeleteAttachment
                                    }
                                    uploadedFile={uploadedFile}
                                    uploadProgress={uploadProgress}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between mt-2">
                            <div className="flex">
                              <input
                                onChange={handleCheck}
                                checked={isChecked}
                                type="checkbox"
                                name="checkbox"
                                id=""
                              />
                              <h1 className="text-[#5d6a7e] text-[14px] ml-2">
                                Include Signature
                              </h1>
                            </div>

                            <ModalFooterIcon
                              handleFileChange={handleFileChange}
                              uploadProgress={uploadProgress}
                              isPersonalisePopupOpen={isPersonalisePopupOpen}
                              setIsPersonalisePopupOpen={
                                setIsPersonalisePopupOpen
                              }
                              isPopupOpenTemplate={isPopupOpenTemplate}
                              personalizetooltipid={"four"}
                              setIsPopupOpenTemplate={setIsPopupOpenTemplate}
                              templatetooltipid={"three"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex  items-center mt-2">
                    <div>
                      <button
                        onClick={onSubmit}
                        className={`button-background text-white rounded-[4px] h-[32px] px-[12px] text-[13px]`}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                {/* preview  */}

                <div
                  className={`${
                    styles.toggled && "hidden"
                  } col-span-1 pt-4 pl-[.8rem]`}
                >
                  <p className="text-[14px] mt-2 color-gray">
                    This is preview here :
                  </p>

                  <div className="bg-[#f7f9fb] border border-[#d3dae3] p-3 mt-1 rounded-[4px] ">
                    <h1 className="color-gray text-[14px] font-semibold  mb-1">
                      Subject :{" "}
                      <span className="color-gray text-[14px]">
                        {inputValue}
                      </span>{" "}
                    </h1>
                    <div></div>

                    <div className=" text-[14px] ">
                      <br />

                      <div className="preview-container">
                        <Editor
                          editorState={previewEditorState}
                          toolbarHidden={true}
                          readOnly={true}
                        />
                      </div>
                      <br />

                      <p
                        dangerouslySetInnerHTML={{
                          __html: isChecked ? signatureData : "",
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepModal;
