import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Select from "react-select";
import "./Scedule.css";
import { TimeInterval } from "../../../../Utils/AllTimeInterval";
import timeZoneObjects from "../../../../Utils/TimeZone";
import { toast } from "react-toastify";
import {
  getSequenceWithSteps,
  updateSettingTime,
} from "../../../../Services/apiServices";
import GetUser from "../../../../Hooks/GetUser";
import { useLocation } from "react-router-dom";
import Loading from "../../../Shared/Loading/Loading";
import { UserContext } from "../../../../Hooks/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { format, parse } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const Schedule = () => {
  const { fetchSequenceStep, setFetchSequenceStep } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [saveSpinner, setSaveSpinner] = useState(false);
  const user = GetUser();
  const location = useLocation();
  const sequenceIdUrl = location.pathname.split("/")[2];
  const [weekDays, setWeekDays] = useState({
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
  });
  const [selectedStartTime, setSelectedStartTime] = useState();
  const [selectedEndTime, setSelectedEndTime] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const id = open ? "simple-popover" : undefined;
  const validateTime = (time) => {
    if (time && time.length === 4) {
      return `0${time.trim()}`;
    } else {
      return time;
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchSequenceStats = async () => {
      const payload = {
        sequenceId: sequenceIdUrl,
        userId: user,
      };
      try {
        const response = await getSequenceWithSteps(payload);
        if (response.data.status) {
          setSelectedStartTime(
            TimeInterval.find(
              (interval) =>
                interval.value ===
                validateTime(response.data.data.triggerSettings?.startTime)
            )
          );
          setSelectedEndTime(
            TimeInterval.find(
              (interval) =>
                interval.value ===
                validateTime(response.data.data.triggerSettings?.endTime)
            )
          );
          setSelectedTimeZone(
            timeZoneObjects.find(
              (timeZone) =>
                timeZone.value === response.data.data.triggerSettings?.timeZone
            )
          );

          setWeekDays(response.data.data.triggerSettings?.weekDays);
          const endDateString = response.data.data.triggerSettings?.endDate;
          const startDateString = response.data.data.triggerSettings?.startDate;

          try {
            const parsedDate = parse(endDateString, "dd-MM-yyyy", new Date());
            const parsedDate2 = parse(
              startDateString,
              "dd-MM-yyyy",
              new Date()
            );
            if (isNaN(parsedDate || parsedDate2)) {
              throw new Error("Invalid date");
            }

            setSelectedEndDate(parsedDate);
            setSelectedStartDate(parsedDate2);
          } catch (error) {
            console.error("Error parsing or formatting date:", error);
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Error fetching sequence stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSequenceStats();
  }, [sequenceIdUrl, setSelectedTimeZone, user]);

  const handleSave = async () => {
    setSaveSpinner(true);
    try {
      const payload = {
        userId: user,
        sequenceId: sequenceIdUrl,
        triggerSettings: {
          weekDays: weekDays,
          startTime: selectedStartTime.value,
          endTime: selectedEndTime.value,
          timeZone: selectedTimeZone.value,
        },
      };

      let response = await updateSettingTime(payload);
      if (response.data.status) {
        toast.success("Time updated");
        setFetchSequenceStep(!fetchSequenceStep);
        setSaveSpinner(false);
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const handleDate = async () => {
    try {
      const payload = {
        userId: user,
        sequenceId: sequenceIdUrl,
        triggerSettings: {
          endDate: format(selectedEndDate, "dd-MM-yyyy"),
          startDate: format(selectedStartDate, "dd-MM-yyyy"),
        },
      };

      let response = await updateSettingTime(payload);
      if (response.data.status) {
        setFetchSequenceStep(!fetchSequenceStep);
        setSaveSpinner(false);
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const handleCheckboxChange = (day) => (event) => {
    setWeekDays((prevWeekDays) => ({
      ...prevWeekDays,
      [day]: event.target.checked,
    }));
  };

  if (loading) {
    return <Loading text={"Please wait"} />;
  }

  let footer = (
    <div className="border-t border-[#ddd] py-8 flex justify-center items-start">
      <div className="">
        <Button
          onClick={() => {
            handleClose();
            handleDate();
          }}
          variant="contained"
          sx={{ backgroundColor: "#0000ff" }}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  let footer2 = (
    <div className="border-t border-[#ddd] py-8 flex justify-center items-start">
      <div className="">
        <Button
          onClick={() => {
            handleClose2();
            handleDate();
          }}
          variant="contained"
          sx={{ backgroundColor: "#0000ff" }}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex">
        <div className="w-[22%]">
          <div className="flex">
            <div className="flex">
              <DateRangeIcon sx={{ color: "#8a8888a3" }} />
              <h1 className="ml-2">Start</h1>
              <span className="text-[#8a8888a3] px-3">|</span>
            </div>
            <h1
              onClick={handleClick2}
              className="cursor-pointer text-blue-dark font-semibold"
            >
              {selectedStartDate
                ? format(selectedStartDate, "PP")
                : "Start Date"}
            </h1>
            <Popover
              PaperProps={{
                style: { width: "400px", height: "500px" },
              }}
              className="popover-shadow w-[400px] h-[500px]"
              id={id}
              open={open2}
              anchorEl={anchorEl2}
              onClose={handleClose2}
            >
              <div>
                <DayPicker
                  mode="single"
                  showOutsideDays
                  selected={selectedStartDate}
                  onSelect={setSelectedStartDate}
                  footer={footer2}
                />
              </div>
            </Popover>
          </div>

          <div className="flex mt-4">
            <div className="flex">
              <DateRangeIcon sx={{ color: "#8a8888a3" }} />
              <h1 className="ml-2">End</h1>
              <span className="text-[#8a8888a3] px-4">|</span>
            </div>
            <h1 className="text-blue-dark font-semibold">
              <h1
                onClick={handleClick}
                className="cursor-pointer font-semibold"
              >
                {selectedEndDate ? format(selectedEndDate, "PP") : "End Date"}
              </h1>

              <Popover
                PaperProps={{
                  style: { width: "400px", height: "500px" },
                }}
                className="popover-shadow w-[400px] h-[500px]"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <div>
                  <DayPicker
                    mode="single"
                    showOutsideDays
                    selected={selectedEndDate}
                    onSelect={setSelectedEndDate}
                    footer={footer}
                  />
                </div>
              </Popover>
            </h1>
          </div>
        </div>

        <div className="flex-1">
          <div className="py-12 px-6  icon rounded shadow-medium border border-primary">
            <div className="flex gap-4">
              <WbSunnyIcon sx={{ color: "#ffbf00" }} />
              <h1 className="text-[1.25rem] font-semibold">Days</h1>
            </div>

            <div className="mt-2 ">
              <div className="flex gap-4">
                {Object.keys(weekDays).map((day) => (
                  <FormControlLabel
                    key={day}
                    control={
                      <Checkbox
                        checked={weekDays[day]}
                        onChange={handleCheckboxChange(day)}
                      />
                    }
                    label={day.charAt(0).toUpperCase() + day.slice(1)}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* time  */}
          <div className="py-12 mt-6 px-6  icon rounded shadow-medium border border-primary">
            <div className="flex gap-4">
              <AccessTimeIcon sx={{ color: "#42ABEF" }} />
              <h1 className="text-[1.25rem] font-semibold">Time</h1>
            </div>

            <div className="mt-4 w-1/2">
              <div className="flex gap-4">
                <div className="flex-1">
                  <h1 className="mb-2">From</h1>
                  <Select
                    maxMenuHeight={160}
                    menuPlacement={"top"}
                    defaultValue={selectedStartTime}
                    onChange={setSelectedStartTime}
                    options={TimeInterval}
                  />
                </div>
                <div className="flex-1">
                  <h1 className="mb-2">To</h1>

                  <Select
                    menuPlacement={"top"}
                    maxMenuHeight={160}
                    defaultValue={selectedEndTime}
                    onChange={setSelectedEndTime}
                    options={TimeInterval}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 w-1/2">
              <div>
                <h1 className="uppercase mb-2">TimeZone</h1>
                <Select
                  maxMenuHeight={160}
                  menuPlacement={"top"}
                  defaultValue={selectedTimeZone}
                  onChange={setSelectedTimeZone}
                  options={timeZoneObjects}
                />
              </div>
            </div>
          </div>

          <div className="my-4 mb-8">
            <LoadingButton
              className="font-semibold btn"
              loading={saveSpinner}
              submitLoading
              onClick={handleSave}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
