
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GetUser from "../../../Hooks/GetUser";
import { UserContext } from "../../../Hooks/UserContext";
import "./Modal.css";
// import { ModalData } from "./ModalData";
// import ModalGridItem from "./ModalGridItem";
import Select from "react-select";
import { createSeq } from "../../../Services/apiServices";
import Button from "../../../Componenets/Button";


const SEQUENCE_TEMPLATE_NAMES = [
  "Invite Community via Email",
  "Invite Community via Email + Linkedin",
  "Linkedin Connection Autoamtion",
  "8 Step Personalised Invite to community + Email Linkedin",
  "Manul Outbound Linkedin Invite + Send Message Follow",
  "Completly Custom",
].map((name) => {
  return { label: name, value: name };
});

const Modal = () => {
  const { setActiveModal, reFetch, setRefetch } = useContext(UserContext);
  // const [templateId, setTemplateId] = useState("");
  const [choosenTemplate, setChoosenTemplate] = useState(
    SEQUENCE_TEMPLATE_NAMES[0].value
  );
  const [loader, setLoader] = useState(false)
  const [sequenceName, setSequenceName] = useState("");
  const inputDataref = useRef();
  const navigate = useNavigate();
  // const condition = templateId === "" || sequenceName === "";

  const user = GetUser();
  console.log(user, 'userid')
  const createSequence = async () => {


    if (sequenceName !== "") {
      setLoader(true)
      const payload = {
        userId: user,
        templateName: choosenTemplate,
        sequenceName: sequenceName,
      }

      let res = await createSeq(payload)
      console.log(res)
      if (res.data.success) {
        setLoader(false)
        setActiveModal(false);
        setRefetch(!reFetch);
        navigate(`sequence/${res.data.sequenceId}`);
        setSequenceName('')
      }

    }
  };

  return (
    <div className="popup">
      <div className="modal-empty-div"></div>
      {/* <div className="content-container w-[1024px] !h-[98%] max-h-[98%]"> */}
      <div className="content-container w-[750px] !top-[10rem]">
        <div className=" w-full  popup-content min-h-full  !bg-white">
          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray p-4">
            <h1 className="primary-heading-color font-semibold">
              New Sequence
            </h1>
            <button
              onClick={() => setActiveModal(false)}
              className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[30px] w-[20px] h-[20px] flex justify-center items-center"
            >
              &times;
            </button>
          </div>

          <div className=" p-4 bg-[#F7F9FB]">
            <label
              htmlFor="sequenceName"
              className="text-[14px] font-500 mb-2 inline-block color-gray"
            >
              Select Template
            </label>
            <Select
              // className={
              //   "w-full focus:border-blue-400 text-[13px] custom-shadow  pl-2 focus:outline-none input-border rounded-[4px] h-8"
              // }
              id={"channel"}
              defaultValue={SEQUENCE_TEMPLATE_NAMES[0]}
              onChange={(selectedTemplate) => {
                setChoosenTemplate(selectedTemplate.value);
              }}
              options={SEQUENCE_TEMPLATE_NAMES}
            ></Select>
          </div>

          <div className=" p-4 !pt-0 bg-[#F7F9FB] border-b border-border-gray">
            <label
              htmlFor="sequenceName"
              className="text-[14px] font-500 mb-2 inline-block color-gray"
            >
              Sequence Name
            </label>
            <input
              id="sequenceName"
              ref={inputDataref}
              value={sequenceName}
              onChange={(e) => setSequenceName(e.target.value)}
              type="text"
              className="w-full text-[13px] custom-shadow pl-2 focus:outline-none border rounded-[4px] h-8 border-gray-400 focus:border-blue-400"
            />
          </div>

          <div className="flex justify-end p-4 ">
            <div className="flex">
              <button
                onClick={() => setActiveModal(false)}
                className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
              >
                Cancel
              </button>

              <div>

                <Button disabled={loader} onClick={createSequence} loading={loader} tittle={'Create'}></Button>

                {/* <button

                  className={`button`}
                >
                  Create
                </button> */}
              </div>
            </div>
          </div>

          {/* <div className=" pt-2 px-6 hidden">

            <div className="grid grid-cols-3 mt-3 grid-rows-2 gap-6">
              {ModalData.map((singleGridData) => (
                <ModalGridItem
                  setTemplateId={setTemplateId}
                  singleGridData={singleGridData}
                  key={singleGridData?.id}
                ></ModalGridItem>
              ))}
            </div>
            <div className="flex mt-12 items-center  justify-between">
              <div className="flex w-[90%]">
                <h1 className="primary-heading-color font-semibold">
                  Sequence Name
                </h1>
                <input
                  ref={inputDataref}
                  value={sequenceName}
                  onChange={(e) => setSequenceName(e.target.value)}
                  type="text"
                  className="w-[75%] text-[13px] ml-8 pl-2 focus:outline-none input-border rounded-[4px] h-8"
                />
              </div>
              <div>


                <button
                  disabled={condition}
                  onClick={() => createSequence()}
                  className={`button-background text-white font-500   h-[32px] rounded-[3px] px-[12px] text-[13px]`}
                >
                  Save
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Modal;
