import http from "./httpService";

export function userLogin(data) {
  return http.post(`/api/v1/users/login`, data);
}
export function userSignup(data) {
  return http.post(`/api/v1/users/signup`, data);
}

export function userGoogleLogin(data) {
  return http.post(`/api/v1/users/googleLogin`, data);
}

export function deleteProspect(data) {
  return http.post(`/api/v1/prospect/deleteProspects`, data);
}

// sequence
export function createSeq(data) {
  return http.post(`/api/v1/sequence/createSequence`, data);
}

export function createStep(data) {
  return http.post(`api/v1/step/createStep`, data);
}

export function getSequenceWithSteps(data) {
  return http.post(`/api/v1/sequence/getSequenceWithSteps`, data);
}

export function getSequenceStat(data) {
  return http.get(
    `/api/v1/sequence/getSequenceStat/${data.userId}/${data.sequenceId}`
  );
}

export function getSequenceStats(data) {
  return http.get(
    `/api/v1/sequence/getSequenceStats/${data.userId}/${data.sequenceId}`
  );
}

export function getStepStats(data) {
  return http.get(
    `/api/v1/step/getStepsStat/${data.userId}/${data.sequenceId}`
  );
}

export function activeSeq(data) {
  return http.post(`api/v1/sequence/activeSequence`, data);
}

export function activeTemplate(data) {
  return http.put(`/api/v1/template/activeTemplate`, data);
}
export function addMailbox(data) {
  return http.post(`/api/v1/userconnections/addEmail`, data);
}

export function userData(data) {
  return http.get(`/api/v1/users/${data.user}`);
}

export function mailboxConnection(data) {
  return http.get(`/api/v1/userconnections/listmailbox/${data.user}`);
}

export function deleteStep(data) {
  return http.delete(
    `/api/v1/step/deleteStep/${data.user}/${data.sequenceId}/${data.stepId}`
  );
}

//prospect

export function saveProspects(data) {
  return http.post(`/api/v1/prospect/saveProspects`, data);
}

export function uploadProspect(data) {
  return http.post(`/api/v1/prospect/uploadProspect`, data);
}

// signatureModal

export function createEmailSignature(data) {
  return http.post(`/api/v1/userconnections/createEmailSignature`, data);
}

export function getEmailSignature(data) {
  return http.get(
    `/api/v1/userconnections/getEmailSignature/${data.user}/${data?.senderEmail}`
  );
}

// step
export function getStepdata(data) {
  return http.get(`/api/v1/step/getStep/${data.user}/${data.stepId}`);
}

export function updateStepData(data) {
  return http.put(`/api/v1/step/updateStep`, data);
}

export function getCustomTemplate(data) {
  return http.get(`/api/v1/template/getCustomTemplates/${data.user}`);
}

export function updatePassword(data) {
  return http.post(`/api/v1/users/update_password`, data);
}

export function deleteMailbox(data) {
  return http.delete(
    `/api/v1/userconnections/deleteMailBox/${data.user}/${data.senderEmail}`
  );
}

// mailbox data
export function getMailboxData(data) {
  return http.get(`api/v1/userconnections/getMailboxData/${data.mailboxId}`);
}

// set Email send Limit
export function setSenderLimit(data) {
  return http.post(`/api/v1/userconnections/setMailboxLimit`, data);
}

// delete template

export function deleteTemplate(data) {
  return http.delete(`/api/v1/template/deleteTemplate/${data.user}/${data.id}`);
}

// detailsStats
export function detailsStats(query) {
  return http.get(`/api/v1/sequence/seqStatsFilter?${query}`);
}
// hubspot
export function hubspotMatchColumn(data) {
  return http.post(`/api/v1/prospect/get-hubspot-contacts`, data);
}

//delete nthProspect

export function deleteNthProspect(data) {
  return http.post(`/api/v1/prospect/deleteNumberOfProspects`, data);
}

export function unsubscribeEmail(data) {
  return http.post(`/api/v1/prospect/unsubscribe`, data);
}

// notification api endpoints

export function fetchNotification(data) {
  return http.get(`/api/v1/notification/fetchNotif/${data.user}`);
}

export function newNotificationCount(data) {
  return http.get(`/api/v1/notification/numOfNewNotifications/${data.user}`);
}

export function openNotification(data) {
  return http.put(`/api/v1/notification/notifOpened`, data);
}

// ai

export function writeSequenc(data) {
  return http.post(`/api/v1/aibot/writeSequence`, data);
}

export function write7steps(data) {
  return http.post(`/api/v1/aibot/write7StepSequence`, data);
}

//notification

export function notificationOpen(data) {
  return http.put(`/api/v1/notification/notifOpened`, data);
}

export function fetchNotif(data) {
  return http.get(`/api/v1/notification/fetchNotif/${data}`);
}

//upload file /api/v1/attachments/uploadAttachments

export function uploadAttachments(data) {
  return http.post(`/api/v1/attachments/uploadAttachments`, data);
}

export function deleteAttachment(data) {
  return http.post(`/api/v1/attachments/deleteAttachment`, data);
}

//dashboard
export function emailSent7day(userId) {
  return http.get(`/api/v1/dashboard/weeklyEmailSent/${userId}`);
}

// warmUpMode
export function setWarmUpmode(data) {
  return http.post(`/api/v1/userconnections/updatewarmupflag`, data);
}

// sequence

export function updateSettingTime(data) {
  return http.post(`/api/v1/sequence/triggersettings`, data);
}

// sequence setting

export function sequenceSettingTime(data) {
  return http.post(`/api/v1/sequence/updateSequenceSettings`, data);
}

// people database

export function peopleDatabase(data) {
  return http.post(`/api/v1/apollo/fetch_leads`, data);
}

export function addToSequence(data) {
  return http.post(`/api/v1/prospect/addToSeqForNumProspects`, data);
}

//LISTNAME
export function getListName(data) {
  return http.get(`/api/v1/list/${data}`);
}

// fetch prospect
export function fetchProspect(data) {
  return http.post(`api/v1/prospect/fetchProspect_v2`, data);
}

// add prospect to list

export function addProspectTolist(data) {
  return http.post(`/api/v1/list/addProspectToList`, data);
}

// UPDATE PROPECT

export function updatePropspect(data) {
  return http.put(`/api/v1/prospect/updateProspect`, data);
}

// fetch prospect data

export function fetchPropspectData(data) {
  return http.get(
    `/api/v1/prospect/fetchProspect?userId=${data.userId}&email=${data.email}`
  );
}

export function updateStepTriggerDate(data) {
  return http.put(`/api/v1/step/updateStepTriggerDate`, data);
}

// add to sequence
export function addToSequenceV2(data) {
  return http.post(`/api/v1/prospect/addToSeqForNumProspectsV2`, data);
}

export function addProspectToList(data) {
  return http.post(`/api/v1/list/addProspectToList`, data);
}

export function updateUserInfo(data) {
  return http.post(`api/v1/users/update_userinfo`, data);
}

export function addProspect(data) {
  return http.post(`api/v1/prospect/addSingleProspect`, data);
}
