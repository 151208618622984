import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const NewModalUi = ({ showModal, setCloseModal, children, width, title }) => {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999]"
        onClose={() => setCloseModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                style={{ width: width }}
                className="transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <div className="flex justify-between items-center bg-white border-b border-border-gray">
                  <h1 className="primary-heading-color font-semibold">
                    {title}
                  </h1>

                  <button
                    onClick={() => setCloseModal(false)}
                    className="icon-color  hover:text-[#1991eb] hover:bg-[#e8eef4]   w-[18px] h-[18px] flex justify-center items-center"
                  >
                    <CloseIcon className="close-icon" fontSize="small" />
                  </button>
                </div>
                <div className="flex justify-end items-center"></div>

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewModalUi;
