import React, { useEffect, useState } from "react";
import Button from "../../../Componenets/Button";
import Select from "react-select";
import GetUser from "../../../Hooks/GetUser";
import {
  addToSequenceV2,
  mailboxConnection,
} from "../../../Services/apiServices";
import whiteLoadder from "../../../Images/whiteSpinner.gif";
import axios from "axios";
import properties from "../../../config/properties";
import Logger from "../../../Helpers/Logger";
import { toast } from "react-toastify";

const ProspectSenderEmailModal = ({
  setShowNumOfAddedSeq,
  setShowNumOfAddedSeqModal,
  setSenderEmailModalShow,
  filterBy,
  filterByStage,
  activePage,
  numberOfSelectedItem,
  selectedProspects,
  setRefetch,
  selectAllProspect,
}) => {
  const [loader, setLoader] = useState({
    fetchData: false,
    submit: false,
  });
  const user = GetUser();
  const [selectedEmail, setSelectedEmail] = useState();
  const [selectedSequence, setSelectedSequence] = useState();
  const [mailboxes, setMailboxes] = useState([]);
  const [sequences, setSequences] = useState([]);

  useEffect(() => {
    setLoader((prev) => {
      return { ...prev, fetchData: true };
    });
    axios
      .post(`${properties.REACT_APP_SERVER_URL}/api/v1/sequence/getSequences`, {
        userId: user,
      })
      .then((res) => {
        if (res.data.status) {
          setSequences(
            res.data.data.map((sequence) => ({
              value: sequence._id,
              label: sequence.name,
            }))
          );
        }
      });

    async function getMailboxConnection() {
      const payload = {
        user: user,
      };
      let response = await mailboxConnection(payload);

      if (response.data.data) {
        if (!response.data.data.connections) {
          setLoader((prev) => {
            return { ...prev, fetchData: false };
          });
          toast.error("first add mailbox from setting");
          setSenderEmailModalShow(false);
        }
        if (response.data.data.connections.length > 0) {
          setSelectedEmail({
            label: response.data.data.connections[0].email,
            value: response.data.data.connections[0].email,
          });
        }
        setMailboxes(
          response.data.data.connections.map((mailbox) => ({
            value: mailbox.email,
            label: mailbox.email,
          }))
        );
        setLoader((prev) => {
          return { ...prev, fetchData: false };
        });
      }
    }
    getMailboxConnection();
  }, [setSenderEmailModalShow, user]);

  const handleSubmit = async () => {
    if (!selectedSequence.value) {
      toast.error("select the sequence");
      return;
    }
    try {
      setLoader((prev) => {
        return { ...prev, submit: true };
      });
      const payload = {
        userId: user,
        sequenceId: selectedSequence.value,
        senderEmail: selectedEmail.value,
        selectedProspectIds: selectedProspects,
        skipFrom: 0,
        perPage: 20,
      };
      if (selectAllProspect) {
        payload.selectedProspectIds = [];
      }

      let res = await addToSequenceV2(payload);

      if (res.data.success) {
        setLoader((prev) => {
          return { ...prev, submit: false };
        });
        toast.success(res.data.message);
        setShowNumOfAddedSeq(res.data.info);
        setShowNumOfAddedSeqModal(true);
        setSenderEmailModalShow(false);
      }

      //   if (numberOfSelectedItem > 20) {
      //     axios
      //       .post(
      //         `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/addToSeqForNumProspects`,
      //         {
      //           userId: user,
      //           numberOfProspects: numberOfSelectedItem,
      //           sequenceId: selectedSequence.value,
      //           senderEmail: selectedEmail.value,
      //           skipFrom: 20 * activePage,
      //           selectedProspectIds: selectedProspects,
      //           filterBy: filterBy,
      //           filterByStage: filterByStage,
      //         }
      //       )
      //       .then((res) => {
      //         // numOfProspectsAddedToSequence
      //         // numOfProspectsNotAddedToSequence

      //         if (res.data.success) {
      //           setLoader((prev) => {
      //             return { ...prev, submit: false };
      //           });
      //           toast.success(res.data.message);
      //           setShowNumOfAddedSeq(res.data.info);
      //           setShowNumOfAddedSeqModal(true);
      //           setSenderEmailModalShow(false);
      //           // setRefetch(prev => !prev);
      //         }
      //       });
      //   } else {
      //     axios
      //       .post(
      //         `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/addToSequence`,
      //         {
      //           userId: user,
      //           prospectIds: selectedProspects,
      //           sequenceId: selectedSequence.value,
      //           senderEmail: selectedEmail.value,
      //         }
      //       )
      //       .then((res) => {
      //         if (res.data.success) {
      //           setLoader((prev) => {
      //             return { ...prev, submit: false };
      //           });
      //           setShowNumOfAddedSeqModal(true);
      //           toast.success(res.data.message);
      //           setSenderEmailModalShow(false);
      //           // setRefetch(prev => !prev);
      //           setShowNumOfAddedSeq(res.data.info);
      //         }
      //       });
      //   }
    } catch (error) {
      Logger.error(error);
    }
  };

  return (
    <div className="popup">
      <div className="modal-empty-div"></div>
      {/* <div className="content-container w-[1024px] !h-[98%] max-h-[98%]"> */}
      <div className="content-container w-[750px] !top-[12rem]">
        <div className=" w-full  popup-content min-h-full  !bg-white">
          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray p-4">
            <h1 className="primary-heading-color font-semibold">
              Add to Sequence
            </h1>
            <button
              onClick={() => setSenderEmailModalShow(false)}
              className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[30px] w-[20px] h-[20px] flex justify-center items-center"
            >
              &times;
            </button>
          </div>

          {loader.fetchData ? (
            <img
              className="w-[130px] h-[130px] mx-auto"
              src={whiteLoadder}
              alt="spinner"
            />
          ) : (
            <>
              <div className=" p-4 bg-[#F7F9FB]">
                <label
                  htmlFor="sequenceName"
                  className="text-[14px] font-500 mb-2 inline-block color-gray"
                >
                  Sequence
                </label>
                <Select
                  // defaultValue={sequences.length > 0 ? sequences[0] : { label: '', value: '' }}
                  onChange={(selected) => {
                    setSelectedSequence(selected);
                  }}
                  options={sequences}
                />
              </div>

              <div className=" p-4 !pt-2  bg-[#F7F9FB] border-b border-border-gray">
                <label
                  htmlFor="sequenceName"
                  className="text-[14px] font-500 mb-2 inline-block color-gray"
                >
                  Send Email From :
                </label>
                <Select
                  defaultValue={
                    mailboxes.length > 0
                      ? mailboxes[0]
                      : { label: "", value: "" }
                  }
                  onChange={(selected) => {
                    setSelectedEmail(selected);
                  }}
                  options={mailboxes}
                />
              </div>

              <div className="flex justify-end p-4 mt-8">
                <div className="flex">
                  <button
                    onClick={() => setSenderEmailModalShow(false)}
                    className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
                  >
                    Cancel
                  </button>
                  <div>
                    <Button
                      loading={loader.submit}
                      disabled={loader.submit}
                      onClick={handleSubmit}
                      tittle={"Save"}
                    ></Button>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <div className=" pt-2 px-6 hidden">

            <div className="grid grid-cols-3 mt-3 grid-rows-2 gap-6">
              {ModalData.map((singleGridData) => (
                <ModalGridItem
                  setTemplateId={setTemplateId}
                  singleGridData={singleGridData}
                  key={singleGridData?.id}
                ></ModalGridItem>
              ))}
            </div>
            <div className="flex mt-12 items-center  justify-between">
              <div className="flex w-[90%]">
                <h1 className="primary-heading-color font-semibold">
                  Sequence Name
                </h1>
                <input
                  ref={inputDataref}
                  value={sequenceName}
                  onChange={(e) => setSequenceName(e.target.value)}
                  type="text"
                  className="w-[75%] text-[13px] ml-8 pl-2 focus:outline-none input-border rounded-[4px] h-8"
                />
              </div>
              <div>


                <button
                  disabled={condition}
                  onClick={() => createSequence()}
                  className={`button-background text-white font-500   h-[32px] rounded-[3px] px-[12px] text-[13px]`}
                >
                  Save
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProspectSenderEmailModal;
