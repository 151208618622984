import React, { useContext, useState } from 'react';
import SearchTemplate from './SearchTemplate';
import EmailTemplate from './EmailTemplate';
import SequenceNameModal from './SequenceNameModal';
import { UserContext } from '../../../Hooks/UserContext';

const EmailTemplates = (props) => {
    const { openSequenceModal } = useContext(UserContext);
    const [filterTemplate, setFilterTemplate] = useState(props.emailTemplates);

    const handleSearch = (searchTerm) => {
        const filtered = props.emailTemplates.filter((template) =>
            template.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
        setFilterTemplate(filtered);
    };

    return (
        <div>
            <div className='flex justify-between items-center'>
                <h1 className='text-[#2D3748] font-semibold text-[17px]'>Featured</h1>


                <SearchTemplate slackButton={props.slackButton} handleSearch={handleSearch} />

            </div>
            <div className='mt-4'>
                <EmailTemplate filterTemplate={filterTemplate} />
            </div>

            {openSequenceModal && <SequenceNameModal></SequenceNameModal>}
        </div>
    );
};

export default EmailTemplates;
