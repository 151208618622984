import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import logo from "../../../Images/klubstack_logo.png";
import { AuthContext } from "../../../Hooks/Authcontext";
import NotificationBox from "./NotificationBox";
import GetUser from "../../../Hooks/GetUser";
import properties from "../../../config/properties";
import axios from "axios";
import { MdNotificationsNone } from "react-icons/md";
import { BiUserCircle } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import Button from "@mui/material/Button";
import { Menu, MenuItem } from "@mui/material";
import { FiLogOut } from "react-icons/fi";
import { UserContext } from "../../../Hooks/UserContext.js";

const Header = () => {
  const navigate = useNavigate();
  const { setLogged, isLogged } = useContext(AuthContext);
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const { fetchUserData } = useContext(UserContext);

  const [refetch, setRefetch] = useState(true);

  const [showNotification, setShowNotification] = useState(false);

  const notificationRef = useRef();
  const logout = () => {
    let token = localStorage.getItem("token");
    let logintype = localStorage.getItem("isManualLogin");
    if (token || logintype) {
      localStorage.removeItem("token");
      localStorage.removeItem("isManualLogin");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("closeBanner");
    }
    navigate("/login");
    setLogged(false);
  };

  //for mui menu
  const [anchorElm, setAnchorElm] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setAnchorElm(null);
    setOpen(false);
  };

  const handleClick = (e) => {
    setAnchorElm(e.currentTarget);
    setOpen(true);
  };

  const [notificationCount, setNotificationCount] = useState(0);
  const user = GetUser();

  useEffect(() => {
    axios
      .get(
        `${properties.REACT_APP_SERVER_URL}/api/v1/users/getEmailFromUserId/${user}`
      )
      .then((res) => {
        if (res.data.status) {
          setUserData(res.data);
        }
      });
  }, [user, fetchUserData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user) {
        axios
          .get(
            `${properties.REACT_APP_SERVER_URL}/api/v1/notification/numOfNewNotifications/${user}`
          )
          .then((result) => {
            if (result?.data?.status) {
              setNotificationCount(result?.data?.numOfNewNotifications);
            }
          });
      }
    }, 30000);

    const handleClickOutside = (e) => {
      if (
        notificationRef.current &&
        !notificationRef?.current.contains(e.target)
      ) {
        setShowNotification(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
      clearInterval(interval);
    };
    //
  }, [user, refetch]);

  const getTitle = () => {
    const path = location.pathname;
    const parts = path.split("/").filter((part) => part !== "");

    if (parts.length > 0) {
      const baseName = parts[0];

      switch (baseName) {
        case "sequence":
          return "Sequence";
        case "setting":
          return "Setting";
        case "prospects":
          return "Prospects";
        case "template":
          return "Template";
        case "people-database":
          return "People Database";
        default:
          return baseName.charAt(0).toUpperCase() + baseName.slice(1);
      }
    }

    return "Dashboard";
  };

  return (
    <div className=" bg-[#ffffff]  border-b font-500 w-full  top-navbar py-5">
      <div className="flex justify-between items-center conatiner mx-auto">
        <div className="">
          {/* <img
              src={logo}
              className="ml-2 h-[2.5rem] w-[2.5rem]"
              alt="logo of auto-commm"
            />
            <h1 className="font-semibold ml-[12px] text-[17px] primary-heading-color uppercase">
              KlubStack
            </h1> */}
          <h1 className="font-semibold  text-[17px] heading-style uppercase">
            {getTitle()}
          </h1>
        </div>

        <div className=" text-xl hidden lg:flex w-[50%] justify-end">
          <ul className="lg:flex items items-center">
            {isLogged ? (
              <>
                <div ref={notificationRef}>
                  <div>
                    <div className="hover:cursor-pointer flex items-center">
                      <div
                        className="relative mr-3 inline-flex w-fit"
                        onClick={() => setShowNotification((prev) => !prev)}
                      >
                        {notificationCount !== 0 && (
                          <div className="absolute text-white bottom-auto left-auto right-0 top-[6px] z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-red-500 w-4 h-4 text-center  text-xs">
                            <h1 className="text-white font-medium text-[11px]">
                              {" "}
                              {notificationCount}
                            </h1>
                          </div>
                        )}
                        <div
                          data-tooltip-content="Notification"
                          data-tooltip-id="Notification"
                        >
                          <Tooltip id="Notification" />

                          <MdNotificationsNone className="text-[#5C6E7B] font-bold text-[25px]" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="notificationBox">
                    {showNotification && (
                      <NotificationBox
                        setRefetch={setRefetch}
                        refetch={refetch}
                        setShowNotification={setShowNotification}
                      />
                    )}
                  </div>
                </div>

                {/* menu item  */}
                <div>
                  <Button
                    onClick={handleClick}
                    sx={{ color: "#5C6E7B" }}
                    variant="text"
                  >
                    <BiUserCircle className="text-[25px] mr-1 font-semibold" />
                    <p className="font-semibold capitalize">{userData?.name}</p>
                  </Button>

                  <Menu anchorEl={anchorElm} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate("/setting");
                      }}
                    >
                      Setting
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        logout();
                      }}
                    >
                      Log Out <FiLogOut className="ml-2 text-[#5C6E7B]" />
                    </MenuItem>
                  </Menu>
                </div>

                {/* <div
                  className="mr-4  !capitalize font-medium header-link-typography cursor-pointer flex items-center "
                  onClick={() => {
                    logout();
                    posthog.capture("User Logout", { buttonName: "Logout" });
                  }}
                  data-tooltip-content="Logout"
                  data-tooltip-id='Logout'
                >
                  <Tooltip id='Logout' />
                  {userData?.name}
                  <MdLogout className="ml-2 text-[#ABBBC3] font-bold text-[22px]" />
                </div> */}
              </>
            ) : (
              <>
                <NavLink
                  className="mr-5 header-link-typography-mobile font-medium"
                  to="/login"
                  isActive={() => location.pathname === "/login"}
                >
                  {location.pathname !== "/login" && "Login"}
                </NavLink>

                <NavLink
                  className="mr-5 header-link-typography-mobile font-medium"
                  to="/registration"
                  isActive={() => location.pathname === "/register"}
                >
                  {location.pathname !== "/registration" && "Registration"}
                </NavLink>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
