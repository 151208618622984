import React, { useEffect, useState } from "react";
import prospectusEmpty from "../../../Images/prospectus-empty.svg";
import TemplateModal from "./TemplateModal";
import "./Template.css";
import axios from "axios";
import Loading from "../../Shared/Loading/Loading";
import navigationImg from "../../../Images/navigation.svg";
import siri from "../../../Images/Siri.svg";
import properties from "../../../config/properties";
import GetUser from "../../../Hooks/GetUser";
import { activeTemplate } from "../../../Services/apiServices";
import TableRow from "./TableRow";
const Templates = () => {
  const [activeTemplateModal, setActiveTemplateModal] = useState(false);
  const user = GetUser();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetch, setRefetch] = useState();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${properties.REACT_APP_SERVER_URL}/api/v1/template/getCustomTemplates/${user}`
      )
      .then((res) => {
        setTemplates(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      });
  }, [fetch, user]);

  const toggleTemplateActive = async (templateId) => {
    let toggleBtn = document.getElementById(`active-${templateId}`);

    if (toggleBtn.classList?.contains("bg-slate-300")) {
      toggleBtn.classList.remove("bg-slate-300");
      toggleBtn.classList.add("bg-blue-500");
    } else {
      toggleBtn.classList.remove("bg-blue-500");
      toggleBtn.classList.add("bg-slate-300");
    }
    let toggleSwitch = toggleBtn.children[0];
    if (toggleSwitch.classList?.contains("float-right")) {
      toggleSwitch.classList.remove("float-right");
    } else {
      toggleSwitch.classList.add("float-right");
    }

    const payload = {
      userId: user,
      templateId: templateId,
    };

    await activeTemplate(payload);
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div className="p-4">
      {templates?.length === 0 ? (
        <div className="flex justify-center items-center pt-40">
          <div className="flex flex-col items-center justify-center relative">
            <button
              onClick={() => setActiveTemplateModal(true)}
              className="btn"
            >
              + Template
            </button>
            <img src={prospectusEmpty} alt="" />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-between">
            <h1 className="text-[1.1rem] text-[#151D48] font-semibold primary-heading-color capitalize">
              Templates
            </h1>
            <button
              onClick={() => setActiveTemplateModal(true)}
              className="btn"
            >
              + Template
            </button>
          </div>

          <div className="bg-white custom-shadow-1  mt-12 h-full p-6 inline-block rounded-[8px] w-full">
            <div className="flex">
              <div className="w-full flex items-center">
                <input
                  className="bg-[#F7F7FC] w-[90%] relative  mb-3 input-search"
                  type="text"
                  placeholder="search here"
                ></input>
                <div className=" flex w-[10%] justify-around items-center">
                  <img className="w-[18px]" src={siri} alt="" />
                  <img height="16px" src={navigationImg} alt="" />
                </div>
              </div>
            </div>


            <table className="w-full" >
              <thead>
                <tr>
                  <th>ID</th>
                  <th className="w-[11rem] ">Temnplate Name</th>
                  <th>No of Sequence</th>
                  <th>Channel</th>
                  <th>Owner</th>
                  <th colSpan={4} className="!text-center">
                    Template Stats
                  </th>
                  <th>Date Created</th>
                  <th>Activate</th>
                </tr>
              </thead>
              <tbody className="w-full">

                {
                  templates?.map((singleData, index) => <TableRow index={index} templates={templates} setRefetch={setRefetch} toggleTemplateActive={toggleTemplateActive} singleData={singleData} key={singleData._id}></TableRow>)
                }

              </tbody>
            </table>

          </div>
        </div>
      )}

      {activeTemplateModal && (
        <TemplateModal
          fetch={fetch}
          setRefetch={setRefetch}
          closeModal={setActiveTemplateModal}
        ></TemplateModal>
      )}
    </div>
  );
};

export default Templates;
