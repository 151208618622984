import React, { useContext } from "react";
import { UserContext } from "../../../Hooks/UserContext";
import UseHeight from "../../../Hooks/UseHeight";

const badgeColors = {
  new: 'bg-[#7edc87] text-[#fff]',
  improved: 'bg-[#E9D8FD] text-[#44337A]',
  development: 'bg-[#fef4d3] text-[#505050]'
}

const SingleCard = ({ template }) => {
  const { setSelectedTemplate } = useContext(UserContext)

  const heightFromhook = UseHeight()
  function getHeightClassName(height) {
    if (height < 700) {
      return '15.5rem';
    } else if (height <= 827) {
      return '14.5rem';
    } else {
      return '13.5rem';

    }
  }




  return (
    <div onClick={() => { setSelectedTemplate(template?.name) }} style={{ height: getHeightClassName(heightFromhook) }} className="bg-white  relative p-4 md:p-4 lg:p-6 xl:p-6 rounded-[0.625rem] hover:shadow-md cursor-pointer border border-[#e2e8f0]">
      <div>
        <div className="flex justify-between items-center">
          <div>
            <img className="w-[30px] h-[30px]" src={template.icon} alt="" />
          </div>

          <div>
            {template?.badge ? (
              <h1 className={` rounded-lg p-[0.225rem] uppercase font-medium text-[1.2ch] ${badgeColors[template?.badge.toLocaleLowerCase()]} `}>
                {template?.badge}
              </h1>
            ) : (
              <h1 className="text-[#ffffff] rounded-lg p-[0.225rem] uppercase font-medium text-[1.2ch] bg-[#7edc87]">
                new
              </h1>
            )}
          </div>
        </div>
        <div className="mt-5">
          <h1 className="text-[#2D3748] font-medium text-[15px]">{template.name}</h1>
          <p className="mt-1 leading-5 text-[#718096] font-medium text-[14px]">

            {
              template.description ? template.description : 'Personalizer Craft cold personalized email that focuses on your prospect .'
            }

          </p>
        </div>
        <div className="outbound flex justify-center text-[13px] mt-3 w-[5rem] absolute bottom-[15px]">
          <h1>{template.footerBadge}</h1>
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
