import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RiBracesLine } from "react-icons/ri";

import { toast } from "react-toastify";
import properties from "../../../config/properties";
import { AuthContext } from "../../../Hooks/Authcontext";
import SubjectPersonalize from "../PersonalizeComponent/SubjectPersonalize";
import PersonalizeComponent from "../PersonalizeComponent/PersonalizeComponent";
import Button from "../../../Componenets/Button";
import DraftEditor from "../../../Componenets/DraftEditor";
import { EditorState, convertToRaw, ContentState, } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Tooltip } from "react-tooltip";
import ConformationModal from "../../../Componenets/ConformationModal";

const EditTemplateModal = ({ closeModal, templates, setRefetch, templateId }) => {
    const { user } = useContext(AuthContext);
    const [state, setState] = useState({ value: "" });
    const [editorData, setEditorData] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [subjectPopup, setSubjectPopup] = useState(false);
    const [templateName, settemplateName] = useState("");
    const [channelValue, setChannelValue] = useState("email");
    const [isPersonalisePopupOpen, setIsPersonalisePopupOpen] = useState(false);

    const [loader, setLoader] = useState(false)
    const quillRef = useRef(null);
    const personalizeBtn = useRef(null);
    const modalRef = useRef()
    const [confirmModal, setConfirmModal] = useState(false)
    const [detectFocus, setDetectFocus] = useState(false)
    const [isFocusedSubject, setIsFocusedSubject] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleChangee = (value) => {
        setState({ value });
        setEditorData(value);
    };

    const handleInsertInput = (value) => {
        setInputValue(inputValue + value);
        setSubjectPopup(false);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInsertVariable = (variable) => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const selection = quill.getSelection(true);

            quill.insertText(selection.index, variable);
            setIsPersonalisePopupOpen(false);
        }
    };

    const handleClickOutside = (e) => {
        // if (personalizeBtn.current && !personalizeBtn.current?.contains(e.target)) {
        //   setIsPopupOpen(false);
        // }
        // if (!selectSubject?.current?.contains(e.target)) {
        //   setSubjectPopup(false);
        // }
    };

    useEffect(() => {

        const filteredObject = templates.find(item => item._id === templateId);
        setInputValue(filteredObject.subject)

        settemplateName(filteredObject.templateName)
        setState({
            value: filteredObject.templateBody
        });
        const contentBlock = htmlToDraft(filteredObject.templateBody);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);

        }


        const handleClickOutside = (e) => {
            if (personalizeBtn.current && !personalizeBtn?.current.contains(e.target)) {
                setIsPersonalisePopupOpen(false);

            } if (modalRef.current && !modalRef?.current.contains(e.target)) {
                // closeModal(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside, true);
        document.body.style.overflow = 'hidden'

        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true);
            document.body.style.overflow = 'auto'

        };
    }, [closeModal, templateId, templates]);



    const onSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        axios
            .put(
                `${properties.REACT_APP_SERVER_URL}/api/v1/template/updateTemplate`,
                {
                    userId: user,
                    templateName: templateName,
                    channel: channelValue,
                    subject: inputValue,
                    templateBody: html,

                    templateId: templateId
                }
            )
            .then((res) => {
                if (res.data.success) {
                    closeModal(false);
                    toast.success("Update Template sucessfully");
                    setLoader(false)
                    setRefetch(prev => !prev);
                }
            });
    };




    // close modal
    const handleModal = () => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const isEmptyTemplate = html.replace(/\s/g, '') === '<p></p>';

        if ((!isEmptyTemplate && detectFocus) || (inputValue.trim() !== '' && isFocusedSubject)) {
            setConfirmModal(true);
        } else {
            closeModal(false)

        }
    };











    return (
        <div className="popup">

            {
                confirmModal && <ConformationModal setConfirmModal={setConfirmModal} setActiveStepModal={closeModal} />
            }

            <div className="modal-empty-div"></div>
            <div ref={modalRef} className="content-container w-[100vw] !h-[100%] max-h-[100%] !z-[9]">
                <div className="  popup-content min-h-full  !bg-white  p-4">
                    <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
                        <h1 className="primary-heading-color font-semibold">
                            Template Creation
                        </h1>
                        <button
                            onClick={handleModal}
                            className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="mt-4">
                        <form id="template-form" onSubmit={onSubmit}>
                            <div className="">
                                <h1 className={`color-gray text-[13px] font-semibold mb-1`}>
                                    Template Name:
                                </h1>
                                <input
                                    onChange={(e) => settemplateName(e.target.value)}

                                    value={templateName}
                                    placeholder="name"
                                    id="subject"
                                    type="text"
                                    className={`border border-[#d3dae3] pl-2 pr-8 w-full h-8 rounded-[4px] text-[14px] hour-input`}
                                />
                            </div>

                            <div className="mt-4">
                                <label className="color-gray text-[13px] font-semibold  mb-2">
                                    Channel:
                                </label>

                                <select
                                    onChange={(e) => setChannelValue(e.target.value)}
                                    value={channelValue}
                                    className="text-[13px] focus:outline-none border border-[#d3dae3] rounded-[4px] h-8 w-full "
                                >
                                    <option className="step-number" value="email">
                                        Email
                                    </option>
                                    <option className="step-number" value="linkedin invite">
                                        LinkedIn invite
                                    </option>
                                </select>
                            </div>

                            <div className="relative">
                                <PersonalizeComponent
                                    personalizeBtn={personalizeBtn}
                                    handleInsertVariable={handleInsertVariable}
                                />

                                <div>
                                    <div
                                        className={`  ${channelValue.includes("linkedin") ? "hidden" : ""
                                            }  my-4  relative`}
                                    >
                                        <SubjectPersonalize
                                            handleInsertInput={handleInsertInput}
                                            subjectPopup={subjectPopup}
                                        ></SubjectPersonalize>

                                        <div>
                                            <h1
                                                className={`color-gray text-[13px] font-semibold mb-2`}
                                            >
                                                Subject:
                                            </h1>
                                            <div className="flex relative">
                                                <input
                                                    onFocus={() => setIsFocusedSubject(true)}
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    id="subject"
                                                    type="text"
                                                    className={` border border-[#d3dae3] pl-2 pr-8 w-full h-8 rounded-[4px] text-[14px] hour-input`}
                                                />
                                                <div
                                                    onClick={() => setSubjectPopup(!subjectPopup)}
                                                    className="cursor-pointer absolute right-0 bg-[#f7f9fb] px-2 flex justify-center items-center  h-full custom-brace-icon"
                                                >
                                                    <h1 title="personalisation" className="text-[13px] ">
                                                        <Tooltip id='templatePersonalise' />
                                                        <RiBracesLine
                                                            data-tooltip-id='templatePersonalise'
                                                            data-tooltip-content="personalisation"
                                                            className="text-[17px] icon-color font-500 "
                                                        ></RiBracesLine>
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <h1 className={`mr-8`}>Subject</h1>
                    <input
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="enter a subject here"
                      id="subject"
                      type="text"
                      className={`border-blue pl-2 ml-4 h-10 rounded-[4px] text-[14px] hour-input`}
                    />

                    <h1
                      onClick={() => setSubjectPopup(true)}
                      className="absolute right-0 top-0 m-2 text-[15px] cursor-pointer"
                    >
                      +Personalisation
                    </h1> */}
                                    </div>
                                    <div>
                                        <label className="color-gray text-[13px] font-semibold  mb-2">
                                            Email body:
                                        </label>
                                        <div className="">
                                            <div className="text-editor">
                                                <DraftEditor setDetectFocus={setDetectFocus} setIsPersonalisePopupOpen={setIsPersonalisePopupOpen}


                                                    isPersonalisePopupOpen={isPersonalisePopupOpen} personalizeBtn={personalizeBtn} editorState={editorState} setEditorState={setEditorState}>

                                                </DraftEditor>

                                                <ul className="flex gap-4 my-2">


                                                    <li
                                                        onClick={() =>
                                                            setIsPersonalisePopupOpen((prev) => !prev)
                                                        }
                                                        className="border border-[#d3dae3] p-1 rounded-[4px] cursor-pointer  hover:border-blue-500 icon-color hover:text-blue-500"
                                                    >
                                                        <Tooltip id='templatePersonalise' />
                                                        <RiBracesLine
                                                            data-tooltip-id='templatePersonalise'
                                                            data-tooltip-content="personalisation"
                                                            className="text-[17px] icon-color font-500 "
                                                        ></RiBracesLine>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="flex  items-center mt-4">
                            <div>
                                <Button form={"template-form"} tittle={'save'} disabled={loader} loading={loader} type={'submit'}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTemplateModal;
