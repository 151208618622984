import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Hooks/UserContext";
import dashedLine from "../../../Images/Line 7.svg";
import uploadComplete from "../../../Images/uploadComplete.svg";
import XLSX from "xlsx";
import ColumnMatch from "./ColumnMatch";
import Loading from "../../Shared/Loading/Loading";
import { toast } from "react-toastify";
import "./ProspectModal.css";

import { MdDangerous } from "react-icons/md";
import { posthog } from "posthog-js";
import properties from "../../../config/properties";
import { downloadCSV } from "../../../Utils/csvFileMaker";
import { sample_import_data } from "../../../Constants/sampleImportData";

const ProspectusModal = () => {
  const { setActiveProspectusModal, setRefetch, reFetch } =
    useContext(UserContext);
  const [upload, setUpload] = useState(false);
  const [user, setUser] = useState(null);
  // const [excelFile, setExcelFile] = useState(null)
  const [columnMatchCom, setColumMatchCom] = useState(false);
  // const [handleSubmitModal] = useState(false)
  const [csvColumn, setCsvColumn] = useState([]);
  const [columnMatch, setColumnMatch] = useState(false);
  const [excelsheetId, setExcelsheedId] = useState(null);

  const [disabledbtn, setDisablebtn] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // check auth
    const token = localStorage.getItem("token");
    if (token) {
      const user = jwtDecode(token);
      if (!user) {
        localStorage.removeItem("token");
      } else {
        setUser(user.id);
      }
    }
  }, []);

  // height and width

  // navigate column page logic
  const handleMatch = () => {
    if (upload) {
      setColumMatchCom(true);
    }
  };

  // handleSubmit modal

  const handleSubmit = () => {
    if (columnMatch) {
      axios
        .post(
          `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/saveProspects`,
          {
            matchColumns: columnMatch,
            userId: user,
            excelsheetId: excelsheetId,
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            setActiveProspectusModal(false);
            setRefetch(!reFetch);
          }
        });
    }
  };

  // upload handle function
  const handleFileUpload = async (excelFile) => {
    posthog.capture("Upload excell sheet");
    // console.log(excelFile)
    if (excelFile) {
      // extract csv file
      const data = await excelFile?.arrayBuffer();
      const workbook = XLSX.read(data);
      const workseet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(workseet);

      if (jsonData?.length === 0 || Object.values(jsonData[0]).length === 0) {
        toast.error("invalid excell sheect");
        setDisablebtn(true);
        setUpload(false);
        return;
      }

      if (jsonData) {
        const concatArr = Object.keys(jsonData[0])
          .join(" ")
          .toLocaleLowerCase();
        if (!concatArr.includes("email")) {
          toast.error("email not exits on the file");
          setDisablebtn(true);
          setUpload(false);
          return;
        }
      }
      setCsvColumn(Object.keys(jsonData[0]));

      // send file to the backend
      const formData = new FormData();
      formData.append("excelFile", excelFile);
      formData.append("userId", user);

      setLoading(true);
      axios
        .post(
          `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/uploadProspect`,
          formData
        )
        .then((res) => {
          console.log(res.data);

          if (res.data.success === true) {
            setExcelsheedId(res.data.excelsheetId);
            setDisablebtn(false);
            console.log("in");
            setUpload(true);
            toast.success(`${jsonData.length} contact imported`);
          }
          setLoading(false);
        });
    }
  };

  // handle back
  const handleBack = () => {
    setColumMatchCom(false);
  };

  return (
    <div>
      <div className="popup flex justify-center items-center ">
        <div className="modal-empty-div"></div>
        <div className="content-container w-[900px] !h-[98%] max-h-[98%]">
          <div
            className={`  popup-content min-h-full  !bg-white  p-4  ${
              !loading && "overflow-y-auto"
            }   rounded-[20px]`}
          >
            <div className="overflow-Y ">
              <div className="flex justify-between items-center radius-top bg-[#D2DCFF] px-4 py-2">
                <h1 className=" cursor-pointer text-[25px] text-[#151D48] font-semibold">
                  Import Prospects
                </h1>
                <div className="flex items-center">
                  {/* save and next handle  */}

                  {columnMatchCom && (
                    <button
                      onClick={handleBack}
                      className="w-[130px] back-button  cursor-pointer h-[40px] rounded-[8px]  font-semibold bg-[#E8ECFF]  text-[#333333]"
                    >
                      Back
                    </button>
                  )}

                  <div id="submitBtn">
                    <button
                      id={`${disabledbtn ? "btn" : ""}`}
                      disabled={disabledbtn}
                      onClick={() => {
                        handleMatch();
                        handleSubmit();
                        posthog.capture("Click Save And Next Button", {
                          buttonName: "Save & Next",
                        });
                      }}
                      className={`${
                        disabledbtn && "disabled-btn"
                      } w-[130px] mx-4 relative cursor-pointer h-[40px] rounded-[8px]  font-semibold bg-[#3B59DF] text-white`}
                    >
                      Save & Next
                    </button>

                    <div class="main" id="hiddenDiv">
                      <div className="flex  items-center h-full ml-1">
                        {/* <CgDanger className="text-white"></CgDanger> */}
                        <MdDangerous className="text-white"></MdDangerous>
                        <h1 className="text-white z-20 text-[12px] ml-1">
                          Upload Valid File
                        </h1>
                      </div>
                      <div className="shape z-10"></div>
                    </div>
                  </div>

                  <button
                    className="text-[#000000] text-[35px]"
                    onClick={() => {
                      setActiveProspectusModal(false);

                      posthog.capture("Close Modal", { buttonName: "X Icon" });
                    }}
                  >
                    &times;
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center py-12">
                <div className="flex  items-center flex-col">
                  {columnMatchCom ? (
                    <img
                      className={`upload-complete-image w-[30px] h-[30px]`}
                      src={uploadComplete}
                      alt=""
                    />
                  ) : (
                    <div className="w-[30px] h-[30px]  rounded-full flex justify-center items-center ">
                      <div className=" w-[13px] h-[13px] bg-[rgb(59,89,223)] rounded-full"></div>
                    </div>
                  )}

                  <h1 className="text-[13px] font-semibold mt-2 ">
                    Upload leads
                  </h1>
                </div>

                <div>
                  <img className="mx-2 mb-6" src={dashedLine} alt="" />
                </div>
                <div className="flex  items-center flex-col">
                  {columnMatchCom ? (
                    <div className="w-[30px] h-[30px]  rounded-full flex justify-center items-center ">
                      <div className=" w-[13px] h-[13px] bg-[#3B59DF] rounded-full"></div>
                    </div>
                  ) : (
                    <div className="w-[30px] h-[30px] bg-white rounded-full border-blue"></div>
                  )}
                  <h1 className="text-[13px] font-semibold mt-2">
                    Match Columns
                  </h1>
                </div>
              </div>

              {/* matching column components conditional rendering */}
              {columnMatchCom ? (
                <ColumnMatch
                  setDisablebtn={setDisablebtn}
                  setColumnMatch={setColumnMatch}
                  csvColumn={csvColumn}
                ></ColumnMatch>
              ) : (
                <div
                  className={`} w-[60%] h-[23rem] mt-4 mx-auto border-dashed rounded-[5px] bg-[#DAE2FF] flex justify-center items-center`}
                >
                  <div>
                    {loading ? (
                      <Loading changeLoader={"changeLoader"}></Loading>
                    ) : (
                      <>
                        <div className="text-center upload-complete">
                          <label
                            htmlFor="file-upload"
                            className={`${
                              upload ? "hidden" : ""
                            } mb-4 custom-file-upload  mx-auto flex justify-center items-center h-12 w-40 rounded-[8px]`}
                          >
                            Upload
                          </label>
                          <img
                            className={`${
                              upload ? "inline-block" : "hidden"
                            } upload-complete-image`}
                            src={uploadComplete}
                            alt=""
                          />
                          {/* file input  */}
                          <input
                            type="file"
                            onChange={(e) => {
                              posthog.capture("Open Upload File", {
                                buttonName: "Upload",
                              });
                              handleFileUpload(e.target.files[0]);
                            }}
                            id="file-upload"
                            className={`${upload ? "hidden" : ""}`}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            required
                          />
                          {upload && (
                            <h1 className="text-[#333333] fadeindown text-[17px] font-semibold mt-2 mb-4">
                              Upload complete
                            </h1>
                          )}

                          <label
                            htmlFor="file-upload"
                            className={`${
                              upload ? "inline-block" : "hidden"
                            } fadeindown cursor-pointer text-[13px] text-[#3B59DF] underline font-semibold`}
                          >
                            Re-upload CSV
                          </label>
                          <h1 className="text-[13px] font-bold fadeindown">
                            Upload only CSV, XLS files
                          </h1>
                          <h1 className="text-[13px] fadeindown mt-2">
                            download{" "}
                            <span className="color-link cursor-pointer">
                              {" "}
                              <div
                                onClick={() => {
                                  downloadCSV(sample_import_data);
                                }}
                              >
                                sample CSV
                              </div>
                            </span>{" "}
                            file to make sure{" "}
                          </h1>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProspectusModal;
