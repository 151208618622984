import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from "../../../Componenets/Button";
import GetUser from "../../../Hooks/GetUser";
import { updatePassword } from "../../../Services/apiServices";
import Loading from "../../Shared/Loading/Loading";
const PasswordUpdateModal = ({
  setShowpasswordModal,
  userInfo,
  setRefetch,
}) => {
  // const [isGoogleLogin, isSetGoogleLogin] = useState(false)
  const user = GetUser();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    // Send currentPassword and newPassword to backend
    if (user) {
      const requestBody = {
        userId: user,
        updatedPassword: newPassword,
        currentPassword: "",
      };
      if (userInfo.hasPassword) {
        requestBody.currentPassword = currentPassword;
      }

      const res = await updatePassword(requestBody);

      if (res.data.success) {
        setShowpasswordModal(false);
        toast.success(res.data.message);
        setLoader(false);
        setRefetch((prev) => !prev);
      } else {
        setShowpasswordModal(false);
        toast.warning(res.data.message);
        setLoader(false);
        setRefetch((prev) => !prev);
      }
    }
    console.log("Submitting current password:", currentPassword);
    console.log("Submitting new password:", newPassword);
  };

  // if (loader) {
  //   return <Loading whiteLoader='whiteLoader'></Loading>
  // }

  return (
    <div>
      <div className="popup">
        <div className="modal-empty-div"></div>
        {/* <div className="content-container w-[1024px] !h-[98%] max-h-[98%]"> */}
        <div className="content-container w-[600px] !top-[9rem]">
          <div className=" w-full  popup-content min-h-full  !bg-white">
            <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray p-4">
              <h1 className="primary-heading-color text-[15px] font-semibold">
                Change Password
              </h1>
              <button
                onClick={() => setShowpasswordModal(false)}
                className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[30px] w-[20px] h-[20px] flex justify-center items-center"
              >
                &times;
              </button>
            </div>

            <form onSubmit={handleSubmit} className="p-4" id="passwordForm">
              <div className={`${userInfo?.hasPassword ? "" : " hidden"}`}>
                <label
                  htmlFor="currentPassword"
                  className="text-[12px] font-semibold mb-1 text-[#5d6a7e] block"
                >
                  Current Password
                </label>
                <div className="relative">
                  <input
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="currentPassword"
                    value={currentPassword}
                    onChange={handleCurrentPasswordChange}
                    className="w-full text-[13px] custom-shadow pl-2 pr-8 focus:outline-none border rounded-[4px] h-7 border-[#d3dae3] focus:border-blue-400"
                  />
                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible className="icon-color" />
                    ) : (
                      <AiOutlineEye className="icon-color" />
                    )}
                  </button>
                </div>
              </div>

              <div className={`${userInfo?.hasPassword ? "mt-3" : "mt-0"}`}>
                <label
                  htmlFor="newPassword"
                  className="text-[12px] font-semibold mb-1 text-[#5d6a7e] block"
                >
                  New Password
                </label>
                <div className="relative">
                  <input
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    className="w-full text-[13px] custom-shadow pl-2 pr-8 focus:outline-none border rounded-[4px] h-7 border-[#d3dae3] focus:border-blue-400"
                  />
                  <button
                    type="button"
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible className="icon-color" />
                    ) : (
                      <AiOutlineEye className="icon-color" />
                    )}
                  </button>
                </div>
              </div>
            </form>

            <div className="flex  p-4 ">
              <div className="flex">
                <div>
                  {/* <button

                    className={`button`}
                  >
                    Save
                  </button> */}

                  <Button
                    disabled={loader}
                    loading={loader}
                    tittle={"Save"}
                    form="passwordForm"
                    type="submit"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdateModal;
