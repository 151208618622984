import React, { useEffect, useState } from "react";
import { getSequenceStats } from "../../../../Services/apiServices";
import GetUser from "../../../../Hooks/GetUser";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../Shared/Loading/Loading";
import { toast } from "react-toastify";
import AssessmentIcon from "@mui/icons-material/Assessment";

const Analytics = () => {
  const [sequenceStats, setSequenceStats] = useState([]);
  const [loadingAllstats, setloadingAllstats] = useState(false);
  const user = GetUser();
  const location = useLocation();
  const sequenceIdUrl = location.pathname.split("/")[2];
  const navigate = useNavigate();
  // for overall stats
  useEffect(() => {
    setloadingAllstats(true);
    const fetchSequenceStats = async () => {
      const payload = {
        sequenceId: sequenceIdUrl,
        userId: user,
      };
      try {
        const response = await getSequenceStats(payload);
        if (response.data.status) {
          setSequenceStats(response.data.data);
        }
      } catch (error) {
        toast.error("Error fetching sequence stats:", error);
      } finally {
        setloadingAllstats(false);
      }
    };

    fetchSequenceStats();
  }, [sequenceIdUrl, user]);

  const schema = {
    totalProspects: "Prospect",
    totalActiveProspects: "Active",
    totalMailsSent: "Delivered",
    totalMailsOpen: "Opened",
    totalClicks: "Clicked",
    totalReplies: "Replied",
    totalBounced: "Bounced",
    bouncedRateInPercentage: "Bounce Rate",
    clickRateInPercentage: "Click Rate",
    openRateInPercentage: "Open Rate",
    replyRateInPercentage: "Reply Rate",
    totalUnsubscribes: "Unsubscribed",
  };

  const allFilterBy = {
    totalProspects: "activeProspects",
    totalActiveProspects: "prospectsInSeq",
    totalMailsSent: "messageDelivered",
    totalMailsOpen: "openedEmail",
    totalClicks: "clickedEmail",
    totalReplies: "repliedEmail",
    totalBounced: "bouncedProspects",
    totalUnsubscribes: "unsubscribedProspects",
  };

  const handlerFilterClick = (fieldName, stepId) => {
    if (allFilterBy.hasOwnProperty(fieldName)) {
      const filterValue = allFilterBy[fieldName];
      let url = `detailsStats/${sequenceIdUrl}/${filterValue}`;

      if (stepId) {
        url += `/${stepId}`;
      }

      navigate(url);
    }
  };

  if (loadingAllstats) {
    return <Loading text={"Loading Sequence Stats"} />;
  }

  return (
    <div>
      {sequenceStats.map((stepData, index) => {
        return (
          <div key={index} className="mt-12">
            <div className="flex gap-3">
              <h1 className="font-semibold mb-6">{stepData.name} </h1>
              <AssessmentIcon className="text-blue-dark" />
            </div>
            <div className="w-full mx-auto h-full  custom-shadow  rounded-[7px]">
              <div className="flex w-full items-center h-full justify-between stats">
                {Object.keys(stepData).map((fieldName) => {
                  if (
                    fieldName !== "stepNumber" &&
                    fieldName !== "name" &&
                    schema.hasOwnProperty(fieldName)
                  ) {
                    const displayValue =
                      fieldName.endsWith("RateInPercentage") &&
                      typeof stepData[fieldName] === "number"
                        ? `${stepData[fieldName]}%`
                        : stepData[fieldName];

                    return (
                      <div
                        onClick={() => {
                          if (
                            displayValue !== 0 &&
                            !fieldName.endsWith("RateInPercentage")
                          ) {
                            handlerFilterClick(fieldName, stepData.stepId);
                          }
                        }}
                        className={`text-center py-6 px-2 ${
                          displayValue !== 0 &&
                          !fieldName.endsWith("RateInPercentage") &&
                          "cursor-pointer hover:bg-[#f1f4fc] transition-all duration-200"
                        } `}
                        key={fieldName}
                      >
                        <span className="text-[#3c4858] font-medium">
                          {displayValue}
                        </span>
                        <h1 className="font-medium text-[90%]">
                          {schema[fieldName]}
                        </h1>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Analytics;
