import React from "react";
import "./Campaign.css";
import Emailstat from "./EmailStat/Emailstat";

const CampainStat = () => {

  return (
    <div className="font-500 mt-8">
      <div className="bg-white  ">
        <h1 className="text-[#05004E]  font-semibold mb-6">Campaign Progress</h1>
        <Emailstat />
      </div>
    </div>
  );
};

export default CampainStat;
