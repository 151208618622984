import React from 'react';
import { FcExpired } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';

const MailboxExpiredBanner = ({ expiredMailbox, mailboxEmpty, handleCloseBanner }) => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate('/setting/mailboxes')
    }






    return (

        <div className="p-4">
            <div className="bg-[#0b2f73] px-6 py-[10px] min-h-[65px] text-white custom-shadow-1 rounded-[6px] flex justify-between">
                <div className="flex items-center">
                    <FcExpired className="text-[40px]" />
                    <div className="ml-4">
                        {
                            mailboxEmpty ? <h1 className="text-xl font-semibold">Your Mailbox is Empty !</h1> : <h1 className="text-xl font-semibold">Your Mailbox Got Expired !</h1>
                        }
                        {
                            mailboxEmpty ? <p className="">Please add a mailbox otherwise, you can't activate the sequence</p> : <p className="">Your {expiredMailbox.map(email => <span className="mr-2">{email}</span>)} mailbox is expired . Please re-link your expired mailbox to send email</p>

                        }
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <button onClick={handleNavigate} className='bg-white py-[4px] px-[12px] rounded-[4px] text-[#242d3e] font-medium text-[14px]'>{mailboxEmpty ? "Link" : "Re-Link"}</button>
                    <button onClick={handleCloseBanner} className='text-white hover:text-[#1991eb] ml-4 text-[20px]'> &times;</button>
                </div>
            </div>
        </div>

    );
};

export default MailboxExpiredBanner;