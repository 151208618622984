import React, { useState } from 'react';
import { MdSearch } from 'react-icons/md';
import slack from '../../../Images/AI/Slack_ipng.png'

const SearchTemplate = ({ handleSearch, slackButton }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        handleSearch(value);
    }


    return (

        <div className="flex">
            {
                slackButton && <button className='mr-2 bg-white border border-border-gray flex items-center shadow-sm rounded-[4px] p-2'>
                    <img className='w-[20px] h-[20px] mr-2' src={slack} alt="" />
                    <a className='primary-heading-color text-[14px] font-medium' href="https://slack.com/oauth/v2/authorize?client_id=4254994490752.5281555711622&scope=channels:history,channels:read,chat:write,chat:write.public,commands,groups:history,im:history,im:read,mpim:history,mpim:read,users:read,users:read.email,files:write,groups:read&user_scope=" target="_blank" rel="noreferrer">Add to Slack</a>
                </button>
            }

            <div className='flex  search-parent  bg-white border border-border-gray shadow-sm items-center p-1 pl-2'>
                <div>
                    <MdSearch className='text-[#718096] text-[18px]' />
                </div>
                <div>
                    <input
                        type="text"
                        placeholder='Search Here'
                        className="input-template text-[14px]  primary-heading-color font-medium  pl-2 focus:outline-none  h-7  focus:border-blue-400"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>

    );
};

export default SearchTemplate;
