import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Modal from "../Sequence/Modal";
import Sidebar from "../Sidebar/Sidebar";
import { UserContext } from "../../../Hooks/UserContext";
import StepModal from "../Sequence/StepModal";
import ProspectusModal from "../Prospectus/ProspectusModal";
import { FcExpired } from "react-icons/fc";
import { mailboxConnection } from "../../../Services/apiServices";
import GetUser from "../../../Hooks/GetUser";
import { toast } from "react-toastify";
import MailboxExpiredBanner from "../../../Componenets/MailboxExpiredBanner";
import SidebarNew from "../Sidebar/Sidebar";
import Header from "../../Shared/Header/Header";
const DashboardMain = () => {
  const {
    activeStepModal,
    activeProspectusModal,
    closeBanner,
    setCloseBanner,
    sequenceId,
    activeModal,
    refetchMailbox,
    setmailboxEmpty,
    mailboxEmpty,
  } = useContext(UserContext);

  const [expiredMailbox, setExpiredMailbox] = useState([]);

  const user = GetUser();

  useEffect(() => {
    const fetchMailbox = async () => {
      const payload = {
        user: user,
      };

      try {
        if (user) {
          const response = await mailboxConnection(payload);
          if (
            response.data?.data?.connections?.length === 0 ||
            !response.data?.data?.connections
          ) {
            setmailboxEmpty(true);
            return;
          } else {
            setmailboxEmpty(false);
          }

          if (response?.data?.data) {
            const expiredMailboxEmails = response.data.data.connections
              .filter((connection) => connection.expiredToken)
              .map((connection) => connection.email);

            setExpiredMailbox(expiredMailboxEmails);
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while fetching mailbox");
      }
    };

    fetchMailbox();
  }, [user, refetchMailbox, setmailboxEmpty]);

  const handleCloseBanner = () => {
    setCloseBanner(true);
    localStorage.setItem("closeBanner", JSON.stringify(true));
  };

  return (
    <div className="flex gap-4">
      {activeModal && <Modal></Modal>}
      <div>
        {activeStepModal && <StepModal sequenceId={sequenceId}></StepModal>}
      </div>

      <div>{activeProspectusModal && <ProspectusModal></ProspectusModal>}</div>

      {/* admin sidebar */}
      <div className="min-h-[100vh] h-[100%] shdaow-primary sidebar-new z-[999]">
        <SidebarNew />
        {/* <Sidebar setPrevHideSidebar={setPrevHideSidebar} prevHideSidebar={prevHideSidebar} setHideSidebar={setHideSidebar} hideSidebar={hideSidebar} /> */}
      </div>

      {/* admin component render */}
      <div className=" w-full  ml-[72px] bg-white px-4 ">
        {!closeBanner && (expiredMailbox.length !== 0 || mailboxEmpty) && (
          <MailboxExpiredBanner
            handleCloseBanner={handleCloseBanner}
            mailboxEmpty={mailboxEmpty}
            expiredMailbox={expiredMailbox}
          />
        )}
        <Header />
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default DashboardMain;
