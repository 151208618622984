import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../Componenets/Button";
import GetUser from "../../../Hooks/GetUser";
import { UserContext } from "../../../Hooks/UserContext";
import {
  createEmailSignature,
  mailboxConnection,
  setSenderLimit,
} from "../../../Services/apiServices";
import Loading from "../../Shared/Loading/Loading";
import MailboxesSetting from "./MailboxesSetting";
import { AiFillBackward } from "react-icons/ai";
import { addMargin } from "../../../Utils/ParagraphStyle";
// import htmlToDraft from "html-to-draftjs";
// import { EditorState, convertToRaw, ContentState } from "draft-js";
// import draftToHtml from "draftjs-to-html";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const MailboxDetails = () => {
  const { register, handleSubmit, reset } = useForm();

  const [loader, setLoader] = useState({
    button: false,
    fetchData: false,
    limitButton: false,
  });
  const user = GetUser();
  let { mailboxId } = useParams();
  const [filteredMailboxList, setFilteredMailboxList] = useState({});

  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState("");

  const [editorInitialValue, setEditorInitalvalue] = useState(null);

  useEffect(() => {
    setLoader((prev) => {
      return { ...prev, fetchData: true };
    });
    async function getMailboxConnection() {
      const payload = {
        user: user,
      };
      let response = await mailboxConnection(payload);
      if (response?.data?.data) {
        const filteredObject = response?.data?.data.connections.find(
          (item) => item._id === mailboxId
        );

        reset({
          dailyLimit: filteredObject.dailyLimit,
          hourlyLimit: filteredObject.hourlyLimit,
          emailSentGap: filteredObject.emailSentGap,
        });

        setEditorInitalvalue(filteredObject?.emailSignature);
        setEditorState(filteredObject?.emailSignature);
        // const contentBlock = htmlToDraft(filteredObject.emailSignature);
        // if (contentBlock) {
        //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //   const editorState = EditorState.createWithContent(contentState);
        //   setEditorState(editorState);

        // }

        setFilteredMailboxList({ ...filteredObject });
        setLoader((prev) => {
          return { ...prev, fetchData: false };
        });
      }
    }
    getMailboxConnection();
  }, [mailboxId, reset, user]);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/setting/mailboxes");
  };

  // create signature

  let handleCreateSignature = async () => {
    console.log("clciked");

    setLoader((prev) => {
      return { ...prev, button: true };
    });

    // const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const payload = {
      userId: user,
      senderEmail: filteredMailboxList.email,
      emailSignature: addMargin(editorState),
    };
    let response = await createEmailSignature(payload);
    if (response.data.success) {
      setLoader((prev) => {
        return { ...prev, button: false };
      });
      toast.success("successfully added signature");
    }
  };
  const onSubmit = async (data) => {
    setLoader((prev) => {
      return { ...prev, limitButton: true };
    });
    const payload = {
      userId: user,
      senderEmail: filteredMailboxList.email,
      hourlyLimit: data?.hourlyLimit,
      dailyLimit: data?.dailyLimit,
      timeGap: data?.emailSentGap,
    };

    let response = await setSenderLimit(payload);
    if (response.data.success) {
      toast.success(response.data.message);
      setLoader((prev) => {
        return { ...prev, limitButton: false };
      });
    }
  };

  return (
    <div>
      <div className="mb-6">
        <button
          onClick={handleBack}
          className="flex p-1 hover:bg-[#E8EEF4] items-center text-[#1991eb] cursor-pointer text-[14px] font-medium "
        >
          {" "}
          <AiFillBackward className="mr-2 " /> Go Back
        </button>
      </div>
      {loader?.fetchData ? (
        <Loading whiteLoader={"whiteLoader"}></Loading>
      ) : (
        <>
          <div>
            <h1 className=" primary-heading-color text-[1.1rem] font-semibold">
              Mailbox Settings
            </h1>
            <div className="bg-white shadow-medium mt-4">
              <h1 className="text-[#242d3e] text-[15px] font-medium p-4 border-b border-border-gray">
                Sending Limit
              </h1>

              <form onSubmit={handleSubmit(onSubmit)} className=" p-4">
                <div className="flex gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor="dailyLimit"
                      className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                    >
                      Daily (24 Hour) Sending Limit
                    </label>
                    <input
                      id="dailyLimit"
                      type="number"
                      className="w-full text-[13px]  primary-heading-color font-medium  pl-2 focus:outline-none border rounded-[5px] h-[2.2rem] border-[#d3dae3] focus:border-blue-400"
                      // className="w-full text-[13px]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                      {...register("dailyLimit")}
                    />
                  </div>

                  <div className="flex-1">
                    <label
                      htmlFor="hourLimit"
                      className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                    >
                      Maximum # of Emails to Send Per Hour
                    </label>
                    <input
                      id="hourLimit"
                      type="number"
                      className="w-full text-[13px]  primary-heading-color font-medium  pl-2 focus:outline-none border rounded-[5px] h-[2.2rem] border-[#d3dae3] focus:border-blue-400"
                      // className="w-full text-[13px]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                      {...register("hourlyLimit")}
                    />
                  </div>
                </div>

                <div className="my-4">
                  <label
                    htmlFor="hourLimit"
                    className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                  >
                    Minimum Delay Between Emails (in seconds)
                  </label>
                  <input
                    type="number"
                    className="w-full text-[13px]  primary-heading-color font-medium  pl-2 focus:outline-none border rounded-[5px] h-[2.2rem] border-[#d3dae3] focus:border-blue-400"
                    // className="w-full text-[13px]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                    {...register("emailSentGap")}
                  />
                </div>
                <div className="mt-4">
                  <LoadingButton
                    type="submit"
                    className=" bg-[#583cea] btn"
                    loading={loader.limitButton}
                    // onClick={handleSubmit}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </div>
                {/* <Button
                  loading={loader.limitButton}
                  disabled={loader.limitButton}
                  type={"submit"}
                  tittle={"Save"}
                ></Button> */}
              </form>
            </div>
          </div>

          <div className="mt-12 shadow-medium">
            <MailboxesSetting
              loader={loader.button}
              handleCreateSignature={handleCreateSignature}
              setEditorState={setEditorState}
              editorInitialValue={editorInitialValue}
              editorState={editorState}
            ></MailboxesSetting>
          </div>
        </>
      )}
    </div>
  );
};

export default MailboxDetails;
