import React from 'react';
import BtnLanding from './BtnLanding';

const ValueProp = ({ fakeData }) => {
    return (
        <div className='bg-[#f7f5f1] py-20 '>
             <h1 className='langding-page-heading text-black text-[2rem] mb-8  mx-auto text-center'>
                    Unconditional Value <br></br> from the members and founders
                </h1>
                <div className="max-w-[1000px] mx-auto">
    <div className='grid grid-cols-2 gap-x-6 gap-y-4'>
        {
            fakeData.valueProp.map((value, index, array) => {
                const isLastItem = index === array.length - 1;
                const centerAlign = isLastItem && array.length % 2 !== 0;

                return (
                    <div
                        style={{ background: value.background }}
                        className={`p-[1.8rem] shadow-sm rounded-[10px] ${centerAlign ? 'w-[49%] mx-auto col-span-2' : ''}`}
                    >
                        <h1 className='box-title' style={{ color: value.btnBg }}>{value.title}</h1>
                        <p className='my-6 text-[#4a5980]'>{value.description}</p>
                        <BtnLanding title={value.btnTittle} color={value.color} background={value.btnBg} />
                    </div>
                )
            })
        }
    </div>
</div>

        </div>
    );
};

export default ValueProp;