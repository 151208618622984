import React, { useState } from 'react';
import BackButton from '../../../Componenets/BackButton';
import SevenStepLeft from './SevenStepLeft';
import SevenStepRight from './SevenStepRight';

const SevenStepAi = () => {
    const [aiResponseSevenStep, setAiresponseSevenStep] = useState([]);
    const [loader, setLoader] = useState(false)
    return (
        <div className='h-full'>
            <div className="grid grid-cols-11 h-full">

                <div className='col-span-5'>
                    <SevenStepLeft setLoader={setLoader} loader={loader} setAiresponseSevenStep={setAiresponseSevenStep} />
                </div>




                <div className='col-span-6'>
                    <SevenStepRight setLoader={setLoader} setAiresponseSevenStep={setAiresponseSevenStep} loader={loader} aiResponseSevenStep={aiResponseSevenStep} />

                </div>
            </div>

        </div>
    );
};

export default SevenStepAi;