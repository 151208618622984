import React, { useEffect } from 'react';

import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useState } from 'react';
import OptionsComponent from './OptionsComponent';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import GetUser from '../../../../Hooks/GetUser';
import { useLocation } from 'react-router-dom';
import { getSequenceWithSteps, sequenceSettingTime } from '../../../../Services/apiServices';
import { toast } from 'react-toastify';
import Loading from '../../../Shared/Loading/Loading';

const Options = () => {
    const user = GetUser();
    const location = useLocation();
    const sequenceIdUrl = location.pathname.split('/')[2];


    const [checked, setChecked] = useState(true)
    const [delivaryChecked, setDelivaryChecked] = useState(true)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchSequenceStats = async () => {
            const payload = {
                sequenceId: sequenceIdUrl,
                userId: user,
            };
            try {
                const response = await getSequenceWithSteps(payload);
                if (response.data.status) {
                    setChecked(response.data.data?.settings?.pauseProspectsOnReply)
                    setDelivaryChecked(response.data.data?.settings?.followUpOnPrevEmailOpened)
                }
            } catch (error) {
                toast.error('Error fetching sequence stats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSequenceStats();
    }, [sequenceIdUrl, user]);




    const handleChangePauseProspect = async (event) => {
        setChecked(event.target.checked);
        const payload = {
            userId: user,
            sequenceId: sequenceIdUrl,
            settings: {
                pauseProspectsOnReply: event.target.checked
            }

        };

        await sequenceSettingTime(payload);

    };


    const handlefollowUpOnPrevEmailOpened = async (event) => {
        setDelivaryChecked(event.target.checked);
        const payload = {
            userId: user,
            sequenceId: sequenceIdUrl,
            settings: {
                followUpOnPrevEmailOpened: event.target.checked
            }

        };

        await sequenceSettingTime(payload);

    };






    if (loading) {
        return <Loading text={'Please Wait'} />
    }



    return (
        <div>
            <div className='w-[50%] mx-auto'>
                <OptionsComponent checked={checked} handleChange={handleChangePauseProspect} PauseCircleIcon={PauseCircleIcon} heading={'Pause Prospects on Reply'} description={'Stop Sending Follow-Up emails to prospects after receiving replies'} />
                <OptionsComponent checked={delivaryChecked} handleChange={handlefollowUpOnPrevEmailOpened} PauseCircleIcon={LocalPostOfficeIcon} heading={'Delivery Optimisation'} description={'Send Follow-Up emails to only to prospects who viewed Last Email.'} />
            </div>
        </div>
    );
};

export default Options;