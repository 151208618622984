import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
import loginLottie from "./927-triangle-loading.json";
import lottie2 from "./simple-loader.json";
import whiteLoadder from '../../../Images/whiteSpinner.gif'
import CircularProgress from '@mui/material/CircularProgress';
const Loading = ({ changeLoader, whiteLoader, text }) => {
  // for lottie
  const anime = useRef(null);
  useEffect(() => {
    Lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: changeLoader ? lottie2 : loginLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
    // More logic goes here
  }, [changeLoader]);
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40">
      {
        whiteLoader && <CircularProgress color="inherit" />

      }
      <div className="flex justify-center items-center">
        <div className="w-fit mx-auto">
          {!whiteLoader && <div
            className="overflow-hidden flex items-center gap-4"
          >
            <CircularProgress />
            <h1 className="font-semibold">{text}</h1>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Loading;
