import { useEffect, useState } from 'react';

const UseHeight = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    // const [isHovered, setIsHovered] = useState(false);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return windowSize.innerHeight
};

export default UseHeight;