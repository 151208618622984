import React, { useContext, useEffect, useState } from "react";
import CampaignSearch from "./CampaignSearch";
import CampainStat from "./CampainStat/CampainStat";
import "./Sequence.css";
import { UserContext } from "../../../Hooks/UserContext";
import axios from "axios";
import Loading from "../../Shared/Loading/Loading";
import Empty from "../../../Images/prospectus-empty.svg";
import GetUser from "../../../Hooks/GetUser";
import properties from "../../../config/properties";
import EmailTemplateModal from "./EmailTemplateModal";

const SequenceIndex = () => {
  const [openEmailTemplate, setOpenEmailTemplate] = useState(false);
  const { reFetch } = useContext(UserContext);
  const [campainData, setcampainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = GetUser();

  useEffect(() => {
    if (user) {
      setLoading(true);
      axios
        .post(
          `${properties.REACT_APP_SERVER_URL}/api/v1/sequence/getSequences`,
          { userId: user }
        )
        .then((camapaign) => {
          setcampainData(camapaign.data.data);
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [reFetch, user]);

  if (loading) {
    return <Loading text={"Loading Your Sequence"}></Loading>;
  }
  return (
    <div className="p-4 mt-6">
      <div className=" fadeinup font-500">
        {/* <h1 className="heading-style text-primary-heading-size font-semibold">
          Outbound Sequence
        </h1> */}
        {campainData?.length !== 0 && (
          <div className="flex justify-end items-center">
            <button
              className="btn !text-medium"
              onClick={() => setOpenEmailTemplate(true)}
            >
              + Create Sequence
            </button>
          </div>
        )}
      </div>

      {campainData?.length === 0 && (
        <div className="flex flex-col items-center justify-center pt-40">
          <button className="btn" onClick={() => setOpenEmailTemplate(true)}>
            + Create Sequence
          </button>

          <img src={Empty} alt="" />
        </div>
      )}

      {campainData?.length !== 0 && (
        <>
          <CampainStat></CampainStat>
          <CampaignSearch></CampaignSearch>
        </>
      )}

      {openEmailTemplate && (
        <EmailTemplateModal setOpenEmailTemplate={setOpenEmailTemplate} />
      )}
    </div>
  );
};

export default SequenceIndex;
