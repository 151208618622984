import React, { useContext, useEffect, useState } from "react";
import { VscEllipsis } from "react-icons/vsc";
import Loading from "../../Shared/Loading/Loading";
import GetUser from "../../../Hooks/GetUser";
import { FiMail } from "react-icons/fi";
import { UserContext } from "../../../Hooks/UserContext";
import { mailboxConnection } from "../../../Services/apiServices";
import properties from "../../../config/properties";
import DeleteMailboxModal from "./DeleteMailboxModal";
import SingleRow from "./SingleRow";
import ExpiredMailbox from "../../../Componenets/ExpiredMailbox";
import googleLogo from "../../../Images/google.png";
import AgremnetModal from "./AgremnetModal";

const Mailboxes = () => {
  const [loader, setLoader] = useState(false);
  const [mailboxes, setMailboxes] = useState([]);
  const { reFetch } = useContext(UserContext);
  const [showMailboxError, setShowMailboxError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = GetUser();
  useEffect(() => {
    setLoader(true);
    async function getMailboxConnection() {
      const payload = {
        user: user,
      };
      let response = await mailboxConnection(payload);
      if (response?.data?.data) {
        setMailboxes(response.data.data.connections);
        console.log(response.data.data.connections)
        setLoader(false);
      }
    }
    getMailboxConnection();
  }, [user, reFetch]);

  const handleNavigate = () => {
    localStorage.removeItem("importLocation");
  };

  if (loader) {
    return <Loading whiteLoader="whiteLoader"></Loading>;
  }
  return (
    <div>
      {mailboxes?.length !== 0 ? (
        <div className=" bg-white custom-shadow border border-gray">
          <div className=" p-4 flex justify-between items-center">
            <h1 className=" primary-heading-color text-[14px] font-semibold">
              My Mailboxes
            </h1>
            {/* <button
              className={`button-background text-white font-500 hover:bg-[#1991eb]  h-[32px] rounded-[3px] px-[12px] text-[13px]`}
            >
              <a
                onClick={handleNavigate}
                rel="noreferrer"
                target="_blank"
                href={`${properties.REACT_APP_SERVER_URL}/api/v1/auth/google`}
              >
                Link Mailbox
              </a>
            </button> */}

            <button
              onClick={() => setShowModal(true)}
              className={`flex items-center text-black font-500 hover:bg-[#bbe39f] shadow-md h-[32px] rounded-[3px] px-[12px] text-[13px]`}
              style={{ backgroundColor: "text-white" }}
            >
              <img
                src={googleLogo}
                className="w-[15px] h-[15px] mr-2"
                alt="google logo"
              />
              <h1>Sign in with Google</h1>
            </button>
          </div>
          <div className=" border-b border-border-gray px-4 pb-4">
            <div className="color-gray text-[15px] font-semibold flex ">
              <div className="flex flex-1 basis-[25%]">Mailbox</div>
              <div className="flex flex-1">Warmup Mode</div>
              <div className="flex flex-1">Daily limit</div>
              <div className="flex flex-1">Hourly limit</div>
              <div className="flex flex-1 pr-[22px]">Last synced</div>
            </div>
          </div>
          {mailboxes?.map((mailbox, index) => (
            <SingleRow mailbox={mailbox} key={index}></SingleRow>
          ))}
        </div>
      ) : (
        <div className="w-[350px] mx-auto bg-white flex justify-center items-cente custom-shadow  p-4">
          <div className="text-center flex flex-col justify-center items-center">
            <FiMail className="text-[#1991EB] text-[35px] inline-block mt-2" />
            <h1 className="primary-heading-color text-[1.1rem] font-semibold my-4">
              No mailbox linked
            </h1>



            <button
              onClick={() => setShowModal(true)}
              className={`bg-white flex items-center shadow-deep font-500   h-[32px] rounded-[3px] px-[12px] text-[13px]`}
            >
              <img
                src={googleLogo}
                className="w-[15px] h-[15px] mr-2"
                alt="google logo"
              />
              <h1>Sign in with Google</h1>
            </button>
          </div>
        </div>
      )}

      {showMailboxError && <ExpiredMailbox></ExpiredMailbox>}

      {showModal && (
        <AgremnetModal
          handleNavigate={handleNavigate}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default Mailboxes;
