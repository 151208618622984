import React from 'react';
import AIimage from '../../../Images/AI/icons8-artificial-intelligence-64.png'

import { useNavigate } from 'react-router-dom';
import EmailTemplates from './EmailTemplates';



const Ai = () => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate('/write_cold_email')
    }

    const handleNavigateSevenStep = () => {
        navigate('/7_step_community_invite')
    }

    const TEMPLATES = [
        {
            name: "Write Cold Email",
            icon: AIimage,
            description: "Provide prospect company details and Let AI Write the cold email for you!",
            footerBadge: "Outbound",
            onclick: handleNavigate,

        }, {
            name: "Write Cold Email using pain point & Value prop",
            icon: AIimage,
            description: "Provide your pain points and value propositions, and let AI Write the email for you.",
            footerBadge: "Outbound",
            badge: 'New'

        }, {
            name: "Create a 7 step Community invite sequence  in moments ",
            icon: AIimage,
            description: "Provide your pain points and value propositions, and let AI Write the email for you.",
            footerBadge: "Outbound",
            badge: 'New',
            onclick: handleNavigateSevenStep

        }
    ]
    return (
        <div>

            <EmailTemplates emailTemplates={TEMPLATES} />

        </div>
    );
};

export default Ai;