import { useEffect, useState } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { MdOutlineDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import properties from "../../../config/properties";
const Verify = () => {
  const [msg, setMsg] = useState("Please Wait a moment...");
  const [symbol, setSymbol] = useState(null); // used for rendering either Tick sign or X sign.
  const navigate = useNavigate();

  useEffect(() => {
    let symbol = document.getElementById("symbol");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    const token = urlParams.get("token");
    if (id && token) {
      axios
        .post(`${properties.REACT_APP_SERVER_URL}/api/v1/users/verifyEmail`, {
          id: id,
          token: token,
        })
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            if (res.data.verified) {
              symbol.classList.remove("hidden");
              setMsg("Your Email Has Been Verified!");
              setSymbol(
                <MdOutlineDone className="font-semibold text-green-700 text-4xl md:text-7xl mx-auto"></MdOutlineDone>
              );
              setTimeout(() => {
                navigate("/");
              }, 4000);
            } else {
              symbol.classList.remove("hidden");
              setMsg("Verification link might have expired!");
              setSymbol(
                <FaTimes className="font-semibold text-red-700 text-4xl md:text-7xl mx-auto"></FaTimes>
              );
              setTimeout(() => {
                navigate("/signup");
              }, 4000);
            }
          }
        });
    } else {
      navigate("/signup");
    }
  }, []);
  return (
    <div className="flex flex-col h-[90vh] justify-center">
      <div id="symbol" className="mx-auto mb-10 hidden">
        {symbol}
      </div>
      <h1 className="text-center text-xl md:text-3xl font-semibold">{msg}</h1>
    </div>
  );
};
export default Verify;
