import React from "react";
const SingleEmailStat = ({ singleData, title, statValue }) => {
  const { image, bg } = singleData;

  return (
    <div>
      <div
        className={` p-4 rounded-[4px] flex items-center gap-2 box-shadow-main `}
      >
        <div className="font-semibold text-[18px]">
          {image}
        </div>
        <div>
        </div>
        <div className="font-medium">
          <h1 className=" text-[17px] ">{statValue ? statValue : 0}</h1>
          <h1 className=" ">{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default SingleEmailStat;
