import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const useDebounce = (value, delay) => {
    const [debounceValue, setDebounceValue] = useState(value)


    useEffect(() => {
        const id = setTimeout(() => {
            setDebounceValue(value)
        }, delay)

        return () => {
            clearTimeout(id)
        }
    }, [delay, value])
    return debounceValue
};

export default useDebounce;