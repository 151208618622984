import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import { truncateFileName } from '../../../Utils/TrucFileName';

const UploadAttachment = ({ uploadProgress, uploadedFile, handleDeleteAttachment }) => {
    return (
        <div>
            {Object.keys(uploadProgress).map((fileName) => (
                <div key={fileName} className={`${uploadProgress[fileName] === 100 ? 'hidden' : ''} progress-wrapper mt-3`}>

                    <div className="progress-bar" style={{ width: `${uploadProgress[fileName]}%` }}>
                        <span className="sr-only">{uploadProgress[fileName]}% Complete</span>
                    </div>
                </div>
            ))}

            {uploadedFile.map((file, index) => (
                <div key={index} className="fileNameParent rounded-[4px] flex justify-between items-center mt-2">
                    <h1 className="text-blue-600">{truncateFileName(file.fileName, 30)}</h1>
                    <RxCross2 onClick={() => handleDeleteAttachment(file.fileKey)} className="cursor-pointer" />
                </div>
            ))}
        </div>
    );
};

export default UploadAttachment;