export default function extractDateTime(reply) {

    var pattern = /On\s+(\w{3},?\s+\w{3}\s+\d{1,2},?\s+\d{4})\s+at\s+(\d{1,2}:\d{1,2}\s+[APM]+)/;
    var match = reply.match(pattern);
    if (match) {
        var date = match[1].replace(',', '');
        var time = match[2];
        return date + ' ' + time;
    } else {
        return null;
    }
}
