import React, { useState } from "react";
import CommunityInteraction from "./CommunityInteraction";
import ComunityContributer from "./ComunityContributer";
import LinkedInstat from "./LinkedInstat";
import NewDashboard from "./NewDashboard";
import OverallInsite from "./OverallInsite";
import ProspectStat from "./ProspectStat";
import TodayInsite from "./TodayInsite";
import TotalMessege from "./TotalMessege";
import TotalOutreach from "./TotalOutreach";
import Emailstat from "../Sequence/CampainStat/EmailStat/Emailstat";

const DashboardHome = () => {
  return (
    <div id="dashboard">
      <div className="bg-white  ">
        <h1 className="text-[#05004E]  font-semibold inline-block mb-4">
          Campaign Progress
        </h1>
        <Emailstat />
      </div>

      {/* <div className="col-span-3">
          <OverallInsite></OverallInsite>
        </div> */}

      {/* 2nd row */}
      <div className="grid grid-cols-12 gap-3 mt-8">
        <div className="col-span-6">
          <TotalOutreach></TotalOutreach>
        </div>
        <div className="col-span-6">
          <OverallInsite></OverallInsite>
        </div>
        {/* <div className="col-span-3">
          <ProspectStat></ProspectStat>
        </div> */}
      </div>

      {/* 3rd row  */}
      <div className="grid grid-cols-11 gap-3 mt-8">
        <div className="col-span-5">
          <ComunityContributer></ComunityContributer>
        </div>
        <div className="col-span-3">
          <CommunityInteraction></CommunityInteraction>
        </div>
        <div className="col-span-3">
          <TotalMessege></TotalMessege>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
