export function extractBodyContentFromEmail(snippet) {
    if (!snippet) {
        snippet = "";
    }
    let bodyMatcher = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
    let removeHTMLTags = /(<([^>]+)>)/gim;

    if (snippet.includes("<body>")) {
        let matched_strings = bodyMatcher.exec(snippet);
        if (Array.isArray(matched_strings)) {
            snippet = matched_strings.join(" ");
        }
    }
    snippet = snippet.replace(removeHTMLTags, "");



    const emailText = snippet

    const endIndex = emailText?.indexOf('This email') || -1;

    if (endIndex !== -1) {
        const extractedString = emailText.substring(0, endIndex);
        return extractedString
    } else {
        return snippet
    }
}


