import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../Componenets/Button';
import { writeSequenc } from '../../../Services/apiServices';
import GetUser from '../../../Hooks/GetUser';
import BackButton from '../../../Componenets/BackButton';

const AileftSide = ({ setAiresponse }) => {
    const { register, handleSubmit, } = useForm();
    const [loader, setLoader] = useState(false)
    const userId = GetUser()

    const onSubmit = async data => {
        setLoader(true)
        data['userId'] = userId;
        let response = await writeSequenc(data)
        if (response.data.data) {
            setAiresponse(response.data.data)
            setLoader(false)
        }
    };

    return (
        <div className='border-r-2 border-border-gray'>
            <div className="mt-2 ml-2">
                <BackButton to='/aicontent' />
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className=" p-4">
                <div className="">
                    <div className="flex-1">
                        <label
                            htmlFor='yourCompany'
                            className="text-[12px] capitalize font-semibold mb-2  text-[#5d6a7e] block"
                        >
                            your Company:
                        </label>
                        <input
                            id='yourCompany'
                            type='text'
                            className="w-full text-[13px]  primary-heading-color font-medium custom-shadow-1 pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                            {...register("yourCompany")}
                        />
                    </div>

                    <div className="mt-4">
                        <label
                            htmlFor="yourCompanyDescription"

                            className="text-[12px] capitalize font-semibold mb-2  text-[#5d6a7e] block"
                        >
                            your Company Description:
                        </label>
                        {/* <textarea


                            className="w-full text-[13px]  primary-heading-color font-medium custom-shadow-1 pl-2 focus:outline-none border rounded-[2px]  border-[#d3dae3] focus:border-blue-400"
                            {...register("yourCompanyDescription")}
                        /> */}


                        <textarea
                            placeholder='Enter Your Company Description'

                            className=" text-[13px] w-full  primary-heading-color font-medium custom-shadow-1 pl-2 focus:outline-none border rounded-[2px]  border-[#d3dae3] focus:border-blue-400"


                            {...register("yourCompanyDescription")} id="yourCompanyDescription" name="yourCompanyDescription" rows="6" cols="50"></textarea>
                    </div>
                </div>

                <div className="my-4">
                    <label
                        htmlFor="targetCompany"
                        className="text-[12px] capitalize font-semibold mb-2  text-[#5d6a7e] block"
                    >
                        prospect Company:
                    </label>
                    <input
                        id='targetCompany'
                        type="text"
                        className="w-full text-[13px]  primary-heading-color font-medium custom-shadow-1 pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                        {...register("targetCompany")}
                    />
                </div>


                <div className="my-4">
                    <label
                        htmlFor="targetCompanyDescription"
                        className="text-[12px] capitalize font-semibold mb-2  text-[#5d6a7e] block"
                    >
                        prospect Company Description:
                    </label>
                    <textarea
                        id='targetCompanyDescription'
                        rows="6" cols="50"
                        placeholder='Enter prospect Company Description'
                        type="number"
                        className="w-full text-[13px]  primary-heading-color font-medium custom-shadow-1 pl-2 focus:outline-none border rounded-[2px]  border-[#d3dae3] focus:border-blue-400"
                        {...register("targetCompanyDescription")}
                    />
                </div>

                <Button disabled={loader} type='submit' loading={loader} tittle={'Submit'} />

            </form>
        </div>
    );
};

export default AileftSide;