import React, { useContext, useEffect, useRef } from "react";
import { UserContext } from "../Hooks/UserContext";

const ModalComponenet = ({
  setActiveModal,
  zIndex,
  fromProspectLoad,
  title,
  children,
  top,
  width,
  background,
  radious,
  childrenPadding,
}) => {
  const { setRefetch } = useContext(UserContext);
  const closeModal = () => {
    setActiveModal(false);
    if (fromProspectLoad) {
      setRefetch((prev) => !prev);
    }
  };
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef?.current.contains(e.target)) {
        setActiveModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside, true);
    document.body.style.overflow = "hidden";

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
      document.body.style.overflow = "auto";
    };
  }, [setActiveModal]);

  return (
    <div className={`popup`} style={{ zIndex: zIndex }}>
      <div className="modal-empty-div"></div>
      <div
        ref={modalRef}
        className={`content-container `}
        style={{ width: width, top: top, borderRadius: radious }}
      >
        <div
          className={`w-full popup-content min-h-full`}
          style={{ backgroundColor: background }}
        >
          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray p-4">
            <h1 className="primary-heading-color font-semibold">{title}</h1>
            <button
              onClick={closeModal}
              className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[30px] w-[20px] h-[20px] flex justify-center items-center"
            >
              &times;
            </button>
          </div>
          <div
            className={`p-[${childrenPadding}]`}
            style={{ padding: childrenPadding }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponenet;
