import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";

import "./ProspectDrawer.css";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import InputForProspectUpdate from "./InputForProspectUpdate";
import { useEffect } from "react";
import GetUser from "../../../Hooks/GetUser";
import { updatePropspect } from "../../../Services/apiServices";
import { toast } from "react-toastify";
import { FormControlLabel } from "@mui/material";
import { Android12Switch } from "../../../Utils/SwitchIcon";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const stringFieldsList = [
  "mailBoxEmail",
  "name",
  "firstName",
  "secondName",
  "companyName",
  "companyWebUrl",
  "city",
  "location",
  "jobTitle",
  "promotion",
  "recentJobChange",
  "companyDescription",
  "ceo",
  "competitor",
  "email",
  "linkedinUrl",
  "phoneNumber",
  "whatsappNumber",
  "twitterUrl",
  "oneLinePersonalization",
];

export default function ProspectDrawerTabs({
  singleRowData,
  setFetchDetailsStats,
}) {
  const [value, setValueMui] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: generateDefaultValues(),
  });

  function generateDefaultValues() {
    const defaultValues = {};
    stringFieldsList.forEach((field) => {
      defaultValues[field] = "";
    });
    return defaultValues;
  }

  const [booleanFieldsValue, setBooleanFieldsValue] = useState({
    activeInSequence: false,
    bouncedEmail: false,
    paused: false,
    unsubsribed: false,
    blockedEmail: false,
    replied: false,
  });

  const user = GetUser();
  const handleChange = (event, newValue) => {
    setValueMui(newValue);
  };

  const toogleBooleanList = (item) => (event) => {
    setBooleanFieldsValue((prevState) => ({
      ...prevState,
      [item]: event.target.checked,
    }));
  };

  useEffect(() => {
    if (singleRowData) {
      stringFieldsList.forEach((item) => {
        setValue(item, singleRowData[item]);
      });

      setBooleanFieldsValue({
        activeInSequence: singleRowData.activeInSequence,
        bouncedEmail: singleRowData.bouncedEmail,
        paused: singleRowData.paused,
        unsubsribed: singleRowData.unsubsribed,
        blockedEmail: singleRowData.unsubsribed,
        replied: singleRowData.replied,
      });
    }
  }, [reset, setValue, singleRowData, user]);

  console.log(singleRowData);

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    const payload = {
      userId: user,
      prospectId: singleRowData?._id || singleRowData.emailTracks[0].prospectId,
      updateData: {
        ...data,
        ...booleanFieldsValue,
      },
    };
    console.log(payload);
    try {
      let response = await updatePropspect(payload);
      if (response.data.success) {
        setFetchDetailsStats((prevState) => !prevState);
        setSubmitLoading(false);
        toast.success("updated prospect data");
      }
    } catch (error) {
      console.error("Error updating prospect:", error);
      setSubmitLoading(false);
      toast.error("An error occurred while updating prospect data");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="font-semibold capitalize"
            label="Lead Details"
            {...a11yProps(0)}
          />
          <Tab
            className="font-semibold capitalize"
            label="Activities"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel className="p-0" value={value} index={0}>
        <div>
          {stringFieldsList.map((item) => (
            <InputForProspectUpdate
              key={item}
              name={item}
              register={register}
              label={item}
            />
          ))}
        </div>

        <div className="mt-6">
          {Object.keys(booleanFieldsValue).map((item, index) => (
            <div key={index} className="block mt-2">
              <FormControlLabel
                className="mr-6"
                control={
                  <Android12Switch
                    checked={booleanFieldsValue[item]}
                    onChange={toogleBooleanList(item)}
                  />
                }
              />
              <label
                htmlFor={item}
                className="text-muted  font-semibold text-[90%] "
              >
                {item}
              </label>
            </div>
          ))}
        </div>

        <div className="flex justify-end items-center mt-4">
          <LoadingButton
            loading={submitLoading}
            submitLoading
            onClick={handleSubmit(onSubmit)}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            className="btn"
          >
            Save
          </LoadingButton>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Activities
      </CustomTabPanel>
    </Box>
  );
}
