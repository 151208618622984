import React, { useEffect } from "react";
import ReactApexChart from "apexcharts";

const CommunityInteraction = () => {
  const chartRef = React.useRef(null);

  useEffect(() => {
    const options = {
      series: [
        {
          name: "last month",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: "this month",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
      ],
      chart: {
        height: 430,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };

    var chart = new ReactApexChart(chartRef.current, options);
    chart.render();
  }, []);

  return <div ref={chartRef} className="bg-white custom-shadow" id="chart" />;
};

export default CommunityInteraction;
