import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import navigationImg from "../../../Images/navigation.svg";
import siri from "../../../Images/Siri.svg";
import Loading from "../../Shared/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../Hooks/UserContext";
import GetUser from "../../../Hooks/GetUser";
import properties from "../../../config/properties";
import SingleRow from "./SingleRow";

const CampaignSearch = () => {
  const user = GetUser();
  const { reFetch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [campainData, setcampainData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [totalLeads, setTotalLeads] = useState({})


  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);

    axios
      .post(`${properties.REACT_APP_SERVER_URL}/api/v1/sequence/getSequences`, {
        userId: user,
      })
      .then((camapaign) => {
        setcampainData(camapaign?.data?.data);
        setTotalLeads(camapaign?.data?.countLeadsOfSeq)
        setFilterData(camapaign?.data?.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [reFetch, user]);



  const filterSeqStat = (id) => {

    let temp = {};
    for (let i = 0; campainData.length > i; i++) {
      if (campainData[i]._id === id) {
        temp = campainData[i];
        break;
      }
    }
    return temp;
  };




  useEffect(() => {
    let result = campainData;
    if (/[a-z0-9]+/.test(search.toLowerCase())) {
      result = campainData?.filter((singledata) => {
        return singledata.name?.toLowerCase().match(search.toLowerCase());
      });
    }
    setFilterData(result);
  }, [campainData, search]);



  const navigateSequence = (id) => {
    navigate(`/sequence/${id}`);
  };









  return (
    <>
      {
        loading
          ?
          <Loading whiteLoader="whiteLoader"></Loading>
          :
          <div className="bg-white shadow-deep-two mt-20 h-[100%] p-4 inline-block rounded-[8px] w-full">
            <div className="flex">
              <div className="w-full flex items-center justify-between">
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className=" w-[50%] relative  mb-3 input-search "
                  type="text"
                  placeholder="search here"
                ></input>
                <div className=" flex w-[10%] justify-around items-center">
                  <img className="w-[18px]" src={siri} alt="" />
                  <img height="16px" src={navigationImg} alt="" />
                </div>
              </div>
            </div>
            <table className="w-full font-500 border-separate">
              <thead>
                <tr>
                  <th>ID</th>
                  <th className="w-[11rem] ">Campaign Name</th>
                  <th className="text-center">Total leads</th>
                  <th className="text-center">Start Date</th>

                  <th className="text-center">Contacted</th>
                  <th colSpan={4} className="!text-center">
                    Mail Stats
                  </th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Active Status</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {filterData?.map((singleData, index) => (
                  <SingleRow user={user} sequenceStats={filterSeqStat(singleData._id)} totalLeads={totalLeads} index={index} navigateSequence={navigateSequence} singleData={singleData} key={index}></SingleRow>
                ))}
              </tbody>
            </table>
          </div>
      }
    </>

  );
};
export default CampaignSearch;
