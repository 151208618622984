import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import googleLogo from "../../../Images/google.png";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { AuthContext } from "../../../Hooks/Authcontext";
import { posthog } from "posthog-js";
import { userGoogleLogin } from "../../../Services/apiServices";
import Logger from "../../../Helpers/Logger";

const logger = new Logger("social.js");

const getUserInfo = async (accessToken) => {
  try {
    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log("Error fetching user info:", err);
  }
};

const Social = ({ setloginLoading }) => {
  const navigate = useNavigate();
  const { setLogged } = useContext(AuthContext);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setloginLoading(true);
      try {
        const userInfoData = await getUserInfo(tokenResponse.access_token);
        if (userInfoData) {
          const googleLoginPayload = {
            accessToken: tokenResponse.access_token,
            firstName: userInfoData.given_name,
            lastName: userInfoData.family_name,
            email: userInfoData.email,
            googleId: userInfoData.sub,
          };
          let googleLoginResponse = await userGoogleLogin(googleLoginPayload);
          if (
            googleLoginResponse.data?.auth &&
            googleLoginResponse.data?.token
          ) {
            localStorage.setItem("token", googleLoginResponse.data.token);
            localStorage.setItem("isManualLogin", true);
            setloginLoading(false);
            navigate("/");
            setLogged(true);
            posthog.capture("Login With Google", {
              buttonName: "Continue With Google",
            });
          } else {
            logger.error(
              "Google Login Response Failed",
              googleLoginResponse.data
            );
          }
        } else {
          logger.error("userInfoData is Missing", userInfoData);
        }
      } catch (err) {
        logger.error(`login`, err);
      }
    },
  });

  return (
    <div>
      <div className="form-control my-4">
        <button
          onClick={login}
          className=" py-2 rounded-[4px] flex justify-center items-center input-border  font-semibold  text-[#737791]  w-full "
        >
          <img src={googleLogo} alt="" />
          <span className="ml-2 text-[13px]">Sign in With Google</span>
        </button>
      </div>
    </div>
  );
};
export default Social;
