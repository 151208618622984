import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import properties from "../../../config/properties";
import { toast } from "react-toastify";


const EditSequence = ({

  setEditSeqModal,
  setFetchSeqWithSteps,
  sequenceId,
  user,
  sequenceName,
}) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (data) {
      axios
        .put(
          `${properties.REACT_APP_SERVER_URL}/api/v1/sequence/updateSequenceName`,
          {
            userId: user,
            sequenceId: sequenceId,
            sequenceName: data.sequenceName,
          }
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            setFetchSeqWithSteps(prevState => !prevState)
            setEditSeqModal(false)
            toast.success('Updated Sequence Name')

          }
        });
    }
  };
  return (
    <div>
      <div className="popup  ">
        <div className="modal-empty-div"></div>
        <div className="content-container">
          <div className="bg-white w-[550px]  popup-content !rounded-[8px] p-6">
            <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
              <h1 className="text-[#242d3e] text-[15px] font-semibold">
                Edit Sequence Name
              </h1>
              <button
                onClick={() => setEditSeqModal(false)}
                className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
              >
                &times;
              </button>
            </div>

            <div className="flex justify-center items-center mt-4 ">
              <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                {/* email */}
                <div className="form-control">
                  <input
                    type="text"
                    placeholder={sequenceName}
                    className="input-customize !h-8"
                    {...register("sequenceName", {
                      required: {},
                    })}
                  />
                </div>

                {/* password  */}

                <button
                  type="submit"
                  className={`button-background float-right text-white font-500 mt-5 hover:bg-[#1991eb]  h-[32px] rounded-[3px] px-[12px] text-[13px]`}
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSequence;
