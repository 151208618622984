import React from 'react';
import spinner from '../Images/button-loader.gif'
import './Button.css'
const Button = ({ loading, onClick, tittle, disabled, type, form, importantbg }) => {
    return (
        <button style={importantbg && { background: importantbg }} onClick={onClick} disabled={disabled} className='button' type={type} form={form}>
            {
                loading ? <img src={spinner} alt="" /> : tittle
            }

        </button>
    );
};

export default Button;