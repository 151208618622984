import React, { useContext, useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import Button from "../../../Componenets/Button";
import { UserContext } from "../../../Hooks/UserContext";
import {
  deleteNthProspect,
  deleteProspect,
} from "../../../Services/apiServices";
import { toast } from "react-toastify";
const DeleteModal = ({
  setactiveDeleteModal,
  selectedProspects,
  user,
  oneProspect,
  numberOfSelectedItem,
  setCheckmarkPopup,
  setLoadData,
  activePage,
  filterBy,
  filterByStage,
  setNumberOfSelectedItem,
}) => {
  const { reFetch, setRefetch } = useContext(UserContext);
  const [loader, setLoader] = useState(false);

  const handleDelete = async () => {
    setLoader(true);

    if (numberOfSelectedItem < 20) {
      const payload = {
        userId: user,
        prospectIds: oneProspect ? [oneProspect] : selectedProspects,
      };
      let response = await deleteProspect(payload);
      if (response.data.success === true) {
        setactiveDeleteModal(false);
        setRefetch(!reFetch);
        setLoader(false);
      }
    } else {
      const payload = {
        userId: user,
        numberOfProspects: Number(numberOfSelectedItem),
        skipFrom: 20 * activePage,
        selectedProspectIds: selectedProspects,
        filterBy: filterBy,
        filterByStage: filterByStage,
      };

      let response = await deleteNthProspect(payload);

      if (response.data.success) {
        setLoadData((prevState) => !prevState);
        toast.success(response.data.message);
        setactiveDeleteModal(false);
        setNumberOfSelectedItem(0);
      }
    }
  };

  return (
    <div className="popup">
      <div className="modal-empty-div"></div>
      <div className="content-container mt-3">
        <div className="bg-white w-[500px]  popup-content !rounded-[4px] p-4">
          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
            <h1 className="text-[#242d3e] text-[15px] font-semibold">
              Delete Prospect
            </h1>
            <button
              onClick={() => setactiveDeleteModal(false)}
              className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
            >
              &times;
            </button>
          </div>

          <div className="py-4">
            <h1 className="text-[14px] font-500">
              Are you sure you want to delete{" "}
              {numberOfSelectedItem > 20
                ? numberOfSelectedItem
                : selectedProspects.length}{" "}
              prospect
            </h1>
          </div>
          <div className="flex justify-end">
            <div className="flex">
              <button
                onClick={() => setactiveDeleteModal(false)}
                className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
              >
                Cancel
              </button>
              <div>
                <Button
                  loading={loader}
                  disabled={loader}
                  onClick={handleDelete}
                  tittle={"Delete"}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
