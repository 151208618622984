import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../Hooks/UserContext";
import { useForm } from "react-hook-form";
import "./StepModal.css";
import "quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading/Loading";
import SubjectPersonalize from "../PersonalizeComponent/SubjectPersonalize";
import { ImInsertTemplate } from "react-icons/im";
import { RiArrowDropDownLine, RiBracesLine } from "react-icons/ri";
import GetUser from "../../../Hooks/GetUser";
import {
  deleteAttachment,
  getCustomTemplate,
  getStepdata,
  mailboxConnection,
  updateStepData,
} from "../../../Services/apiServices";
import Button from "../../../Componenets/Button";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import DraftEditor from "../../../Componenets/DraftEditor";
import draftToHtml from "draftjs-to-html";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ModalFooterIcon from "./ModalFooterIcon";
import { Tooltip } from "react-tooltip";
import UploadAttachment from "./UploadAttachment";
import properties from "../../../config/properties";
import ConformationModal from "../../../Componenets/ConformationModal";
import { validatePersonalize } from "../../../Utils/PersonalizeError";
import { addMargin } from "../../../Utils/ParagraphStyle";

const UpdateStepModal = ({
  sequenceId,
  setModalState,
  allstepData,
  stepId,
  stepStartDate,
  setStepUpdateModalActive,
  formattedDate,
  index,
  fromAi,
  step,
  setFetchSequenceStep,
}) => {
  const { setRefetch, reFetch } = useContext(UserContext);

  const [isPersonalisePopupOpen, setIsPersonalisePopupOpen] = useState(false);
  const [isPopupOpenTemplate, setIsPopupOpenTemplate] = useState(false);

  const modalRef = useRef();
  const [subjectPopup, setSubjectPopup] = useState(false);
  const user = GetUser();
  const [loading, setLoading] = useState(false);
  // const [stepData, setStepData] = useState('')
  const [detectFocus, setDetectFocus] = useState(false);
  const [isFocusedSubject, setIsFocusedSubject] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isChecked, setIschecked] = useState(false);
  const [signatureData, setSignatureData] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const [subject, setSubject] = useState("");
  const [threadData, setThreadData] = useState();
  const handleThreadData = (event) => {
    setThreadData(event.target.value);
  };

  const [styles, setStyles] = useState({
    colSpan: 2,
    border: true,
    toggled: false,
  });
  // dradft js
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [previewEditorState, setPreviewEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  // ref
  const subjectinputRef = useRef(null);
  const personalizeBtn = useRef(null);
  const templateBtn = useRef(null);
  const selectSubject = useRef(null);

  const [uploadedFile, setUploadedFile] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  // handle checkbox
  const handleCheck = (e) => {
    console.log(e.target.checked);
    setIschecked(e.target.checked);
  };

  // insert subject template
  const handleInsertInput = (value) => {
    const updatedValue = subject + value;
    setSubject(updatedValue);
    subjectinputRef.current.value = updatedValue;
    subjectinputRef.current.defaultValue = updatedValue;
    setSubjectPopup(false);
  };
  const handleInputChange = (event) => {
    setSubject(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    async function stepData() {
      const payload = {
        user: user,
        stepId: stepId,
      };
      let res = await getStepdata(payload);
      if (res.data.status) {
        // setStepData(res.data.data)

        setIschecked(res.data.data.signatureEnabled);
        setSubject(res?.data?.data?.subject);
        const singleObj = res?.data?.data?.attachments.map((obj) => obj);
        setUploadedFile([...uploadedFile, ...singleObj]);
        setThreadData(res.data.data.thread);
        const contentBlock = htmlToDraft(res?.data?.data?.template);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
          setPreviewEditorState(editorState);
        }
      }
    }

    if (!fromAi) {
      stepData();
    } else {
      setIschecked(step?.signatureEnabled);
      setSubject(step?.subject);

      const contentBlock = htmlToDraft(step?.template);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        setPreviewEditorState(editorState);
      }
    }

    async function getMailboxConnection() {
      const payload = {
        user: user,
      };
      let response = await mailboxConnection(payload);
      if (
        response?.data?.data &&
        response.data.data?.connections &&
        response.data.data?.connections.length > 0 &&
        response.data.data?.connections[0].hasOwnProperty("emailSignature")
      ) {
        setSignatureData(response.data.data?.connections[0].emailSignature);
        setLoading(false);
      } else {
        setSignatureData("");
        setLoading(false);
      }
    }
    getMailboxConnection();

    async function getTemplate() {
      const payload = {
        user: user,
      };
      let res = await getCustomTemplate(payload);

      if (res) {
        setTemplates(res.data.data);
      }
    }

    getTemplate();

    const handleClickOutside = (e) => {
      if (!personalizeBtn?.current?.contains(e.target)) {
        setIsPersonalisePopupOpen(false);
      }
      if (!templateBtn?.current?.contains(e.target)) {
        setIsPopupOpenTemplate(false);
      }
      if (!selectSubject?.current?.contains(e.target)) {
        setSubjectPopup(false);
      }
      if (modalRef.current && !modalRef?.current.contains(e.target)) {
        // setModalState((prevState) => ({
        //   ...prevState,
        //   activeUpdateModal: false,
        // }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [setModalState, stepId]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getInitialState = () => {
    const value = "automatedEmail";
    return value;
  };
  const [channel, setchennel] = useState(getInitialState);

  const onSubmit = async () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setLoading(true);
    const payload = {
      userId: user,
      sequenceId: sequenceId,

      stepId: allstepData._id,

      stepData: {
        signatureEnabled: isChecked,
        subject: subjectinputRef?.current?.value,
        template: html,
        thread: threadData,
        attachments: uploadedFile,
      },
    };

    //validate personalize
    const incorrectPersonalize = validatePersonalize(
      html,
      subjectinputRef?.current?.value
    );

    if (incorrectPersonalize.length > 0) {
      const errorMessage =
        "Wrong personalization variables: " + incorrectPersonalize.join(", ");
      toast.error(errorMessage);
      setLoading(false);
      return;
    }

    const isEmptyTemplate = html.replace(/\s/g, "") === "<p></p>";

    if (
      isEmptyTemplate ||
      (threadData !== "same" && subjectinputRef?.current?.value.trim() === "")
    ) {
      setLoading(false);
      toast.error("Fill all the fields");
      return;
    }

    if (fromAi) {
      step.subject = subjectinputRef?.current?.value;
      step.signatureEnabled = isChecked;
      step.template = html;
      toast.success("updated");
      setLoading(false);
      setStepUpdateModalActive(false);
    } else {
      let res = await updateStepData(payload);
      if (res.data.success) {
        setRefetch(!reFetch);
        setFetchSequenceStep((prevState) => !prevState);
        toast.success("Updated Successfully");
        setStepUpdateModalActive(false);
        setLoading(false);
      }
    }
  };

  function handleBack() {
    if (styles.toggled) {
      setStyles({ colSpan: 2, border: true, toggled: false });
    } else {
      setStyles({ colSpan: 3, border: false, toggled: true });
    }
  }

  // upload file

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > 25 * 1024 * 1024) {
      alert("File size should be less than 25 MB");
      return;
    }
    const formData = new FormData();
    formData.append("File", file);
    formData.append("userId", user);
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${properties.REACT_APP_SERVER_URL}/api/v1/attachments/uploadAttachments`,
      true
    );
    xhr.upload.addEventListener("progress", function (event) {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: percentComplete,
        }));
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          if (response.success) {
            const singleObj = response.fileNamesAndKeys.map((obj) => obj);
            setUploadedFile([...uploadedFile, ...singleObj]);
          }
        } else {
          toast.error("file not uploaded");
        }
      }
    };

    xhr.send(formData);
  };

  const handleDeleteAttachment = async (fileKey) => {
    const payload = {
      stepId: stepId,
      userId: user,
      fileKey: fileKey,
    };

    let response = await deleteAttachment(payload);
    if (response.data.success) {
      setUploadedFile(uploadedFile.filter((file) => file.fileKey !== fileKey));
      toast.success("succesfully delete attachment");
    }
  };

  // close modal
  const handleModal = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const isEmptyTemplate = html.replace(/\s/g, "") === "<p></p>";
    const inputValue = subjectinputRef.current.value;

    if (
      (!isEmptyTemplate && detectFocus) ||
      inputValue.trim() !== "" ||
      isFocusedSubject
    ) {
      setConfirmModal(true);
    } else {
      setStepUpdateModalActive(false);
    }
  };

  return (
    <div className="popup">
      {confirmModal && (
        <ConformationModal
          setConfirmModal={setConfirmModal}
          setActiveStepModal={setStepUpdateModalActive}
        />
      )}
      <div className="modal-empty-div"></div>
      <div
        ref={modalRef}
        className="content-container  w-[100vw] !h-[100%] max-h-[100%] !z-[9]"
      >
        <div className="w-full  popup-content min-h-full  !bg-white  p-4">
          <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
            <h1 className="primary-heading-color font-medium">Step Update</h1>
            <button
              onClick={handleModal}
              className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
            >
              &times;
            </button>
          </div>
          <div className="w-[97%] mx-auto">
            {loading ? (
              <Loading whiteLoader="whiteLoader"></Loading>
            ) : (
              <div className="div">
                <div className="grid grid-cols-3 h-[96%]">
                  <div
                    className={`${
                      styles.toggled ? "col-span-3" : "col-span-2"
                    } ${
                      styles.border ? "border-r border-border-gray" : ""
                    } relative`}
                  >
                    <div
                      onClick={handleBack}
                      className="absolute  text-[20px] right-[-10px]  cursor-pointer bg-[#e8eef4] text-[#1991eb]"
                    >
                      <Tooltip id="Preview ON" />
                      <Tooltip id="Preview OFF" />

                      {styles.toggled ? (
                        <AiOutlineEye
                          data-tooltip-content="Preview ON"
                          data-tooltip-id="Preview ON"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="text-[#1991eb]"
                          data-tooltip-content="Preview OFF"
                          data-tooltip-id="Preview OFF"
                        />
                      )}
                    </div>
                    <div className="relative">
                      <div className="pt-4 pr-2">
                        <div>
                          <div
                            ref={selectSubject}
                            className={`${
                              channel.includes("LinkedIn") ? "hidden" : ""
                            }   justify-between mb-4 w-full relative     `}
                          >
                            <SubjectPersonalize
                              selectSubject={selectSubject}
                              handleInsertInput={handleInsertInput}
                              subjectPopup={subjectPopup}
                            ></SubjectPersonalize>

                            <div className="mb-4">
                              <h1 className="color-gray text-[15px] font-medium mb-2">
                                Thread:
                              </h1>
                              <div className="relative">
                                <select
                                  value={threadData}
                                  onChange={handleThreadData}
                                  className="text-[15px] bg-white focus:outline-none border border-[#d3dae3] rounded-[4px] h-8 w-full appearance-none pl-3 pr-8"
                                >
                                  <option value="new">New Thread</option>
                                  <option value="same">Same Thread</option>
                                </select>
                                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                  <RiArrowDropDownLine className="icon-color text-[25px]" />
                                </div>
                              </div>
                            </div>

                            {/* subject  */}
                            <div className="">
                              <h1
                                className={`${
                                  channel.includes("LinkedIn") ||
                                  threadData === "same"
                                    ? "hidden"
                                    : ""
                                } color-gray text-[15px] font-medium mb-2`}
                              >
                                Subject:
                              </h1>
                              <div
                                className={`${
                                  channel.includes("LinkedIn") ||
                                  threadData === "same"
                                    ? "hidden"
                                    : ""
                                }    flex relative`}
                              >
                                <input
                                  onChange={handleInputChange}
                                  onFocus={() => setIsFocusedSubject(true)}
                                  ref={subjectinputRef}
                                  defaultValue={subject}
                                  placeholder="enter subject here"
                                  id="subject"
                                  type="text"
                                  className={`${
                                    channel.includes("LinkedIn") ||
                                    threadData === "same"
                                      ? "hidden"
                                      : ""
                                  } border border-[#d3dae3] pl-2 w-full h-8 rounded-[4px] text-[15px] hour-input`}
                                />
                                <div
                                  data-tooltip-id="subject-personalize"
                                  data-tooltip-content="personalization"
                                  onClick={() => setSubjectPopup(!subjectPopup)}
                                  className="cursor-pointer absolute right-0 bg-[#f7f9fb] px-2 flex justify-center items-center  h-full custom-brace-icon"
                                >
                                  <Tooltip id="subject-personalize" />
                                  <h1 className="text-[15px] ">
                                    <RiBracesLine className="icon-color"></RiBracesLine>
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* //email body  */}
                          <div className="w-full">
                            <h1 className="color-gray text-[15px] font-medium  mb-1">
                              Email Body :
                            </h1>

                            <div>
                              <div className="relative">
                                <div className="text-editor  text-[15px] ">
                                  <DraftEditor
                                    setDetectFocus={setDetectFocus}
                                    templateBtn={templateBtn}
                                    personalizeBtn={personalizeBtn}
                                    setIsPersonalisePopupOpen={
                                      setIsPersonalisePopupOpen
                                    }
                                    setIsPopupOpenTemplate={
                                      setIsPopupOpenTemplate
                                    }
                                    setSubject={setSubject}
                                    subjectinputRef={subjectinputRef}
                                    templates={templates}
                                    isPopupOpenTemplate={isPopupOpenTemplate}
                                    isPersonalisePopupOpen={
                                      isPersonalisePopupOpen
                                    }
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                    setPreviewEditorState={
                                      setPreviewEditorState
                                    }
                                  />

                                  <UploadAttachment
                                    handleDeleteAttachment={
                                      handleDeleteAttachment
                                    }
                                    uploadedFile={uploadedFile}
                                    uploadProgress={uploadProgress}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="flex items-center justify-between mt-2">
                              <div className="flex">
                                <input
                                  onChange={handleCheck}
                                  checked={isChecked}
                                  type="checkbox"
                                  name="checkbox"
                                  id=""
                                />
                                <h1 className="text-[#5d6a7e] text-[14px] ml-2">
                                  Include Signature
                                </h1>
                              </div>

                              <ModalFooterIcon
                                fromAi={fromAi}
                                handleFileChange={handleFileChange}
                                isPersonalisePopupOpen={isPersonalisePopupOpen}
                                setIsPersonalisePopupOpen={
                                  setIsPersonalisePopupOpen
                                }
                                isPopupOpenTemplate={isPopupOpenTemplate}
                                personalizetooltipid={"two"}
                                setIsPopupOpenTemplate={setIsPopupOpenTemplate}
                                templatetooltipid={"one"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex  items-center mt-2">
                      <div>
                        <Button
                          loading={loading}
                          disabled={loading}
                          onClick={onSubmit}
                          tittle="Save"
                        ></Button>
                        {/* <button
                        onClick={onSubmit}
                        className={`button-background text-white rounded-[4px] h-[32px] px-[12px] text-[15px]`}
                      >
                        Save
                      </button> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      styles.toggled && "hidden"
                    } col-span-1 pt-4 pl-[.8rem]`}
                  >
                    <p className="text-[14px] mt-2 color-gray">
                      This is preview here :
                    </p>

                    <div className="bg-[#f7f9fb] border border-[#d3dae3] p-3 mt-1 rounded-[4px] ">
                      <h1 className=" text-[15px]   mb-1">
                        Subject :{" "}
                        <span className=" text-[15px]">{subject}</span>{" "}
                      </h1>
                      <div></div>

                      <div className=" text-[12px] ">
                        <div className="preview-container">
                          <Editor
                            editorState={previewEditorState}
                            toolbarHidden={true}
                            readOnly={true}
                          />
                        </div>

                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: isChecked ? signatureData : "",
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateStepModal;
