function formatCSV(data) {
  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join("\t"));
  for (const row of data) {
    const values = headers.map((header) => {
      const val = row[header];
      return `"${val}"`;
    });
    csvRows.push(values.join("\t"));
  }
  return csvRows.join("\n");
}
export function downloadCSV(data, filename = "klubstack_sample") {
  const CSV = formatCSV(data);
  var blob = new Blob(["\ufeff", CSV]);
  var url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
