import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import CloseIcon from "@mui/icons-material/Close";
import InputForProspectUpdate from "../Sequence/InputForProspectUpdate";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { IoMdSave } from "react-icons/io";
import { addProspect } from "../../../Services/apiServices";
import GetUser from "../../../Hooks/GetUser";
import { toast } from "react-toastify";
const stringFieldsList = [
  "firstName",
  "secondName",
  "email",
  "companyName",
  "companyWebUrl",
  "city",
  "location",
  "jobTitle",
  "promotion",
  "recentJobChange",
  "companyDescription",
  "ceo",
  "competitor",

  "linkedinUrl",
  "phoneNumber",
  "whatsappNumber",
  "twitterUrl",
  "oneLinePersonalization",
];
const AddProspectDrawer = ({ isOpen, toggleDrawer, fromLeads, sequenceId }) => {
  const [submitLoading, setSubmitLoading] = useState();

  const { register, handleSubmit } = useForm();
  const user = GetUser();
  console.log(fromLeads, sequenceId);
  const onSubmit = async (data) => {
    const payload = {
      userId: user,
      ...data,
    };

    if (fromLeads) {
      payload.sequenceId = sequenceId;
    }

    if (!data.email) {
      toast.error("email is required");
      return;
    }
    try {
      setSubmitLoading(true);
      let response = await addProspect(payload);
      if (response?.data.success) {
        setSubmitLoading(false);
        toast.success("prospect added");
      }
    } catch (error) {
      if (error) {
        console.log(error);
        setSubmitLoading(false);
        toast.error("somethings went wrong");
      }
    }
  };
  return (
    <Drawer
      duration={500}
      overlayOpacity={0}
      open={isOpen}
      direction="right"
      className="shadow-drawer h-screen min-w-[400px] w-[30%] overflow-y-auto  "
      onClose={toggleDrawer}
    >
      <div className="p-4">
        <div className="flex justify-between pt-8">
          <h1 className=" text-muted font-medium">Add Your Prospect</h1>
          <CloseIcon
            onClick={toggleDrawer}
            className="text-[#6d727e] hover:text-blue-dark cursor-pointer"
          />
        </div>
        <div>
          {stringFieldsList.map((item) => (
            <InputForProspectUpdate
              key={item}
              name={item}
              register={register}
              label={item}
            />
          ))}
          <div className="flex mb-8 justify-end items-center mt-4">
            <LoadingButton
              type="submit"
              loading={submitLoading}
              // submitLoading
              onClick={handleSubmit(onSubmit)}
              loadingPosition="start"
              startIcon={<IoMdSave />}
              variant="contained"
              className="btn"
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddProspectDrawer;
