import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai'
import properties from '../../../config/properties';
const AgremnetModal = ({ setShowModal, handleNavigate }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    }

    return (

        <div className="popup">
            <div className="modal-empty-div"></div>
            <div className="content-container min-w-[300px] w-[750px] !top-[10rem]">
                <div className=" w-full  popup-content min-h-full  !bg-white">
                    <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray p-4">
                        <h1 className="primary-heading-color font-semibold">
                            Link Mailbox
                        </h1>
                        <button
                            onClick={() => setShowModal(false)}
                            className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[30px] w-[20px] h-[20px] flex justify-center items-center"
                        >
                            &times;
                        </button>
                    </div>


                    <div className='p-4'>
                        <input placeholder='xyz@gmail.com' type="text" name="" id="" className="w-full text-[13px]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-7 border-[#d3dae3] focus:border-blue-400" />

                        <div className='mt-3'>
                            <input type="checkbox" name="" id="agreement" className='mr-1' checked={isChecked} onChange={handleCheckboxChange} />
                            <label htmlFor="agreement" className='text-[13px] font-medium'>I will comply with KlubStack <a className='text-[#6EC0FD] underline' rel="noreferrer"
                                target="_blank"
                                href='https://www.klubstack.com/klubstacktermsandconditions'>Terms of Services</a>  when sending emails. I agree not to send any spam, unsolicited or harassing emails (commercial or otherwise).</label>

                            <div className='flex mt-3'>
                                <AiOutlineInfoCircle className='text-[20px] mr-2' />
                                <p className='text-[13px] font-medium'>Klubstack use and transfer of information received from


                                    Google APIs to any other app will adhere to

                                    <a className='text-[#6EC0FD] underline' rel="noreferrer"
                                        target="_blank"
                                        href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>    Google API Services User Data Policy</a>

                                    , including the Limited Use requirements.</p>
                            </div>


                        </div>


                        <div className='mt-3'>
                            <button disabled={!isChecked}
                                className={`button-background text-white font-500 hover:bg-[#1991eb]  h-[32px] rounded-[3px] px-[12px] text-[13px]`}
                            >
                                <a

                                    onClick={handleNavigate}
                                    rel="noreferrer"
                                    target="_blank"
                                    href={`${properties.REACT_APP_SERVER_URL}/api/v1/auth/google`}
                                >
                                    Link
                                </a>
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    );
};

export default AgremnetModal;