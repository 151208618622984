import { FormControlLabel } from "@mui/material";
import React from "react";
import { Android12Switch } from "../../../Utils/SwitchIcon";
import { useState } from "react";
import { activeSeq } from "../../../Services/apiServices";

const SingleRow = ({
  singleData,
  user,
  sequenceStats,
  navigateSequence,
  totalLeads,
  index,
}) => {
  const [activeStatus, setActiveStatus] = useState(singleData?.activeStatus);
  // convert the date
  const date = new Date(singleData?.startDate);
  const formattedDate = date.toLocaleDateString("default", {
    day: "numeric",
    month: "short",
  });

  const statusShow = (activeStatus, completed) => {
    if (activeStatus && completed) {
      return <h1 className="text-[#245900] bg-[#def2d0] badge">Completed</h1>;
    } else if (activeStatus && !completed) {
      return <h1 className="text-[#151414] bg-[#F8F814] badge">Running</h1>;
    } else {
      return <h1 className="text-[#900] bg-[#ffdcdc] badge">Disable</h1>;
    }
  };



  const toggleSeqActive = async (event) => {
    setActiveStatus(event.target.checked);
    const payload = {
      userId: user,
      sequenceId: singleData._id,

    };

    await activeSeq(payload)

  };






  return (
    <tr
      className="cursor-pointer stats-color hover:bg-[#F7F9FB] transition-all duration-300"
      key={singleData._id}
    >
      <td className="text-[#999999]">0{index + 1}</td>
      <td
        onClick={() => navigateSequence(singleData?._id)}
        className="text-[13px] cursor-pointer color-blue"
      >
        {singleData?.name}
      </td>
      <td className="text-center stats-color text-[14px] font-semibold">
        {singleData?.numOfProspects ? singleData?.numOfProspects : '0'}
      </td>
      <td className="stats-color text-center text-[14px] font-semibold">
        {formattedDate}
      </td>

      <td className="stats-color text-[14px] text-center font-semibold">
        {sequenceStats.mailStats?.totalDelivered}
      </td>
      <td className="text-center">
        {/* <h1>{parseInt((singleData?.sequenceStats?.mailStats?.clicked / singleData?.numberOfLeads) * 100)}%</h1> */}
        <h1 className="stats-color text-[14px] font-semibold">
          {sequenceStats.mailStats?.clickRate}
        </h1>
        <h1 className="color-gray text-[14px] font-semibold">Clicked</h1>
      </td>
      <td className="text-center">
        {/* <h1>{parseInt((singleData?.sequenceStats?.mailStats?.opened / singleData?.numberOfLeads) * 100)}%</h1> */}
        <h1 className=" stats-color text-[14px] font-semibold ">
          {sequenceStats.mailStats?.openRate}
        </h1>
        <h1 className=" color-gray text-[14px] ">Opened</h1>
      </td>
      <td className="text-center">
        <h1 className="stats-color text-[14px] font-semibold">
          {sequenceStats.mailStats?.replyRate}
        </h1>
        <h1 className=" color-gray text-[14px] ">Replied</h1>
      </td>
      <td className="text-center">
        <h1 className="  stats-color text-[14px] font-semibol">
          {sequenceStats.mailStats?.bouncedRate}
        </h1>
        <h1 className="  color-gray text-[14px]">Bounced</h1>
      </td>
      <td className="text-center">
        {statusShow(singleData.activeStatus, singleData.completed)}
      </td>
      <td className="text-center status">
        <FormControlLabel control={<Android12Switch checked={activeStatus} onChange={toggleSeqActive} />} />

      </td>
    </tr>
  );
};

export default SingleRow;
