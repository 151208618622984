import React, { useEffect, useState } from "react";
import { EmailStatData } from "./EmailStatData";
import SingleEmailStat from "./SingleEmailStat/SingleEmailStat";
import GetUser from "../../../../../Hooks/GetUser.js";
import axios from "axios";
import properties from "../../../../../config/properties";

const Emailstat = () => {
  const user = GetUser();
  const [camapaignStats, setCampaignStats] = useState({});
  const matchEmailStat = {
    "Campaigns": "totalCampaign",
    "Prospects": "totalLeads",
    "Mails Sent": "totalMailsSent",
    "Mails Open": "totalMailsOpen",
    "Clicks": "totalClicks",
    "Replies": "totalReplies"
  }

  useEffect(() => {
    const getCampaignStats = async () => {
      axios
        .get(
          `${properties.REACT_APP_SERVER_URL}/api/v1/sequence/getCampaignStats/${user}`
        )
        .then((response) => {
          if (response.data.status) {
            setCampaignStats(response.data.campaignStats);
          }
        })
        .catch((error) => console.log(error));
    }

    getCampaignStats();
  }, [user]);

  const getStatValue = (title) => {
    return camapaignStats[matchEmailStat[title]]
  }

  return (
    <div>
      <div className="grid grid-cols-6 gap-4">
        {EmailStatData.map((singleData, index) => (
          <SingleEmailStat
            key={singleData?.id}
            singleData={singleData}
            title={Object.keys(matchEmailStat)[index]}
            statValue={getStatValue(Object.keys(matchEmailStat)[index])}
          ></SingleEmailStat>
        ))}
      </div>
    </div>
  );
};

export default Emailstat;
