import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../Hooks/UserContext";
import prospectusEmpty from "../../../Images/prospectus-empty.svg";
import "./Prospectus.css";
import ProspectStats from "./ProspectStats";
import ShowProspectData from "./ShowProspectData";
import Loading from "../../Shared/Loading/Loading";
import { BiCaretDown } from "react-icons/bi";
import HubSpotMatchModal from "./HubSpotMatchModal";
import { posthog } from "posthog-js";
import { useNavigate } from "react-router-dom";
import properties from "../../../config/properties";
import ModalComponenet from "../../../Componenets/ModalComponenet";
import { Button, Grid, Tooltip } from "@mui/material";
import FilterDrawer from "../Sequence/MiniComponent/FilterDrawer";
import { RiContactsFill } from "react-icons/ri";
import AddProspectDrawer from "./AddProspectDrawer";
const Prospectus = () => {
  let [user, setUser] = useState(null);
  const [prospectData, setProspectData] = useState([]);
  const {
    setRefetchSeq,
    reFetch,
    importData,
    importDataModal,
    setImportDataModal,
  } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const selectImportButton = useRef(null);
  const [loading, setLoading] = useState(false);
  // for hubspot match properties
  const [openHubspotModal, setOpenHubspotModal] = useState(false);
  const [hubspotAccessToken, setHubspotAccessToken] = useState("");
  const [hubspotProperties, setHubspotProperties] = useState([]);
  const [toggleFilter, setToggleFilter] = useState(false);
  const navigate = useNavigate();

  function redirectToHubspot() {
    axios
      .post(
        `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/create-hubspot-auth-url`,
        { userId: user }
      )
      .then((res) => {
        window.open(
          res.data.hubspotAuthUrl,
          "_blank" // <- This is what makes it open in a new window.
        );
      });
  }

  // handle click outside
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      // check auth
      const token = localStorage.getItem("token");
      if (token) {
        const user = jwtDecode(token);
        if (!user) {
          localStorage.removeItem("token");
        } else {
          setUser(user.id);

          // check hubspot access token in url
          const params = new Proxy(
            new URLSearchParams(window.location.search),
            {
              get: (searchParams, prop) => searchParams.get(prop),
            }
          );
          // Get the value of "some_key" in eg "https://example.com/?hubspot_token=xxxxxx"
          let hubspot_token = params.hubspot_token; // "hubspot_token"

          if (hubspot_token) {
            setHubspotAccessToken(hubspot_token);
            await axios
              .post(
                `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/get-hubspot-properties`,
                {
                  userId: user.id,
                  hubspotAccessToken: hubspot_token,
                }
              )
              .then((res) => {
                if (res.data.success) {
                  setOpenHubspotModal(true);
                  setHubspotProperties(res.data.hubspotProperties);
                  // window.location.href = res.data.redirect;
                }
              });
          }
          // fetch propspect data
          axios
            .post(
              `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/fetchProspects`,
              { userId: user.id }
            )
            .then((res) => {
              setProspectData(res.data.data);
              setRefetchSeq((prev) => !prev);
              // setSequenceNameObj(res.data.sequenceNameObj);
              setLoading(false);
            });
        }
      }

      const handleClickOutside = (e) => {
        if (
          selectImportButton.current &&
          !selectImportButton?.current.contains(e.target)
        ) {
          setShow(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside, true);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside, true);
      };
    }
    fetchData();
  }, [setRefetchSeq, reFetch]);

  const handleImport = () => {
    navigate("/contact-import");
    posthog.capture("Click Import From Excell sheet Button");
  };

  const handleCloseFilter = () => {
    setToggleFilter(!toggleFilter);
  };

  // for drawer
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  if (loading) {
    return <Loading text={"Loading Your Prospect"}></Loading>;
  }

  return (
    <div className="w-full flex pt-6">
      <div
        className={`w-[20%] transition-all duration-200 ${
          toggleFilter ? "hidden" : "inline-block"
        } border-r border-primary`}
      >
        <FilterDrawer />
      </div>
      <div className={`pl-2 ${toggleFilter ? "w-[100%]" : "w-[80%]"}`}>
        {importDataModal && !loading && (
          <ModalComponenet
            childrenPadding={"1rem"}
            radious={"10px"}
            title={"imported data"}
            width={"50vw"}
            setActiveModal={setImportDataModal}
          >
            <div className="w-[90%] mx-auto">
              <div className=" grid grid-cols-2 gap-8">
                <div>
                  <div className=" shadow-sm  rounded-[0.7em] border border-border-gray flex justify-center items-center p-3 h-full">
                    <div className="rouned-[0.75em]   font-medium flex flex-col  justify-center items-center rounded-[.7em] p-2">
                      <span className="text-[40px]">
                        {importData?.newProspectsCount}
                      </span>
                      <h1 className=" mt-4">New Prospects</h1>
                    </div>
                  </div>
                </div>

                <div>
                  <div className=" shadow-sm rounded-[0.7em] border border-border-gray flex justify-center items-center p-3">
                    <div className=" rouned-[0.75em]   font-medium  flex-col flex justify-center items-center rounded-[.7em] p-2">
                      <span className="text-[40px]">
                        {importData?.updatedProspectsCount}
                      </span>
                      <h1 className=" mt-4">Updated Prospects</h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex justify-center">
                <button
                  onClick={() => setImportDataModal(false)}
                  className="button-normal bg-blue-dark !text-white"
                >
                  Proced
                </button>
              </div>
            </div>
          </ModalComponenet>
        )}

        <div>
          <div className="mb-4 inline-block" onClick={handleCloseFilter}>
            <Tooltip
              title={` ${toggleFilter ? "Open Filter" : "Close Filter"} `}
              arrow
              placement="top"
            >
              <button className="transition-all duration-150 border border-primary rounded-[10px] px-[15px] py-[3px] hover:bg-[#fff] hover:border-blue-500 hover:text-blue-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-adjustments-horizontal"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="1.25"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="w-[15px] h-[16px]"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M4 6l8 0"></path>
                  <path d="M16 6l4 0"></path>
                  <path d="M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M4 12l2 0"></path>
                  <path d="M10 12l10 0"></path>
                  <path d="M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M4 18l11 0"></path>
                  <path d="M19 18l1 0"></path>
                </svg>
              </button>
            </Tooltip>
          </div>

          <div className="mt-4">
            {prospectData?.length !== 0 && (
              <ProspectStats prospectData={prospectData} />
            )}
          </div>
        </div>
        {prospectData?.length !== 0 ? (
          <div ref={selectImportButton} className="mt-[4rem] ">
            <div>
              <Button
                className="bg-[#583cea] btn float-right mb-4 z-[99]"
                onClick={() => {
                  setShow((prev) => !prev);
                  posthog.capture("Click Import Propspect Button", {
                    buttonName: "Import Propspect",
                  });
                }}
                variant="contained"
                endIcon={<BiCaretDown />}
              >
                Import Prospects
              </Button>

              <Button
                onClick={() => {
                  setIsOpen((prevState) => !prevState);
                }}
                className="bg-[#583cea] btn float-right mr-4 z-[99]"
                variant="contained"
                endIcon={<RiContactsFill className="text-[14px]" />}
              >
                Add Prospects
              </Button>
            </div>
            <div
              className={`bg-white ${
                show ? "" : "hidden"
              }  w-[17rem]  border border-color-gray absolute  shadow-favourite     rounded-[6px] p-3  right-4 top-[365px] z-[990]  text-[12px]`}
            >
              <button
                onClick={() => {
                  posthog.capture("Click Import From Crm Button");
                  redirectToHubspot();
                }}
                className="py-[7px] px-[14px] text-[15px] w-full hover:bg-[#f7f7f7] transition-all duration-200 hover:text-black  cursor-pointer font-semibold"
              >
                Import From CRM
              </button>
              <button
                onClick={handleImport}
                className="py-[7px] px-[14px] text-[15px] w-full hover:bg-[#f7f7f7] transition-all duration-200 hover:text-black  cursor-pointer font-semibold"
              >
                Import from Excel sheet
              </button>
              <button
                onClick={handleImport}
                className="py-[7px] px-[14px] text-[15px] w-full hover:bg-[#f7f7f7] transition-all duration-200 hover:text-black  cursor-pointer font-semibold"
              >
                Import from CSV sheet
              </button>
            </div>

            <ShowProspectData data={prospectData} />
          </div>
        ) : (
          <div className="flex justify-center items-center pt-40">
            <div
              ref={selectImportButton}
              className="flex flex-col items-center justify-center relative"
            >
              <Button
                onClick={() => {
                  setIsOpen((prevState) => !prevState);
                }}
                className="bg-[#583cea] btn text-white font-medium mb-4 capitalize"
                variant="contained"
                endIcon={<RiContactsFill className="text-[14px]" />}
              >
                Add Prospects
              </Button>
              <Button
                className="bg-[#583cea] btn text-white font-medium capitalize"
                onClick={() => {
                  setShow((prev) => !prev);
                  posthog.capture("Click Import Propspect Button", {
                    buttonName: "Import Propspect",
                  });
                }}
                variant="contained"
                endIcon={<BiCaretDown />}
              >
                Import Prospects
              </Button>

              <div
                className={`bg-white ${
                  show ? "" : "hidden"
                } absolute  shadow-favourite    w-[17rem] rounded-[6px] p-3`}
              >
                <button
                  onClick={() => {
                    posthog.capture("Click Import From Crm Button");
                    redirectToHubspot();
                  }}
                  className="py-[7px] px-[14px] text-[15px] w-full hover:bg-[#f7f7f7] transition-all duration-200   hover:text-black  cursor-pointer font-semibold"
                >
                  Import From CRM
                </button>
                <button
                  onClick={handleImport}
                  className="py-[7px] px-[14px] text-[15px] w-full hover:bg-[#f7f7f7] transition-all duration-200 hover:text-black  cursor-pointer font-semibold"
                >
                  Import from Excel sheet
                </button>
                <button
                  onClick={handleImport}
                  className="py-[7px] px-[14px] text-[15px] w-full hover:bg-[#f7f7f7] transition-all duration-200 hover:text-black  cursor-pointer font-semibold"
                >
                  Import from CSV sheet
                </button>
              </div>
              <img src={prospectusEmpty} alt="" />
            </div>
          </div>
        )}

        <AddProspectDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        {openHubspotModal && (
          <HubSpotMatchModal
            hubspotProperties={hubspotProperties}
            hubspotAccessToken={hubspotAccessToken}
            setOpenHubspotModal={setOpenHubspotModal}
          ></HubSpotMatchModal>
        )}
      </div>
    </div>
  );
};
export default Prospectus;
