const properties = {
  PORT: process.env.PORT || 3001,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || "development",
  REACT_APP_SERVER_URL:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://api.klubstack.com"
      : "https://api.klubstack.com",
  REACT_APP_CLIENT_URL:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://app.klubstack.com"
      : "https://app.klubstack.com",
  HTTPS: process.env.REACT_APP_ENVIRONMENT === "production" ? true : false,
  REACT_APP_OAUTH_CLIENT_ID:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "1001488136915-g21tr2naanm4505fms3g6o0pgqebp65b.apps.googleusercontent.com"
      : "998655503363-ms5ecsqhikncuiqfgecl3h3fmgl11ibd.apps.googleusercontent.com",
  REACT_APP_OAUTH_CLIENT_KEY:
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "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"
      : "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",
};

export default properties;
