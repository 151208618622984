import React, { useContext, useRef, useState } from 'react';
import ModalComponenet from '../../../Componenets/ModalComponenet';
import { UserContext } from '../../../Hooks/UserContext';
import Button from '../../../Componenets/Button';
import GetUser from '../../../Hooks/GetUser';
import { createSeq } from '../../../Services/apiServices';
import { useNavigate } from 'react-router-dom';
import email from '../../../Images/AI/email-inbox-letter-svgrepo-com.svg'


const SequenceNameModal = () => {
    const { setOpenSequenceModal, selectedTemplate, setSelectedTemplate, reFetch, setRefetch } = useContext(UserContext)

    const [loader, setLoader] = useState(false)

    const navigate = useNavigate()
    const user = GetUser();
    const sequenceRef = useRef()


    const createSequence = async () => {


        if (sequenceRef.current.value !== "" || selectedTemplate !== '') {
            setLoader(true)
            const options = { timeZoneName: 'long' };
            const timezone = new Intl.DateTimeFormat(undefined, options).resolvedOptions().timeZone;
            const payload = {
                userId: user,

                templateName: selectedTemplate,
                sequenceName: sequenceRef.current.value,
                triggerSettings:
                {
                    startTime: "09:00",
                    endTime: "18:00",
                    timeZone: timezone,
                }
            }

            let res = await createSeq(payload)

            if (res.data.success) {
                setLoader(false)
                setRefetch(!reFetch);
                navigate(`/sequence/${res.data.sequenceId}`);
                setOpenSequenceModal(false)
                setSelectedTemplate('')
            }

        }
    };


    return (

        <ModalComponenet childrenPadding={'1rem'} radious={'1rem'} width={'37vw'} title={selectedTemplate} setActiveModal={setOpenSequenceModal}>
            <div className="">


                <div className='mb-3'>
                    <img className="w-[70px] h-[70px] mx-auto" src={email} alt="" />
                    {/* <h1 className=' mt-1'>{selectedTemplate}</h1> */}
                </div>
                <div>
                    <label
                        htmlFor="sequenceName"
                        className="text-[12px] font-semibold mb-1  text-[#5d6a7e] block"
                    >
                        Sequence Name:
                    </label>
                    <input
                        ref={sequenceRef}
                        id="sequenceName"
                        type="text"


                        className="w-full p-2  primary-heading-color font-medium custom-shadow  focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                    />
                </div>

                <div className='mt-3  '>
                    <Button disabled={loader} onClick={createSequence} loading={loader} tittle={'Create'}></Button>

                </div>
            </div>
        </ModalComponenet>

    );
};

export default SequenceNameModal;