import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const LogoSlider = ({ fakeData }) => {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear"
    };








    return (
        <div className='bg-[#E2F8FF] py-20'>
            {/* <img className='w-[130px] h-[130px]' src='https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61b7367c72f2bb76bde931cb_hero-graphic8.png' alt="" /> */}
            <div className='conatiner'>
                <h1 className='langding-page-heading text-black text-[2rem]  mx-auto text-center'>  Join the closed invite only community <br />
                    and be early founding members</h1>
            </div>


            <div className='mt-8 overflow-hidden'>
                <Slider {...settings}>
                    {
                        fakeData.logos.map((image, index) => <div key={index}>
                            <img src={image.link} className='w-[130px] h-[130px]' alt="" />
                        </div>)
                    }

                </Slider>
            </div>
        </div>
    );
};

export default LogoSlider;