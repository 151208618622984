import React, { useContext } from 'react';
import ModalComponenet from '../../../Componenets/ModalComponenet';
import EmailTemplates from '../AItoolkit/EmailTemplates';
import email from '../../../Images/AI/email-inbox-letter-svgrepo-com.svg'
import { UserContext } from '../../../Hooks/UserContext';






const EmailTemplateModal = ({ setOpenEmailTemplate }) => {
    const { setOpenSequenceModal } = useContext(UserContext)

    const handleOpen = () => {
        setOpenSequenceModal(true)
    }
    const EMAIL_TEMPLATES = [
        {
            name: "Invite Community via Email",
            icon: email,
            badge: 'new',
            footerBadge: 'Outbound',
            description: 'Personalizer Craft cold personalized email that focuses on your prospect .',
            onclick: handleOpen

        },

        {
            name: "Invite Community via Email + Linkedin",
            icon: email,
            badge: 'improved',
            footerBadge: 'Outbound',
            description: 'Personalizer Craft cold personalized email that focuses on your prospect .',

            onclick: handleOpen



        },
        {
            name: "Linkedin Connection Automation",
            icon: email,
            badge: 'new',
            footerBadge: 'Outbound',
            description: 'Personalizer Craft cold personalized email that focuses on your prospect .',

            onclick: handleOpen


        },
        {
            name: "8 Step Personalised Invite to community + Email Linkedin",
            icon: email,
            footerBadge: 'Outbound',
            badge: 'new',

            description: 'Personalizer Craft cold personalized email that focuses on your prospect .',

            onclick: handleOpen

        },
        {
            name: "Manual Outbound Linkedin Invite + Send Message Follow",
            icon: email,
            footerBadge: 'Outbound',
            badge: 'new',
            description: 'Personalizer Craft cold personalized email that focuses on your prospect .',

            onclick: handleOpen

        }, {
            name: "Completly Custom",
            icon: email,
            footerBadge: 'Outbound',
            badge: 'new',
            description: 'Personalizer Craft cold personalized email that focuses on your prospect .',

            onclick: handleOpen

        }

    ]
    return (
        <ModalComponenet radious='10px' childrenPadding={'1rem'} setActiveModal={setOpenEmailTemplate} title={' Template'} width='85vw' background='#fff'>
            <EmailTemplates emailTemplates={EMAIL_TEMPLATES} />
        </ModalComponenet>
    );
};

export default EmailTemplateModal;