import React from "react";

const LinkedInTemplate = () => {
  return (
    <div>
      <h1>From linkedin Template</h1>
    </div>
  );
};

export default LinkedInTemplate;
