import React, { useContext, useEffect, useState } from 'react';
import VerifiedPng from '../../../Images/verified.png'
import EmailExist from '../../../Images/email exists.png'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../Hooks/UserContext';


const VerifyTokenModal = ({ responseType }) => {

    const { setRefetch } = useContext(UserContext)
    const navigate = useNavigate()
    const [pathName, setPathname] = useState('')



    useEffect(() => {
        const pathName = localStorage.getItem('importLocation')
        if (pathName) {
            setPathname(pathName)
        }
    }, [])
    const navigateSetting = () => {

        if (pathName) {
            navigate('/setting/mailboxes')
            navigate(pathName)

        } else {
            navigate('/setting/mailboxes')
            setRefetch(prev => !prev)
        }


    }




    return (
        <div>
            <div className="popup">
                <div className="modal-empty-div"></div>
                <div className="content-container mt-3">
                    <div className="bg-white w-[450px] h-[300px] popup-content !rounded-[4px] p-4">
                        <div className='flex justify-center items-center flex-col mt-4'>


                            {
                                responseType ? <img src={VerifiedPng} alt="" /> : <img src={EmailExist} alt="" />
                            }


                            <h1 className=" my-3 primary-heading-color text-[1.1rem] font-semibold">
                                {
                                    responseType ? 'Verified' : 'Email Exists'
                                }
                            </h1>
                            <h1 className='text-[13px] color-gray font-500'>{responseType ? 'Yahoo ! Mailbox linked Successfully' : 'Your Email is already in the list'}
                            </h1>
                            <button onClick={navigateSetting}
                                className={`button-background mt-4 text-white font-500 hover:bg-[#1991eb]  h-[32px] rounded-[3px] px-[12px] text-[13px]`}
                            >
                                {pathName ? 'Go To Sequence' : 'Go to setting'}
                            </button>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyTokenModal;