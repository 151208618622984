import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosDoneAll } from "react-icons/io";
import GetUser from "../../../Hooks/GetUser";
import properties from "../../../config/properties";
import formatDate from "../../../Utils/formatDateTime";
import { fetchNotif, notificationOpen } from "../../../Services/apiServices";
import 'react-loading-skeleton/dist/skeleton.css'
import whiteLoadder from '../../../Images/whiteSpinner.gif'

const NotificationBox = ({ setShowNotification, refetch, setRefetch }) => {
    const [notifications, setNotifications] = useState([]);
    const [loader, setLoader] = useState(false)
    const user = GetUser();
    const navigate = useNavigate();



    //call this when user click on  the icon
    useEffect(() => {
        setLoader(true)

        async function fetchNotification() {
            let result = await fetchNotif(user)
            if (result.data?.status) {

                setNotifications(result.data?.data?.notifications);
                setLoader(false)
            }

        }
        fetchNotification()

    }, [setShowNotification, user, refetch]);



    const openNotification = async (notificationId, redirectUrl, markAllAsRead = false) => {
        let payload = {
            userId: user,
        }
        if (markAllAsRead) {
            payload.openedAll = true;
        } else {
            payload.notificationId = notificationId;
        }
        let response = await notificationOpen(payload)
        if (response.data?.success) {
            setRefetch(!refetch);
            !markAllAsRead && setShowNotification(false);
            if (redirectUrl) {
                redirectUrl = redirectUrl.replace(properties.REACT_APP_CLIENT_URL, "");
                navigate(redirectUrl);
            }
        }
    }



    return (
        <div className="w-[350px] z-20 max-h-[60vh] rounded-[5px] py-2 notification-container  absolute right-7 overflow-y-auto  mt-2 bg-white">
            <div className="flex justify-between p-3">
                <div className="font-medium text-[16px] select-none">Notifications</div>
                <div onClick={() => openNotification('', '', true)} className="flex items-center text-[#42ABEF] font-medium cursor-pointer">
                    <IoIosDoneAll /> <h1 className="text-[.8rem]">Mark as read</h1>
                </div>
            </div>
            <div className=" text-[12px] font-sans leading-5">
                {
                    loader ? <div className="flex justify-center">
                        <img className="w-[100px] h-[100px]" src={whiteLoadder} alt="" />
                    </div> : notifications?.map((eachNotification, index) => {
                        const bgColor = eachNotification.isOpened ? 'bg-white' : ' bg-[#F3F8FC]';

                        return (
                            <div
                                key={eachNotification?._id}
                                onClick={() => openNotification(eachNotification?._id, eachNotification?.redirectUrl)}
                                className={`hover:bg-gray-200 ${index !== notifications.length - 1 ? "border-b-2 border-border-gray" : ""} ${bgColor}`}
                            >
                                <div className="cursor-pointer p-3 select-none">
                                    <h1 className="font-medium text-[14px]">{eachNotification?.text}</h1>
                                </div>
                                <div className="text-xs text-gray-600 pl-5 pb-3">
                                    {formatDate(eachNotification?.createdAt)}
                                </div>
                            </div>
                        );
                    })


                }

            </div>
        </div>
    )
}

export default NotificationBox;