import React, { useState } from 'react';
import { ImInsertTemplate } from 'react-icons/im';
import { RiBracesLine } from 'react-icons/ri';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import { uploadAttachments } from '../../../Services/apiServices';
import GetUser from '../../../Hooks/GetUser';
import properties from '../../../config/properties';

const ModalFooterIcon = ({ setIsPopupOpenTemplate, fromAi, handleFileChange, uploadProgress, templatetooltipid, personalizetooltipid, isPopupOpenTemplate, setIsPersonalisePopupOpen, isPersonalisePopupOpen }) => {





    return (
        <div className='w-[50%]'>

            <ul className="flex gap-4  mt-3 justify-end">
                <li
                    className={`border ${fromAi && 'hidden'} border-[#d3dae3] p-1 rounded-[4px] cursor-pointer  hover:border-blue-500 icon-color hover:text-blue-500`}

                    data-tooltip-id='upload'
                    data-tooltip-content="upload file">
                    <Tooltip id='upload' />

                    <label htmlFor="fileupload">
                        <input style={{ display: 'none' }} onChange={handleFileChange} type="file" name="" id="fileupload" />
                        <AiOutlineCloudUpload className="text-[17px] icon-color font-500 cursor-pointer" />

                    </label>




                </li>
                <li
                    data-tooltip-id={templatetooltipid}
                    data-tooltip-content="template"
                    onClick={() => {
                        setIsPopupOpenTemplate(!isPopupOpenTemplate)

                    }
                    }
                    className="border border-[#d3dae3] p-1 rounded-[4px] cursor-pointer  hover:border-blue-500 icon-color hover:text-blue-500"
                >
                    <Tooltip id={templatetooltipid} />

                    <ImInsertTemplate
                        className="text-[17px] icon-color font-500"
                    ></ImInsertTemplate>
                </li>

                <li

                    data-tooltip-id={personalizetooltipid}

                    data-tooltip-content="personalisation"

                    onClick={() => {

                        setIsPersonalisePopupOpen(
                            !isPersonalisePopupOpen
                        )
                    }
                    }
                    className="border border-[#d3dae3] p-1 rounded-[4px] cursor-pointer  hover:border-blue-500 icon-color hover:text-blue-500"
                >
                    <Tooltip id={personalizetooltipid} />

                    <RiBracesLine
                        className="text-[17px] icon-color font-500 "
                    ></RiBracesLine>
                </li>
            </ul>
        </div>
    );
};

export default ModalFooterIcon;