import React, { useContext, useEffect, useRef, useState } from "react";
import { VscEllipsis } from "react-icons/vsc";
import { AuthContext } from "../../../Hooks/Authcontext";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DeleteSequenceModal from "./DeleteSequenceModal";
import properties from "../../../config/properties";
import { BsPencilFill } from 'react-icons/bs'
import UpdateTimeModal from "./UpdateTimeModal";
import { FiMail } from "react-icons/fi";
import { TiSocialLinkedin } from "react-icons/ti";
import { Tooltip } from "react-tooltip";
import UpdateStepModal from "./UpdateStepModal";
import { FormControlLabel, Menu, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import { UserContext } from "../../../Hooks/UserContext";
import { Android12Switch } from "../../../Utils/SwitchIcon";
import { updateStepData } from "../../../Services/apiServices";


const SequenceStep = ({
  fromAi,

  sequenceId,


  firstTriggerGapDate,
  step,

  index,

  stepStat,
}) => {
  const [show, setShow] = useState(false);
  // const [stepStat, setStepStat] = useState(null);
  const { reFetch, setRefetch } = useContext(UserContext);
  const { user } = useContext(AuthContext);
  const [activeStatus, setActiveStatus] = useState(step.activeStatus);
  const [formattedDate, setFromatedDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const [updateDateModal, setUpdateDateModal] = useState(false);
  const [stepUpdateModalActive, setStepUpdateModalActive] = useState(false)


  // day logic
  const currentDate = new Date(step?.triggerGapDate);
  const firstDate = new Date(firstTriggerGapDate);
  var diff = currentDate.getTime() - firstDate.getTime();
  const diffDays = Math.round(diff / (1000 * 60 * 60 * 24));





  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (!ref?.current?.contains(e.target)) {
      setShow(false);
    } else if (ref?.current?.contains(e.target)) {
      setShow(show);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    const date = new Date(step?.triggerGapDate);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    setFromatedDate(formattedDate);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.addEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on hover change the stats
  const handleItemHover = (statName, statValue, stepid) => {
    if (!stepid || statValue === 0 || !statName || !statValue) {
      return;
    }
    const delivered = stepStat?.delivered;
    const newValue = Math.round((delivered / 100) * statValue);
    const element = document?.getElementById(`${statName}-${stepid}`);
    if (element) {
      element.innerText = newValue;
    }
  };

  const renderSkeletonOrValue = (value, showPercentage = true) => {
    if (value || value === 0) {
      if (value === "NaN") {
        return 0;
      } else {
        return showPercentage ? value + "%" : value;
      }
    } else {
      return <Skeleton />;
    }
  };


  const statusShow = (activeStatus) => {
    if (activeStatus.toLowerCase() === 'completed') {
      return <h1 className="text-[#245900] bg-[#def2d0] badge">Completed</h1>;
    } else if (activeStatus.toLowerCase() === 'running') {
      return <h1 className="text-[#151414] bg-[#F8F814] badge">Running</h1>;
    } else if (activeStatus.toLowerCase() === 'not started') {
      return <h1 className="text-[#900] bg-[#ffdcdc] badge">Not Started</h1>;
    } else {
      return <h1 className="text-white bg-red-500 badge">Disabled</h1>;
    }
  };



  const handleMouseOut = (statName, stepid) => {
    if (!stepid) {
      return;
    }
    const element = document.getElementById(`${statName}-${stepid}`);
    element.innerText = stepStat[statName] + "%";
  };






  const handleToggleChange = async (event) => {
    setActiveStatus(event.target.checked);


    const payload = {
      userId: user,
      sequenceId: sequenceId,
      stepId: step._id,
      stepData: { activeStatus: event.target.checked },
    };

    let response = await updateStepData(payload);

  };


  //for mui menu
  const [anchorElm, setAnchorElm] = useState(null)
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setAnchorElm(null)
    setOpen(false)
  }

  const handleClick = (e) => {
    setAnchorElm(e.currentTarget)
    setOpen(true)

  }



  // delete sequence step 
  const deleteSequenceStep = () => {
    Swal.fire({
      title: 'Are you sure?',
      html: `Are you sure you want to delete step <b>${index + 1}</b>`,
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${properties.REACT_APP_SERVER_URL}/api/v1/step/deleteStep/${user}/${sequenceId}/${step._id}`
          )
          .then((res) => {
            if (res.data.success) {
              Swal.fire(
                'Deleted!',
                'Your step has been deleted.',
                'success'
              );

              setRefetch(!reFetch);
            }

          })
          .catch((error) => {
            Swal.fire('Oops!', 'Something went wrong.', 'error');

          });
      }
    });
  };


  return (
    <div className="mt-8">
      <h1 className="text-[13px]  font-semibold mb-2">Step {index + 1}</h1>
      <div className="bg-white custom-shadow border border-gray  p-4">
        <div className=" border-b-2 border-border-gray pb-1 flex justify-between items-center">
          <div className="flex items-center">
            <div className="flex items-center">
              <div className="flex justify-center items-center w-[30px] mr-4 h-[30px] bg-[#E9F2FF] rounded-full">
                {
                  step.channel.includes("LinkedIn") ? <TiSocialLinkedin className="text-[#1991EB] text-[16px] inline-block" /> : <FiMail className="text-[#1991EB] text-[14px] inline-block" />
                }
              </div>

              <h1 className="text-[13px] font-semibold primary-heading-color flex items-center">
                {step.channel}
              </h1>
            </div>
            {
              !fromAi && <>
                <div className="  day color-gray text-[12px] ml-3 w-[45px] h-[30px] flex justify-center items-center">
                  day {diffDays + 1}

                </div>
                <div className={`text-[12px]  transition duration-200 ease-in-out color-gray  mx-2 h-[30px] flex justify-center items-center`}>
                  {statusShow(step?.status)}
                </div>


                <div
                  data-tooltip-content="Edit Time"
                  data-tooltip-id='EditTime'
                  onClick={() => setUpdateDateModal(true)}
                  className="text-[15px] ml-2 icon-color Ellipsis cursor-pointer"    >
                  <Tooltip id='EditTime' />

                  <BsPencilFill></BsPencilFill>
                </div>


                <div>
                  <h1 className={`${step.thread.toLowerCase() === 'new' ? 'text-white ml-4 bg-[#1fc662] badge-small' : 'text-white ml-4 bg-[#17a2b8] badge-small'}   `}>{step.thread.toLowerCase() === 'new' ? 'new' : 'reply'}</h1>
                </div>
              </>
            }
          </div>

          <div className="cursor-pointer ">
            <div >
              <Tooltip id='ellipsis' />
              <VscEllipsis
                data-tooltip-content="Edit"
                data-tooltip-id='ellipsis'
                onClick={handleClick}
                className="text-[25px] icon-color Ellipsis"
              ></VscEllipsis>
            </div>


            <Menu anchorEl={anchorElm} open={open} onClose={handleClose}>
              <MenuItem onClick={() => {
                handleClose();
                setStepUpdateModalActive(true);
              }}>Edit</MenuItem>
              <MenuItem onClick={() => {
                handleClose();
                deleteSequenceStep();
              }}>Delete</MenuItem>
            </Menu>


          </div>
        </div>
        <div
          style={{ display: "-webkit-box", WebkitBoxOrient: "vertical" }}
          className={`flex justify-between items-center py-6`}
        >
          <div className="flex">
            <FormControlLabel control={<Android12Switch checked={activeStatus} onChange={handleToggleChange} />} />
          </div>

          <div
            className="text-overflow"
            style={{
              width: "500px",
              height: "50px",
            }}
          >
            <div
              className="color-gray text-overflow"
              style={{
                display: "inline-block",
                width: "500px",
                height: "50px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
                whiteSpace: "nowrap", // add this line
              }}
              dangerouslySetInnerHTML={{ __html: step?.template }}
            ></div>
          </div>

          {
            !fromAi && <div>
              <ul className="flex gap-6">
                <li
                  className="text-center cursor-pointer"
                  onMouseEnter={() =>
                    handleItemHover("delivered", stepStat?.delivered, "delivered")
                  }
                >
                  <h1 className="stats-color text-[14px] font-semibold">
                    {" "}
                    {renderSkeletonOrValue(stepStat?.delivered, false)}
                  </h1>
                  <h1 className="color-gray text-[14px]">Delivered</h1>
                </li>
                <li
                  className="text-center cursor-pointer"
                  onMouseOut={() => handleMouseOut("openRate", step?._id)}
                  onMouseEnter={() =>
                    handleItemHover("openRate", stepStat?.openRate, step?._id)
                  }
                >
                  <h1
                    onMouseEnter={() =>
                      handleItemHover("openRate", stepStat?.openRate, step?._id)
                    }
                    className="stats-color text-[14px] font-semibold"
                    id={`openRate-${step?._id}`}
                  >
                    {renderSkeletonOrValue(stepStat?.openRate)}
                  </h1>
                  <h1 className="color-gray text-[14px]">Opened </h1>
                </li>
                <li
                  className="text-center cursor-pointer"
                  onMouseOut={() => handleMouseOut("clickRate", step?._id)}
                  onMouseEnter={() =>
                    handleItemHover("clickRate", stepStat?.clickRate, step?._id)
                  }
                >
                  <h1
                    onMouseEnter={() =>
                      handleItemHover("clickRate", stepStat?.clickRate, step?._id)
                    }
                    className="stats-color text-[14px] font-semibold"
                    id={`clickRate-${step?._id}`}
                  >
                    {renderSkeletonOrValue(stepStat?.clickRate)}
                  </h1>
                  <h1 className="color-gray text-[14px]">Clicked </h1>
                </li>
                <li
                  className="text-center cursor-pointer"
                  onMouseOut={() => handleMouseOut("bouncedRate", step?._id)}
                  onMouseEnter={() =>
                    handleItemHover(
                      "bouncedRate",
                      stepStat?.bouncedRate,
                      step?._id
                    )
                  }
                >
                  <h1
                    onMouseEnter={() =>
                      handleItemHover(
                        "bouncedRate",
                        stepStat?.bouncedRate,
                        step?._id
                      )
                    }
                    className="stats-color text-[14px] font-semibold"
                    id={`bouncedRate-${step?._id}`}
                  >
                    {renderSkeletonOrValue(stepStat?.bouncedRate)}
                  </h1>
                  <h1 className="color-gray text-[14px]">Bounce </h1>
                </li>
                <li
                  className="text-center cursor-pointer"
                  onMouseOut={() => handleMouseOut("replyRate", step?._id)}
                  onMouseEnter={() =>
                    handleItemHover("replyRate", stepStat?.replyRate, step?._id)
                  }
                >
                  <h1
                    onMouseEnter={() =>
                      handleItemHover("replyRate", stepStat?.replyRate, step?._id)
                    }
                    className="stats-color text-[14px] font-semibold"
                    id={`replyRate-${step?._id}`}
                  >
                    {renderSkeletonOrValue(stepStat?.replyRate)}
                  </h1>
                  <h1 className="color-gray text-[14px]">Replied</h1>
                </li>
              </ul>
            </div>
          }



        </div>
      </div>


      {

        stepUpdateModalActive && <UpdateStepModal
          fromAi={fromAi}
          step={step}
          formattedDate={step?.triggerGapDate}
          stepId={step._id}
          allstepData={step}
          index={index}
          sequenceId={sequenceId}
          setStepUpdateModalActive={setStepUpdateModalActive}
        ></UpdateStepModal>
      }




      {
        activeDeleteModal && (
          <DeleteSequenceModal
            stepDelete={true}
            stepId={step._id}
            user={user}
            sequenceId={sequenceId}
            index={index}
            setActiveDeleteModal={setActiveDeleteModal}
          ></DeleteSequenceModal>
        )
      }

      {
        updateDateModal && <UpdateTimeModal formattedDate={step?.triggerGapDate} stepid={step._id} index={index} setUpdateDateModal={setUpdateDateModal}></UpdateTimeModal>
      }
    </div >
  );
};

export default SequenceStep;
