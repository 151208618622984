import React from 'react';
import paintPointLogo from '../../../Images/AI/painpoint.png'

const PainPoint = ({ fakeData }) => {
    return (
        <div className="py-20">
            <div className='conatiner'>
                <h1 className='langding-page-heading text-black text-[2rem]  mx-auto text-center'>
                    If you resonate with these problems as a <br />
                    <span>{fakeData.icp}</span>
                </h1>

                <div className='flex justify-center gap-8 mt-12'>
                    {
                        fakeData.painpoints.map(paintPoint => {
                            return (
                                <div className='painpoint-container w-[14%]'>
                                    <img src={paintPointLogo} alt='paintpoint' className='w-[50px] h-[50px] mx-auto' />
                                    <p className='painpoint-title text-[13px] text-center font-medium  mx-auto mt-4'>{paintPoint.painPoint}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div >
        </div>

    );
};

export default PainPoint;