import React from 'react';
import { AiFillBackward } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ to }) => {

    const navigate = useNavigate()

    const handleBack = (path) => {

        navigate(`${path}`)
    }
    return (
        <div className='mb-3'>
            <button onClick={() => handleBack(to)} className='flex p-1 hover:bg-[#E8EEF4] items-center text-[#1991eb] cursor-pointer text-[14px] font-medium '> <AiFillBackward className='mr-2 ' /> Go Back</button>
        </div>
    );
};

export default BackButton;