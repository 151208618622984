import React, { useContext } from 'react';
import { UserContext } from '../Hooks/UserContext';
import Button from './Button';

const ModalDelete = ({
    title,
    message,
    buttonText,
    onClose,
    onClick,
    isLoading,
    key
}) => {

    const handleConfirm = async () => {

        if (onClick) {
            await onClick();
        }
    };

    return (
        <div key={key} className="popup">
            <div className="modal-empty-div"></div>
            <div className="content-container mt-3">
                <div className="bg-white w-[500px]  popup-content !rounded-[4px] p-4">
                    <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-4">
                        <h1 className="text-[#242d3e] text-[15px] font-semibold">{title}</h1>
                        <button
                            onClick={onClose}
                            className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
                        >
                            &times;
                        </button>
                    </div>

                    <div className="py-4">
                        <h1 className="text-[14px] font-500">{message}</h1>
                    </div>
                    <div className="flex justify-end">
                        <div className="flex">
                            <button
                                onClick={onClose}
                                className={`border border-gray-300 text-gray-500 hover:border-blue-500 hover:text-blue-500 input-border cancel-button-hover mr-4 font-500 h-[32px] px-[12px] rounded-[3px] text-[13px]`}
                            >
                                Cancel
                            </button>

                            <div>
                                <Button
                                    loading={isLoading}
                                    disabled={isLoading}
                                    onClick={handleConfirm}
                                    tittle={buttonText}
                                ></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ModalDelete;