const timeZoneObjects = [
    { label: 'International Date Line West (UTC-12:00)', value: 'International Date Line West (UTC-12:00)' },
    { label: 'Coordinated Universal Time-11 (UTC-11:00)', value: 'Coordinated Universal Time-11 (UTC-11:00)' },
    { label: 'Hawaii (UTC-10:00)', value: 'Hawaii (UTC-10:00)' },
    { label: 'Alaska (UTC-09:00)', value: 'Alaska (UTC-09:00)' },
    { label: 'Pacific Time (US & Canada) (UTC-08:00)', value: 'Pacific Time (US & Canada) (UTC-08:00)' },
    { label: 'Arizona (UTC-07:00)', value: 'Arizona (UTC-07:00)' },
    { label: 'Chihuahua, La Paz, Mazatlan (UTC-07:00)', value: 'Chihuahua, La Paz, Mazatlan (UTC-07:00)' },
    { label: 'Mountain Time (US & Canada) (UTC-07:00)', value: 'Mountain Time (US & Canada) (UTC-07:00)' },
    { label: 'Central America (UTC-06:00)', value: 'Central America (UTC-06:00)' },
    { label: 'Central Time (US & Canada) (UTC-06:00)', value: 'Central Time (US & Canada) (UTC-06:00)' },
    { label: 'Guadalajara, Mexico City, Monterrey (UTC-06:00)', value: 'Guadalajara, Mexico City, Monterrey (UTC-06:00)' },
    { label: 'Saskatchewan (UTC-06:00)', value: 'Saskatchewan (UTC-06:00)' },
    { label: 'Bogota, Lima, Quito (UTC-05:00)', value: 'Bogota, Lima, Quito (UTC-05:00)' },
    { label: 'Eastern Time (US & Canada) (UTC-05:00)', value: 'Eastern Time (US & Canada) (UTC-05:00)' },
    { label: 'Indiana (East) (UTC-05:00)', value: 'Indiana (East) (UTC-05:00)' },
    { label: 'Caracas (UTC-04:30)', value: 'Caracas (UTC-04:30)' },
    { label: 'Asuncion (UTC-04:00)', value: 'Asuncion (UTC-04:00)' },
    { label: 'Atlantic Time (Canada) (UTC-04:00)', value: 'Atlantic Time (Canada) (UTC-04:00)' },
    { label: 'Cuiaba (UTC-04:00)', value: 'Cuiaba (UTC-04:00)' },
    { label: 'Georgetown, La Paz, Manaus, San Juan (UTC-04:00)', value: 'Georgetown, La Paz, Manaus, San Juan (UTC-04:00)' },
    { label: 'Santiago (UTC-04:00)', value: 'Santiago (UTC-04:00)' },
    { label: 'Newfoundland (UTC-03:30)', value: 'Newfoundland (UTC-03:30)' },
    { label: 'Brasilia (UTC-03:00)', value: 'Brasilia (UTC-03:00)' },
    { label: 'Buenos Aires (UTC-03:00)', value: 'Buenos Aires (UTC-03:00)' },
    { label: 'Cayenne, Fortaleza (UTC-03:00)', value: 'Cayenne, Fortaleza (UTC-03:00)' },
    { label: 'Greenland (UTC-03:00)', value: 'Greenland (UTC-03:00)' },
    { label: 'Montevideo (UTC-03:00)', value: 'Montevideo (UTC-03:00)' },
    { label: 'Salvador (UTC-03:00)', value: 'Salvador (UTC-03:00)' },
    { label: 'Coordinated Universal Time-02 (UTC-02:00)', value: 'Coordinated Universal Time-02 (UTC-02:00)' },
    { label: 'Azores (UTC-01:00)', value: 'Azores (UTC-01:00)' },
    { label: 'Cape Verde Is. (UTC-01:00)', value: 'Cape Verde Is. (UTC-01:00)' },
    { label: 'Casablanca (UTC)', value: 'Casablanca (UTC)' },
    { label: 'Coordinated Universal Time (UTC)', value: 'Coordinated Universal Time (UTC)' },
    { label: 'Dublin, Edinburgh, Lisbon, London (UTC)', value: 'Dublin, Edinburgh, Lisbon, London (UTC)' },
    { label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)', value: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)' },
    { label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)', value: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)' },
    { label: 'Brussels, Copenhagen, Madrid, Paris (UTC+01:00)', value: 'Brussels, Copenhagen, Madrid, Paris (UTC+01:00)' },
    { label: 'Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)', value: 'Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)' },
    { label: 'West Central Africa (UTC+01:00)', value: 'West Central Africa (UTC+01:00)' },
    { label: 'Windhoek (UTC+01:00)', value: 'Windhoek (UTC+01:00)' },
    { label: 'Athens, Bucharest (UTC+02:00)', value: 'Athens, Bucharest (UTC+02:00)' },
    { label: 'Beirut (UTC+02:00)', value: 'Beirut (UTC+02:00)' },
    { label: 'Cairo (UTC+02:00)', value: 'Cairo (UTC+02:00)' },
    { label: 'Damascus (UTC+02:00)', value: 'Damascus (UTC+02:00)' },
    { label: 'E. Europe (UTC+02:00)', value: 'E. Europe (UTC+02:00)' },
    { label: 'Harare, Pretoria (UTC+02:00)', value: 'Harare, Pretoria (UTC+02:00)' },
    { label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)', value: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)' },
    { label: 'Istanbul (UTC+03:00)', value: 'Istanbul (UTC+03:00)' },
    { label: 'Jerusalem (UTC+03:00)', value: 'Jerusalem (UTC+03:00)' },
    { label: 'Baghdad (UTC+03:00)', value: 'Baghdad (UTC+03:00)' },
    { label: 'Kaliningrad (UTC+02:00)', value: 'Kaliningrad (UTC+02:00)' },
    { label: 'Kuwait, Riyadh (UTC+03:00)', value: 'Kuwait, Riyadh (UTC+03:00)' },
    { label: 'Nairobi (UTC+03:00)', value: 'Nairobi (UTC+03:00)' },
    { label: 'Moscow, St. Petersburg, Volgograd (UTC+03:00)', value: 'Moscow, St. Petersburg, Volgograd (UTC+03:00)' },
    { label: 'Samara, Ulyanovsk, Saratov (UTC+04:00)', value: 'Samara, Ulyanovsk, Saratov (UTC+04:00)' },
    { label: 'Tehran (UTC+03:30)', value: 'Tehran (UTC+03:30)' },
    { label: 'Abu Dhabi, Muscat (UTC+04:00)', value: 'Abu Dhabi, Muscat (UTC+04:00)' },
    { label: 'Baku (UTC+04:00)', value: 'Baku (UTC+04:00)' },
    { label: 'Port Louis (UTC+04:00)', value: 'Port Louis (UTC+04:00)' },
    { label: 'Tbilisi (UTC+04:00)', value: 'Tbilisi (UTC+04:00)' },
    { label: 'Yerevan (UTC+04:00)', value: 'Yerevan (UTC+04:00)' },
    { label: 'Kabul (UTC+04:30)', value: 'Kabul (UTC+04:30)' },
    { label: 'Ashgabat, Tashkent (UTC+05:00)', value: 'Ashgabat, Tashkent (UTC+05:00)' },
    { label: 'Yekaterinburg (UTC+05:00)', value: 'Yekaterinburg (UTC+05:00)' },
    { label: 'Islamabad, Karachi (UTC+05:00)', value: 'Islamabad, Karachi (UTC+05:00)' },
    { label: 'Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)', value: 'Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)' },
    { label: 'Sri Jayawardenepura (UTC+05:30)', value: 'Sri Jayawardenepura (UTC+05:30)' },
    { label: 'Kathmandu (UTC+05:45)', value: 'Kathmandu (UTC+05:45)' },
    { label: 'Astana (UTC+06:00)', value: 'Astana (UTC+06:00)' },
    { label: 'Dhaka (UTC+06:00)', value: 'Dhaka (UTC+06:00)' },
    { label: 'Yangon (Rangoon) (UTC+06:30)', value: 'Yangon (Rangoon) (UTC+06:30)' },
    { label: 'Bangkok, Hanoi, Jakarta (UTC+07:00)', value: 'Bangkok, Hanoi, Jakarta (UTC+07:00)' },
    { label: 'Novosibirsk (UTC+07:00)', value: 'Novosibirsk (UTC+07:00)' },
    { label: 'Beijing, Chongqing, Hong Kong, Urumqi (UTC+08:00)', value: 'Beijing, Chongqing, Hong Kong, Urumqi (UTC+08:00)' },
    { label: 'Krasnoyarsk (UTC+08:00)', value: 'Krasnoyarsk (UTC+08:00)' },
    { label: 'Kuala Lumpur, Singapore (UTC+08:00)', value: 'Kuala Lumpur, Singapore (UTC+08:00)' },
    { label: 'Perth (UTC+08:00)', value: 'Perth (UTC+08:00)' },
    { label: 'Taipei (UTC+08:00)', value: 'Taipei (UTC+08:00)' },
    { label: 'Ulaanbaatar (UTC+08:00)', value: 'Ulaanbaatar (UTC+08:00)' },
    { label: 'Irkutsk (UTC+08:00)', value: 'Irkutsk (UTC+08:00)' },
    { label: 'Seoul (UTC+09:00)', value: 'Seoul (UTC+09:00)' },
    { label: 'Osaka, Sapporo, Tokyo (UTC+09:00)', value: 'Osaka, Sapporo, Tokyo (UTC+09:00)' },
    { label: 'Darwin (UTC+09:30)', value: 'Darwin (UTC+09:30)' },
    { label: 'Adelaide (UTC+09:30)', value: 'Adelaide (UTC+09:30)' },
    { label: 'Canberra, Melbourne, Sydney (UTC+10:00)', value: 'Canberra, Melbourne, Sydney (UTC+10:00)' },
    { label: 'Brisbane (UTC+10:00)', value: 'Brisbane (UTC+10:00)' },
    { label: 'Hobart (UTC+10:00)', value: 'Hobart (UTC+10:00)' },
    { label: 'Guam, Port Moresby (UTC+10:00)', value: 'Guam, Port Moresby (UTC+10:00)' },
    { label: 'Vladivostok (UTC+11:00)', value: 'Vladivostok (UTC+11:00)' },
    { label: 'Magadan (UTC+12:00)', value: 'Magadan (UTC+12:00)' },
    { label: 'Auckland, Wellington (UTC+12:00)', value: 'Auckland, Wellington (UTC+12:00)' },
    { label: 'Coordinated Universal Time+12 (UTC+12:00)', value: 'Coordinated Universal Time+12 (UTC+12:00)' },
    { label: 'Fiji (UTC+12:00)', value: 'Fiji (UTC+12:00)' },
    { label: "Petropavlovsk-Kamchatsky - Old (UTC+12:00)", value: "Petropavlovsk-Kamchatsky - Old (UTC+12:00)" },
    { label: "Nuku'alofa (UTC+13:00)", value: "Nuku'alofa (UTC+13:00)" },
    { label: "Samoa (UTC+13:00)", value: "Samoa (UTC+13:00)" }
];



const timeZoneMap = {
    "International Date Line West (UTC-12:00)": "Etc/GMT+12",
    "Coordinated Universal Time-11 (UTC-11:00)": "Etc/GMT+11",
    "Hawaii (UTC-10:00)": "Pacific/Honolulu",
    "Alaska (UTC-09:00)": "America/Anchorage",
    "Pacific Time (US & Canada) (UTC-08:00)": "America/Los_Angeles",
    "Arizona (UTC-07:00)": "America/Phoenix",
    "Chihuahua, La Paz, Mazatlan (UTC-07:00)": "America/Chihuahua",
    "Mountain Time (US & Canada) (UTC-07:00)": "America/Denver",
    "Central America (UTC-06:00)": "America/Guatemala",
    "Central Time (US & Canada) (UTC-06:00)": "America/Chicago",
    "Guadalajara, Mexico City, Monterrey (UTC-06:00)": "America/Mexico_City",
    "Saskatchewan (UTC-06:00)": "America/Regina",
    "Bogota, Lima, Quito (UTC-05:00)": "America/Bogota",
    "Eastern Time (US & Canada) (UTC-05:00)": "America/New_York",
    "Indiana (East) (UTC-05:00)": "America/Indiana/Indianapolis",
    "Caracas (UTC-04:30)": "America/Caracas",
    "Asuncion (UTC-04:00)": "America/Asuncion",
    "Atlantic Time (Canada) (UTC-04:00)": "America/Halifax",
    "Cuiaba (UTC-04:00)": "America/Cuiaba",
    "Georgetown, La Paz, Manaus, San Juan (UTC-04:00)": "America/La_Paz",
    "Santiago (UTC-04:00)": "America/Santiago",
    "Newfoundland (UTC-03:30)": "America/St_Johns",
    "Brasilia (UTC-03:00)": "America/Sao_Paulo",
    "Buenos Aires (UTC-03:00)": "America/Argentina/Buenos_Aires",
    "Cayenne, Fortaleza (UTC-03:00)": "America/Cayenne",
    "Greenland (UTC-03:00)": "America/Godthab",
    "Montevideo (UTC-03:00)": "America/Montevideo",
    "Salvador (UTC-03:00)": "America/Bahia",
    "Coordinated Universal Time-02 (UTC-02:00)": "Etc/GMT+2",
    "Azores (UTC-01:00)": "Atlantic/Azores",
    "Cape Verde Is. (UTC-01:00)": "Atlantic/Cape_Verde",
    "Casablanca (UTC)": "Africa/Casablanca",
    "Coordinated Universal Time (UTC)": "Etc/UTC",
    "Dublin, Edinburgh, Lisbon, London (UTC)": "Europe/London",
    "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)": "Europe/Berlin",
    "Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)": "Europe/Belgrade",
    "Brussels, Copenhagen, Madrid, Paris (UTC+01:00)": "Europe/Paris",
    "Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)": "Europe/Warsaw",
    "West Central Africa (UTC+01:00)": "Africa/Lagos",
    "Windhoek (UTC+01:00)": "Africa/Windhoek",
    "Athens, Bucharest (UTC+02:00)": "Europe/Athens",
    "Beirut (UTC+02:00)": "Asia/Beirut",
    "Cairo (UTC+02:00)": "Africa/Cairo",
    "Damascus (UTC+02:00)": "Asia/Damascus",
    "E. Europe (UTC+02:00)": "Europe/Sofia",
    "Harare, Pretoria (UTC+02:00)": "Africa/Harare",
    "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)": "Europe/Helsinki",
    "Istanbul (UTC+03:00)": "Europe/Istanbul",
    "Jerusalem (UTC+03:00)": "Asia/Jerusalem",
    "Baghdad (UTC+03:00)": "Asia/Baghdad",
    "Kaliningrad (UTC+02:00)": "Europe/Kaliningrad",
    "Kuwait, Riyadh (UTC+03:00)": "Asia/Riyadh",
    "Nairobi (UTC+03:00)": "Africa/Nairobi",
    "Moscow, St. Petersburg, Volgograd (UTC+03:00)": "Europe/Moscow",
    "Samara, Ulyanovsk, Saratov (UTC+04:00)": "Europe/Samara",
    "Tehran (UTC+03:30)": "Asia/Tehran",
    "Abu Dhabi, Muscat (UTC+04:00)": "Asia/Dubai",
    "Baku (UTC+04:00)": "Asia/Baku",
    "Port Louis (UTC+04:00)": "Indian/Mauritius",
    "Tbilisi (UTC+04:00)": "Asia/Tbilisi",
    "Yerevan (UTC+04:00)": "Asia/Yerevan",
    "Kabul (UTC+04:30)": "Asia/Kabul",
    "Ashgabat, Tashkent (UTC+05:00)": "Asia/Tashkent",
    "Yekaterinburg (UTC+05:00)": "Asia/Yekaterinburg",
    "Islamabad, Karachi (UTC+05:00)": "Asia/Karachi",
    "Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)": "Asia/Calcutta",
    "Sri Jayawardenepura (UTC+05:30)": "Asia/Colombo",
    "Kathmandu (UTC+05:45)": "Asia/Kathmandu",
    "Astana (UTC+06:00)": "Asia/Almaty",
    "Dhaka (UTC+06:00)": "Asia/Dhaka",
    "Yangon (Rangoon) (UTC+06:30)": "Asia/Yangon",
    "Bangkok, Hanoi, Jakarta (UTC+07:00)": "Asia/Bangkok",
    "Novosibirsk (UTC+07:00)": "Asia/Novosibirsk",
    "Beijing, Chongqing, Hong Kong, Urumqi (UTC+08:00)": "Asia/Shanghai",
    "Krasnoyarsk (UTC+08:00)": "Asia/Krasnoyarsk",
    "Kuala Lumpur, Singapore (UTC+08:00)": "Asia/Kuala_Lumpur",
    "Perth (UTC+08:00)": "Australia/Perth",
    "Taipei (UTC+08:00)": "Asia/Taipei",
    "Ulaanbaatar (UTC+08:00)": "Asia/Ulaanbaatar",
    "Irkutsk (UTC+08:00)": "Asia/Irkutsk",
    "Seoul (UTC+09:00)": "Asia/Seoul",
    "Osaka, Sapporo, Tokyo (UTC+09:00)": "Asia/Tokyo",
    "Darwin (UTC+09:30)": "Australia/Darwin",
    "Adelaide (UTC+09:30)": "Australia/Adelaide",
    "Canberra, Melbourne, Sydney (UTC+10:00)": "Australia/Sydney",
    "Brisbane (UTC+10:00)": "Australia/Brisbane",
    "Hobart (UTC+10:00)": "Australia/Hobart",
    "Guam, Port Moresby (UTC+10:00)": "Pacific/Guam",
    "Vladivostok (UTC+11:00)": "Asia/Vladivostok",
    "Magadan (UTC+12:00)": "Asia/Magadan",
    "Auckland, Wellington (UTC+12:00)": "Pacific/Auckland",
    "Coordinated Universal Time+12 (UTC+12:00)": "Etc/GMT-12",
    "Fiji (UTC+12:00)": "Pacific/Fiji",
    "Petropavlovsk-Kamchatsky - Old (UTC+12:00)": "Asia/Kamchatka",
    "Nuku'alofa (UTC+13:00)": "Pacific/Tongatapu",
    "Samoa (UTC+13:00)": "Pacific/Apia",
};




const modifiedTimeZoneObjects = timeZoneObjects.map((timeZoneObject) => ({
    label: timeZoneObject.label,
    value: timeZoneMap[timeZoneObject.label],
}));






export default modifiedTimeZoneObjects;
