// import { FaUserCircle, FaUsers, FaUserCheck, FaEnvelopeOpenText, FaDollarSign, FaCalendar } from 'react-icons/fa';
import React, { useContext, useEffect, useState } from "react";
import userIcon from "../../../Images/propspect-stat/Account.svg";
import adduser from "../../../Images/propspect-stat/Add User Group Man Man.svg";
import checkUser from "../../../Images/propspect-stat/Checked User Male.svg";
import messege from "../../../Images/propspect-stat/messege.svg";
import { GiPaperPlane } from 'react-icons/gi';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import { MdOutlineUnsubscribe } from 'react-icons/md'
import jwtDecode from "jwt-decode";
import { UserContext } from "../../../Hooks/UserContext";

const ProspectStats = () => {
  const { reFetchSeq, prospectStats, setFilterBy } = useContext(UserContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = jwtDecode(token);
      if (!user) {
        localStorage.removeItem("token");
      } else {
        setUser(user.id);
      }
    }
  }, [reFetchSeq, user]);

  const cards = [
    {
      logoName: <ContactMailIcon sx={{ color: "#99d400" }} />,

      number: prospectStats?.totalProspects,
      des: "Total Prospects",
      bgColor: "bg-orange-100",
    },
    {
      logoName: <CampaignIcon sx={{ color: "#006bff" }} />,

      number: prospectStats?.prospectsInSeq,
      des: "In Campaign",

    },
    {
      logoName: <MarkEmailReadIcon sx={{ color: "#49d5a2" }} />,
      logoColor: "text-purple-500",
      number: "",
      des: "Accepted Invite",
      bgColor: "bg-purple-100",
    },
    {
      logoName: <GiPaperPlane className="text-[20px] text-[#006bff]" />,

      number: prospectStats?.numOfEmailSent,
      des: "Send Email",
      bgColor: "bg-purple-200",
    },

    {
      logoName: <UnsubscribeIcon sx={{ color: "#006bff" }} />,

      number: prospectStats?.numOfUnsubscribedProspects,
      des: "Unsubscribed",

    },

  ];

  const [showClose, setShowClose] = useState(false);

  const handleFilter = () => {
    setFilterBy("activeInSeq");
    setShowClose(true);
  };

  const handleClose = () => {
    setFilterBy(null);
    setShowClose(false);
  };

  return (
    <div className={`grid grid-cols-5 gap-4`}>
      {cards.map((card) => {
        return (
          <div >
            <div
              className={` p-4 rounded-[4px] flex items-center gap-2 box-shadow-main `}
            >
              <div className="font-semibold text-[18px]">
                {card.logoName}
              </div>
              <div>
              </div>
              <div className="font-semibold">
                <h1 className=" text-xl ">{card.number ? card.number : 0}</h1>
                <h1 className=" ">{card.des}</h1>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProspectStats;
