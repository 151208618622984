import React from "react";

const SubjectPersonalize = ({
  subjectPopup,
  handleInsertInput,
  selectSubject,
}) => {
  return (
    <div>
      {subjectPopup && (
        <div className="w-[17rem] border-2 rounded-[6px] border-[#d3dae3] bottom-[-16rem] shadow-personalize z-40 right-0 h-[15.5rem] bg-white absolute">
          <div className="relative h-full w-full">
            <ul className="absolute h-full w-full overflow-auto  scrollbar-container ">
              <li
                onClick={() => handleInsertInput("{{full name}}")}
                className="border-personalize py-2 hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue">
                  {`{{full name}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{first name}}")}
                className="border-personalize py-2 hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue  ">
                  {`{{first name}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{last name}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue  ">
                  {`{{last name}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{company name}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue  ">
                  {`{{comapny name}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{job title}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue  ">
                  {`{{job title}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{CEO name}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue   ">
                  {`{{CEO name}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{location}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue   ">
                  {`{{location}}`}
                </button>
              </li>

              <li
                onClick={() =>
                  handleInsertInput("{{one line personalization}}")
                }
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue   ">
                  {`{{one line personalisation}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{company size}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue   ">
                  {`{{company Size}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{competitor}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue   ">
                  {`{{competitor}}`}
                </button>
              </li>

              <li
                onClick={() => handleInsertInput("{{years in the company}}")}
                className="py-2 border-personalize hover:bg-gray-200 transition duration-200 font-semibold cursor-pointer"
              >
                <button className="text-[11px] color-blue   ">
                  {`{{years in the company}}`}
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubjectPersonalize;
