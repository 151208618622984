import React from "react";
import { RiFileCopy2Line } from "react-icons/ri";
import { GiRegeneration } from "react-icons/gi";
import prospectusEmpty from "../../../Images/prospectus-empty.svg";
import AIimage from '../../../Images/AI/icons8-artificial-intelligence-64.png'


const AiRightSide = ({ aiResponse }) => {
  return (
    <div className="p-4 bg-white">
      <div className="flex justify-end">
        <div className="text-[13px] cursor-pointer w-28 flex items-center custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] hover:border-blue-400">
          <div className="flex items-center">
            <GiRegeneration className="icon-color text-[18px] hover:text-blue-400"></GiRegeneration>
            <span className="ml-2 font-medium text-[12px] color-gray hover:text-blue-400">
              Re Generate
            </span>
          </div>
        </div>
        <div className="text-[13px] ml-3 w-20 cursor-pointer  flex items-center custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] hover:border-blue-400">
          <div className="flex items-center">
            <RiFileCopy2Line className="icon-color text-[18px] hover:text-blue-400"></RiFileCopy2Line>
            <span className="ml-2 font-medium text-[12px] color-gray hover:text-blue-400">
              Copy
            </span>
          </div>
        </div>
      </div>

      <div className="">
        {
          aiResponse ?
            <div className="mt-2 border border-[#d3dae3] bg-[#f7f9fb] p-3  rounded-[4px]">
              {
                aiResponse?.split("\n\n").map((line) => {
                  return (



                    <div>
                      <p>{line}</p>
                      <br />
                    </div>


                  );
                })
              }
            </div>
            :
            <div className="flex justify-center items-center h-full pt-[5rem]">
              <div>
                <img className="w-[100px] h-[100px] mx-auto opacity-30" src={AIimage} alt="" />
                <p className="text-center">Fill out the form on the left to see
                  your generated content here</p>
              </div>
            </div>
        }

      </div>
    </div>
  );
};

export default AiRightSide;
