import React, { useEffect, useState } from "react";
import { AiOutlineDatabase } from "react-icons/ai";
import Select from "react-select";
import { sample_import_data } from "../Constants/sampleImportData";
import ColumnMatch from "../Pages/Dashboard/Prospectus/ColumnMatch";
import Loading from "../Pages/Shared/Loading/Loading";
import { downloadCSV } from "../Utils/csvFileMaker";
import BackButton from "./BackButton";
import CreatableSelect from "react-select/creatable";
import { getListName } from "../Services/apiServices";
import GetUser from "../Hooks/GetUser";
const ImportComponent = ({
  setFileUpload,
  uploadedData,
  handleFileUpload,
  handleImport,
  to,
  showMailbox,
  mailboxes,
  setSelectedOption,
  loader,
  fileUpload,
  setColumnMatch,
  csvColumn,
  selectedListName,
  setSelectedListName,
}) => {
  const user = GetUser();
  const [options, setOptions] = useState([]);
  const handleSelectChange = (newValue, { action }) => {
    if (action === "create-option") {
      console.log("New option created:", newValue);
      setSelectedListName(newValue);
    } else {
      setSelectedListName(newValue);
    }
  };

  useEffect(() => {
    async function listName() {
      try {
        const response = await getListName(user);

        if (response.data.status && response.data.data.length > 0) {
          const newOptions = response.data.data.map((item) => ({
            value: item.name,
            label: item.name,
          }));
          setOptions(newOptions);
        }
      } catch (error) {
        console.error("Error fetching list names:", error);
      }
    }

    listName();
  }, [user]);

  if (loader) {
    return <Loading whiteLoader="whiteLoader"></Loading>;
  }

  return (
    <div className="mt-16">
      <div className="px-4 pt-4">
        <BackButton to={to}></BackButton>
      </div>
      <div className="pb-8 w-full min-h-screen  flex justify-center items-start">
        <div
          className={`${
            fileUpload && "hidden"
          } h-full  justify-center items-center flex pt-[10rem]`}
        >
          <div className=" shadow-favourite bg-white flex justify-center items-cente custom-shadow  p-8">
            <div className="text-center">
              <div className="bg-[#E6D8D8] p-4 rounded-[10px] w-[70px] h-[70px] flex justify-center items-center mx-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-database-plus"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="1.25"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="text-[#E98989] text-[35px] inline-block mt-2"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3"></path>
                  <path d="M4 6v6c0 1.657 3.582 3 8 3c1.075 0 2.1 -.08 3.037 -.224"></path>
                  <path d="M20 12v-6"></path>
                  <path d="M4 12v6c0 1.657 3.582 3 8 3c.166 0 .331 -.002 .495 -.006"></path>
                  <path d="M16 19h6"></path>
                  <path d="M19 16v6"></path>
                </svg>
              </div>
              <h1 className="primary-heading-color text-[1.1rem] font-semibold my-6">
                Import your data into Klubstack
              </h1>

              <label
                htmlFor="upload"
                className="bg-[#583cea]  flex justify-center items-center mx-auto w-[150px] mb-2 text-white font-500 hover:bg-transparent hover:border hover:border-[#583cea] transition-all duration-150 hover:text-[#593cea] h-[35px] rounded-[3px] px-[12px] text-[13px] cursor-pointer"
              >
                Upload File
                <input
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  required
                  type="file"
                  id="upload"
                  style={{ display: "none" }}
                />
              </label>

              <div
                className="text-[12px] text-[#583cea] cursor-pointer mt-4 "
                onClick={() => {
                  downloadCSV(sample_import_data);
                }}
              >
                download sample File
              </div>
            </div>
          </div>
        </div>

        {fileUpload && (
          <div className="w-[95%] mx-auto">
            {/* for matchcolumn  */}
            <div className="matcholumn  bg-white border-border-gray  custom-shadow p-8 w-full">
              <div className="flex gap-4 text-[#242d3e] text-[15px] font-semibold radius-top bg-white border-b-2 border-border-gray pb-4">
                <h1 className="">Column Mappings</h1>

                <h1>{uploadedData} rows uploaded</h1>
              </div>

              <ColumnMatch
                setColumnMatch={setColumnMatch}
                csvColumn={csvColumn}
              ></ColumnMatch>
            </div>

            <div className="matcholumn  mt-4 bg-white border-border-gray  custom-shadow p-8  w-full">
              <div className=" radius-top bg-white border-b-2 border-border-gray pb-4">
                <h1 className="text-[#242d3e] text-[15px] font-semibold">
                  Setting
                </h1>
              </div>

              <div className="flex gap-4 mt-8">
                <div className="flex-1">
                  <label
                    htmlFor="sequenceName"
                    className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                  >
                    What stage would you like to set the contacts to?
                  </label>

                  <select
                    name=""
                    id=""
                    className="w-full text-[13px]  primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                  >
                    <option value="">
                      Update the existing record with information from CSV
                    </option>
                  </select>
                </div>

                <div className="flex-1">
                  <label
                    htmlFor="sequenceName"
                    className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                  >
                    If contacts already exist in Klubstack
                  </label>

                  <select
                    name=""
                    id=""
                    className="w-full text-[13px] primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400"
                  >
                    <option value="">Use Stage from Csv</option>
                  </select>
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="sequenceName"
                  className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                >
                  Auto-assign accounts?
                </label>
                <select
                  name=""
                  id=""
                  className="w-full text-[13px] primary-heading-color font-medium custom-shadow pl-2 focus:outline-none border rounded-[2px] h-7 border-[#d3dae3] focus:border-blue-400"
                >
                  <option value="">
                    Assign/create account based on Website/Email Domain in the
                    CSV
                  </option>
                </select>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="sequenceName"
                  className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                >
                  List Name
                </label>

                <CreatableSelect
                  value={selectedListName}
                  onChange={handleSelectChange}
                  options={options}
                  isClearable
                  placeholder="Enter List Name..."
                />
              </div>

              {showMailbox && (
                <div className="mt-6">
                  <label
                    htmlFor="sequenceName"
                    className="text-[12px] font-semibold mb-2  text-[#5d6a7e] block"
                  >
                    Send Emails From:
                  </label>
                  <Select
                    defaultValue={
                      mailboxes.length > 0
                        ? mailboxes[0]
                        : { label: "", value: "" }
                    }
                    onChange={(selected) => {
                      setSelectedOption(selected);
                    }}
                    options={mailboxes}
                  />
                </div>
              )}

              <div className="flex justify-center gap-8 items-center mt-8">
                <button
                  onClick={() => setFileUpload(false)}
                  className="button-normal  bg-red-600 !text-white !border-0"
                >
                  Cancel
                </button>

                <button
                  onClick={handleImport}
                  className="button-normal bg-blue-dark !text-white"
                >
                  Import
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportComponent;
