import React, { useContext, useState } from 'react';
import BackButton from '../../../Componenets/BackButton';
import { useForm } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import InputField from './InputFiled';
import Button from '../../../Componenets/Button';
import { write7steps } from '../../../Services/apiServices';
import GetUser from '../../../Hooks/GetUser';
import { UserContext } from '../../../Hooks/UserContext';

const SevenStepLeft = ({ setAiresponseSevenStep, loader, setLoader }) => {
    const { setFetch7step } = useContext(UserContext)

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [additionalFields, setAdditionalFields] = useState({
        painPoint: 1,
        communityStats: 1,
        sharedPurpose: 1,
        valueProp: 1,
        caseStudy: 1
    });


    const delay = (time) => {
        let timeoutId;
        const delayPromise = new Promise((resolve) => {
            timeoutId = setTimeout(resolve, time);
        });

        const cleanup = () => {
            clearTimeout(timeoutId);
        };

        return { delayPromise, cleanup };
    };

    const onSubmit = async (data) => {
        let cleanupDelay;
        try {
            setLoader(true);
            const painPointFieldsData = [];
            for (let i = 0; i < additionalFields.painPoint; i++) {
                painPointFieldsData.push(data[`Pain_Point_${i}`]);
            }

            const sharedPurposeFieldsData = [];
            for (let i = 0; i < additionalFields.sharedPurpose; i++) {
                sharedPurposeFieldsData.push(data[`Shared_Purpose_${i}`]);
            }

            const communityStatsFieldsData = [];
            for (let i = 0; i < additionalFields.communityStats; i++) {
                communityStatsFieldsData.push(data[`Community_stats_${i}`]);
            }

            const caseStudyFieldsData = [];
            for (let i = 0; i < additionalFields.caseStudy; i++) {
                caseStudyFieldsData.push(data[`Case_Study_${i}`]);
            }

            const valuePropFieldsData = [];
            for (let i = 0; i < additionalFields.valueProp; i++) {
                valuePropFieldsData.push(data[`Value_Prop_${i}`]);
            }

            const payload = {
                userId: GetUser(),
                communityName: data.Community_Name,
                communityLink: data.Community_Link,
                communityICP: data.Community_ICP,
                activeMembersNames: data.Some_Big_name_who_are_active,
                painPoint: painPointFieldsData,
                communityGoals: sharedPurposeFieldsData,
                valueProposition: valuePropFieldsData,
                communityStats: communityStatsFieldsData,
                caseStudy: caseStudyFieldsData,
            };

            const responsePromise = write7steps(payload);
            const delayPromise = delay(120000);
            cleanupDelay = delayPromise.cleanup;

            const response = await Promise.race([responsePromise, delayPromise.delayPromise]);
            if (response?.data?.data) {
                cleanupDelay();
                setLoader(false);
                setAiresponseSevenStep(response.data.data);
                setFetch7step((prevState) => ({
                    ...prevState,
                    data: response.data.data,
                }));
            }
        } catch (error) {
            console.log(error);
        } finally {
            if (cleanupDelay) {
                cleanupDelay();
            }
        }
    };




    return (
        <div className='p-4'>
            <div className="">
                <BackButton to='/aicontent' />
            </div>
            <form id='aimodel' onSubmit={handleSubmit(onSubmit)}>
                <div className="flex gap-4 mt-1">
                    <div className='w-1/2'>
                        <InputField
                            required={true}
                            label="Community Name"
                            name="Community_Name"
                            register={register}
                        />

                        {errors?.Community_Name && (
                            <p className="text-red-500 text-[12px] mt-1">Community Name is required.</p>
                        )}
                    </div>
                    <div className='w-1/2'>
                        <InputField
                            label="Community Link"
                            name="Community_Link"
                            required={true}

                            register={register}
                        />
                        {errors?.Community_Link && (
                            <p className="text-red-500 text-[12px] mt-1">Community Link is required.</p>
                        )}
                    </div>
                </div>

                <div className="mt-4">
                    <InputField
                        label="Community for ( ICP)"
                        name="Community_ICP"
                        register={register}
                    />
                </div>
                <div className="mt-4">
                    <InputField
                        label="Some Big name who are active"
                        name="Some_Big_name_who_are_active"
                        register={register}
                    />
                </div>




                {[...Array(additionalFields.painPoint)].map((_, index) => (
                    <div className='mt-4 flex items-start'>
                        <InputField
                            setAdditionalFields={setAdditionalFields}
                            additionalFields={additionalFields.painPoint}
                            icon={true}
                            key={index}
                            label={`Pain Point ${index === 0 ? "" : index}`}
                            name={`Pain_Point_${index}`}
                            register={register}
                            stateName={'painPoint'}

                        />


                    </div>

                ))}



                {[...Array(additionalFields.sharedPurpose)].map((_, index) => (
                    <div className='mt-4 flex items-start'>
                        <InputField
                            setAdditionalFields={setAdditionalFields}
                            additionalFields={additionalFields.sharedPurpose}
                            icon={true}
                            key={index}
                            label={`Shared Purpose ${index === 0 ? "" : index}`}
                            name={`Shared_Purpose_${index}`}
                            register={register}
                            stateName={'sharedPurpose'}


                        />



                    </div>

                ))}




                {[...Array(additionalFields.communityStats)].map((_, index) => (
                    <div className='mt-4 flex items-start'>
                        <InputField
                            setAdditionalFields={setAdditionalFields}
                            additionalFields={additionalFields.communityStats}
                            icon={true}
                            key={index}
                            label={`Community stats ${index === 0 ? "" : index}`}
                            name={`Community_stats_${index}`}
                            register={register}
                            stateName={'communityStats'}
                        />

                    </div>

                ))}

                {[...Array(additionalFields.valueProp)].map((_, index) => (
                    <div className='mt-4 flex items-start'>
                        <InputField
                            setAdditionalFields={setAdditionalFields}
                            additionalFields={additionalFields.valueProp}
                            icon={true}
                            key={index}
                            label={`Value Prop ${index === 0 ? "" : index}`}
                            name={`Value_Prop_${index}`}
                            register={register}
                            stateName={'valueProp'}
                        />

                    </div>

                ))}


                {[...Array(additionalFields.caseStudy)].map((_, index) => (
                    <div className='mt-4 flex'>
                        <InputField
                            setAdditionalFields={setAdditionalFields}
                            additionalFields={additionalFields.caseStudy}
                            icon={true}
                            key={index}
                            label={`Case Study ${index === 0 ? "" : index}`}
                            name={`Case_Study_${index}`}
                            register={register}
                            stateName={'caseStudy'}
                        />

                    </div>

                ))}

            </form>


            <div className='mt-4'>
                {
                    loader && <h1 className='text-[14px] text-red-500 mb-2'>Since this is an AI response, it may take some time. Please be patient...</h1>
                }

                <Button loading={loader} disabled={loader} tittle={'Save'} type={'submit'} form='aimodel'>

                </Button>
            </div>

        </div>


    );
};

export default SevenStepLeft;









