import React, { useState, useEffect } from "react";
import downArrow from "../../../Images/downarrow.svg";

const ColumnMatch = ({ csvColumn, setColumnMatch }) => {
  const [optionObj, setOptionObj] = useState({});
  const [matchColumn, setMatchColumn] = useState({});
  const optionConfig = {
    "Full Name": "name",
    "First Name": "firstName",
    "Last Name": "secondName",
    Email: "email",
    "Job Title": "jobTitle",
    "Company Name": "companyName",
    "Years of Experience": "yearsOfExperience",
    "Company Website": "companyWebUrl",
    "Location": "location",
    "City":"city",
    "State":"state",
    "Country":"country",
    "Company size": "companySize",
    "LinkedIn Url": "linkedinUrl",
    "Phone Number": "phoneNumber",
    "Whatsapp Number": "whatsappNumber",
    "Twitter Url": "twitterUrl",
    Pomotion: "promotion",
    "Recent Job change": "recentJobChange",
    "Years in the company": "yersInTheCompany",
    "Company Description": "companyDescription",
    CEO: "ceo",
    Competitor: "competitor",
    "One line personalization": "oneLinePersonalization",
  };
  const [allOptions] = useState(Object.keys(optionConfig));
  useEffect(() => {
    let options = {};
    let allColumns = [];
    csvColumn.map((column) => {
      allColumns.push(column);
    });
    // example: allColumns = ["First Name", "Second Name", "Email"] <= Column Name from excelsheet
    for (let i = 0; i < allColumns.length; i++) {
      options[allColumns[i]] = allOptions;
    }
    setOptionObj({ ...options });
    // example: optionObj = {
    //     "First Name": ["First Name", "Second Name", "Email"],
    //     "Second Name": ["First Name", "Second Name", "Email"],
    //     "Email": ["First Name", "Second Name", "Email"]
    // }
  }, []);
  function updateOptions(index, selectedValue) {
    let allColumns = [];
    let selectedOption = {};
    csvColumn.map((column) => {
      allColumns.push(column);
    });
    for (let i = 0; i < allColumns.length; i++) {
      selectedOption[allColumns[i]] = document.getElementById(
        `${allColumns[i]} ${i}`
      ).value;
    }
    // check if same option selected more than once.
    let selectedOptionsArr = Object.values(selectedOption);
    // example: selectedOptionsArr ['Full Name', 'First Name', 'Email']
    // if email selected twice, selectedOptionsArr ['Full Name', 'First Name', 'Email', 'Email']
    for (let i = 0; i < selectedOptionsArr.length; i++) {
      for (let j = 0; j < selectedOptionsArr.length; j++) {
        if (
          i !== j &&
          i !== index &&
          selectedOptionsArr[i] === selectedOptionsArr[j]
        ) {
          // Select Previous Mapping to Ignore
          selectedOption[allColumns[i]] = "Ignore";
          document.getElementById(`${allColumns[i]} ${i}`).value = "Ignore";
          delete matchColumn[allColumns[i]];
        }
      }
    }
    // example: selectedOption = {
    //     "Full Name": "Full Name",
    //     "Email Address": "Email",
    // }
    // In selectedOption, Keys represents column names of excelsheet and Values represents selected options
    for (let i = 0; i < allColumns.length; i++) {
      if (selectedOption[allColumns[i]] !== "Ignore") {
        matchColumn[allColumns[i]] =
          optionConfig[selectedOption[allColumns[i]]];
      }
    }
    setMatchColumn({ ...matchColumn });
    // example: matchColumn = {
    //     "Full Name": "name",
    //     "Email Address": "email",
    // }
    // In matchColumn, Keys represents column names of excelsheet and Values represents attributes of database schema
    setColumnMatch(matchColumn);
    if (selectedValue === "Ignore") {
      delete matchColumn[Object.keys(optionObj)[index]]; // if selected option is Ignore, then remove from matchColumn
      setMatchColumn({ ...matchColumn });
    }


  }
  return (
    <div className="w-full mt-6">
      <div className="flex w-full">
        <div className="w-full">
          <div className="flex justify-between mb-2">
            <h1 className="text-[14px] font-medium ">
              Column Name in your file
            </h1>
            <h1 className="text-[14px] font-medium ">Klubstack Properties</h1>
            <h1 className="text-[14px] font-medium w-1/2">Example Data</h1>
          </div>
          <div>
            {Object.keys(optionObj)?.map((singleCol, index) => {
              return (
                <div onLoad={() => updateOptions(index)}>
                  <div key={index} className="flex  relative items-center">
                    <div
                      className={` flex items-center my-4 w-[17.6rem]  h-[2rem] `}
                    >
                      <p className="text-[14px]">{singleCol}</p>
                    </div>
                    <div className="relative">
                      <select
                        id={`${singleCol} ${index}`}
                        className={`primary-heading-color w-[15rem] font-medium custom-shadow text-[13px] pl-2 focus:outline-none border rounded-[2px] h-8 border-[#d3dae3] focus:border-blue-400`}
                        onChange={(e) => updateOptions(index, e.target.value)}
                      >
                        <option className="input-item" value="Ignore" selected>
                          Ignore
                        </option>
                        {optionObj[Object.keys(optionObj)[index]].map((i) => {
                          return singleCol
                            .toLowerCase()
                            .includes(i.toLowerCase()) ? (
                            <option
                              key={i}
                              className="input-item"
                              value={i}
                              selected
                            >
                              {i}
                            </option>
                          ) : singleCol
                            .toLowerCase()
                            .includes(i.toLowerCase().split(" ")[0]) ? (
                            <option
                              key={i}
                              className="input-item"
                              value={i}
                              selected
                            >
                              {i}
                            </option>
                          ) : (
                            <option key={i} className="input-item" value={i}>
                              {i}
                            </option>
                          );
                        })}
                      </select>
                      <div className=" absolute right-[.6rem] top-[.7rem]">
                        <img
                          className="w-[9px] h-[9px]"
                          src={downArrow}
                          alt=""
                        />
                      </div>
                    </div>
                    {/* <KlubstackColumn index={index}></KlubstackColumn> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className='ml-20'>
                    <h1 className='text-[17px] font-semibold'>Klubstack Properties</h1>
                    {
                        csvColumn.slice(0, 5).map((singleCsv, index) =>
                            <div key={index} className={`bg-white flex items-center pl-4 w-[17.6rem] my-6 h-[3rem] `}>
                                <p className='text-[14px]'>{singleCsv}</p>
                            </div>
                        )
                    }
                </div> */}
      </div>
    </div>
  );
};
export default ColumnMatch;