import { Button, FormControlLabel, Grid, Menu, MenuItem } from "@mui/material";
import React from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { Android12Switch } from "../../../Utils/SwitchIcon";
import GetUser from "../../../Hooks/GetUser";
import { activeSeq, getSequenceWithSteps } from "../../../Services/apiServices";
import { useState } from "react";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { FcOvertime } from "react-icons/fc";
import { Tooltip } from "react-tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "axios";
import properties from "../../../config/properties";
import Swal from "sweetalert2";
import EditSequence from "./EditSequence";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { UserContext } from "../../../Hooks/UserContext";
import { useContext } from "react";
import FilterDrawer from "./MiniComponent/FilterDrawer";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const SingleSequenceIndex = () => {
  const user = GetUser();
  const { sequenceIdUrl } = useParams();
  const [activeStatus, setActiveStatus] = useState(null);
  const [canTriggerModal, setCantriggerModal] = useState(false);
  // const [fetchSeqWithSteps, setFetchSeqWithSteps] = useState(false)
  const [editSeqModal, setEditSeqModal] = useState(false);
  const [triggerSettings, setTriggerSettings] = useState(null);
  const [sequenceName, setSequencName] = useState(null);
  const [trueWeekDays, setTrueWeekDays] = useState([]);
  const {
    setSequenceId,
    fetchSequenceStep,
    setFetchSequenceStep,
    setStepOneTimeZone,
    selectedTimeZone,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const active = "border-b-2 color-active border-[#255bff]";
  const deactive = "text-[#737791] text-[#262f3ba3]";

  const routes = [
    {
      name: "Analytics",
      route: "",
      end: true,
    },
    {
      name: "Leads",
      route: `leads/${sequenceIdUrl}/prospectsInSeq`,
    },
    {
      name: "Sequence",
      route: "sequence",
    },
    {
      name: "Schedule",
      route: "schedule",
    },
    {
      name: "Options",
      route: "options",
    },
  ];

  useEffect(() => {
    async function getSeqWithsteps() {
      const payload = {
        sequenceId: sequenceIdUrl,
        userId: user,
      };
      let response = await getSequenceWithSteps(payload);
      if (response?.data?.status) {
        setActiveStatus(response.data.data.activeStatus);
        setSequencName(response.data.data.name);

        if (response.data.data.triggerSettings) {
          setTriggerSettings(response.data.data.triggerSettings);

          const weekDays = response?.data?.data?.triggerSettings?.weekDays;
          const enabledWeekDays = [];

          for (let key in weekDays) {
            if (weekDays[key]) {
              enabledWeekDays.push(key);
            }
          }

          setTrueWeekDays(enabledWeekDays);

          const currentTime = DateTime.local();
          const startTime = DateTime.fromFormat(
            response.data.data.triggerSettings.startTime,
            "HH:mm"
          );
          const endTime = DateTime.fromFormat(
            response.data.data.triggerSettings.endTime,
            "HH:mm"
          );

          const currentWeekdayString = currentTime.toLocaleString({
            weekday: "long",
          });

          const isCurrentDayEnabled =
            response.data.data.triggerSettings.weekDays[
              currentWeekdayString.toLowerCase()
            ];

          // Convert the start and end times to the specified timeZone
          const timeZone = response.data.data.triggerSettings.timeZone;
          const startTimeInTimeZone = startTime.setZone(timeZone);
          const endTimeInTimeZone = endTime.setZone(timeZone);
          const currentTimeInTimeZone = currentTime.setZone(timeZone);

          let isWithinTimeRange = false;

          if (startTimeInTimeZone <= endTimeInTimeZone) {
            if (
              currentTimeInTimeZone >= startTimeInTimeZone &&
              currentTimeInTimeZone <= endTimeInTimeZone &&
              isCurrentDayEnabled
            ) {
              isWithinTimeRange = true;
            }
          } else {
            if (
              (currentTimeInTimeZone >= startTimeInTimeZone ||
                currentTimeInTimeZone <= endTimeInTimeZone) &&
              isCurrentDayEnabled
            ) {
              isWithinTimeRange = true;
            }
          }

          setCantriggerModal(!isWithinTimeRange);
        }

        setStepOneTimeZone(response.data.data.triggerSettings?.timeZone);
      }
    }

    getSeqWithsteps();
    setSequenceId(sequenceIdUrl);
  }, [
    sequenceIdUrl,
    user,
    fetchSequenceStep,
    setSequenceId,
    setStepOneTimeZone,
  ]);

  //toggle seq
  const toggleSeqActive = async (event) => {
    setActiveStatus(event.target.checked);
    const payload = {
      userId: user,
      sequenceId: sequenceIdUrl,
    };

    await activeSeq(payload);
  };

  //for mui menu
  const [anchorElm, setAnchorElm] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setAnchorElm(null);
    setOpen(false);
  };

  const handleClick = (e) => {
    setAnchorElm(e.currentTarget);
    setOpen(true);
  };

  const deleteSequence = () => {
    Swal.fire({
      title: "Are you sure?",
      html: `You will not be able to recover <b>${sequenceName}</b> sequence!`,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${properties.REACT_APP_SERVER_URL}/api/v1/sequence/deleteSequence/${user}/${sequenceIdUrl}`
          )
          .then((res) => {
            if (res.data.success) {
              Swal.fire(
                "Deleted!",
                "Your sequence has been deleted.",
                "success"
              );
              navigate("/sequence");
            }
          })
          .catch((error) => {
            Swal.fire("Oops!", "Something went wrong.", "error");
          });
      }
    });
  };

  function formatTime(time) {
    // Create a Luxon DateTime object for the input time
    const dateTime = DateTime.fromFormat(time, "HH:mm");

    // Set the time zone for the DateTime object
    const dateTimeWithTimeZone = dateTime.setZone(triggerSettings.timeZone);

    // Format the DateTime object in the desired time format (HH:mm)
    return dateTimeWithTimeZone.toFormat("h:mm a");
  }

  return (
    <div className="mt-8">
      <div className="flex gap-2 items-center m-2">
        <div
          data-tooltip-content="Back"
          data-tooltip-id="Back"
          onClick={() => navigate("/sequence")}
          className="bg-[#f1f4fc]  cursor-pointer rounded-full w-[40px] h-[40px] flex justify-center items-center"
        >
          <ArrowBackIosNewIcon className="text-[15px]" />
          <Tooltip id="Back" />
        </div>
        <div>
          <h1 className="text-muted">{sequenceName}</h1>
        </div>
      </div>
      <Grid container>
        {/* <Grid item xs={2}>
          <FilterDrawer />
        </Grid> */}
        <Grid item xs={12} className="pl-2">
          <div className="border-b border-border-gray bg-white h-[50px] px-4 flex justify-between">
            <ul className="flex text-[14px] color-gray font-medium gap-8 h-full">
              {routes.map((route) => (
                <NavLink
                  end={route?.end}
                  className={({ isActive }) =>
                    `${
                      isActive ? active : deactive
                    } flex justify-center font-semibold items-center text-[17px] `
                  }
                  to={route.route}
                >
                  {route.name}
                </NavLink>
              ))}
            </ul>

            <div className="flex items-center">
              <div
                data-tooltip-content="Sequence Activation"
                data-tooltip-id="ActiveSeq"
              >
                <Tooltip id="ActiveSeq" />
                <FormControlLabel
                  control={
                    <Android12Switch
                      checked={activeStatus}
                      onChange={toggleSeqActive}
                    />
                  }
                />
              </div>

              <div>
                <Button
                  onClick={handleClick}
                  variant="text"
                  sx={{ color: "#5C6E7B" }}
                >
                  <MoreHorizIcon />
                </Button>

                <Menu anchorEl={anchorElm} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setEditSeqModal(true);
                    }}
                  >
                    Edit Name
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      deleteSequence();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>

          <div className="mt-4 ">
            {/* can trigger modal */}
            {canTriggerModal && (
              <div className="pb-4">
                <div className="bg-[#0b2f73] px-6 py-[10px] min-h-[65px] text-white custom-shadow-1 rounded-[6px] flex justify-between">
                  <div className="flex items-center">
                    <FcOvertime className="text-[40px]" />
                    <div className="ml-4">
                      <div>
                        Sequence will trigger only from{" "}
                        {formatTime(triggerSettings?.startTime)} to{" "}
                        {formatTime(triggerSettings?.endTime)} on following days
                        only
                        <h1 className="inline-block ml-2">
                          {trueWeekDays.map((day, index) => (
                            <span key={index}>{`${day},`}</span>
                          ))}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <button
                      onClick={() => setCantriggerModal(false)}
                      className="text-white hover:text-[#1991eb] ml-4 text-[20px]"
                    >
                      {" "}
                      &times;
                    </button>
                  </div>
                </div>
              </div>
            )}
            <Outlet></Outlet>
          </div>
        </Grid>
      </Grid>

      {editSeqModal && (
        <EditSequence
          setFetchSeqWithSteps={setFetchSequenceStep}
          sequenceId={sequenceIdUrl}
          user={user}
          sequenceName={sequenceName}
          setEditSeqModal={setEditSeqModal}
        ></EditSequence>
      )}
    </div>
  );
};

export default SingleSequenceIndex;
