import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../Hooks/UserContext";
import downArrow from "../../../Images/downarrow.svg";

// Initialization for ES Users
import { Datepicker, Input, initTE, Timepicker } from "tw-elements";

import TimeAndDateUi from "../../../Componenets/TimeAndDateUi";
import { formattingTime } from "../../../Utils/FormateTimeAndDate";
import DateAndTimePicker from "./DateAndTimePicker";
// import { format } from "dayjs";
initTE({ Datepicker, Input });
const TimeintervalModal = ({ setTimeIntervalModal, sequenceData }) => {
  const {
    days,
    setDays,
    hours,
    setHours,
    setActiveStepModal,
    channel,
    setchennel,
    setStepStartDate,
  } = useContext(UserContext);

  // const [selectedTime, setSelectedTime] = useState();
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    // initTE({ Datepicker, Input, Timepicker });
    // try {
    //   let timePickerElement = document.getElementById("step-trigger-time");
    //   let datePickerElement = document.getElementById("step-trigger-date");
    //   const date = new Date();
    //   timePickerElement.value = getParsedDateAndTime(date).time;
    //   datePickerElement.value = getParsedDateAndTime(date).date;
    // } catch (error) { }
  }, [selectedDate]);

  const handleChange = (e) => {
    setchennel(e.target.value);
  };

  // for time
  const handleDaysChange = (event) => {
    if (event.target.value.length <= 2) {
      setDays(event.target.value);
    }
  };

  const handleHoursChange = (event) => {
    if (event.target.value.length <= 2) {
      setHours(event.target.value);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (sequenceData.length === 0) {
      setStepStartDate({
        date: selectedDate.format("DD-MM-YYYY"),
      });
    }

    setTimeIntervalModal(false);
    setActiveStepModal(true);
  };

  return (
    <div>
      <div className="popup">
        <div className="modal-empty-div"></div>
        <div className="content-container w-[40%] mt-4 !overflow-y-visible">
          <div className="bg-white max-w-[900px]  popup-content !rounded-[4px] p-4">
            <div className="flex justify-between items-center radius-top bg-white border-b border-border-gray pb-2">
              <h1 className="primary-heading-color">Add Step</h1>
              <button
                onClick={() => setTimeIntervalModal(false)}
                className="icon-color hover:text-[#1991eb] hover:bg-[#e8eef4] text-[25px] w-[20px] h-[20px] flex justify-center items-center"
              >
                &times;
              </button>
            </div>

            <form className="mt-4" id="intervalForm">
              {sequenceData.length === 0 ? (
                <>
                  <DateAndTimePicker
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                  />
                </>
              ) : (
                <div className="mt-6">
                  <h1 className="color-gray text-[13px] font-semibold">
                    Time Interval
                  </h1>
                  <div className="flex mt-2">
                    <div className="flex items-center">
                      <input
                        type="number"
                        className="w-[7rem] text-center ml-4 h-8 rounded-[4px] text-[14px] input-border days-input"
                        value={days}
                        onChange={handleDaysChange}
                        defaultValue={0}
                      />
                      <h1 className="ml-2 color-gray-2 text-[13px] font-semibold">
                        Days
                      </h1>
                    </div>
                    <div className="flex items-center">
                      <input
                        max={24}
                        type="number"
                        className="w-[7rem] text-center ml-4 h-8 rounded-[4px] input-border text-[14px] hour-input"
                        value={hours}
                        onChange={handleHoursChange}
                      />
                      <h1 className="ml-2 color-gray-2 text-[13px] font-semibold">
                        Hour
                      </h1>
                    </div>
                  </div>
                </div>
              )}
              <div className="!h-full mt-6">
                <label
                  className="color-gray text-[13px] font-semibold"
                  htmlFor="channel"
                >
                  Channel
                </label>
                <select
                  className="w-[16.87rem] overflow-auto  scrollbar-container relative ml-4 h-9 rounded-[4px] text-[13px] input-border"
                  id="channel"
                  style={{ maxHeight: "10rem", overflowY: "scroll" }}
                  value={channel}
                  onChange={handleChange}
                >
                  <option disabled className="text-[13px]">
                    Automated Tasks
                  </option>
                  <option value="Automated Email">Automated Email</option>
                  <option value="Automated LinkedIn Connection">
                    Automated LinkedIn Connection
                  </option>
                  <option value="Automated LinkedIn Messege">
                    Automated LinkedIn Messege
                  </option>
                  <option value="Automated LinkedIn Profile">
                    Automated LinkedIn View Profile
                  </option>
                  <option disabled></option>
                  <option
                    id="disabledOption"
                    className="pt-4 inline-block text-[13px]"
                    disabled
                  >
                    Manual Tasks
                  </option>
                  <option value="Manual Cold Email">Manual Cold Email</option>
                  <option value="Manual LinkedIn Connection">
                    Manual LinkedIn Connection
                  </option>
                  <option value="Manual LinkedIn Messege">
                    Manual LinkedIn Messege
                  </option>
                  <option value="Manual LinkedIn Comment">
                    Manual LinkedIn Comment
                  </option>
                </select>
                <div className="inline-block absolute ml-[-1.8rem] mt-[0.8rem]">
                  <img className="w-[11px] h-[11px]" src={downArrow} alt="" />
                </div>
              </div>
              <button
                onClick={handleSave}
                form="intervalForm"
                className="button-background text-white rounded-[4px] h-[32px] px-[12px] text-[13px] mt-8"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeintervalModal;
