import { FormControlLabel } from '@mui/material';
import React from 'react';
import { Android12Switch } from '../../../../Utils/SwitchIcon';


const OptionsComponent = ({ heading, description, PauseCircleIcon, checked, handleChange }) => {
    return (
        <div className='p-6 shadow-medium mt-8'>
            <div className='flex gap-4 items-center'>
                <div className='w-[50px] h-[50px] rounded-full bg-blue-dark flex justify-center items-center'>
                    <PauseCircleIcon className='text-white text-[30px]' />
                </div>
                <h1 className='text-[18px] font-semibold'>{heading}</h1>
            </div>
            <h1 className='py-4 text-muted font-[400] lowercase'>{description}</h1>

            <div>
                <FormControlLabel control={<Android12Switch checked={checked} onChange={handleChange} />} />
            </div>
        </div>
    );
};

export default OptionsComponent;