import React from 'react';
import vimage from '../../../Images/V-sign.png'
import chillimage from '../../../Images/v2.png'

const HeroSection = ({ fakeData }) => {
    return (
        <div className='h-full bg-[#007671]  '>
            <div className='conatiner mx-auto h-full'>
                <h1 className='langding-page-heading text-[1.5rem] text-white pt-4'>{fakeData?.communityName}</h1>
                <div className='flex justify-center items-center h-full'>
                    <div >
                        <div className='h-full flex justify-center items-center max-w-[1268px] mx-auto'>
                            <div className='flex gap-4 justify-center items-center h-full w-[940px] mx-auto'>
                                <img src={chillimage} className='w-[130px] h-[130px] mt-[4rem]' alt="" />
                                <div className='text-center'>
                                    <h1 className='langding-page-heading text-[4rem] text-white leading-none'>The community for <br />
                                        <span className='large-underline'>{fakeData?.icp}</span>

                                    </h1>
                                    <p className='my-8 text-white w-[80%] mx-auto'>
                                        When you join The Community Club, our community of community professionals becomes an extension of your own Community team. Come for the events, resources, and programs. Stay for the people.
                                    </p>

                                    <button className='landing-btn mx-auto text-xl'>Join The Community</button>


                                </div>
                                <img src={vimage} className='w-[130px] h-[130px] mb-[3rem]' alt="" />
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-8 w-[940px] mx-auto mt-16 ">
                            <div className='flex'>
                                <div className='w-[6px] h-full bg-[#70e3b0] mr-2'></div>
                                <div>
                                    <p className='text-white'> <strong>Find a mentor</strong> who can be a trusted partner through your community journey</p>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='w-[6px] h-full bg-[#70e3b0] mr-2'></div>
                                <div>
                                    <p className='text-white'> Find a mentor who can be
                                        a trusted  partner through
                                        your fundraising journey</p>
                                </div>
                            </div><div className='flex'>
                                <div className='w-[6px] h-full bg-[#70e3b0] mr-2'></div>
                                <div>
                                    <p className='text-white'> Attend our events for detailed
                                        knowledge from community
                                        experts across industries
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default HeroSection;