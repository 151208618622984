import React from "react";
import InputField from "../AItoolkit/InputFiled";
import { useForm } from "react-hook-form";
import CardView from "./CardView";

const LandingPageForm = () => {
  const { register, handleSubmit, watch } = useForm();

  // Watch the value of the radio button input
  const selectedCardValue = watch("flexRadioDefault");
  const onSubmit = (data) => {
     // Access the selected radio option
     const selectedOption = data.flexRadioDefault;
     console.log(selectedOption);
 
     // Handle the rest of the form submission
     console.log(data);
   
    
  };
  return (
    <div className="p-4">
      <div className="flex w-[50%] gap-4">
        <InputField
          label="Community Name"
          name="Community_Name"
          register={register}
        />

        <InputField
          label="Community Invite Lnik"
          name="Community_Invite_link"
          register={register}
        />
      </div>

      <div className="mt-4">
        <h1 className="text-[12px] font-semibold  text-[#5d6a7e] block">
          Type of Community
        </h1>
        <div className="mt-4 flex gap-4">
         <CardView register={register} cardTitle={'Closed Community'} CardDes={'Need approval of the admin to join the community'} />
         <CardView register={register} cardTitle={'Opened Community'} CardDes={'Need approval of the admin to join the community'} />
         <CardView register={register} cardTitle={'Paid Community'} CardDes={'Need approval of the admin to join the community'} />
        </div>
      </div>

   
    </div>
  );
};

export default LandingPageForm;
