import React from "react";

const InputForProspectUpdate = ({ name, register, label }) => {
  return (
    <div className="mt-6 ">
      <label
        htmlFor={name}
        className="text-muted uppercase inline-block font-normal mb-2 text-[12px]"
      >
        {label.split(/(?=[A-Z])/).join(" ")}
      </label>

      <input
        required={name === "email"}
        {...register(name)}
        id={name}
        type="text"
        className="border-bottom-input w-full text-[#495057]"
      />
    </div>
  );
};

export default InputForProspectUpdate;
