import React, { useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "react-select";
import { TimeInterval } from "../../../Utils/AllTimeInterval";
import "./DateAndTimePicker.css";

const DateAndTimePicker = ({
  setSelectedDate,

  selectedDate,
}) => {
  return (
    <div>
      <label
        htmlFor="hourLimit"
        className="text-[13px] font-semibold mb-2  text-[#5d6a7e] block"
      >
        Date
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            format="DD-MM-YYYY"
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default DateAndTimePicker;
