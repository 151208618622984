import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './unsubscribe.css';
import { unsubscribeEmail } from '../Services/apiServices';
import { toast } from 'react-toastify';
import Loading from '../Pages/Shared/Loading/Loading';
const Unsubscribe = () => {
    let { prospectId } = useParams();
    const [loader, setLoader] = useState(true);


    useEffect(() => {
        const unsubscribeUser = async () => {
            const payload = {
                prospectId: prospectId,
                reason: 'no_longer_want',
            };

            try {
                let response = await unsubscribeEmail(payload);
                if (response?.data?.success) {
                    toast.success(response?.data?.message);
                }
            } catch (error) {
                console.log('Unsubscribe API error:', error);
                toast.error("Somethings went wrong");

            } finally {
                setLoader(false);
            }
        };

        unsubscribeUser();
    }, [prospectId]);

    if (loader) {
        return <Loading text={'Loading Please Wait'} />;
    }

    return (
        <div className='h-screen pt-[10rem]'>

        </div>
    );
};

export default Unsubscribe;
