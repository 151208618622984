import React from 'react';
import { BsArrowRight } from 'react-icons/bs'

const BtnLanding = ({ title, background, color }) => {
    return (
        <button className='button-sm flex items-center' style={{
            backgroundColor: background,
            color: color
        }} >
            {title} <BsArrowRight className='ml-2' />

        </button>
    );
};

export default BtnLanding;
