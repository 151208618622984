import React from 'react';
import './LandingPage.css';
import HeroSection from './HeroSection';
import LogoSlider from './LogoSlider';
import PainPoint from './PainPoint';
import ValueProp from './ValueProp';

// Get {{heading}} Updates
const fakeData = {

    "communityName": "SaaS Fundraising Club",
    "communityLink": "app.klubstack.com",
    "communityType": {
        "type": "Closed Community",
        "description": "Need Approval of Admin to Join"
    },
    "icp": "SaaS Founder",
    "painpoints": [
        {
            "painPoint": 'Sending personalised cold mails to VCs is hard'
        },
        {
            "painPoint": 'VC Fundraising is hard'
        },

        {
            "painPoint": 'Finalising Pitch to investors is hard'
        }
    ],

    "valueProp": [
        {
            title: 'Education',
            background: '#efedff',
            description: 'Talk to other SaaS founders who are raising funds and increase your awareness about current deal scenarios and process to get to the top',
            btnBg: '#693cf3',
            color: '#fff',
            btnTittle: 'Start the Program'

        },
        {
            title: 'Free Resources',
            background: '#fef5c6',
            description: 'Take the next step in your fundraising by learning basics of fundraising,, prospecting and  how you send cold email to VCs  and get them to meeting',
            btnBg: '#fdab00',
            color: '#1b2644',
            btnTittle: 'Get Free Resource'


        }, {
            title: 'Mentorship program',
            background: '#ffeae0',
            description: 'Talk to SaaS founders and VCs who have raised funds or invested successfully, talking to them 1:1 and get to know how good was the process',
            btnBg: '#ff6e76',
            color: '#1b2644',
            btnTittle: 'Get Free Resource'

        },
        {
            title: 'Workshop',
            background: '#d1fadf',
            description: 'Talk to SaaS founders and VCs who have raised funds or invested successfully, talking to them 1:1 and get to know how good was the process',
            btnBg: '#70e3b0',
            color: '##1b2644',
            btnTittle: 'Get WorkShop Updates'

        },
        {
            title: 'Workshop',
            background: '#d1fadf',
            description: 'Talk to SaaS founders and VCs who have raised funds or invested successfully, talking to them 1:1 and get to know how good was the process',
            btnBg: '#70e3b0',
            color: '##1b2644',
            btnTittle: 'Get WorkShop Updates'

        },
        {
            title: 'Free Resources',
            background: '#fef5c6',
            description: 'Take the next step in your fundraising by learning basics of fundraising,, prospecting and  how you send cold email to VCs  and get them to meeting',
            btnBg: '#fdab00',
            color: '#1b2644',
            btnTittle: 'Get Free Resource'


        }
    ],
    "membersCount": [],
    "logos": [{
        link: 'https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61b71e10f88d96056022556c_invision%20logo.svg',

    },
    {
        link: 'https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61f4759bc0ff034c0c0dcf55_logo-twilio-p-500.png',

    }, {
        link: 'https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61f4754c267a7b61fb489360_logo-google-p-500.png',

    }, {
        link: 'https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61b71e10f88d96608f22556d_spendesk%20logo.svg',

    }, {
        link: 'https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61b71e10f88d9663f62254cc_Notion%20logo.svg',

    }, {
        link: 'https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61b71e10f88d96056022556c_invision%20logo.svg',

    }, {
        link: 'https://uploads-ssl.webflow.com/61b71e10f88d963c49225496/61b71e10f88d96056022556c_invision%20logo.svg',

    },],
    pitch:{
        text:"The Community Club is the first place I go when I'm looking to brainstorm or bounce ideas around, and their content and conversations are staples for my professional development.",
        
    },
    user:{
        name:'Sumon Bala',
        jobTitle:'Bekar',
        logo:'https://media.licdn.com/dms/image/C5603AQEwl8xzlZ1OpQ/profile-displayphoto-shrink_800_800/0/1654705764589?e=2147483647&v=beta&t=HM2gUI4uce7EjsLO-eNonCe4YDmJrg-To-YKOrjjHno'

    }

}



const LandingPageUi = () => {
    return (
        <div className='h-screen'>
            <div className='mx-auto h-full'>
                <HeroSection fakeData={fakeData} />
                <LogoSlider fakeData={fakeData} />
                <PainPoint fakeData={fakeData} />
                <ValueProp fakeData={fakeData} />
            </div>
        </div>
    );
};

export default LandingPageUi;