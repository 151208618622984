import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import jwtDecode from "jwt-decode";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import {
  BsFillBarChartFill,
  BsBarChartSteps,
  BsPeopleFill,
} from "react-icons/bs";
import { CgTemplate } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { posthog } from "posthog-js";
import { Tooltip } from "react-tooltip";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Diversity1 from "@mui/icons-material/Diversity1";
import logo from "../../../Images/klubstack_logo.png";
const sidebarItems = [
  {
    to: "/",
    icon: <QueryStatsIcon className="text-[20px]  " />,
    tooltip: "Dashboard",
    posthogEvent: "Enter Dashboard Page",
    buttonName: "Dashboard in Sidebar",
  },
  {
    to: "sequence",
    icon: <BsBarChartSteps className=" text-[20px]" />,
    tooltip: "Sequence",
    posthogEvent: "Enter Sequence Page",
    buttonName: "Sequence in Sidebar",
  },
  {
    to: "aicontent",
    icon: <AiOutlineThunderbolt className=" text-[20px]" />,
    tooltip: "AI Content",
  },
  {
    to: "prospects",
    icon: <BsPeopleFill className=" text-[20px]" />,
    tooltip: "Prospects",
    posthogEvent: "Enter Prospect Page",
    buttonName: "Prospect in Sidebar",
  },
  {
    to: "template",
    icon: <TextSnippetIcon className=" text-[20px]" />,
    tooltip: "Template",
  },
  {
    to: "people-database",
    icon: <Diversity1 className=" text-[20px]" />,
    tooltip: "People Database",
  },
  {
    to: "setting",
    icon: <FiSettings className=" text-[20px]" />,
    tooltip: "Settings",
  },
];

const Sidebar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // check auth
    const token = localStorage.getItem("token");
    if (token) {
      const user = jwtDecode(token);
      if (!user) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const active =
    " flex text-[#006bff] items-center px-4  py-4 bg-[#f1f9ff] rounded-[2px] transition-all duration-200";
  const deactive =
    " flex  items-center px-4  py-4  rounded-[12px] transition-all duration-200";

  // const handleClick = (event, posthogEvent, buttonName) => {
  //   event.preventDefault();
  //   if (posthogEvent) {
  //     posthog.capture(posthogEvent, {
  //       buttonName,
  //     });
  //   }
  // };

  return (
    <div className="bg-white ">
      <div className="min-h-[100vh]  h-[100%]">
        <div className="flex justify-center items-center mt-4">
          <img
            onClick={() => navigate("/")}
            src={logo}
            className=" h-[2.5rem] w-[2.5rem] cursor-pointer"
            alt="logo of auto-commm"
          />
        </div>

        <ul className="font-500 px-5 mt-6">
          {sidebarItems.map((item, index) => (
            <li className={` mt-2 transition-all duration-200  `} key={index}>
              <NavLink
                data-tooltip-content={item.tooltip}
                data-tooltip-id={item.tooltip}
                className={({ isActive }) => (isActive ? active : deactive)}
                to={item.to}
              >
                <Tooltip style={{}} place="right" id={item.tooltip} />
                {item.icon}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
