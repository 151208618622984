import React from 'react';
import slack from '../../../Images/AI/Slack_ipng.png'
import EmailTemplates from './EmailTemplates';

const TEMPLATES = [
    {
        name: "Welcome and Orient New Members",
        icon: slack,
        badge: "Development",
        footerBadge: "Onboard",
        description:
            "Implement a sequence of messages to welcome new members and ensure that they feel at home in your Slack community.",
    },
    {
        name: "Onboard Members with a Survey",
        icon: slack,
        badge: "New",
        footerBadge: "Onboard",
        description:
            "Create a comprehensive 15-day process that includes surveys and targeted messaging to onboard new members.",
    },
    {
        name: "Channel Introductions for New Members",
        icon: slack,
        badge: "New",
        footerBadge: "Onboard",
        description:
            "Greet and introduce new members to specific channels upon joining your Slack community.",
    },
    {
        name: "Inactive Member Alerts",
        icon: slack,
        badge: "Development",
        footerBadge: "Alerts",
        description:
            "Configure a Slack notification to receive alerts when members who were active last month become inactive.",
    },
    {
        name: "New Member Alerts",
        icon: slack,
        badge: "Development",
        footerBadge: "Alerts",
        description:
            "Configure a Slack notification to receive alerts when new members join your community.",
    },
    {
        name: "Request Feedback",
        icon: slack,
        badge: "Development",
        footerBadge: "Engage",
        description:
            "Solicit feedback from members about their experiences with the community one month after they join.",
    },
    {
        name: "Promote Member Engagement",
        icon: slack,
        badge: "Development",
        footerBadge: "Engage",
        description:
            "Encourage new members to get involved and participate in the community.",
    },
    {
        name: "Recognize Top Contributors",
        icon: slack,
        badge: "Development",
        footerBadge: "Engage",
        description:
            "Show appreciation for the community members who have been particularly active since joining.",
    },
    {
        name: "Express Gratitude to Top Contributors",
        icon: slack,
        badge: "Development",
        footerBadge: "Engage",
        description:
            "Send a message of appreciation and a thank-you note to the top contributors.",
    },
];



const Slack = () => {
    return (
        <div>
            <EmailTemplates slackButton={true} emailTemplates={TEMPLATES} />
        </div>
    );
};

export default Slack;