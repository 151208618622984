import icon1 from "../../../../../Images/sequence/Subtract.svg";
import { BsReply } from 'react-icons/bs'
import { GiPaperPlane } from 'react-icons/gi';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
export const EmailStatData = [
  {
    image: <CampaignIcon sx={{ color: "#006bff" }} />,
    id: "1",
    bg: "#FFE2E5",
  },
  {
    image: <ContactMailIcon sx={{ color: "#99d400" }} />,
    id: "2",
    bg: "#FFF4DE",
  },
  {
    image: <GiPaperPlane className="text-[20px] text-[#006bff]" />,
    id: "3",
    percentage: 0.5,
    bg: "#F9F3FF",
  },
  {
    image: <MarkEmailReadIcon sx={{ color: "#006bff" }} />,
    id: "4",
    percentage: 0.5,
    bg: "#F3E8FF",
  },

  {
    image: <AdsClickIcon sx={{ color: "#006bff" }} />,
    percentage: 0.5,
    id: "6",
    bg: "#49d5a2",
  }, {
    image: <BsReply className="text-[20px] text-[#49d5a2]" />,
    percentage: 0.5,
    id: "6",
    bg: "#49d5a2",
  },
];
