import { useState, useEffect, useContext } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import axios from "axios";
import Loading from "../../Shared/Loading/Loading";
import { UserContext } from "../../../Hooks/UserContext";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useRef } from "react";
import { posthog } from "posthog-js";
import properties from "../../../config/properties";
import Select from "react-select";
import ProspectSenderEmailModal from "./ProspectSenderEmailModal";
import ProspectTable from "./ProspectTable";
import useDebounce from "../../../Hooks/useDebounce";
import { useCallback } from "react";
import ModalComponenet from "../../../Componenets/ModalComponenet";
import ListNameModal from "./ListNameModal";
import GetUser from "../../../Hooks/GetUser";
import { AiOutlineCaretDown } from "react-icons/ai";
import AddToListModal from "./AddToListModal";
import { Checkbox, Popover } from "@mui/material";
import Swal from "sweetalert2";
import { deleteProspect } from "../../../Services/apiServices";

const ShowProspectData = () => {
  const [selectedProspects, setSelectedProspects] = useState([]);
  // one propspect
  const [oneProspect, setOneProspect] = useState("");
  const [loading] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [fethcDetailsStats, setFetchDetailsStats] = useState(false);

  const {
    setRefetch,

    prospectStats,
    filterBy,
    setFilterBy,
    setProspectStats,
    openListModal,

    filterQuery: query,
  } = useContext(UserContext);

  const [activeDeleteModal, setactiveDeleteModal] = useState(false);
  const [senderEmailModalShow, setSenderEmailModalShow] = useState(false);
  // prospect  data
  const [result, setResult] = useState(null);
  // for sequence name
  const [sequenceNameObj, setSequenceNameObj] = useState({});
  // skipFrom decides where to start fetching data from
  const [skipFrom, setSkipFrom] = useState(0);
  const [loadData, setLoadData] = useState(false);
  const [activePage, setActivePage] = useState(() => {
    const savedPage = localStorage.getItem("currentPage");
    const parsedSavedPage = parseInt(savedPage);
    setSkipFrom(20 * (savedPage - 1));
    return isNaN(parsedSavedPage) ? 1 : parsedSavedPage;
  });

  // for filter
  const [filterByStage, setFilterByStage] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [totalActiveData, setTotalActiveData] = useState([]);
  const [numberOfSelectedItem, setNumberOfSelectedItem] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [refetchProspect, setRefetchProspect] = useState(false);
  const [showNumOfAddedSeq, setShowNumOfAddedSeq] = useState(null);
  const [showNumOfAddedSeqModal, setShowNumOfAddedSeqModal] = useState(false);

  const [openAddtoListModal, setOpenAddtoListModal] = useState(false);

  const [selectedListName, setSelectedListName] = useState(null);
  const [selectThisPage, setSelectThisPage] = useState(false);
  const [selectAllProspect, setSelectAllProspect] = useState(false);

  const user = GetUser();
  const dropdownOptions = [];
  for (let i = 1; i <= Math.ceil(prospectStats?.totalProspects / 20); i++) {
    dropdownOptions.push({ value: i, label: i.toString() });
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted gray",
      color: state.isSelected ? "white" : "gray",
      backgroundColor: state.isSelected ? "#1991EB" : "white",
      padding: 10,
      fontSize: 18,
      textAlign: "center",
      cursor: "pointer",
    }),

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };

  const handleDropdownChange = (selectedOption) => {
    const selectedPage = selectedOption.value;
    setSkipFrom(20 * (selectedPage - 1));
    setActivePage(selectedPage);
  };

  const handleNextClick = () => {
    const totalPages = Math.ceil(prospectStats?.totalProspects / 20);
    const nextPage = activePage + 1 > totalPages ? totalPages : activePage + 1;
    setSkipFrom(20 * (nextPage - 1));

    setActivePage(nextPage);
  };

  const handlePrevClick = () => {
    const prevPage = activePage - 1 < 1 ? 1 : activePage - 1;
    setSkipFrom(20 * (prevPage - 1));
    setActivePage(prevPage);
  };

  const addTosequenceBtn = useRef(null);

  useEffect(() => {
    localStorage.setItem("currentPage", activePage);
    setSkipFrom(20 * (activePage - 1));
  }, [activePage]);

  useEffect(() => {
    setSkipFrom(20 * (activePage - 1));

    setTableLoader(true);

    axios
      .post(
        `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/fetchProspect_v2`,
        {
          userId: user,
          skipFrom: skipFrom,
          perPage: 20,
          query,
        }
      )
      .then((res) => {
        if (res.data.status) {
          setResult(res.data.data);
          setSequenceNameObj(res.data.sequenceNameObj);
          setTotalActiveData(res.data.totalProspects);
        }
      });

    axios
      .get(
        `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/getProspectStats/${user}`
      )
      .then((res) => {
        if (res.data.status) {
          setProspectStats(res.data.data);
          setTableLoader(false);
        }
      });
  }, [
    activePage,
    query,
    setProspectStats,
    skipFrom,
    user,
    refetchProspect,
    fethcDetailsStats,
  ]);

  const handleSearch = useCallback(
    (searchValue) => {
      setTableLoader(true);

      axios
        .post(
          `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/fetchProspect_v2`,
          {
            userId: user,

            query: {
              searchText: searchValue ? searchValue : null,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            setResult(res.data.data);
            // setTotalActiveData(res.data.data.length);
            setTableLoader(false);
            setSequenceNameObj(res.data.sequenceNameObj);
          }
        })
        .catch((error) => {
          console.error("Error fetching prospects:", error);
          setTableLoader(false);
        });
    },
    [user]
  );

  const debouncedSearchValue = useDebounce(search, 1000);

  useEffect(() => {
    handleSearch(debouncedSearchValue);
  }, [debouncedSearchValue, handleSearch]);

  const handleChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };

  // const toggle sequence
  const toggleSeqActive = async (prospectId) => {
    let toggleBtn = document.getElementById(`active-${prospectId}`);
    // console.log(toggleBtn)
    if (toggleBtn.classList?.contains("bg-slate-300")) {
      toggleBtn.classList.remove("bg-slate-300");
      toggleBtn.classList.add("bg-blue-500");
    } else {
      toggleBtn.classList.remove("bg-blue-500");
      toggleBtn.classList.add("bg-slate-300");
    }
    let toggleSwitch = toggleBtn.children[0];
    if (toggleSwitch.classList?.contains("float-right")) {
      toggleSwitch.classList.remove("float-right");
    } else {
      toggleSwitch.classList.add("float-right");
    }

    await axios
      .post(
        `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/activeSequence`,
        {
          userId: user,
          prospectId: prospectId,
        }
      )
      .then((res) => {
        if (!res.data.sequenceExist) {
          // if not connected to sequence
          if (toggleBtn.classList?.contains("bg-slate-300")) {
            toggleBtn.classList.remove("bg-slate-300");
            toggleBtn.classList.add("bg-blue-500");
          } else {
            toggleBtn.classList.remove("bg-blue-500");
            toggleBtn.classList.add("bg-slate-300");
          }
          let toggleSwitch = toggleBtn.children[0];
          if (toggleSwitch.classList?.contains("float-right")) {
            toggleSwitch.classList.remove("float-right");
          } else {
            toggleSwitch.classList.add("float-right");
          }
        }
      });

    await axios
      .get(
        `${properties.REACT_APP_SERVER_URL}/api/v1/prospect/getProspectStats/${user}`
      )
      .then((res) => {
        if (res.data.status) {
          setProspectStats(res.data.data);
        }
      });
  };

  // delete prospects
  const deleteProspects = () => {
    Swal.fire({
      title: "Are you sure?",
      html: `Are you sure you want to delete  <b>${
        selectAllProspect ? "All" : selectedProspects.length
      }</b> prospect`,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          userId: user,
          prospectIds: selectAllProspect ? [] : selectedProspects,
        };

        let res = await deleteProspect(payload);
        if (res.data.success) {
          Swal.fire("Deleted!", "Prospect has been deleted.", "success");
          setRefetchProspect(!refetchProspect);
        }
      }
    });
  };

  // for the dot echlips
  const [showProspectus, setShowProspectus] = useState(
    new Array(result?.length).fill(false)
  );

  // for three dot handle

  const handleDot = (index) => {
    setShowProspectus((prevState) => {
      const newShowProspectus = [...prevState];
      newShowProspectus[index] = !newShowProspectus[index];
      return newShowProspectus;
    });
  };

  // for single prospect select
  const selectProspect = (prospectId) => {
    setSelectedProspects((prevSelectedProspects) => {
      if (prevSelectedProspects.includes(prospectId)) {
        return prevSelectedProspects.filter((id) => id !== prospectId);
      } else {
        return [...prevSelectedProspects, prospectId];
      }
    });
  };

  // falsy linkedin
  const falsyLinkedInUrl = () => {
    toast.error("url not exits");
  };

  // handle select all

  const handleSelectAll = async (event) => {
    if (!event.target.checked) {
      setSelectedProspects([]);
    } else {
      setSelectedProspects(["hii"]);
    }

    if (selectThisPage) {
      setSelectThisPage(false);
      setSelectAllProspect(event.target.checked);
    } else {
      setSelectAllProspect(event.target.checked);
    }

    const checkboxes = document.querySelectorAll(".checkboxall");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });
  };

  // for selecting this page
  const handleSelectThisPage = (event) => {
    if (selectAllProspect) {
      setSelectAllProspect(false);
      setSelectThisPage(event.target.checked);
    } else {
      setSelectThisPage(event.target.checked);
    }

    const checkboxes = document.querySelectorAll(".checkboxall");
    const selectedIds = [];

    checkboxes.forEach((checkbox) => {
      let prospectId = checkbox.getAttribute("id");
      checkbox.checked = event.target.checked;

      if (event.target.checked) {
        selectedIds.push(prospectId);
      }
    });

    setSelectedProspects(selectedIds);
  };
  // mui popover \
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div>
      <div className="bg-white shadow-deep-two relative  p-6 inline-block rounded-[10px] w-full">
        {selectedListName && (
          <div className="mb-4 w-fit">
            <div className="bg-[#E6E6E6]  rounded-[2px] flex items-center">
              <p className="p-[3px] text-[12px] pl-[6px]">
                {selectedListName.label}
              </p>
              <div
                onClick={() => {
                  setSelectedListName(null);
                  setFilterBy(null);
                  setActiveButton(null);
                }}
                className="hover:bg-[#FFBDAD] hover:text-[#DE350B] h-full flex justify-center items-center"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="0"
                  viewBox="0 0 15 15"
                  class="hover:text-[#DE350B] p-[4px]"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        )}
        <div className="flex  relative">
          <div className="w-full gap-4 flex items-center justify-between search bar mb-4">
            <input
              value={search}
              onChange={handleChange}
              className=" w-[60%] relative  input-search"
              type="text"
              placeholder="search name,company name"
            ></input>

            {/* table icon here  */}
            <div className="flex w-[40%] justify-between relative items-center">
              <button
                disabled={selectedProspects.length === 0}
                className={`btn-outline`}
                onClick={() => {
                  setOpenAddtoListModal(true);
                  posthog.capture("Click Add to Sequence");
                }}
              >
                Add to List
              </button>

              <button
                disabled={selectedProspects.length === 0}
                ref={addTosequenceBtn}
                className={`btn-outline`}
                onClick={() => {
                  setSenderEmailModalShow(true);
                  posthog.capture("Click Add to Sequence");
                }}
              >
                Add to Sequences
              </button>

              <button
                disabled={selectedProspects.length === 0}
                className="btn-outline"
                onClick={deleteProspects}
                title="Delete Prospects"
              >
                <FaRegTrashAlt className="text-lg" />
              </button>
            </div>
          </div>
        </div>

        {/* {
          !tableLoader && <h1>{totalActiveData + 'records'}</h1>
        } */}

        <div
          style={{ height: "44rem", overflow: "auto" }}
          className="scrollbar-container"
        >
          {tableLoader ? (
            <Loading whiteLoader={"whiteLoader"}></Loading>
          ) : (
            <table className="w-full scrollbar-container border-separate  overflow-auto">
              <thead className="sticky top-0 z-[99] bg-white">
                <tr>
                  <th className=" text-[#11142D] font-semibold w-[6rem] ">
                    <div className="hover-parent relative inline-block">
                      <div className="flex items-center gap-[1.2rem]">
                        <div
                          aria-describedby={id}
                          className="hover-parent"
                          onClick={handleClickPopover}
                        >
                          <label class="checkbox-container">
                            <input
                              checked={selectThisPage || selectAllProspect}
                              type="checkbox"
                              className="cursor-pointer"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <AiOutlineCaretDown />
                        <h1> Name</h1>
                      </div>

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div className="p-6  rounded-[7px] shadow-deep-two  border-[#ddd]  min-w-[300px]  border border-color-gray">
                          <div className="flex justify-center flex-col">
                            <div className=" flex items-center ">
                              <Checkbox
                                onChange={handleSelectThisPage}
                                id="selectAll"
                                checked={selectThisPage}
                              />
                              <h1 className="py-[7px] font-500 text-[#5d6a7e] px-[14px] text-[15px] w-full  cursor-pointer">
                                Select this page
                              </h1>
                            </div>
                            <div
                              onClick={handleSelectAll}
                              className=" flex items-center "
                            >
                              <Checkbox
                                id="selectAll"
                                checked={selectAllProspect}
                              />
                              <h1 className="py-[7px] font-500  px-[14px] text-[#5d6a7e] text-[15px] w-full  cursor-pointer">
                                Select all{" "}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </Popover>
                    </div>
                  </th>

                  <th className=" text-[#11142D] font-semibold  whitespace-nowrap">
                    Company Name
                  </th>
                  <th className=" text-[#11142D] font-semibold  whitespace-nowrap">
                    Job Title
                  </th>
                  <th className=" text-[#11142D] font-semibold text-center whitespace-nowrap">
                    Sequence Name
                  </th>
                  <th className="text-center w-[14rem] text-[#11142D] font-semibold  whitespace-nowrap">
                    Handles
                  </th>

                  <th className=" text-[#11142D] font-semibold text-center">
                    Status
                  </th>
                </tr>
              </thead>

              <ProspectTable
                setFetchDetailsStats={setFetchDetailsStats}
                setNumberOfSelectedItem={setNumberOfSelectedItem}
                isSelectAll={isSelectAll}
                result={result}
                deleteProspects={deleteProspects}
                handleDot={handleDot}
                toggleSeqActive={toggleSeqActive}
                selectProspect={selectProspect}
                falsyLinkedInUrl={falsyLinkedInUrl}
                sequenceNameObj={sequenceNameObj}
                showProspectus={showProspectus}
              />
            </table>
          )}
        </div>
      </div>

      {/* pagination  */}
      {prospectStats?.totalProspects > 20 && (
        <div className="flex justify-start items-center mt-6">
          <div className="flex items-center border-2 border-border-gray bg-white p-1">
            <div
              disabled={activePage === 1}
              onClick={handlePrevClick}
              className={`cursor-pointer border-r-2 border-border-gray px-1`}
            >
              <GrFormPrevious className="icon-color" />
            </div>
            <div className="pr-3">
              <Select
                id="react-select"
                value={{ value: activePage, label: activePage?.toString() }}
                options={dropdownOptions}
                onChange={handleDropdownChange}
                styles={customStyles}
                menuPlacement="top"
              />
            </div>

            <div
              disabled={activePage === 1}
              onClick={handleNextClick}
              className={`cursor-pointer border-r-2 border-border-gray px-1`}
            >
              <GrFormNext className="icon-color"></GrFormNext>
            </div>
          </div>
        </div>
      )}

      {/* modal  */}
      {activeDeleteModal && (
        <DeleteModal
          filterBy={filterBy}
          filterByStage={filterByStage}
          numberOfSelectedItem={numberOfSelectedItem}
          oneProspect={oneProspect}
          user={user}
          selectedProspects={selectedProspects}
          setactiveDeleteModal={setactiveDeleteModal}
          setLoadData={setLoadData}
          activePage={activePage}
          setNumberOfSelectedItem={setNumberOfSelectedItem}
        ></DeleteModal>
      )}

      {showNumOfAddedSeqModal && (
        <ModalComponenet
          fromProspectLoad={true}
          childrenPadding={"1rem"}
          radious={"10px"}
          title={"Added To Sequence"}
          width={"50vw"}
          setActiveModal={setShowNumOfAddedSeqModal}
        >
          <div className="w-[90%] grid grid-cols-2 gap-8 mx-auto">
            <div>
              <div className=" shadow-sm  rounded-[0.7em] border border-border-gray flex justify-center items-center p-3 h-full">
                <div className="rouned-[0.75em]   font-medium flex flex-col  justify-center items-center rounded-[.7em] p-2">
                  <span className="text-[40px]">
                    {showNumOfAddedSeq.numOfProspectsAddedToSequence}
                  </span>

                  <h1 className=" mt-4">Prospect Added</h1>
                </div>
              </div>
            </div>

            <div>
              <div className=" shadow-sm rounded-[0.7em] border border-border-gray flex justify-center items-center p-3">
                <div className=" rouned-[0.75em]   font-medium  flex-col flex justify-center items-center rounded-[.7em] p-2">
                  <span className="text-[40px]">
                    {showNumOfAddedSeq.numOfProspectsNotAddedToSequence}
                  </span>

                  <h1 className=" mt-4">Prospect Not Added</h1>
                </div>
              </div>
            </div>
          </div>
        </ModalComponenet>
      )}

      {senderEmailModalShow && (
        <ProspectSenderEmailModal
          selectAllProspect={selectAllProspect}
          setShowNumOfAddedSeqModal={setShowNumOfAddedSeqModal}
          setShowNumOfAddedSeq={setShowNumOfAddedSeq}
          setNumberOfSelectedItem={setNumberOfSelectedItem}
          filterBy={filterBy}
          filterByStage={filterByStage}
          setLoadData={setLoadData}
          numberOfSelectedItem={numberOfSelectedItem}
          activePage={activePage}
          setRefetch={setRefetch}
          selectedProspects={selectedProspects}
          setSenderEmailModalShow={setSenderEmailModalShow}
        ></ProspectSenderEmailModal>
      )}

      {/* filter modal for list name  */}
      {openListModal && (
        <ListNameModal
          setActivePage={setActivePage}
          setSelectedListName={setSelectedListName}
          setActiveButton={setActiveButton}
          setFilterBy={setFilterBy}
        />
      )}

      {/* add to list email list  */}
      {openAddtoListModal && (
        <AddToListModal
          selectedProspects={selectedProspects}
          setOpenAddtoListModal={setOpenAddtoListModal}
        />
      )}
    </div>
  );
};

export default ShowProspectData;
