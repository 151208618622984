import React from "react";
import {
  EditorState,
  convertToRaw,
  Modifier,
  convertFromRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UseHeight from "../Hooks/UseHeight";
import PersonalizeComponent from "../Pages/Dashboard/PersonalizeComponent/PersonalizeComponent";

import "simplebar-react/dist/simplebar.min.css";
import { emoji } from "../Utils/emoji";
import colorIcon from "../Images/AI/color-icons-12540.png";
const DraftEditor = ({
  setIsPersonalisePopupOpen,
  templateBtn,
  personalizeBtn,
  setSubject,
  setDetectFocus,
  subjectinputRef,
  isPopupOpenTemplate,
  templates,
  isPersonalisePopupOpen,
  editorState,
  setEditorState,
  setPreviewEditorState,
  setIsPopupOpenTemplate,
}) => {
  const toolbar = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "link",
      "colorPicker",
      "emoji",
      "image",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
    fontSize: {
      className: "font-size",
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72,
      ],
      defaultValue: 16,
    },

    fontFamily: {
      className: "font-family",
      options: ["Arial", "Helvetica", "Georgia", "Times New Roman", "Verdana"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    textAlign: {
      options: ["left", "center", "right", "justify"],
    },
    link: {
      options: ["link", "unlink"],
    },
    emoji: {
      className: "w-[20px] h-[20px]",
      emojis: emoji,
    },

    image: {
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "70px",
        width: "300",
      },
      resize: true, // add this line to enable resizing
    },

    colorPicker: {
      icon: colorIcon,
      className: "colorPickerIcon",
      colors: [
        "rgb(97,189,109)",
        "rgb(26,188,156)",
        "rgb(84,172,210)",
        "rgb(44,130,201)",
        "rgb(147,101,184)",
        "rgb(71,85,119)",
        "rgb(204,204,204)",
        "rgb(65,168,95)",
        "rgb(0,168,133)",
        "rgb(61,142,185)",
        "rgb(41,105,176)",
        "rgb(85,57,130)",
        "rgb(40,50,78)",
        "rgb(0,0,0)",
        "rgb(247,218,100)",
        "rgb(251,160,38)",
        "rgb(235,107,86)",
        "rgb(226,80,65)",
        "rgb(163,143,132)",
        "rgb(239,239,239)",
        "rgb(255,255,255)",
        "rgb(250,197,28)",
        "rgb(243,121,52)",
        "rgb(209,72,65)",
        "rgb(184,49,47)",
        "rgb(124,112,107)",
        "rgb(209,213,216)",
      ],
    },
    history: {
      options: ["undo", "redo"],
    },
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const newContentState = convertFromRaw(rawContentState);
    if (setPreviewEditorState) {
      setPreviewEditorState(EditorState.createWithContent(newContentState));
    }
  };
  const heightFromhook = UseHeight();

  function getHeightClassName(height) {
    if (height < 700) {
      return "18rem";
    } else if (height <= 827) {
      return "26rem";
    } else if (height >= 828) {
      return "28rem";
    }
  }
  const handleInsertVariable = (variable) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const ncs = Modifier.insertText(contentState, selectionState, variable);

    const newEditorState = EditorState.push(
      editorState,
      ncs,
      "insert-characters"
    );
    setEditorState(newEditorState);
    setIsPersonalisePopupOpen(false);
  };

  const handleInsertTemplate = (template) => {
    console.log(template);
    // Convert HTML to Draft.js ContentState
    const contentState = convertFromHTML(template?.templateBody);

    // Create new EditorState with converted ContentState
    const editorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        contentState.contentBlocks,
        contentState.entityMap
      )
    );
    setPreviewEditorState(editorState);
    // Set the new EditorState as the editor's content
    setEditorState(editorState);

    setSubject(template?.subject);
    subjectinputRef.current.value = template?.subject;
    setIsPopupOpenTemplate(false);
  };

  const handlePastedText = (text, html, editorState) => {
    if (html) {
      // Create a temporary div element to extract the pasted content
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;

      // Iterate over all img tags in the pasted content
      const imgTags = tempDiv.getElementsByTagName("img");
      for (let i = 0; i < imgTags.length; i++) {
        const imgTag = imgTags[i];
        const alt = imgTag.getAttribute("alt");
        const emojiCharacter = alt ? alt.trim() : "";

        // Replace the img tag with the corresponding emoji character
        const emojiTextNode = document.createTextNode(emojiCharacter);
        imgTag.parentNode.replaceChild(emojiTextNode, imgTag);
      }

      // Get the sanitized text from the modified temporary div
      const sanitizedText = tempDiv.textContent || tempDiv.innerText;

      // Create a new EditorState with the sanitized text
      const contentState = ContentState.createFromText(sanitizedText.trim());
      const newEditorState = EditorState.createWithContent(contentState);

      // Return the new EditorState
      return newEditorState;
    }

    // If there is no HTML content, proceed with the original logic
    const sanitizedText = text.replace(/<(?!\s*br\s*\/?)[^>]+>/gi, "");
    const contentState = convertFromRaw({
      entityMap: {},
      blocks: [
        {
          text: sanitizedText.trim(),
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    });
    const newEditorState = EditorState.createWithContent(contentState);

    return newEditorState;
  };

  const handleEditorFocus = () => {
    setDetectFocus(true);
  };

  return (
    <div>
      {isPopupOpenTemplate && (
        <div
          ref={templateBtn}
          className="w-[16rem]  right-2 shadow-personalize z-40 border-2 rounded-[6px] border-[#d3dae3] bottom-0 h-[15rem] bg-white absolute"
        >
          {templates?.length === 0 ? (
            <h1 className="p-2">No template avaiable</h1>
          ) : (
            <div className="div">
              <ul className="">
                {templates.map((template) => (
                  <li
                    onClick={() => handleInsertTemplate(template)}
                    className="p-2 cursor-pointer color-blue text-[14px] hover:bg-gray-200 transition duration-200 font-semibold "
                  >
                    {template?.templateName}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <PersonalizeComponent
        isPersonalisePopupOpen={isPersonalisePopupOpen}
        personalizeBtn={personalizeBtn}
        handleInsertVariable={handleInsertVariable}
      />

      <div className="draft-editor">
        <Editor
          onFocus={handleEditorFocus}
          handlePastedText={handlePastedText}
          toolbar={toolbar}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          editorStyle={{ minHeight: getHeightClassName(heightFromhook) }}
        />
      </div>
    </div>
  );
};

export default DraftEditor;
